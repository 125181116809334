import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classes from "./MyCompany.module.scss";
import { Link, useHistory } from "react-router-dom";
import CompanyInventory from "../../../components/CompanyInventory/CompanyInventory";
import CompanyActivity from "../../../components/CompanyActivity/CompanyActivity";
import CompanyUsers from "../../../components/CompanyUsers/CompanyUsers";
import CompanyInvites from "../../../components/CompanyInvites/CompanyInvites";
import useHttp from "../../../hooks/use-http";
import { API_COMPANIES_URL } from "../../../constants/API";
import { getAccessToken } from "../../../utils/authentication";
import ConfirmModal from "../../../components/UI/ConfirmModal/ConfirmModal";
import { addToast } from "../../../redux/Toast/toastAction";
import { createRandomId } from "../../../utils/common";
import { updateIsCompanyChanged } from "../../../redux/Company/companyAction";
import {updateIsSubscriptionChange} from "../../../redux/User/userAction";

const MyCompany = () => {
  const userCompany = useSelector((state) => state.user.userCompany);
  const userRole = useSelector((state) => state.user.userRole);
  const userSubscription = useSelector((state) => state.user.userSubscription);
  const [companyAssets, setCompanyAssets] = useState(null);
  const [companyParticipants, setCompanyParticipants] = useState([]);
  const [joinRequestCompany, setJoinRequestCompany] = useState([]);
  const [invitedUsersList, setInvitedUsersList] = useState([]);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [modalButtonText, setModalButtonText] = useState(null);
  const [modalHeader, setmodalHeader] = useState(null);
  const [userId, setUserId] = useState(null);
  const { sendRequest, error } = useHttp();
  const dispatch = useDispatch();
  let history = useHistory();

  const getCompanyAssets = (data) => {
    console.log(data, "getCompanyAssets");
    setCompanyAssets(data);
  };

  const getCompanyParticipants = (data) => {
    console.log(data);
    setCompanyParticipants(data);
  };

  const getJoinRequestCompany = (data) => {
    console.log(data);
    setJoinRequestCompany(data);
  };

  const getUserActionResponse = (data) => {
    console.log(data);
    setUserId(null);
    switch (modalButtonText) {
      case "Grant Access":
        fetchJoinRequestsCompany();
        fetchCompanyParticipants();
        break;
      case "Deny User":
        fetchJoinRequestsCompany();
        break;
      case "Deactive":
        dispatch(updateIsCompanyChanged(true));
        history.push("/");
        break;
      case "Cancel Invitation":
        fetchInviteUserList();
        dispatch(updateIsSubscriptionChange(true));
        break;
      default:
        fetchCompanyParticipants();
        break;
    }
  };

  const confirmUserAction = (str) => {
    console.log(str);
    const token = getAccessToken();
    if (str === "confirm") {
      let url;
      if (modalButtonText === "Make Admin") {
        url = `${API_COMPANIES_URL}/company/${userCompany.id}/promote-admin/`;
      } else if (modalButtonText === "Remove Admin") {
        url = `${API_COMPANIES_URL}/company/${userCompany.id}/demote-admin/`;
      } else if (modalButtonText === "Delete User") {
        url = `${API_COMPANIES_URL}/delete-user/${userId}`;
      } else if (modalButtonText === "Grant Access") {
        url = `${API_COMPANIES_URL}/company/${userCompany.id}/grant/`;
      } else if (modalButtonText === "Deny User") {
        url = `${API_COMPANIES_URL}/company/${userCompany.id}/deny/`;
      } else if (modalButtonText === "Cancel Invitation") {
        url = `${API_COMPANIES_URL}/invitation/${userId}`;
      } else if (modalButtonText === "Deactive") {
        url = `${API_COMPANIES_URL}/company/deactivate/`;
      }
      sendRequest(
        {
          url: url,
          method:
            modalButtonText === "Delete User" ||
            modalButtonText === "Cancel Invitation"
              ? "DELETE"
              : "POST",
          body:
            modalButtonText === "Grant Access" ||
            modalButtonText === "Deny User"
              ? { access_request: userId }
              : modalButtonText === "Deactive"
              ? { slug: userCompany.slug, title: userCompany.title }
              : { user: userId },
          headers: { Authorization: `Bearer ${token}` },
        },
        getUserActionResponse
      );
    }
    setModalType(null);
    setModalButtonText(null);
    setModalMessage(null);
    setmodalHeader(null);
  };

  const getInviteUserToCompanyRes = (data) => {
    console.log(data);
    dispatch(
      addToast({
        id: createRandomId(),
        status: "success",
        description: "Your invitation was successfully send out.",
      })
    );
    dispatch(updateIsSubscriptionChange(true));
    fetchInviteUserList();
  };

  const getInviteUserList = (data) => {
    console.log("invite", data);
    setInvitedUsersList(data);
  };

  const fetchComapayAssets = () => {
    const token = getAccessToken();
    sendRequest(
      {
        url: `${API_COMPANIES_URL}/company/${userCompany.id}/assets/`,
        headers: { Authorization: `Bearer ${token}` },
      },
      getCompanyAssets
    );
  };

  const fetchCompanyParticipants = () => {
    const token = getAccessToken();
    sendRequest(
      {
        url: `${API_COMPANIES_URL}/company/${userCompany.id}/participants/`,
        headers: { Authorization: `Bearer ${token}` },
      },
      getCompanyParticipants
    );
  };

  const fetchJoinRequestsCompany = () => {
    const token = getAccessToken();
    sendRequest(
      {
        url: `${API_COMPANIES_URL}/company/${userCompany.id}/access/`,
        headers: { Authorization: `Bearer ${token}` },
      },
      getJoinRequestCompany
    );
  };

  const fetchInviteUserList = () => {
    const token = getAccessToken();
    sendRequest(
      {
        url: `${API_COMPANIES_URL}/company/${userCompany.id}/invite-user/`,
        headers: { Authorization: `Bearer ${token}` },
      },
      getInviteUserList
    );
  };

  const inviteUserToCompany = (email) => {
    console.log(email);
    const token = getAccessToken();
    sendRequest(
      {
        url: `${API_COMPANIES_URL}/company/${userCompany.id}/invite-user/`,
        method: "POST",
        body: { email: email },
        headers: { Authorization: `Bearer ${token}` },
      },
      getInviteUserToCompanyRes
    );
  };

  const promoteUserToAdmin = (userId) => {
    console.log(userId);
    setUserId(userId);
    setModalType("success");
    setModalButtonText("Make Admin");
    setModalMessage("Do you confirm to make admin?");
    setmodalHeader("Make Admin");
  };

  const removeUserFromAdmin = (userId) => {
    console.log(userId);
    setUserId(userId);
    setModalType("warning");
    setModalButtonText("Remove Admin");
    setModalMessage("Do you confirm to remove admin?");
    setmodalHeader("Remove Admin");
  };

  const deleteUser = (userId) => {
    console.log(userId);
    setUserId(userId);
    setModalType("warning");
    setModalButtonText("Delete User");
    setModalMessage("Do you confirm to delete user?");
    setmodalHeader("Delete User");
  };

  const grantAccessUser = (userId) => {
    console.log(userId);
    setUserId(userId);
    setModalType("success");
    setModalButtonText("Grant Access");
    setModalMessage("Do you confirm to Grant Access?");
    setmodalHeader("Grant Access");
  };

  const denyUser = (userId) => {
    console.log(userId);
    setUserId(userId);
    setModalType("warning");
    setModalButtonText("Deny User");
    setModalMessage("Do you confirm to Deny user?");
    setmodalHeader("Deny User");
  
  };

  const deleteInvitation = (inviteId) => {
    console.log(inviteId);
    setUserId(inviteId);
    setModalType("warning");
    setModalButtonText("Cancel Invitation");
    setModalMessage("Do you confirm to Cancel Invitation?");
    setmodalHeader("Cancel Invitation");
  };

  const deactivateCompany = () => {
    setModalType("warning");
    setModalButtonText("Deactive");
    setModalMessage(
      "Are you sure to deactivate your company profile and remove all its assets?"
    );
    setmodalHeader("Deactive");
  };

  useEffect(() => {
    // company , freelancer
    if (userCompany) {
      fetchComapayAssets();
    }
    // only company who is admin
    if (userCompany && userCompany.is_admin) {
      fetchJoinRequestsCompany();
      fetchInviteUserList();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCompany]);

  useEffect(() => {
    // memeber of company : admin or not admin
    if (userRole === "company") {
      fetchCompanyParticipants();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);
  
  useEffect(() => {
    console.log(error);
    if (error && error.status === 400) {
      dispatch(
        addToast({
          id: createRandomId(),
          status: "error",
          description: error.data.errors[0]
            ? error.data.errors[0]
            : error.data.errors["non_field_errors"],
        })
      );
    }
  }, [dispatch, error]);

  return (
    <div className={`container ${classes["my-company"]}`}>
      {modalMessage && (
        <ConfirmModal
          modalButtonText={modalButtonText}
          modalType={modalType}
          modalText={modalMessage}
          modalHeader={modalHeader}
          onConfirm={confirmUserAction}
        />
      )}
      <div className="row align-items-center mb-50">
        {userCompany && userRole === "company" && (
          <div  className=" col-xl-2 col-lg-6 col-md-6 col-12">
            <div
              style={{ backgroundImage: `url(${userCompany.logo})` }}
              className={classes["company-logo"]}
            ></div>
          </div>
        )}
        <div className=" col-xl-4 col-lg-6 col-md-6 col-12 text-md-start text-center">
          {userCompany && (
            <span className={classes["company-name"]}>{userCompany.title}</span>
          )}
          

          {userCompany && (
            <div className={classes["company-address"]}>
              {userCompany.address}
            </div>
          )}
        </div>
        <div
          className={`${
            userRole === "company" ? "col-xl-6 col-lg-12 col-md-12" : "col-xl-4 col-lg-12"
          } col-12 text-xl-end text-center mt-xl-0 mt-5`}
        >         
          {userCompany && (
            <Link
              className={`${classes["view-profile"]} `}
              to={`/company/${userCompany.id}`}
            >
              View Public Profile
            </Link>
          )}
           {userRole === "company" && userCompany.is_admin && (
            <Link className={classes["edit-company"]} to="/dashboard/my-company/edit">
              Edit Company
            </Link>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-5">
          <CompanyInventory
            userSubscription={userSubscription}
            userCompany={userCompany}
          />
        </div>
        <div className="col-lg-7">
          <CompanyActivity companyAssets={companyAssets} />
        </div>
        {userRole === "company" && (
          <Fragment>
            <div className="col-12">
              <CompanyUsers
                joinRequestCompany={joinRequestCompany}
                companyParticipants={companyParticipants}
                onPromoteUserToAdmin={promoteUserToAdmin}
                onRemoveUserFromAdmin={removeUserFromAdmin}
                onDeleteUser={deleteUser}
                onGrantAccessUser={grantAccessUser}
                onDenyUser={denyUser}
                onDeactiveCompany={deactivateCompany}
                isAdmin={userCompany.is_admin}
              />
            </div>
            {userCompany.is_admin && (
              <div className="col-12">
                <CompanyInvites
                  invitedUsersList={invitedUsersList}
                  userSubscription={userSubscription}
                  onInviteUserToCompany={inviteUserToCompany}
                  onDeleteInvitation={deleteInvitation}
                />
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default MyCompany;
