import classes from "./ServiceCategoryCarouselItem.module.scss";

const ServiceCategoryCarouselItem = ({item, isSelected, onSelect}) => {
    return (
        <div className={`${classes['carousel-item-container']}`}>
            <div className={`${classes['carousel-item']} ${isSelected ? classes.selected : ''}`} onClick={onSelect}>
                <div className={classes["carousel-item__image"]}>

                    <img src={item.icon} alt="Service Category"/>
                </div>
                <p className={classes['item-title']}>
                    {item.title}
                </p>
                <div className={classes['left-bottom']}></div>
                <div className={classes['right-bottom']}></div>
            </div>
        </div>
    );
};

export default ServiceCategoryCarouselItem;
