import { Fragment } from "react";
import { useSelector } from "react-redux";
import classes from "./RequestViewSummary.module.scss";
import CardV2 from "../UI/CardV2/CardV2";
import defaultLogo from "../../assets/images/placeholder.svg";
import NoData from "../UI/NoData/NoData";
import { REQUEST_STATUS } from "../../constants/RequestStatus";
import { dateFormmater } from "../../utils/common";
import { Link } from "react-router-dom";
import ExtendDueDtae from "../ExtendDueDate/ExtendDueDtae";
import anonLogo from "../../assets/images/logo/anon-request.svg";

const RequesViewSummary = (props) => {
  const {
    requestInfo,
    applicationStatus,
    onConfirmDelete,
    isApplicationView,
    isRequestApplication,
    isSentApplication,
    onSendApplicationHandler,
    userSubscription,
    applicationSender,
    isCreateApplication,
    activeFlag,
    isApplicationOwner
  } = props;
  const { userCompany, isLoggedIn } = useSelector((state) => state.user);
  let srcLogo = requestInfo.is_anonymous ? anonLogo : requestInfo.company_logo
    ? requestInfo.company_logo
    :  defaultLogo;
  let status = '';
  if (requestInfo)
    status = REQUEST_STATUS.find(
      (element) => element.id === requestInfo.status
    );
  return (
    <Fragment>
      {requestInfo && (
        <CardV2 className={classes.card}>
          <div className={classes["header-card"]}>
            <div
              style={{ backgroundImage: `url(${srcLogo})` }}
              className={classes["company-logo"]}
            ></div>
            <div className={classes["company-status"]}>
              <span style={{ color: status.color, backgroundColor: status.backgroundColor }}> {status.cardTitle}</span>
              <span>{dateFormmater(requestInfo.created_at)}</span>
            </div>
          </div>
          <div className={classes.title}>
            {requestInfo.is_anonymous ? "" : <span>{requestInfo.company_title}</span>}
          </div>
          <div className={classes.desc}>{requestInfo.title}</div>
          <div className={classes["card-info"]}>
            <div className={classes.item}>
              <span className={classes.key}>Due Date:&nbsp;</span>
              {requestInfo.due_date ? (
                <span className={classes.value}>
                  {dateFormmater(requestInfo.due_date)}
                </span>
              ) : (
                <NoData className={classes["no-data"]} />
              )}
              {requestInfo.can_extend_due_date &&
                <ExtendDueDtae
                  previewdValue={requestInfo.due_date}
                  requestId={requestInfo.id}
                />
              }

            </div>
            {/* <div className={classes.item}>
              <span className={classes.key}>Project Size:&nbsp;</span>
              {requestInfo.request_size_data.title ? (
                <span className={classes.value}>
                  {requestInfo.request_size_data.title}
                </span>
              ) : (
                <NoData className={classes["no-data"]} />
              )}
            </div>
            <div className={classes.item}>
              <span className={classes.key}>Region(s):&nbsp;</span>
              {requestInfo.regions_data.length > 0 ? (
                requestInfo.regions_data.map((region) => (
                  <span key={region.id} className={classes.region}>
                    {region.title}
                  </span>
                ))
              ) : (
                <NoData className={classes["no-data"]} />
              )}
            </div> */}
          </div>
          {isCreateApplication && (
            <Fragment>
              <div className={classes.buttons}>
                <button
                  onClick={() => onSendApplicationHandler("create application")}
                  className={`${classes["send-button"]} btn`}
                  disabled={activeFlag}
                >
                  Send Application
                </button>

                {userSubscription && userSubscription.is_active && (
                  <span className={classes["app-remain"]}>
                    { userSubscription.remaining_applications > 100 && (
                      <span>You Have Unlimited Applications</span>
                    )}
                    { userSubscription.remaining_applications <= 100 && (
                      <span>
                        <strong>{userSubscription.remaining_applications}</strong>{" "}
                        Application(s) Remains
                      </span>
                    )}
                  </span>
                )}
              </div>
            </Fragment>
          )}
          {isSentApplication && (
            <Fragment>
              <div className={classes.buttons}>
                <Link
                  to={`/request/${requestInfo.id}`}
                  className={`${classes["show-request-button"]} btn`}
                >
                  Show Request Detail
                </Link>
              </div>
              {applicationStatus === "waiting" && isApplicationOwner && (
                <div className="text-center">
                  <button
                    onClick={onConfirmDelete}
                    className={`${classes["delete-app-button"]} btn`}
                  >
                    Delete Application
                  </button>
                </div>
              )}
            </Fragment>
          )}
          {isRequestApplication && (
            <Fragment>
              <div className={classes["recieved-app"]}>
                {requestInfo.received_applications_count} Applications recieved
              </div>
              <div className={classes.buttons}>
                <Link
                  to={`/request/${requestInfo.id}`}
                  className={`${classes["show-request-button"]} btn`}
                >
                  View Details
                </Link>
              </div>
              {(requestInfo.status === 3 || requestInfo.status === 4) && (
                <div className="text-center">
                  <button
                    onClick={onConfirmDelete}
                    className={`${classes["delete-app-button"]} btn`}
                  >
                    Close Request
                  </button>
                </div>
              )}
              {userCompany && !userCompany.is_admin && (
                <p className={classes["request-message"]}>
                  Great! This Request belongs to another User of your Company. Soon
                  the best Service Provider will be identified by your colleagues
                </p>
              )}
            </Fragment>
          )}

          {(isApplicationView ||
            (userCompany &&
              userCompany.id === requestInfo.company &&
              !isRequestApplication)) && (
              <Fragment>
                <div className={classes["recieved-app"]}>
                  {requestInfo.received_applications_count} Applications recieved
                </div>
                <div className={classes.buttons}>
                  <Link
                    to={`/request-applications/${requestInfo.id}`}
                    className={`${classes["show-request-button"]} btn`}
                  >
                    Receieved Applications
                  </Link>
                </div>
                {(requestInfo.status === 3 || requestInfo.status === 4) && (
                  <div className="text-center">
                    <button
                      onClick={onConfirmDelete}
                      className={`${classes["delete-app-button"]} btn`}
                    >
                      Close Request
                    </button>
                  </div>
                )}
                {userCompany && !userCompany.is_admin && (
                  <p className={classes["request-message"]}>
                    Great! This Request belongs to another User of your Company. Soon
                    the best Service Provider will be identified by your colleagues
                  </p>
                )}
              </Fragment>
            )}
          {(requestInfo.status === 3 || requestInfo.status === 4) && !isLoggedIn && !isCreateApplication && (
            <div className={classes.buttons}>
              <button
                onClick={() => onSendApplicationHandler("no login")}
                className={`${classes["send-button"]} btn`}
              >
                Send Application
              </button>
            </div>
          )}
          {(requestInfo.status === 3 || requestInfo.status === 4) && !isCreateApplication &&
            isLoggedIn &&
            !userCompany && (
              <div className={classes.buttons}>
                <button
                  onClick={() => onSendApplicationHandler("no company")}
                  className={`${classes["send-button"]} btn`}
                >
                  Send Application
                </button>
              </div>
            )}
          {(requestInfo.status === 3 || requestInfo.status === 4) && !isCreateApplication &&
            isLoggedIn &&
            userCompany &&
            userCompany.id !== requestInfo.company &&
            userCompany.id !== applicationSender &&
            requestInfo.can_user_apply &&
            (
              <div className={classes.buttons}>
                <button
                  onClick={() => onSendApplicationHandler("has company")}
                  className={`${classes["send-button"]} btn`}
                >
                  Send Application
                </button>
                {userSubscription && userSubscription.is_active && (
                  <span className={classes["app-remain"]}>
                    { userSubscription.remaining_applications > 100 && (
                      <span>You Have Unlimited Applications</span>
                    )}
                    { userSubscription.remaining_applications <= 100 && (
                      <span>
                        <strong>{userSubscription.remaining_applications}</strong>{" "}
                        Application(s) Remains
                      </span>
                    )}
                  </span>
                )}
              </div>
            )}
        </CardV2>
      )}
    </Fragment>

  );
};
export default RequesViewSummary;
