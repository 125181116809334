import { useState } from "react";
import { Link } from "react-router-dom";
import classes from "./DetailPageViewCard.module.scss";
import downloadIcon from "../../../assets/images/Icons/download.svg";
import Card from "../CardV2RequestDetail/CardV2RequestDetail";
import NoData from "../NoData/NoData";
import certificateIcon from '../../../assets/images/Icons/certificate-outline.svg';
import ShowMoreText from "react-show-more-text";
import { Fragment } from "react";
import { useEffect } from "react";

const DetailPageViewCard = (props) => {
  const {
    backgroundTitle,
    backgroundItem,
    title,
    desc,
    serviceCategories,
    keyServices,
    specialities,
    pageViewItem,
    certificates,
  } = props;

  const [maxHeight, setMaxHeight] = useState(true);
  const [activeShowMore, setActiveShowMore] = useState(true);
  const [maxKeyService, setMaxKeyService] = useState(0);
  //-------------------------------------------------------------------------------
  let description =
    "desc" in props && desc !== null ? (
      <p className={classes.desc}>{desc}</p>
    ) : "desc" in props && desc == null ? (
      // <NoData className={classes.item} />
      ''
    ) : (
      ""
    );
  //-------------------------------------------------------------------------------
  let srvCtgs =
    "serviceCategories" in props && serviceCategories.length > 0 ? (
      serviceCategories.map((serviceCategory) => (
        <Link
          to={`/${pageViewItem}?page=1&service_categories=${serviceCategory.id}`}
          key={serviceCategory.id}
          className={`${classes.item} ${backgroundItem}`}
        >
          {serviceCategory.title}
        </Link>
      ))
    ) : "serviceCategories" in props && serviceCategories.length === 0 ? (
      <NoData className={classes.item} />
    ) : (
      ""
    );
  //-------------------------------------------------------------------------------
  let keySrvs =
    "keyServices" in props && keyServices.length > 0 ? (
      // keyServices.map((keyService) => (
      //   <Link
      //     to={`/${pageViewItem}?page=1&key_services=${keyService.id}`}
      //     key={keyService.id}
      //     className={`${classes.item} ${backgroundItem}`}
      //   >
      //     {keyService.title}
      //   </Link>
      // ))
      <Fragment>
        {maxKeyService !== 0 && (
          <Fragment>
            {keyServices.slice(0, maxKeyService).map((keyService) => (
              <Link
                to={`/request?page=1&key_services=${keyService.id}`}
                key={keyService.id}
                className={`${classes.item} ${backgroundItem}`}
              >
                {keyService.title}
              </Link>
            ))}
            <div
              className={classes["show-more"]}
              onClick={() => { setMaxKeyService(0) }}
            >
              +{keyServices.length - 6}  more
            </div>
          </Fragment>
        )}
        {maxKeyService == 0 && (
          keyServices.map((keyService) => (
            <Link
              to={`/request?page=1&key_services=${keyService.id}`}
              key={keyService.id}
              className={`${classes.item} ${backgroundItem}`}
            >
              {keyService.title}
            </Link>
          ))
        )}
      </Fragment>
    ) : "keyServices" in props && keyServices.length === 0 ? (
      <NoData className={classes.item} />
    ) : (
      ""
    );
  //-------------------------------------------------------------------------------
  let spclts =
    "specialities" in props && specialities.length > 0 ? (
      specialities.map((specialitie) => (
        <Link
          to={`/${pageViewItem}?page=1&specialities=${specialitie.id}`}
          key={specialitie.id}
          className={`${classes.item} ${backgroundItem}`}
        >
          {specialitie.title}
        </Link>
      ))
    ) : "specialities" in props && specialities.length === 0 ? (
      <NoData className={classes.item} />
    ) : (
      ""
    );
  //-------------------------------------------------------------------------------
  let certfcte =
    "certificates" in props && certificates.length > 0 ? (
      certificates.map((certificate) => (

        <a
          key={certificate}
          href={certificate}
          target="_blank"
          rel="no noreferrer"
        >
          <div className={classes['certificate-btn']}>
            <img src={certificateIcon} />
            <div>Customer Life Science...</div>
          </div>
        </a>
      ))
    ) : "certificates" in props && certificates.length === 0 ? (
      <NoData className={classes.item} />
    ) : (
      ""
    );

  useEffect(() => {
    if (keyServices?.length > 6)
      setMaxKeyService(6);

  }, [keyServices]);
  useEffect(() => {
    const height = document.getElementById('div-desc')?.clientHeight;
    if (height && height < 100)
      setActiveShowMore(false);
    console.log('height:', height);
  }, []);

  return (
    <div className={classes.parent}>
      <div className={classes.card}>
        <div className={`${classes.title}`}>
          <span>{title}</span>
        </div>
        {desc && (
          <Fragment>

            <div
              id='div-desc'
              className={`${classes.body} ${classes.description}`}
              style={{ maxHeight: maxHeight ? '100px' : 'none' }}
              dangerouslySetInnerHTML={{ __html: desc }}
            >
            </div>



            {activeShowMore && (
              <div
                className={classes['show-more']}
                onClick={() => setMaxHeight(!maxHeight)}
              >
                {maxHeight && ('Show more')}
                {!maxHeight && ('Show less')}
              </div>
            )}

          </Fragment>
        )}
        {!desc && (
          <div
            className={classes.body}>
            {srvCtgs}
            {keySrvs}
            {spclts}
            {certfcte}
          </div>
        )}

      </div>
    </div>
  );
};

export default DetailPageViewCard;
