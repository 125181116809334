import classes from "./DebouncedSearchInput.module.scss";
import Card from "../UI/Card/Card";
import Loading from "../UI/Loading/Loading";

const DebouncedSearchInput = ({
  input,
  onDebouncedSearch,
  isDisplay,
  isLoading,
  isSubmited,
  register,
  resultList,
  selectItemHandler,
}) => {
  console.log(isDisplay);
  const searchHandler = (e) => {
    onDebouncedSearch(e.target.value);
  };

  const selectItem = (comapny) => {
    selectItemHandler(comapny);
  };

  return (
    <div className={`${classes["single-input-box"]}`}>
      <input
        className={classes["single-input"]}
        {...input}
        {...register(input.name)}
        type="text"
        autoComplete="off"
        id="companyName"
        placeholder="company Name"
        onChange={searchHandler}
      />
      <label htmlFor="companyName" className={classes["single-input-label"]}>
        {input.label}
      </label>

      <div className="col-12 position-relative">
        {isDisplay && (
          <Card className={classes["result-box"]}>
            <ul className={classes["result-list"]}>
              {resultList.map((company) => (
                <li
                  onClick={() => selectItem(company)}
                  key={company.id}
                  className={classes["result-item"]}
                >
                  {company.title}
                </li>
              ))}
            </ul>
            {(isLoading && !isSubmited) && <Loading className={classes.loading} />}
          </Card>
        )}
        
      </div>
    </div>
  );
};

export default DebouncedSearchInput;
