import React from 'react';
import classes from './CookieBanner.scss';
import CookieConsent, { Cookies } from "react-cookie-consent";

const CookieBanner = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="OK"
            cookieName="myAwesomeCookieName2"
            containerClasses="cookie-banner"
            contentClasses="cookie-content"
            style={{ background: "#2B373B", fontFamily: "HelveticaNeue", justifyContent: "center" }}
            overlayStyle={{background: "red",flex: "none" }}
            contentStyle={{flex: "none"}}
            buttonStyle={{ backgroundColor:"rgba(33, 188, 231)", color: "white", fontSize: "13px", felx: "1", borderRadius:"4px", paddingTop:"8px" }}
            expires={150}
        >
            We use cookies to ensure that we give you the best experience on our website.
             If you continue to use this site we will assume that you are happy with it.
             <a href="/privacy-policy" className='privacy-btn'>
                Privacy Policy
             </a>
        </CookieConsent>
    );
};

export default CookieBanner;