import React from "react";
import { Link } from "react-router-dom";

import classes from "./NotFound.module.scss";

import leftImage from "../../assets/images/404-left.svg";
import rightImage from "../../assets/images/404-right.svg";

const NotFound = () => {
  return (
    <div className={`container`}>
      <div className={`row ${classes["not-found-wrapper"]}`}>
        <div
          className={`col-lg-6 col-md-12 col-sm-12 ${classes["not-found-left"]}`}
        >
          <img src={leftImage} alt="Not Found" />
          <div className="d-flex flex-column align-items-center text-center">
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <h3>
              We’re sorry,the page you requested could not be found Please go
              back to the homepage
            </h3>
            <Link to={"/"} replace className="btn">
              {" "}
              GO Home
            </Link>
          </div>
        </div>
        <div
          className={`d-none d-lg-flex col-6 justify-content-end ${classes["not-found-right"]}`}
        >
          <img src={rightImage} alt="Not Found 2" />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
