import {Link} from "react-router-dom";
import classes from "./FeaturedBlogItem.module.scss";
import Card from "../UI/CardV2/CardV2";
import pharmablogLogo from "../../assets/images/pharmatching-news.png";
import {dateFormmater} from "../../utils/common";

const FeaturedBlogItem = (props) => {
    const {blog, className} = props;
    return (
        <div className={className}>
            <Link to={`/blogs/${blog.id}`}>
                <Card
                    className={`row justify-content-lg-start justify-content-center  align-items-center ${classes.card}`}>
                    <div className={`col-lg-4 col-md-4 col-4 ${classes["blog-box"]}`}>
                        <div style={{backgroundImage: `url(${blog.image ? blog.image : pharmablogLogo})`}}
                             className={classes["blog-image"]}></div>
                    </div>
                    <div className={`col-lg-8 col-md-8 col-8 ${classes["blog-details"]}`}>
                        <div className={classes["blog-header-box"]}>
                            <div className={classes["blog-category"]}>{blog.publish_date && dateFormmater(blog.publish_date)}</div>
                        </div>
                        <div className={classes.title}>
                            <span>{blog.title}</span>
                        </div>
                        <p className={classes.decription}>
                            {blog.content}
                        </p>

                    </div>
                </Card>
            </Link>

        </div>
    );
};

export default FeaturedBlogItem;
