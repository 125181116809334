import {
  IS_LOGGED_IN,
  USER_PROFILE,
  USER_COMPANY,
  USER_ROLE,
  USER_SUBSCRIPTION,
  RESET_USER,
  IS_PROFILE_CHANGED,
  IS_SUBSCRIPTION_CHANGE,
  USER_ASSETS,
  IS_ASSETS_CHANGED,
} from "./userActionType";
import { getAccessToken } from "../../utils/authentication";
const initialState = {
  isLoggedIn: !!getAccessToken(),
  userProfile: null,
  userCompany: null,
  userRole: null,
  userSubscription: null,
  isProfileChanged: false,
  isSubscriptionChange: false,
  USER_ASSETS: null,
  isAssetsChanged: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
      };
    case USER_COMPANY:
      return {
        ...state,
        userCompany: action.payload,
      };
    case USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      };
    case USER_SUBSCRIPTION:
      return {
        ...state,
        userSubscription: action.payload,
      };
    case IS_PROFILE_CHANGED:
      return {
        ...state,
        isProfileChanged: action.payload,
      };
    case IS_SUBSCRIPTION_CHANGE:
      return {
        ...state,
        isSubscriptionChange: action.payload,
      };
    case USER_ASSETS:
      return {
        ...state,
        userAssets: action.payload,
      };

    case IS_ASSETS_CHANGED:
        return{
          ...state,
          isAssetsChanged: action.payload,
      };

    case RESET_USER:
      return initialState;

    default:
      return state;
  }
};

export default userReducer;
