import React from 'react';
import classes from './WelcomePage.module.scss';
import { ReactComponent as Congrats } from "../../assets/images/register/congrat.svg";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

const WelcomePage = () => {
    const history = useHistory();
    const userRole = useSelector((state) => state.user.userRole);
    const [link, setLink] = useState("/dashboard/company-dashboard");
    useEffect(() => {
		if (userRole === "freelancer") setLink("/request?ordering=-created_at");
	}, [userRole]);
    return (
        <section className={classes["section-container"]}>
            <div className={`${classes["box-container"]} container`}>
                <Congrats className={classes["welcome-image"]} />
                <h1 className={classes["welcome-title"]}>You're All Set!</h1>
                <p className={classes["welcome-desc"]}>
                    We're excited to have you join the
                    Pharmatching community.
                </p>
                <button
                    className={`btn ${classes["welcome-start"]}`}
                    onClick={() => history.push(link)}
                >
                    Start matching
                </button>
            </div>
        </section>
    );
};

export default WelcomePage;