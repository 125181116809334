import classes from "./StepTitle.module.scss";

const StepTitle = ({ index, activeIndex, title }) => {
  return (
    <div    
      // style={{ borderColor: activeIndex > index ? "#0F9946" : activeIndex == index ? "#1E4C90" : "#7894BC"  }}
      className={classes["step-title-box"]}
    >
      {/* {activeIndex > index && ( */}
        <div className={`${classes["step-item"]}  align-items-center text-center`}>
          <div className={`${classes["step-index"]} text-center
                            ${activeIndex == index ? classes["active"]:''}
                            ${activeIndex > index ? classes["pass"]:''}
                            ${activeIndex < index ? classes["next"]:''}
                          `}>
            {index}
          </div>
          <div className={`${classes["step-title"]}
                           ${activeIndex >= index ? classes["pass"]:''}
          `}>
            {title}
          </div>
        </div>
        
      {/* )} */}
      {/* {activeIndex <= index && (
        <span className="d-lg-inline-flex d-none" style={{ padding: "14px 10px 10px" }}>       
          <span className={"text-center"} style={{ color: activeIndex >= index ? "#1E4C90" : "#7894BC" }}>
            {index}
          </span>
          <div className={classes["step-title"]}>
            {title}
          </div>
        </span>
      )}     */}
    </div>
  );
};

export default StepTitle;
