import classes from "./DashboardAppFilterSort.module.scss";
import SortItems from "../UI/SortBox/SortItems/SortItems";
import { SORT_ITEMS_TIME, STATUS_ITEMS, SORT_ITEMS } from "../../constants/SortItem";

const DashboardAppFilterSort = ({
  sentAppStatus,
  sortItem,
  dispatchSentAppStatus,
  dispatchSortItem,
}) => {
  const onGetSortItem = (item) => {
    dispatchSortItem(item);
  };

  const onGetStatusItem = (item) => {
    dispatchSentAppStatus(item);
  };

  return (
    <div className={classes["sort-container"]}>
      <div className="container">
        <div className={`row justify-content-between ${classes["sort-box"]}`}>
          <div className="col-2 text-start px-0 h-17">
            <SortItems
              sortItem={sentAppStatus}
              dispatchSortItem={onGetStatusItem}
              className="mr-auto"
              dropdownItems={STATUS_ITEMS}
              dropdownTitle="Status"
            />
          </div>
          <div className="col-2 text-end px-0 h-17">
            <SortItems
              sortItem={sortItem}
              dispatchSortItem={onGetSortItem}
              className="ml-auto"
              dropdownItems={SORT_ITEMS}
              dropdownTitle="Sort By"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAppFilterSort;
