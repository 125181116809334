import { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classes from "./Freelancer.module.scss";
import Card from "../../../components/UI/CardV2/CardV2";
import { useForm } from "react-hook-form";
import SimpleInput from "../../../components/UI/SimpleInput/SimpleInput";
import SingleTextArea from "../../../components/UI/SingleTextArea/SingleTextArea";
import DropDownInput from "../../../components/UI/DropDownInput/DropDownInput";
import useHttp from "../../../hooks/use-http";
import { API_TAGS_URL, API_COMPANIES_URL, API_GENERAL_URL } from "../../../constants/API";
import SearchableDropdownFilter from "../../../components/UI/SearchableDropdownFilter/SearchableDropdownFilter";
import SelectUnSelectInterests from "../../../components/SelectUnSelectInterests/SelectUnSelectInterests";
import AttachFileInput from "../../../components/UI/AttachFileInput/AttachFileInput";
import { getAccessToken } from "../../../utils/authentication";
import Loading from "../../../components/UI/Loading/Loading";
import { updateIsCompanyChanged } from "../../../redux/Company/companyAction";
import { useInterests } from "../../../hooks/use-interests";
import { updateUserCompany } from '../../../redux/User/userAction';
import DropDownInputSearchable from "../../../components/UI/DropDownInputSearchable/DropDownInputSearchable";
import SearchableDropdownFilterKeyServices from "../../../components/UI/SearchableDropdownFilterKeyServices/SearchableDropdownFilterKeyServices";
import SearchableDropDownFilterLifeScience from "../../../components/UI/SearchableDropDownFilterLifeScience/SearchableDropDownFilterLifeScience";
import SelectableButtonList from "../../../components/UI/SelectableButtonList/SelectableButtonList";
import { addToast } from "../../../redux/Toast/toastAction";
import { createRandomId } from "../../../utils/common";
import UploadImageInput from "../../../components/UI/UploadImageInput/UploadImageInput";
const Freelancer = () => {
  const [specialities, setSpecialities] = useState([]);
  const userProfile = useSelector((state) => state.user.userProfile?.user);
  const [isChecked, setIsChecked] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const dispatch = useDispatch();
  let history = useHistory();
  const { sendRequest, error, isLoading } = useHttp();
  const [serviceCategories, setServiceCategories] = useState([]);
  const [vatRegion, setVatRegion] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const {
    handleServiceCategories,
    handleKeyServices,
    handleSpecialities,
    removeSelectedCategories,
    removeSelectedKeyServices,
    removeSelectedSpecialities,
    interestItems,
    detectIsSubmitForm,
  } = useInterests(
    useMemo(() => [], []),
    useMemo(() => [], []),
    useMemo(() => [], []),
    useMemo(() => [], []),
    useMemo(() => [], []),
    serviceCategories,
    useMemo(() => [], []),
    useMemo(() => [], []),
    specialities,
    "freelancer"
  );
  //fetch tel code
  const getCountryCodesList = (codes) => {
    setCountryCodeList(codes);
  };
  const fetchCountryCodes = useCallback(() => {
    sendRequest(
      {
        url: `${API_GENERAL_URL}country-codes/`,
      },
      getCountryCodesList
    );
  }, [sendRequest]);
  //fetch vat region list------------------
  const getVatRegion = (vatRegions) => {
    setVatRegion(vatRegions);
  }
  const fetchVatRegion = useCallback(() => {
    sendRequest(
      {
        url: `${API_TAGS_URL}/vat-regions/`,
      },
      getVatRegion
    );
  }, [sendRequest]);

  // fetch interesting list-----------------------------------------------------------------------

  const getSpecialities = (specialities) => {
    setSpecialities(specialities);
  };

  const getServiceCategories = (serviceCategories) => {
    setServiceCategories(serviceCategories);
  };

  const fetchSpecialitiesList = useCallback(() => {
    sendRequest(
      {
        url: `${API_TAGS_URL}/specialities/`,
      },
      getSpecialities
    );
  }, [sendRequest]);

  const fetchCategories = useCallback(() => {
    sendRequest(
      {
        url: `${API_TAGS_URL}/service-categories/`,
      },
      getServiceCategories
    );
  }, [sendRequest]);

  useEffect(() => {
    fetchSpecialitiesList();
    fetchCategories();
    fetchVatRegion();
    fetchCountryCodes();
  }, [fetchSpecialitiesList, fetchCategories, fetchVatRegion, fetchCountryCodes]);
  const termsConditionHandler = (e) => {
    setIsChecked(e.target.checked);
  };
  const getCreatedFreelancerRes = (data) => {
    console.log(data);
    //uploadFreelancerFiles(data.id);
    dispatch(updateUserCompany(data));
    history.push("/welcome-pharmatching");
    window.location.reload();
  };

  const onSubmit = (submittedForm) => {
    console.log("submitted form:", submittedForm);
    if (
      interestItems.selectedCategoriesId.length !== 0 &&
      interestItems.selectedKeyServicesId.length !== 0 &&
      !interestItems.categoriesErrorMessage &&
      !interestItems.keyServiceErrorMessage
    ) {
      const token = getAccessToken();
      const freelancerRequest = {
        // title: submittedForm.name,
        // slug: submittedForm.name.replace(/ /g, "_"),
        // owner_name: submittedForm.name,
        // owner_sure_name: submittedForm.surname,
        owner_tel: submittedForm.phoneNumber,
        // owner_email: submittedForm.email,
        specialities: [submittedForm.lifeScienceSector],
        service_description: submittedForm.serviceDescription,
        service_categories: interestItems.selectedCategoriesId,
        key_services: interestItems.selectedKeyServicesId,
        company_type: 0,
        vat_id: submittedForm.vat_id,
        vat_region: submittedForm.vat_region,
      };
      const formData = new FormData();
      formData.append('title', submittedForm.companyName);
      formData.append('slug', submittedForm.companyName.replace(/ /g, "_"));
      // formData.append('owner_name', submittedForm.name);
      // formData.append('owner_sure_name', submittedForm.surname);
      formData.append('tel_code', submittedForm.code);
      formData.append('owner_tel', submittedForm.phoneNumber);
      formData.append('owner_email', userProfile.email);
      formData.append('description', submittedForm.serviceDescription);

      // formData.append('specialities', [submittedForm.lifeScienceSector]);
      let specialitiesArray = interestItems.selectedSpecialitiesId.map((i) => Number(i));
      for (var i = 0; i < specialitiesArray.length; i++) {
        formData.append('specialities', specialitiesArray[i]);
      }

      let service_categories = interestItems.selectedCategoriesId.map((i) => Number(i));
      for (var i = 0; i < service_categories.length; i++) {
        formData.append('service_categories', service_categories[i]);
      }

      let keyServiceArray = interestItems.selectedKeyServicesId.map((i) => Number(i));
      for (var i = 0; i < keyServiceArray.length; i++) {
        formData.append('key_services', keyServiceArray[i]);
      }

      formData.append('company_type', 0);
      // formData.append('vat_id', submittedForm.vat_id);
      // formData.append('vat_region', submittedForm.vat_region);
      // formData.append("file", getValues("attachFiles"));
      if (typeof submittedForm.profilePicture === "object") {
        formData.append("logo", submittedForm.profilePicture);
      }
      console.log('profile', userProfile);
      sendRequest(
        {
          url: `${API_COMPANIES_URL}/company/`,
          method: "POST",
          body: formData,
          headers: { Authorization: `Bearer ${token}` },
        },
        getCreatedFreelancerRes
      );
    }
  };

  const getUploadedFreelancerFile = (data) => {
    dispatch(updateIsCompanyChanged(true));
    history.push("/");
  };

  const uploadFreelancerFiles = (id) => {
    const formData = new FormData();
    formData.append("file", getValues("attachFiles"));
    const token = getAccessToken();
    sendRequest(
      {
        url: `${API_COMPANIES_URL}/company/${id}`,
        method: "PATCH",
        body: formData,
        headers: { Authorization: `Bearer ${token}` },
      },
      getUploadedFreelancerFile
    );
  };

  useEffect(() => {
    console.log(error);
    if (error && error.status === 400) {
      error.data.errors.title &&
        setError("name", { type: "custom", message: error.data.errors.title });
      error.data.errors.owner_email &&
        setError("email", {
          type: "custom",
          message: error.data.errors.owner_email,
        });
      dispatch(
        addToast({
          id: createRandomId(),
          status: "error",
          description: error.data.detail,
        })
      );
    }
  }, [dispatch, error, setError]);

  return (
    <section className={classes.section}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-11 col-12">
            <Card className={classes.card}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-12">
                    <div className={classes.title}>
                      About Company
                    </div>
                    <div className={classes.description}>
                      This information will be publicly displayed on your profile
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <UploadImageInput
                      validation={{
                        required: true,
                      }}
                      input={{
                        type: "file",
                        name: "profilePicture",
                      }}
                      errors={errors}
                      register={register}
                      setValue={setValue}
                      getValues={getValues}
                    // previewdValue={generalInfo?.profilePicture}
                    />

                    <SimpleInput
                      validation={{
                        required: true,
                      }}
                      input={{
                        type: "text",
                        label: "Company Name*",
                        name: "companyName",
                        placeholder: "Company Name",
                        id: "Company Name",
                        autoComplete: "off",
                      }}
                      errors={errors}
                      register={register}
                    />
                  </div>
                </div>
                <div className={classes.line}></div>

                <div className="row">
                  <div className="col-12">
                    <div className={classes.title}>
                      General Information
                    </div>
                    <div className={classes.description}>
                      This information will not be publicly displayed on your profile
                    </div>
                  </div>
                </div>
                <div className="row pt-5 px-5">
                  <div className={`${classes["admin-phone-code"]} ml-2 col-md-2 col-12`}>
                    <DropDownInputSearchable
                      validation={{
                        required: true,
                      }}
                      input={{
                        type: "text",
                        label: "Code",
                        name: "code",
                      }}
                      errors={errors}
                      register={register}
                      setValue={setValue}
                      getValues={getValues}
                      dropDownList={countryCodeList}
                    />
                    <div style={{ height: 12 }}></div>
                  </div>
                  <div className={`${classes["admin-phone-container"]} col-md-4 col-12`}>
                    <SimpleInput
                      validation={{
                        required: true,
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: "Please insert valid phone number"
                        },
                      }}

                      input={{
                        type: "text",
                        label: "Administrator Phone*",
                        name: "phoneNumber",
                        placeholder: "Administrator Phone",
                        id: "Administrator Phone",
                        autoComplete: "off",
                      }}
                      errors={errors}
                      register={register}
                    />
                  </div>
                </div>
                <div className={classes.line}></div>



                {/* <div className="col-12">
                  <SimpleInput
                    validation={{
                      required: true,
                    }}
                    input={{
                      type: "text",
                      label: "Name*",
                      name: "name",
                      placeholder: "Name",
                      id: "Name",
                      autoComplete: "off",
                    }}
                    errors={errors}
                    register={register}
                  />
                </div>
                <div className="col-12">
                  <SimpleInput
                    validation={{
                      required: true,
                    }}
                    input={{
                      type: "text",
                      label: "Surname*",
                      name: "surname",
                      placeholder: "Surname",
                      id: "Surname",
                      autoComplete: "off",
                    }}
                    errors={errors}
                    register={register}
                  />
                </div>
                <div className="col-12">
                  <SimpleInput
                    validation={{
                      required: true,
                      // pattern: {
                      //   value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      //   message: "Please enter a valid phone number",
                      // },
                    }}
                    input={{
                      type: "text",
                      label: "Phone Number*",
                      name: "phoneNumber",
                      placeholder: "Phone Number",
                      id: "Phone Number",
                      autoComplete: "off",
                    }}
                    errors={errors}
                    register={register}
                  />
                </div>
                <div className="col-12">
                  <SimpleInput
                    validation={{
                      required: true,
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email",
                      },
                    }}
                    input={{
                      type: "text",
                      label: "Email*",
                      name: "email",
                      placeholder: "Email",
                      id: "Email",
                      autoComplete: "off",
                    }}
                    errors={errors}
                    register={register}
                  />
                </div> */}
                <div className="col-12">
                  <div className={classes.title}>
                    Service Description
                  </div>
                  <div className={classes.description}>
                    This information will be publicly displayed on your profile
                  </div>
                  <div className="pt-5 px-5">
                    <SingleTextArea
                      validation={{
                        required: true,
                      }}
                      input={{
                        type: "text",
                        label: "Service Description*",
                        name: "serviceDescription",
                        placeholder: "Service Description",
                        autoComplete: "off",
                      }}
                      errors={errors}
                      register={register}
                    />
                  </div>
                </div>
                <div className={classes.line}></div>
                {/* <div className={`h-70 col-12`}>
                  {specialities.length > 0 && (
                    <DropDownInput
                      validation={{
                        required: true,
                      }}
                      input={{
                        type: "text",
                        label: "Life Science Sector*",
                        name: "lifeScienceSector",
                      }}
                      errors={errors}
                      register={register}
                      setValue={setValue}
                      getValues={getValues}
                      dropDownList={specialities}
                    />
                  )}
                </div> */}
                {/* <div className="col-12">
                  <input hidden type="submit" />
                </div> */}
              </form>

              <div
                className="row"
              >
                <div className={classes.title}>
                  Expertise
                </div>
                <div className={classes.description}>
                  This information will be publicly displayed on your profile and you can change it later.
                </div>
                <div className="p-5">
                  <div className={classes["custom-input-label"]}>
                    Select your Company Life Science Sectors (Up to 3)
                  </div>
                  <div className={`col-12`}>
                    <SearchableDropDownFilterLifeScience
                      className="w-100"
                      filterName="Life Science Sector "
                      initialFilters={interestItems.selectedSpecialitiesId}
                      filterList={specialities}
                      dispatchFilterList={handleSpecialities}
                      isSelectedItem={interestItems.isSelectedSpecialities}
                      isDisabled={false}
                      errorMessage={interestItems.specialitiesErrorMessage}
                      // customLabel="Select your Life Science Sector"
                      selectUnSelectItems={interestItems.selectedSpecialities}
                      removeSelectedItem={removeSelectedSpecialities}
                    />
                  </div>
                </div>
                <div className={classes["line"]}></div>
                <div className="p-5">
                  <div className={`${classes["custom-input-label-category"]} mb-4`}>
                    Select your Company Categories (Up to 5)
                  </div>
                  <div className={`col-12`}>
                    <SelectableButtonList
                      className="w-100"
                      filterName="Categories"
                      initialFilters={interestItems.selectedCategoriesId}
                      filterList={serviceCategories}
                      dispatchFilterList={handleServiceCategories}
                      isSelectedItem={interestItems.isSelectedCategories}
                      isDisabled={false}
                      errorMessage={interestItems.categoriesErrorMessage}
                      removeSelectedItem={removeSelectedCategories}
                    />
                  </div>

                  <div className={`col-12 pt-5`}>
                    <div className={classes["custom-input-label"]}>
                      Select your Company Key Services (Available Services are affected by Categories you’ve selected)
                    </div>
                    <SearchableDropdownFilterKeyServices
                      className="w-100"
                      filterName="Key Services"
                      initialFilters={interestItems.selectedKeyServicesId}
                      filterList={interestItems.keyServices}
                      dispatchFilterList={handleKeyServices}
                      isSelectedItem={interestItems.isSelectedKeyServices}
                      isDisabled={interestItems.isDiabled}
                      selectedCategories={interestItems.selectedCategoriesId}
                      categories={serviceCategories}
                      selectUnSelectItems={interestItems.selectedKeyServices}
                      removeSelectedItem={removeSelectedKeyServices}
                    // customLabel='Available Services are affected by Categories you’ve selected'
                    />
                  </div>

                </div>

                <div className={classes["invoice-form"]}>
                  <div className="form-check">
                    <input
                      onChange={(e) => termsConditionHandler(e)}
                      className={`form-check-input ${classes["form-check-input"]}`}
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className={`form-check-label ${classes["form-check-label"]}`}
                      htmlFor="flexCheckDefault"
                    >
                      <span style={{ marginRight: 5 }}>I have read and agree to the</span>
                      <a
                        href="/privacy-policy/"
                        rel="noreferrer"
                        target="_blank"
                        className={classes["privacy-terms"]}
                      >
                        Privacy Policy
                      </a>
                    </label>
                  </div>
                </div>

              </div>


              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="text-end">
                  <button
                    disabled={!isChecked}
                    onClick={detectIsSubmitForm}
                    className={`btn ${classes.submit}`}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>




              {/* <div className={`h-70 col-12`}>
                <SearchableDropdownFilter
                  className="w-100"
                  filterName="Categories"
                  initialFilters={interestItems.selectedCategoriesId}
                  filterList={serviceCategories}
                  dispatchFilterList={handleServiceCategories}
                  isSelectedItem={interestItems.isSelectedCategories}
                  isDisabled={false}
                  errorMessage={interestItems.categoriesErrorMessage}
                />
              </div>
              <div className="col-12">
                <SelectUnSelectInterests
                  title="Categories"
                  bgColor="#7894BC"
                  selectedItems={interestItems.selectedCategories}
                  removeSelectedItem={removeSelectedCategories}
                  color="#fff"
                />
              </div>
              <div className={`h-70 col-12`}>
                <SearchableDropdownFilter
                  className="w-100"
                  filterName="Key Services"
                  initialFilters={interestItems.selectedKeyServicesId}
                  filterList={interestItems.keyServices}
                  dispatchFilterList={handleKeyServices}
                  isSelectedItem={interestItems.isSelectedKeyServices}
                  isDisabled={interestItems.isDiabled}
                  errorMessage={interestItems.keyServiceErrorMessage}
                />
              </div>
              <div className="col-12">
                <SelectUnSelectInterests
                  bgColor="#D3F2FA"
                  selectedItems={interestItems.selectedKeyServices}
                  removeSelectedItem={removeSelectedKeyServices}
                  color="#1E4C90"
                />
              </div> */}

              <div className={classes["loading-box"]}>
                {isLoading &&
                  interestItems.selectedCategoriesId.length !== 0 &&
                  interestItems.selectedKeyServicesId.length !== 0 && (
                    <Loading className={classes.loading} />
                  )}
              </div>
            </Card>



          </div>
        </div>
      </div>
    </section>
  );
};

export default Freelancer;
