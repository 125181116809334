import "./SortItems.scss";

const SortItems = (props) => {
  const { sortItem, dispatchSortItem,className,dropdownItems,dropdownTitle } = props;
  
  const getSortItemHandler = (item) => {
    dispatchSortItem(dropdownItems[item]);
  };

  const findSortItem =() => {
  let result = Object.keys(dropdownItems).find(  key => dropdownItems[key] === sortItem);
  return result ? result : dropdownTitle
  }

  return (
    <div className="sorting-box">
      <div className="dropdown">
        <button
          className="btn sort-btn dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {findSortItem()}
        </button>
        <ul className={`${className} dropdown-menu`} aria-labelledby="dropdownMenuButton1">
        {
          Object.keys(dropdownItems).map((key)=> (
            <li key={key}
            className="dropdown-item"
            onClick={() => getSortItemHandler(key)}
          >
          <span>{key}</span> 
          </li>
          ))
        }
        </ul>
      </div>
    </div>
  );
};

export default SortItems;