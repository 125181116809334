import { useState, useEffect } from "react";
import classes from "./NewsFilterBoxAccordion.module.scss";
import Card from "../UI/Card/Card";
import SimpleFormGroup from "../UI/SimpleFormGroup/SimpleFormGroup";
import useHttp from "../../hooks/use-http";
import { API_TAGS_URL } from "../../constants/API";
import SearchableDropdownFilter from "../UI/SearchableDropdownFilter/SearchableDropdownFilter";
import NewsLinkGroup from "../NewsLinkGroup/NewsLinkGroup";
import NewsTimeFilter from "../NewsTimeFilter/NewsTimeFilter";
import WarningLetterDropdownFilter from "../UI/WarningLetterDropdownFilter/WarningLetterDropdownFilter";
import { WARNING_LETTER_SORT_ITEMS } from "../../constants/WarningLetterList";
import AccordionFilterSearchable from "../../components/UI/AccordionFilterSearchable/AccordionFilterSearchable";
import filterIcon from '../../assets/images/Icons/filter.svg';
import clearIcon from '../../assets/images/Icons/clear.svg';
const NewsFilterBoxAccordion = (props) => {
  const {
    onSearchNews,
    searchItem,
    onKeyServices,
    initialKeyServices,
    onGetTimeFilter,
    initialTimeFilter,
    onGetSource,
    sourceItem,
    componentUsage,
    onReset,
  } = props;
  const [keyServices, setKeyServices] = useState([]);
  const { sendRequest, error, isLoading } = useHttp();

  const handleOnSearchNews = (searchItem) => {
    onSearchNews(searchItem);
  };
  const getKeyServices = (keyServices) => {
    setKeyServices(keyServices);
  };
  const handleKeyServices = (keyServices) => {
    onKeyServices(keyServices);
  };

  const handleTimeFilter = (time) => {
    onGetTimeFilter(time);
  };

  const handleSource = (item) => {
    onGetSource(item);
  };

  useEffect(() => {
    if (componentUsage === "news") {
      sendRequest(
        {
          url: `${API_TAGS_URL}/service-categories/`,
        },
        getKeyServices
      );
    }
  }, [componentUsage, sendRequest]);

  return (
    <Card className={classes.card}>
      <div className="container">
        <div className="row justify-content-between">
          <div className={classes["filter-box-header"]}>
            <div className={classes["filter-box-title"]}>
              <img src={filterIcon} alt="filter" />
              <span>Filters</span>
            </div>
            <div className={classes["filter-box-reset"]}>
              <div
                onClick={()=> {onReset()}}
                className={classes["reset-button"]}>
                Clear All
              </div>
              <img src={clearIcon} alt="clear" />
            </div>
          </div>
          <div className={classes["line"]}></div>
          {componentUsage === "news" && (
            <div className="col-12">
              {/* <AccordionFilterSearchable
              filterName="Company Size"
              initialFilters={initialKeyServices}
              filterList={keyServices}
              dispatchFilterList={handleKeyServices}
              isSelectedItem={true}
            /> */}
              {/* <SearchableDropdownFilter
                className={classes["right-0px"]}
                filterName="Categories"
                initialFilters={initialKeyServices}
                filterList={keyServices}
                dispatchFilterList={handleKeyServices}
                isSelectedItem={true}
              /> */}
            </div>
          )}

          {componentUsage === "warning-letter" && (
            <div className={`${classes["warning-letter-filter-wrapper"]} col-12 p-0`}>

              <WarningLetterDropdownFilter
                filterItem={sourceItem}
                dispatchFilterItem={handleSource}
                dropdownItems={WARNING_LETTER_SORT_ITEMS}
                dropdownTitle="Source"
              />
              <div className={classes["line"]}></div>
            </div>
          )}

          <div className="col-12 p-0">
            <NewsTimeFilter
              dispatchTimeFilter={handleTimeFilter}
              initialTimeFilter={initialTimeFilter}
            />
          </div>
        </div>
      </div>
    </Card >
  );
};

export default NewsFilterBoxAccordion;
