import React, { Fragment } from 'react';
import classes from './CompanyExpertiseModal.module.scss';
import { useState, useEffect, useCallback } from "react";
import Loading from '../../UI/Loading/Loading';
import closeModal from '../../../assets/images/Icons/close-modal.svg';
import { showEditCompanyExpertise } from '../../../redux/Modal/modalAction';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import useHttp from "../../../hooks/use-http";
import { getAccessToken } from "../../../utils/authentication";
import { API_COMPANIES_URL, API_TAGS_URL } from "../../../constants/API";
import { addToast } from "../../../redux/Toast/toastAction";
import { updateUserCompany } from "../../../redux/User/userAction";
import {
    createRandomId,
    convertNumberMemberToString,
} from "../../../utils/common";
import { useInterests } from "../../../hooks/use-interests";
import AttachFileInput from "../../UI/AttachFileInput/AttachFileInput";
import SelectableButtonList from "../../UI/SelectableButtonList/SelectableButtonList";
import SearchableDropdownFilterKeyServices from "../../UI/SearchableDropdownFilterKeyServices/SearchableDropdownFilterKeyServices";
import SearchableDropDownFilterLifeScience from "../../UI/SearchableDropDownFilterLifeScience/SearchableDropDownFilterLifeScience";
const CompanyExpertiseModal = () => {
    const userRole = useSelector((state) => state.user.userRole);
    const { sendRequest, error, isLoading } = useHttp();
    const dispatch = useDispatch();
    const [generalInfo, setGeneralInfo] = useState(null);
    const [companyAttachment, setCompanyAttachment] = useState(null);
    const [companyExperties, setCompanyExperties] = useState({
        keyServices: [],
        categories: [],
        regions: [],
        countries: [],
        specialities: [],
    });
    const { userCompany } = useSelector((state) => state.user);
    const [serviceCategories, setServiceCategories] = useState([]);
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [specialities, setSpecialities] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const onGetCompanyExperties = (keyItem, experties) => {
        setCompanyExperties((previousState) => {
            return {
                ...previousState,
                [keyItem]: experties,
            };
        });
    };
    const {
        handleServiceCategories,
        handleKeyServices,
        handleRegions,
        handleCountries,
        handleSpecialities,
        removeSelectedCategories,
        removeSelectedKeyServices,
        removeSelectedRegion,
        removeSelectedCountries,
        removeSelectedSpecialities,
        detectIsSubmitForm,
        interestItems,
    } = useInterests(
        companyExperties.categories,
        companyExperties.keyServices,
        companyExperties.regions,
        companyExperties.countries,
        companyExperties.specialities,
        //useMemo(() => [], []),
        serviceCategories,
        regions,
        countries,
        specialities,
        "company",
        onGetCompanyExperties
    );
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        setError,
        reset,
    } = useForm({

    });

    const onSubmit = (submittedForm) => {
        const isSubmit = detectIsSubmitForm();
        if (isSubmit) {
            sumbitUpdateCompany(submittedForm);
        }
    };
    // get value from steps-----------------------------------------------------------------------------------
    const sumbitUpdateCompany = (submittedForm) => {
        updateCompanyById(userCompany.id, submittedForm);
    };
    //-----------------------------------------------------------------------------------
    // edit company based on some conditions (user changes pictures and files or not)
    const getUpdatedCompanyById = (data) => {
        dispatch(showEditCompanyExpertise(false));
        dispatch(updateUserCompany(data));
        dispatch(
            addToast({
                id: createRandomId(),
                status: "success",
                description: "Your company has been edited successfully.",
            })
        );

    };

    const updateCompanyById = (id, submittedForm) => {
        // console.log('submited form2:', submittedForm);
        const formData = new FormData();
        let specialitiesArray = interestItems.selectedSpecialitiesId.map((i) => Number(i));
        for (var i = 0; i < specialitiesArray.length; i++) {
            formData.append('specialities', specialitiesArray[i]);
        }
        let categoryArray = interestItems.selectedCategoriesId.map((i) => Number(i));
        for (var i = 0; i < categoryArray.length; i++) {
            formData.append('service_categories', categoryArray[i]);
        }
        let keyServiceArray = interestItems.selectedKeyServicesId.map((i) => Number(i));
        for (var i = 0; i < keyServiceArray.length; i++) {
            formData.append('key_services', keyServiceArray[i]);
        }
        let regionsArray = interestItems.selectedRegionId.map((i) => Number(i));
        for (var i = 0; i < regionsArray.length; i++) {
            formData.append('regions', regionsArray[i]);
        }
        let countrieArray = interestItems.selectedCountriesId.map((i) => Number(i));
        for (var i = 0; i < countrieArray.length; i++) {
            formData.append('countries', countrieArray[i]);
        }
        
        if (typeof submittedForm.attachFiles === "object") {
            formData.append("file", submittedForm.attachFiles);
        }

        const token = getAccessToken();
        sendRequest(
            {
                url: `${API_COMPANIES_URL}/company/${id}/`,
                method: "PATCH",
                body: formData,
                headers: { Authorization: `Bearer ${token}` },
            },
            getUpdatedCompanyById
        );
    };
    //-----------------------------------------------------------------------------------
    useEffect(() => {
        // console.log(error);
        if (error && error.status === 400) {
            dispatch(
                addToast({
                    id: createRandomId(),
                    status: "error",
                    description: error.data.errors.title
                        ? error.data.errors.title
                        : error.data.errors.owner_email,
                })
            );
        }
    }, [dispatch, error]);
    //-------------------------------------------------- set defualt values to edit steps
    const setExpertise = useCallback(() => {
        if (userCompany) {
            setCompanyExperties({
                categories: convertNumberMemberToString(userCompany.service_categories),
                regions: convertNumberMemberToString(userCompany.regions),
                keyServices: convertNumberMemberToString(userCompany.key_services),
                countries: convertNumberMemberToString(userCompany.countries),
                specialities: convertNumberMemberToString(userCompany.specialities),
            });
            if (userCompany.attachments && userCompany.attachments[0]) {
                let fileName = userCompany.attachments[0].split('/').slice(-1);
                setCompanyAttachment(fileName[0]);
            }
        }
    }, [userCompany]);
    useEffect(() => {
        setExpertise();
    }, [userCompany, setExpertise]);

    //-------get data
    // set categories /regions and countries lists after fetching-----------------------

    const getServiceCategories = (serviceCategories) => {
        setServiceCategories(serviceCategories);
    };

    const getRegionsList = (regions) => {
        setRegions(regions);
    };

    const getCountriesList = (countries) => {
        setCountries(countries);
    };

    const getSpecialities = (specialities) => {
        setSpecialities(specialities);
    };
    // fetch categories / key services / regions / countires----------------------------------------

    const fetchCategories = useCallback(() => {
        sendRequest(
            {
                url: `${API_TAGS_URL}/service-categories/`,
            },
            getServiceCategories
        );
    }, [sendRequest]);

    const fetchRegions = useCallback(() => {
        sendRequest(
            {
                url: `${API_TAGS_URL}/regions/`,
            },
            getRegionsList
        );
    }, [sendRequest]);

    const fetchCountries = useCallback(() => {
        sendRequest(
            {
                url: `${API_TAGS_URL}/countries/`,
            },
            getCountriesList
        );
    }, [sendRequest]);

    const fetchSpecialitiesList = useCallback(() => {
        sendRequest(
            {
                url: `${API_TAGS_URL}/specialities/`,
            },
            getSpecialities
        );
    }, [sendRequest]);
    useEffect(() => {
        fetchSpecialitiesList();
    }, [fetchSpecialitiesList]);
    useEffect(() => {
        fetchCategories();
        fetchRegions();
        fetchCountries();
        fetchSpecialitiesList();
        return () => {
            setServiceCategories([]);
            setCountries([]);
            setRegions([]);
            setSpecialities([]);
        };
    }, [fetchCategories, fetchRegions, fetchCountries, fetchSpecialitiesList]);
    return (
        <section className={classes.section}>
            {isLoading && (
                <div
                    className={`row justify-content-center  align-items-center ${classes["min-h"]}`}
                >
                    <Loading className={classes.loading} />
                </div>
            )}
            {(!isLoading) && (
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-12 p-0">
                            <div className={classes["modal-header"]}>
                                <div className={classes["modal-title"]}>Edit Company Expertise</div>
                                <button
                                    className={`btn`}
                                    onClick={() => { dispatch(showEditCompanyExpertise(false)) }}
                                >
                                    <img src={closeModal} />
                                </button>
                            </div>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <div className={classes["description-wrapper"]}>
                                    <div className={classes["section-title"]}>
                                        Company Expertise
                                    </div>
                                    <div className={classes["section-subtitle"]}>
                                        This information will be publicly displayed on your company profile and you can change it later.
                                    </div>
                                    <div
                                        className={`row ${classes["input-container"]}`}
                                    >
                                        <div className={``}>
                                            <div className={classes["custom-input-label"]}>
                                                Select your Company Life Science Sectors (Up to 3)
                                            </div>
                                            <div className={`col-12`}>
                                                <SearchableDropDownFilterLifeScience
                                                    className="w-100"
                                                    filterName="Life Science Sector "
                                                    initialFilters={interestItems.selectedSpecialitiesId}
                                                    filterList={specialities}
                                                    dispatchFilterList={handleSpecialities}
                                                    isSelectedItem={interestItems.isSelectedSpecialities}
                                                    isDisabled={false}
                                                    errorMessage={interestItems.specialitiesErrorMessage}
                                                    // customLabel="Select your Life Science Sector"
                                                    selectUnSelectItems={interestItems.selectedSpecialities}
                                                    removeSelectedItem={removeSelectedSpecialities}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes["line"]}></div>
                                        <div className={classes["input-container"]}>
                                            <div className={`${classes["custom-input-label-category"]} mb-4`}>
                                                Select your Company Categories (Up to 5)
                                            </div>
                                            <div className={`col-12`}>
                                                <SelectableButtonList
                                                    className="w-100"
                                                    filterName="Categories"
                                                    initialFilters={interestItems.selectedCategoriesId}
                                                    filterList={serviceCategories}
                                                    dispatchFilterList={handleServiceCategories}
                                                    isSelectedItem={interestItems.isSelectedCategories}
                                                    isDisabled={false}
                                                    errorMessage={interestItems.categoriesErrorMessage}
                                                    removeSelectedItem={removeSelectedCategories}
                                                />
                                            </div>

                                            <div className={`col-12`}>
                                                <div className={classes["custom-input-label"]}>
                                                    Select your Company Key Services (Available Services are affected by Categories you’ve selected)
                                                </div>
                                                <SearchableDropdownFilterKeyServices
                                                    className="w-100"
                                                    filterName="Key Services"
                                                    initialFilters={interestItems.selectedKeyServicesId}
                                                    filterList={interestItems.keyServices}
                                                    dispatchFilterList={handleKeyServices}
                                                    isSelectedItem={interestItems.isSelectedKeyServices}
                                                    isDisabled={interestItems.isDiabled}
                                                    selectedCategories={interestItems.selectedCategoriesId}
                                                    categories={serviceCategories}
                                                    selectUnSelectItems={interestItems.selectedKeyServices}
                                                    removeSelectedItem={removeSelectedKeyServices}
                                                // customLabel='Available Services are affected by Categories you’ve selected'
                                                />
                                            </div>
                                            <div className="col-12">
                                            </div>
                                        </div>
                                        {userRole && userRole !== "freelancer" && (
                                            <Fragment>
                                                <div className={classes["line"]}></div>
                                                <div className={`${classes["section-title"]} mb-3`}>
                                                    Company Certificate
                                                </div>
                                                <div className={classes["input-container"]}>
                                                    <AttachFileInput
                                                        validation={{
                                                            required: false,
                                                        }}
                                                        input={{
                                                            type: "file",
                                                            name: "attachFiles",
                                                        }}
                                                        register={register}
                                                        errors={errors}
                                                        setValue={setValue}
                                                        getValues={getValues}
                                                        previewdValue={companyAttachment}
                                                        placeHolderText="You can upload your Certificates and / or additional company information combined into one PDF (max. 20 MB) here."
                                                    />
                                                </div>
                                            </Fragment>
                                        )}


                                    </div>

                                </div>
                                <div className={classes['action-wrapper']}>
                                    <button className={classes.cancel}
                                        onClick={() => { dispatch(showEditCompanyExpertise(false)) }}
                                    >
                                        Cancel
                                    </button>
                                    <button className={`btn ${classes.submit}`} type="submit">
                                        Save Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default CompanyExpertiseModal;