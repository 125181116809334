import React from 'react';
import classes from './SortableTableRequests.module.scss';
import useSortableData from '../../../hooks/useSortableData';
import { REQUEST_STATUS } from "../../../constants/RequestStatus";
import orderArrow from '../../../assets/images/arrow/order-arrow.svg';
import { dateFormmaterNormal } from "../../../utils/common";
import actionEdit from '../../../assets/images/Icons/actionEdit.svg';
import actionIcon from '../../../assets/images/Icons/actionIcon.svg';
import {Link} from "react-router-dom";
const SortableTableRequests = ({ source, onSortItem }) => {
    // const { items, requestSort, sortConfig } = useSortableData(props.source);

    const items = source;
    const getClassNamesFor = (name) => {
        // if (!sortConfig) {
        //     return;
        // }
        // return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    const handleClickAction = () => {
        console.log('action');
    };
    const handleClickEdit = () => {
        console.log('Edit');
    };
    return (
        <table className={classes['table-requests']}>
            <thead>
                <tr>
                    <th
                        onClick={() => onSortItem('title')}
                    // className={classes[getClassNamesFor('title')]}
                    >
                        Title
                        <img src={orderArrow} alt='arrow' />
                    </th>
                    <th
                        onClick={() => onSortItem('-created_at')}
                    // className={classes[getClassNamesFor('created_at')]}
                    >
                        Creation Date
                        <img src={orderArrow} alt='arrow' />
                    </th>
                    <th
                    // onClick={() => requestSort('due_date')}
                    // className={classes[getClassNamesFor('due_date')]}
                    >
                        Expiring Date
                        {/* <img src={orderArrow} alt='arrow' /> */}
                    </th>
                    <th
                    // onClick={() => requestSort('company_title')}
                    // className={classes[getClassNamesFor('company_title')]}
                    >
                        Creator
                        {/* <img src={orderArrow} alt='arrow' /> */}
                    </th>
                    <th
                    // onClick={() => requestSort('status')}
                    // className={classes[getClassNamesFor('status')]}
                    >
                        Status
                        {/* <img src={orderArrow} alt='arrow' /> */}
                    </th>
                    <th
                    // onClick={() => requestSort('received_applications_count')}
                    // className={classes[getClassNamesFor('received_applications_count')]}
                    >
                        Recieved Application
                        {/* <img src={orderArrow} alt='arrow' /> */}
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {items.map((request) => (
                    <tr key={request.id}>
                        <td>{request.title}</td>
                        <td>{dateFormmaterNormal(request.created_at)}</td>
                        <td>{dateFormmaterNormal(request.due_date)}</td>
                        <td>{request.creator}</td>
                        <td>
                            {REQUEST_STATUS.filter((element) => element.id === request.status)
                                .map((item) =>
                                    <div
                                        key={item.id}
                                        style={{ color: item.color, backgroundColor: item.backgroundColor }}
                                        className={classes["request-status"]}>
                                        {item.cardTitle}
                                    </div>
                                )}
                        </td>
                        <td>{request.received_applications_count}</td>
                        <td>
                            <Link
                                to={`/request-applications/${request.id}`}
                                className='btn'>
                                <img src={actionIcon} />
                            </Link>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default SortableTableRequests;