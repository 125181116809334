import {useEffect, useState} from "react";
import {useParams, Link} from "react-router-dom";
import classes from "./BlogSingle.module.scss";
import useHttp from "../../hooks/use-http";
import {API_BLOG_URL} from "../../constants/API";
import Loading from "../../components/UI/Loading/Loading";

import {getAccessToken} from "../../utils/authentication";
import BlogDetail from "../../components/BlogDetail/BlogDetail";

const BlogSingle = () => {
    const [blog, setblog] = useState();
    const {id} = useParams();
    const {sendRequest, error, isLoading} = useHttp();

    const getNewsDetail = (blog) => {
        setblog(blog);
        window.scrollTo(0, 0);
    };

    useEffect(() => {
            const token = getAccessToken();

            sendRequest(
                {
                    url: `${API_BLOG_URL}${id}`,
                    headers: {Authorization: token && `Bearer ${token}`}
                },
                getNewsDetail
            );
        }, [sendRequest, id]
    )

    return (
        <div className={`${classes["main-background"]}`}>
            <section className={`container  ${classes["blog-container"]}`}>
                {isLoading && (
                    <div
                        className={`row align-items-center justify-content-center ${classes["min-h"]}`}
                    >
                        <Loading className={classes.loading}/>
                    </div>
                )}
                {blog && blog.content && !isLoading && <BlogDetail blogId={id} blog={blog}/>}
            </section>
        </div>
    );
};

export default BlogSingle;
