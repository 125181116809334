import { useSelector } from "react-redux";
import classes from "./DashboardHeader.module.scss";
import { NavLink } from "react-router-dom";
import userIcon from "../../assets/images/Icons/user.svg";
import myCompanyIcon from "../../assets/images/Icons/my-company.svg";
import sendAppIcon from "../../assets/images/Icons/send-Application.svg";
import sendReqIcon from "../../assets/images/Icons/send-request.svg";
import recievedAppIcon from "../../assets/images/Icons/recieved-application.svg";
import draftsIcon from "../../assets/images/Icons/drafts.svg";
import wizardIcon from "../../assets/images/Icons/wizard.svg";
import defaultAvatar from "../../assets/images/Icons/profile-user.svg";
import { Fragment } from "react";

const DashboardHeader = () => {
    const userProfile = useSelector((state) => state.user.userProfile);
    const userRole = useSelector((state) => state.user.userRole);
    const userCompany = useSelector((state) => state.user.userCompany);
    return (
        <Fragment>
            <div className={classes["header"]}></div>
            <div className={``}>
                <ul className={classes["menu-wrapper"]}>
                    {userRole && userRole !== "guest" && (
                        <Fragment>
                            <li className={classes["nav-item"]}>
                                <NavLink
                                    exact
                                    to="/dashboard/company-dashboard"
                                    className={`nav-link ${classes.link}`}
                                    activeClassName={classes["active-link"]}
                                >

                                    <span className={classes.text}> Dashboard</span>
                                </NavLink>
                            </li>
                            <li className={classes["nav-item"]}>
                                <NavLink
                                    exact
                                    to="/dashboard/company-info"
                                    className={`nav-link ${classes.link}`}
                                    activeClassName={classes["active-link"]}
                                >
                                    {userRole !== "freelancer" && (
                                        <span className={classes.text}> Company Info & User Management</span>
                                    )}
                                    {userRole === "freelancer" && (
                                        <span className={classes.text}> Service Information</span>
                                    )}

                                </NavLink>
                            </li>
                            {(userCompany.is_admin || userRole === "freelancer") &&
                                <li className={classes["nav-item"]}>
                                    <NavLink
                                        to="/dashboard/company-plans"
                                        className={`nav-link ${classes.link}`}
                                        activeClassName={classes["active-link"]}
                                    >
                                        <span className={classes.text}>Plan & Invoices</span>
                                    </NavLink>
                                </li>
                            }

                        </Fragment>
                    )}
                </ul>
            </div>
        </Fragment>
    );
};

export default DashboardHeader;
