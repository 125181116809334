import React, { Fragment } from 'react';
import classes from './DashboardLatestRequests.module.scss';
import CardV2 from '../UI/CardV2/CardV2';
import { dateFormmaterNormal, dateFormmater, detectShortestItem } from "../../utils/common";
import { REQUEST_STATUS } from "../../constants/RequestStatus";
import actionEdit from '../../assets/images/Icons/actionEdit.svg';
import actionIcon from '../../assets/images/Icons/actionIcon.svg';
import { Link } from "react-router-dom";
import { showCreateRequestModal } from "../../redux/Modal/modalAction";
import { useDispatch } from "react-redux";
import NotFoundItems from "../../components/UI/NotFoundItems/NotFoundItems";

const DashboardLatestRequests = ({ companyAssets, sentRequestList }) => {
    const dispatch = useDispatch();
    const handleClickAction = () => {
        console.log('action');
    };
    const handleClickEdit = () => {
        console.log('Edit');
    };
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <CardV2 className={classes['card']}>
                        <div className={`row ${classes['header']}`}>
                            <div className={`col-lg-6 col-12   ${classes['wrapper-title']}`}>
                                <div className={classes['title']}>
                                    Posted Requests
                                </div>
                                <Link to="/dashboard/all-requests?page=1&ordering=-created_at" className={classes['view-all']}>
                                    View all
                                </Link>
                            </div>
                            <div className={`col-lg-6 col-12  text-end ${classes['wrapper-assets']}`}>
                                {companyAssets && (
                                    <Fragment>
                                        <div style={{ color: '#34A853' }} className={classes['asset-wrapper']}>
                                            <div className={classes['asset-count']}>
                                                {companyAssets.exact_open_requests}
                                            </div>
                                            <div className={classes['asset-title']}>
                                                Open
                                            </div>
                                        </div>
                                        <div style={{ color: '#24407D' }} className={classes['asset-wrapper']}>
                                            <div className={classes['asset-count']}>
                                                {companyAssets.matchmakings}
                                            </div>
                                            <div className={classes['asset-title']}>
                                                Successful
                                            </div>
                                        </div>
                                        <div style={{ color: '#E10000' }} className={classes['asset-wrapper']}>
                                            <div className={classes['asset-count']}>
                                                {companyAssets.closed_requests}
                                            </div>
                                            <div className={classes['asset-title']}>
                                                Closed
                                            </div>
                                        </div>
                                        <div style={{ color: '#22BDE8' }} className={classes['asset-wrapper']}>
                                            <div className={classes['asset-count']}>
                                                {companyAssets.expired_requests}
                                            </div>
                                            <div className={classes['asset-title']}>
                                                Expired
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                        <div className={classes['body']}>
                            {sentRequestList && (
                                <table className={classes['table-requests']}>
                                    <thead>
                                        <tr>
                                            <th>
                                                Title
                                            </th>
                                            <th>
                                                Creation Date
                                            </th>
                                            <th>
                                                Expiring Date
                                            </th>
                                            <th>
                                                Requester
                                            </th>
                                            <th>
                                                Status
                                            </th>
                                            <th>
                                                Recieved Application
                                            </th>
                                            <th>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sentRequestList.map((request) =>
                                            <tr key={request.id}>
                                                <td>
                                                    {request.title}
                                                </td>
                                                <td>
                                                    {dateFormmaterNormal(request.created_at)}
                                                </td>
                                                <td>
                                                    {dateFormmaterNormal(request.due_date)}
                                                </td>
                                                <td>
                                                    {request.creator}
                                                </td>
                                                <td>
                                                    {REQUEST_STATUS.filter((element) => element.id === request.status)
                                                        .map((item) =>
                                                            <div
                                                                key={item.id}
                                                                style={{ color: item.color, backgroundColor: item.backgroundColor }}
                                                                className={classes["request-status"]}>
                                                                {item.cardTitle}
                                                            </div>
                                                        )}
                                                </td>
                                                <td>
                                                    {request.received_applications_count}
                                                </td>
                                                <td>
                                                    <Link
                                                        to={`/request-applications/${request.id}`}
                                                        className='btn'>
                                                        <img src={actionIcon} />
                                                    </Link>
                                                </td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                            )}
                            {sentRequestList.length == 0 && (
                                <NotFoundItems className={classes["not-found"]} item="request" />
                            )}
                        </div>
                        <div className={classes['footer']}>

                            <Link to="/dashboard/all-drafts?page=1&ordering=-created_at" className={`btn ${classes['btn-see-draft']}`}>
                                See Draft Requests
                            </Link>

                            
                        </div>
                    </CardV2>
                </div>
            </div>

        </div>
    );
};

export default DashboardLatestRequests;