import React from 'react';
import classes from "./Imprint.module.scss";

const Imprint = () => {
    return (
        <div className={`${classes["main-background"]}`}>
            <div className={`container`}>
                <div className={`row ${classes["static-page"]}`}>
                    <div className={`col-12`}>
                        <div className={classes.title}>
                            <h1>Imprint</h1>
                            <h3>www.pharmatching.com</h3>
                        </div>
                        <div className={classes.content}>
                            <div className={classes.section}>
                                <div className={classes.title}>
                                    <span>Pharmatching.com</span> is operated and authored by:
                                </div>
                                <p className={classes.text}>
                                    Zamann Pharma Support GmbH <br />
                                    Siedlerstraße 7 <br />
                                    68623 Lampertheim, Germany
                                </p>
                                <p className={classes.text}>
                                    Phonenumber: +49(0)6206 703 65 70<br />
                                    Email: <span>info@Pharmatching.com</span>
                                </p>
                                <p className={classes.text}>
                                    Registry court: Darmstadt<br />
                                    Registration number: HRB96615
                                </p>
                                <p className={classes.text}>
                                    Ust/VAT: DE312180595<br />
                                    CEO & Managing Director: Alireza Zarei Nejad Farshi<br />
                                    <br />
                                    Deputy Managing Director: Nouredin Soumeh<br />
                                    Responsible for content: Alireza Zarei Nejad Farshi
                                </p>

                                <div className={classes.title}>
                                    Copyright & Liability:
                                </div>
                                <p className={classes.text}>
                                    All pages of <span>Pharmatching </span> are protected and subject to copyright.<br />
                                    They may neither be copied, nor duplicated otherwise. Unless noted otherwise, Zamann Pharma Support GmbH holds the copyright.<br />
                                    We do not take responsibility for any externally provided content or for the
                                    contents of linked sites which are the responsibility of their own operators.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Imprint;