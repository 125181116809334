import React from 'react';
import {useState, useCallback, useEffect} from "react";
import {useForm} from "react-hook-form";
import classes from './CreateApplicationDetail.module.scss';
import Card from '../../components/UI/CardV2/CardV2';
import SingleTextAreaWithWordCounter from '../UI/SingleTextAreaWithWordCounter/SingleTextAreaWithWordCounter';
import AttachFileInput from '../../components/UI/AttachFileInput/AttachFileInput';
import {getAccessToken} from "../../utils/authentication";
import useHttp from "../../hooks/use-http";
import {API_APPLICATION_URL} from "../../constants/API";
import {useDispatch} from "react-redux";
import {addToast} from "../../redux/Toast/toastAction";
import {createRandomId} from "../../utils/common";
import {useHistory} from "react-router-dom";

const CreateApplicationDetail = ({onActiveFlagHandler, isSubmit, requestInfo}) => {
    const {sendRequest, error, isLoading} = useHttp();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isChecked, setIsChecked] = useState(false);
    const [isAcceptedDescription, setIsAcceptedDescription] = useState(false);
    //------get general info
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        getValues,
        setError,
    } = useForm({});

    const termsConditionHandler = (e) => {
        setIsChecked(e.target.checked);
        onActiveFlagHandler(!(!isAcceptedDescription && e.target.checked));
    };
    const onKeyUpHandler = (activeFlag) => {
        setIsAcceptedDescription(activeFlag);
        onActiveFlagHandler(!(!activeFlag && isChecked));
    };

    const displayCreateapplicationToast = useCallback(() => {
        dispatch(
            addToast({
                id: createRandomId(),
                status: "success",
                description: "Congratulations, you have successfully submitted your application. We hope that you can convince the Requestor to choose you as matchmaking partner",
            })
        );
    }, [dispatch]);
    const getSendApplicationRes = (res) => {
        displayCreateapplicationToast();
        history.push("/dashboard/all-sent-applications?page=1&ordering=-created_at");
    };
    const createApplication = (submittedForm) => {
        const token = getAccessToken();
        const formData = new FormData();
        if (submittedForm.attachFiles)
            formData.append("file", submittedForm.attachFiles);
        formData.append("text", submittedForm.description);
        formData.append("request", requestInfo.id);
        formData.append("redirect_url", "https://webapp.pharmatching.com/payment-result")
        sendRequest(
            {
                url: `${API_APPLICATION_URL}`,
                method: "POST",
                body: formData,
                headers: {Authorization: `Bearer ${token}`},
            },
            getSendApplicationRes
        );
    };
    useEffect(() => {
        if (isSubmit) {
            handleSubmit(createApplication)();
        }
    }, [isSubmit]);

    useEffect(() => {
        if (error && error.status === 400) {
            dispatch(
                addToast({
                    id: createRandomId(),
                    status: "error",
                    description: error.data.errors["0"],
                })
            );
        }
    }, [dispatch, error]);


    return (
        <div>
            <Card className={classes.card}>
                <div className={`${classes.title}`}>
                    <span>Application</span>
                </div>
                <div className={classes.body}>
                    <form>
                        <SingleTextAreaWithWordCounter
                            validation={{
                                required: true,
                                validate: {
                                    positive: v => v.split(' ').length > 49,
                                }
                            }}
                            input={{
                                type: "text",
                                label: "Content*",
                                name: "description",
                                placeholder: "Description",
                                autoComplete: "off",
                                //onKeyUp: () => {alert("ads")},

                            }}
                            errors={errors}
                            register={register}
                            isApplication={true}
                            onKeyUpHandler={onKeyUpHandler}
                        />

                        <AttachFileInput
                            validation={{
                                required: false,
                            }}
                            input={{
                                type: "file",
                                name: "attachFiles",
                            }}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            getValues={getValues}
                            placeHolderText="Upload certifications, proof of competencies or other related documents to boost your application success chances! We strongly recommend to only submit files, which are directly related to this Request."
                            // previewdValue={}
                        />
                    </form>
                    <div className={classes["form-check"]}>
                        <input
                            onChange={(e) => termsConditionHandler(e)}
                            className={`form-check-input ${classes["form-check-input"]}`}
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                        />
                        <label
                            className={`form-check-label ${classes["form-check-label"]}`}
                            htmlFor="flexCheckDefault"
                        >
                            <span style={{marginRight: 5}}>I have read and accept</span>
                            <a
                                href="/privacy-policy/"
                                rel="noreferrer"
                                target="_blank"
                                className={classes["privacy-terms"]}
                            >
                                Privacy Policy
                            </a>
                            .
                        </label>
                    </div>
                </div>

            </Card>
        </div>
    );
};

export default CreateApplicationDetail;