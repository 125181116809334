import React from 'react';
import CardV2 from '../UI/CardV2/CardV2';
import classes from './DashboardCompanyExperties.module.scss';
import certificateIcon from '../../assets/images/Icons/certificate-outline.svg';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { showEditCompanyExpertise } from '../../redux/Modal/modalAction';

const DashboardCompanyExperties = ({ userCompany, userRole }) => {
    const dispatch = useDispatch();

    return (
        <CardV2 className={classes['card']}>
            <div className={classes['card-title']}>
                Company Expertise
            </div>
            <div className={classes['edit-btn-container']}>
                {userCompany && userRole && (userCompany.is_admin || userRole === "freelancer") && (
                    <button
                        className={classes['edit-btn']}
                        onClick={() => { dispatch(showEditCompanyExpertise(true)) }}
                    >
                        Edit Company Expertise
                    </button>
                )}
            </div>
            <div className={classes['wrapper-experties']}>
                <div className={classes['title']}>
                    Life Science Sector
                </div>
                <div className={`${classes['value']} ${classes['life']}`}>
                    {userCompany && (
                        userCompany.specialities_data.map((life) =>
                            <div style={{ backgroundColor: '#801d5433' }}> {life.title}</div>
                        )
                    )}
                </div>
                <div className={classes['title']}>
                    Categories
                </div>
                <div className={`${classes['value']} ${classes['categories']}`}>
                    {userCompany && (
                        userCompany.ordered_service_categories_data.map((category) =>
                            <div style={{ backgroundColor: '#21bce733' }}> {category.title}</div>
                        )
                    )}
                </div>
                <div className={classes['title']}>
                    Key Services
                </div>
                <div className={`${classes['value']} ${classes['key-services']}`}>
                    {userCompany && (
                        userCompany.ordered_key_services_data.map((key) =>
                            <div style={{ backgroundColor: '#1e4c9033' }}> {key.title}</div>
                        )
                    )}
                </div>
                {userCompany && userCompany.attachments && userCompany.attachments[0] && (
                    <Fragment>
                        <div className={classes['title']}>
                            Certificate\ Information
                        </div>
                        <div className={` ${classes['certificate']}`}>
                            <a
                                target="_blank"
                                rel="no noreferrer"
                                href={userCompany.attachments[0]}
                            >
                                <div className={classes['certificate-btn']}>
                                    <img src={certificateIcon} />
                                    <div>Customer Life Science...</div>
                                </div>
                            </a>
                        </div>
                    </Fragment>
                )}

            </div>
        </CardV2>
    );
};

export default DashboardCompanyExperties;