export const WARNING_LETTER_LIST = ["fda", "fda_483", "uk", "eudra"];

export const WARNING_LETTER_SORT_ITEMS = {
  "FDA Warning Letters": "fda",
  "FDA 483 Findings": "fda_483",
  "UK Alerts and Recalls": "uk",
  // "EUDRAMED Non Compliance": "eudra",
};

export const WARNING_LETTER_OBJECT = [
  {name:"FDA Warning Letters",val:"fda"},
  {name:"FDA 483 Findings",val:"fda_483"},
  {name:"UK Alerts and Recalls",val:"uk"},
  // {name:"EUDRAMED Non Compliance",val:"eudra"},
  ];