import { useState, useEffect } from "react";
import classes from "./DashboardRequestFilterSort.module.scss";
import SortItems from "../UI/SortBox/SortItems/SortItems";
import { SORT_ITEMS_TIME, SORT_ITEMS_REQUEST } from "../../constants/SortItem";
import { REQUEST_STATUS } from "../../constants/RequestStatus";

const DashboardRequestFilterSort = ({
  sentReqStatus,
  sortItem,
  dispatchSentReqStatus,
  dispatchSortItem,
}) => {
  const [requestStatusList, setRequestStatusList] = useState([]);
  // Approval Pending with id=2 isnt be part of filter
  const getRequestStatusList = () => {
    const result = REQUEST_STATUS.filter((status) => status.id !== 2);
    setRequestStatusList(result);
  };

  const onGetSortItem = (item) => {
    dispatchSortItem(item);
  };

  const getFilterItem = (item) => {
    dispatchSentReqStatus(item);
  };

  let filterTitle = "Status";
  if (requestStatusList.length > 0) {
    let result = requestStatusList.find(
      (item) => item.id === parseInt(sentReqStatus)
    );
    filterTitle = result ? result.title : "Status";
  }

  useEffect(() => {
    getRequestStatusList();
  }, []);

  return (
    <div className={classes["sort-container"]}>
      <div className="container">
        <div className={`row justify-content-between ${classes["sort-box"]}`}>
          <div className="col-2 text-start px-0 h-17">
            <div className="sorting-box">
              <div className="dropdown">
                <button
                  className="btn sort-btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {filterTitle}
                </button>

                <ul
                  className={`dropdown-menu ${classes.menus}`}
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <button
                      className={`dropdown-item ${classes.items}`}
                      onClick={() => getFilterItem(0)}
                    >
                      <span>All</span>
                    </button>
                  </li>
                  {requestStatusList.map((item) => (
                    <li key={item.id}>
                      <button
                        className={`dropdown-item ${classes.items}`}
                        onClick={() => getFilterItem(item.id)}
                      >
                        <span>{item.title}</span>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-2 text-end px-0 h-17">
            <SortItems
              sortItem={sortItem}
              dispatchSortItem={onGetSortItem}
              className="ml-auto"
              dropdownItems={SORT_ITEMS_REQUEST}
              dropdownTitle="Sort By"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardRequestFilterSort;
