import { useState , useEffect } from "react";
import { Link } from "react-router-dom";
import classes from "./KeyServiceItem.module.scss";
import allCategories from "../../../../../../src/assets/images/Icons/all-categories.svg";

const KeyServiceItem = (props) => {
  const { displayedKeyService, categoriesListLentgh } = props;
  const [showAll , setShowAll] = useState("");

useEffect(()=> {
  let queryParam = "/request?page=1";
  displayedKeyService.forEach(element => {
    queryParam +=`&key_services=${element.id}`; 
  });
  setShowAll(queryParam)
} , [displayedKeyService])

 
  
  return categoriesListLentgh > 0 ? (
    <div className={classes["child-wrp"]}>
      <div>
        <Link to={showAll} className={classes["show-all"]}>
          Show All
          <img src={allCategories} alt="All Categories" />
        </Link>
      </div>
      {displayedKeyService.map((keyService) => (
        <div className={classes.item} key={keyService.id}>
          <Link
            to={`/request?page=1&key_services=${keyService.id}`}
            className={classes["link-item"]}
          >
            {keyService.title}
          </Link>
        </div>
      ))}
    </div>
  ) : (
    ""
  );
};

export default KeyServiceItem;
