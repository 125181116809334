import React, {useState} from "react";
import classes from "./DefaultBanner.module.scss";
import LoggedinBanner from "./LoggedInBanner";
import DefaultBanner from "./DefaultBanner";
import {useSelector} from "react-redux";

const MainBanner = () => {
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn)
    return (
        <div className={`${classes['banner-main']}`}>
            {/* {isLoggedIn ? <LoggedinBanner/> : <DefaultBanner/>} */}
            <DefaultBanner/>
        </div>
    );
};

export default MainBanner;
