import React from 'react';
import classes from "./PrivacyPolicy.module.scss";

const PrivacyPolicy = () => {
    return (
        <div className={`${classes["main-background"]}`}>
            <div className={`container`}>
                <div className={`row ${classes["static-page"]}`}>
                    <div className={`col-12`}>
                        <div className={classes.title}>
                            <h1>PRIVACY POLICY</h1>
                            <h3>www.pharmatching.com</h3>
                        </div>
                        <div className={classes.content}>
                            <div className={classes.section}>
                                <div className={classes.title}>
                                    1. ABOUT PRIVACY AT PHARMATCHING
                                </div>
                                <p className={classes.text}>
                                    This Privacy Policy was created to help you understand how PHARMATCHING uses and protects your data. We encourage you to read this entire Policy so that you know exactly how we collect, use, and disclose your data.
                                </p>
                                <div className={classes.title}>
                                    2. OUR COMMITMENT TO YOUR PRIVACY
                                </div>
                                <p className={classes.text}>
                                    Our Privacy Policy tells you what personally identifiable information PHARMATCHING may collect from you, how PHARMATCHING may process your personally identifiable information, how you can limit our use of your personally identifiable information, and your rights to obtain, modify and/or delete any personally identifiable information PHARMATCHING has collected from you.
                                </p>
                                <div className={classes.title}>
                                    3. INFORMATION WE COLLECT
                                </div>
                                <div className={`${classes["inner-title"]}`}>
                                    3.1 Information you provide PHARMATCHING
                                </div>
                                <p className={classes.text}>
                                    PHARMATCHING collects personal information when you request information about our services or otherwise voluntarily provide such information through our site.
                                    <br />
                                    Generally, you will have control over the amount and type of information you provide to us when using our site.
                                    <br />
                                    The following data is collected upon registration. All fields are compulsory except those marked with.
                                    <ul>
                                        <li>First name, Last name</li>
                                        <li>Title*</li>
                                        <li>Name of the company or institution of the User</li>
                                        <li>Characterization as Company, Freelancer or academic or public institution</li>
                                        <li>User ́s position</li>
                                        <li>Full postal address of the company or institution</li>
                                        <li>User ́s business Email address</li>
                                        <li>User ́s business telephone and fax* numbers</li>
                                        <li>Company or university website address*</li>
                                        <li>VAT Number of the company or institution*</li>
                                        <li>Full postal invoicing address</li>
                                        <li>Category of User ́s main core competence (only for Service Providers)</li>
                                        <li>Password</li>
                                        <li>The Email address or own created name could be used as the User name.</li>
                                        <li>Other PHARMATCHING Users cannot view the User name and password.</li>
                                    </ul>
                                    PHARMATCHING will under no circumstances transmit or reveal this information to third parties. PHARMATCHING itself does not know the password, which is submitted and saved encrypted.
                                </p>
                                <div className={`${classes["inner-title"]}`}>
                                    3.2 Information Collected Automatically
                                </div>
                                <p className={classes.text}>
                                    When you use our site, we automatically collect certain information by the interaction of your mobile device or web browser with our platform.
                                </p>
                                <div className={`${classes["inner-title"]}`}>
                                    3.3 Cookies
                                </div>
                                <p className={classes.text}>
                                    Like many other sites, we use “Cookies.” A Cookie is a small piece of data stored on your computer or mobile device by our site. We use Cookies to identify the areas of our site that you have visited. We also use cookies to enhance your online experience by eliminating the need to log in multiple times for specific content. Finally, we may use Cookies to personalize the content that you see on our site or to customize marketing and other information we provide to you.
                                </p>
                                <div className={`${classes["inner-title"]}`}>
                                    3.4 Other Automatically-Gathered Information
                                </div>
                                <p className={classes.text}>
                                    PHARMATCHING may automatically record information when you visit its site, including the URL, IP address, browser type and language, and the date and time of your visit. PHARMATCHING uses this information to analyze trends among its users to help improve its site or customize communications and information that you receive from us. If combined with other information we know about you from previous visits, the data possibly could be used to identify you personally, even if you are not signed in to our site.
                                </p>
                                <div className={`${classes["inner-title"]}`}>
                                    3.5 Do Not Track Settings
                                </div>
                                <p className={classes.text}>
                                    Some web browsers have settings that enable you to request that we do not track your movement within our site. We do not obey such settings when transmitted to and detected by our site. However, this does not change our compliance in any other way with our Privacy Policy.
                                </p>
                                <div className={`${classes["inner-title"]}`}>
                                    3.6 Web analytics with Microsoft Clarity
                                </div>
                                <p className={classes.text}>
                                    This website uses Microsoft Clarity, a web analytics service provided by Microsoft Corporation. The aim is to analyze user behavior on our website, improve user experience and market our products and services more effectively. Information such as mouse movements, clicks and keystrokes are collected through cookies, behavior metrics, heat maps and session replays. All data collected is anonymized and does not allow any conclusions to be drawn about individual persons. Microsoft Clarity is used in accordance with Article 6 (1) (f) GDPR. The information collected is stored on Microsoft servers in the United States. For more details about privacy at Microsoft Clarity, see the Microsoft Privacy Statement, which also explains how Microsoft collects and uses your information. 
                                </p>

                                <div className={classes.title}>
                                    4. HOW INFORMATION IS USED
                                </div>
                                <p className={classes.text}>
                                    When PHARMATCHING uses or processes personal data about you, it does so only as necessary to provide the services you use or otherwise with your consent, to comply with applicable law, or to fulfill other legitimate interests of you or us as described in this Policy.  Through our site, you will be provided with the choice of which types of communications you will receive with us, and the ability to change those choices whenever you want.
                                </p>
                                <div className={`${classes["inner-title"]}`}>
                                    4.1 Information we process with your consent
                                </div>
                                <p className={classes.text}>
                                    Through certain actions when otherwise there is no contractual relationship between us, such as when you browse our site or ask us to provide you more information about our business, you provide your consent to us to process information that may be personally identifiable information.
                                    <br />
                                    Wherever possible, we aim to obtain your explicit consent to process this information, for example, by asking you to agree to our use of Cookies or to receive communications from us.
                                    <br />
                                    We continue to process your information on this basis until you withdraw your consent or it can be reasonably assumed that your consent no longer exists.
                                    <br />
                                    You may withdraw your consent at any time by instructing us using the contact information at the end of this Policy. However, if you do so, you may not be able to use our site further.
                                </p>
                                <div className={`${classes["inner-title"]}`}>
                                    4.2 Legally Required Releases of Information
                                </div>
                                <p className={classes.text}>
                                    We may be legally required to disclose your personally identifiable information, if such disclosure is (a) required by law, or other legal process; (b) necessary to assist law enforcement officials or government enforcement agencies; (c) necessary to investigate violations of or otherwise enforce our Legal Terms; (d) necessary to protect us from legal action or claims from third parties including you; and/or (e) necessary to protect the legal rights, personal/real property, or personal safety of our company, clients, third party partners, employees, and affiliates.
                                </p>
                                <div className={classes.title}>
                                    5. OBTAINING, CHANGING OR DELETING YOUR INFORMATION
                                </div>
                                <div className={`${classes["inner-title"]}`}>
                                    5.1 Access to your personal information
                                </div>
                                <p className={classes.text}>
                                    To obtain a copy of all information PHARMATCHING maintains about you, you may send us a request using the contact information at the end of this Policy or, if available, through a tool on our site.  After receiving the request, we will tell you when we expect to provide you with the information, and whether we require any fee for providing it to you.
                                </p>
                                <div className={`${classes["inner-title"]}`}>
                                    5.2 Remove or Change your Information
                                </div>
                                <p className={classes.text}>
                                    If you wish us to remove or change personally identifiable information that you have provided us you may contact us at the contact information at the end of this Policy or if available through a tool on our site.
                                </p>
                                <div className={`${classes["inner-title"]}`}>
                                    5.3 Verification of your Information
                                </div>
                                <p className={classes.text}>
                                    When we receive any request to access, edit or delete personally identifiable information, we will first take reasonable steps to verify your identity before granting you access or otherwise taking any action. This is important to safeguard your information.
                                </p>
                                <div className={classes.title}>
                                    6. RETENTION PERIOD FOR PERSONAL DATA
                                </div>
                                <p className={classes.text}>
                                    Except as otherwise mentioned in this Policy, PHARMATCHING keeps your personally identifiable information only for as long as required:
                                    <br />
                                    to provide you with the services you have requested, or otherwise to perform or enforce a contract between us;
                                    <br />
                                    to continue to provide the best possible user experience to visitors who return to our site to collect information;
                                    <br />
                                    to comply with other law, including for any period demanded by tax authorities; or
                                    <br />
                                    to support a claim or defense in any court or in any legal, regulatory or administrative proceeding.
                                </p>
                                <div className={classes.title}>
                                    7. LIMITATION OF LIABILITY
                                </div>
                                <p className={classes.text}>
                                    You assume the sole risk of transmitting your information as it relates to the use of this site, and for any data corruptions, intentional interceptions, intrusions or unauthorized access to information, or of any delays, interruptions to or failures preventing the use this site. In no event shall we be liable for any direct, indirect, special, consequential or monetary damages, including fees, and penalties in connection with your use of materials posted on this site or connectivity to or from this site to any other site.
                                </p>
                                <div className={classes.title}>
                                    8. PROTECTING YOUR CHILD’S PRIVACY
                                </div>
                                <p className={classes.text}>
                                    Even though our site is not designed for use by anyone under the age of 18, we realize that a child may attempt to access the site. We do not knowingly collect personally identifiable information from a child. If you are a parent or guardian and believe your child is using our site, please contact us. We may ask for proof of identification before we remove any information to prevent malicious removal of information. If we discover on our own that a child is accessing our site, we will delete the information as soon as we discover it, we will not use the information for any purpose, and we will not disclose the information to third parties. You acknowledge that we do not verify the age of our users nor do we have any liability to do so. If you are a child, please seek the permission of a parent or guardian before accessing the site.
                                </p>
                                <div className={classes.title}>
                                    9. CHANGES TO OUR PRIVACY POLICY
                                </div>
                                <p className={classes.text}>
                                    PHARMATCHING reserves the right to change this privacy policy at any time. If we decide to change this Privacy Policy, we will post those changes on the site so our users are always aware of what information we collect, use, and disclose. In all cases, your continued use of our site after any change to this Privacy Policy will constitute your acceptance of such change.
                                </p>
                                <div className={classes.title}>
                                    10. ACCEPTANCE
                                </div>
                                <p className={classes.text}>
                                    By accessing our site, you have willingly accepted the terms of this Privacy Policy.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;