import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";

import Loading from "../../../components/UI/Loading/Loading";
import SimpleInput from "../../../components/UI/SimpleInput/SimpleInput";
import { API_AUTH_URL } from "../../../constants/API";

import useQuery from "../../../hooks/use-query";
import useHttp from "../../../hooks/use-http";

import classes from "./NewPassword.module.scss";

import { ReactComponent as Icon } from "../../../assets/images/new-password.svg";

const NewPassword = () => {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();
	const passwordWatch = watch("newPassword");
	const query = useQuery();
	const { sendRequest, isLoading } = useHttp();
	const history = useHistory();

	const changeRouteAfterSuccess = () => {
		history.push("/authentication/login");
	};

	const onSubmit = (submittedForm) => {
		const code = query.get("code");
		const newPasswordBody = {
			password: submittedForm.newPassword,
			confirm_password: submittedForm.confirmPassword,
		};
		sendRequest(
			{
				url: `${API_AUTH_URL}forget-password/${code}/confirm/`,
				method: "POST",
				body: newPasswordBody,
			},
			changeRouteAfterSuccess
		);
	};
	return (
		<section className={classes["section-container"]}>
			<div className={`container ${classes["form-container"]} mx-3 mx-lg-0 my-3 my-md-5`}>
				<div className={classes["icon"]}>
					<Icon />
				</div>
				<h1 className={classes["title"]}>Create a new password</h1>
				<p className={classes["desc"]}>Use at least 6 characters.</p>
				<form onSubmit={handleSubmit(onSubmit)}>
					<SimpleInput
						validation={{
							required: true,
							pattern: {
								value:
									/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
								message: "Please enter a valid password",
							},
						}}
						input={{
							type: "password",
							label: "New Password",
							name: "newPassword",
							placeholder: "New Password",
							id: "setPassword",
							autoComplete: "off",
							hasappend: "true"
						}}
						errors={errors}
						register={register}
					/>
					<SimpleInput
						validation={{
							required: true,
							pattern: {
								value:
									/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
								message: "Please enter a valid password",
							},
							validate: (value) =>
								value === passwordWatch || "Passwords do not match",
						}}
						input={{
							type: "password",
							label: "Confirm Password",
							name: "confirmPassword",
							placeholder: "Confirm Password",
							id: "Confirm Password",
							autoComplete: "off",
							hasappend: "true"
						}}
						errors={errors}
						register={register}
					/>
					<button
						className={`btn ${classes["submit"]}`}
						type="submit"
					>
						Set Password
					</button>
				</form>
				<div className={classes["link"]}>
					<span>Back to</span>
					<Link to="/authentication/login">Login</Link>
				</div>
			</div>
		</section>
	);
};
export default NewPassword;
