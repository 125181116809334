


export const getAccessToken = () => {
  return JSON.parse(localStorage.getItem("accessToken"));
};

export const setAuthorization = (authorization) => {
  localStorage.setItem("refreshToken", JSON.stringify(authorization.refresh));
  localStorage.setItem("accessToken", JSON.stringify(authorization.access));
  localStorage.setItem("lifeTime", JSON.stringify(authorization.lifetime));
};

export const getRefreshToken = () => {
  return JSON.parse(localStorage.getItem("refreshToken"));
};

export const removeAuthorization = () => {
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("lifeTime");
};
