
import classes from './AppointmentSubscription.module.scss';
import React from "react";

const AppointmentSubscription = ({ title, link, text, buttonText }) => {
    return (
        <section className={`${classes["appointment"]}`}>
            <div className='container'>
                <div className='row'>
                    <div className={`${classes["appointment-col"]} col-md-12 d-flex align-items-center justify-content-center`}>
                        <div className={classes.contact_content}>
                            <div className={`row `}>
                                <div className="col-xl-8 col-12 ">
                                    <div className={`${classes["contact"]} text-xl-start text-center`}>
                                        <h3>
                                            {title}
                                        </h3>
                                        <p>
                                            {text}
                                        </p>
                                    </div>
                                </div>
                                <div className=" col-xl-4 col-12 d-flex align-items-center justify-content-xl-end justify-content-center mt-xl-0 mt-3">
                                    <div className={`${classes['contact-button']} `}>
                                        <div
                                            className={classes["select-btn"]}
                                            onClick={() => window.open(link, '_blank')}
                                        >
                                            {buttonText}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default AppointmentSubscription;