import React from 'react';
import styles from './BusinessOutreach.module.scss';
import outreach1 from "../../assets/images/Icons/outreach1.svg";
import outreach2 from "../../assets/images/Icons/outreach2.svg";
import outreach3 from "../../assets/images/Icons/outreach3.svg";

const BusinessOutreach = () => {
  return (
    <div className={styles.businessOutreach}>
      <h2>Increase your business outreach</h2>
      <p className={styles.subtitle}>4800 companies can't be wrong!</p>

      <div className={styles.outreachCards}>
        {/* Card 1 */}
        <div className={`row ${styles.outreachCard}`}>
          <div className={`col-xl-3 col-12 ${styles.cardHeader}`}>
            <h3>4800</h3>
            <p>Registered Companies</p>
          </div>

          <div className={`col-xl-8 col-12 ${styles.firstCardP}`}>
            <p>When you post on Pharmatching, your request:</p>

            <ul>
              <li><strong>Wide Reach:</strong> Reaches up to 4800 registered pharmaceutical companies.</li>
              <li><strong>Increased Opportunities:</strong> Maximizes chances of finding the right partners.</li>
              <li><strong>Active Connections:</strong> Links you with companies seeking collaborations.</li>
            </ul>
          </div>
          <div className={`col-xl-1 col-12 d-flex justify-content-end align-items-start position-absolute`} style={{ top: 10, right: 0 }}>
            <img src={outreach1} alt="Icon" />
          </div>

        </div>

        {/* Card 2 */}
        <div className={`row ${styles.outreachCard}`}>
          <div className={`col-xl-3 col-12 ${styles.cardHeader}`}>
            <h3>23.5%</h3>
            <p>Email Opening Rate</p>
          </div>
          <div className={`col-xl-8 col-12 ${styles.firstCardP}`}>
            <ul>
              <li><strong>Global and Direct Outreach:</strong> Requests are published online and emailed to 4800 companies.</li>
              <li><strong>Eager Audience:</strong> Companies on Pharmatching actively seek and open new business opportunities.</li>
              <li><strong>High Open Rate:</strong> 23.5% email opening rate, five times the industry average.</li>
            </ul>
          </div>
          <div className={`col-xl-1 col-12 d-flex justify-content-end align-items-start position-absolute`} style={{ top: 10, right: 0 }}>
            <img src={outreach2} alt="Icon" />
          </div>
        </div>

        {/* Card 3 */}
        <div className={`row ${styles.outreachCard}`}>
          <div className={`col-xl-3 col-12 ${styles.cardHeader}`}>
            <h3>8.1%</h3>
            <p>Click-Through Rate</p>
          </div>
          <div className={`col-xl-8 col-12 ${styles.firstCardP}`}>
            <ul>
              <li><strong>Active Engagement:</strong> Companies seek new business opportunities.</li>
              <li><strong>High Click Rate:</strong> 8.1% of recipients click on email links, four times the industry average.</li>
              <li><strong>Increased Connections:</strong> Requests are actively explored by potential partners.</li>
            </ul>
          </div>
          <div className={`col-xl-1 col-12 d-flex justify-content-end align-items-start position-absolute`} style={{ top: 10, right: 0 }}>
            <img src={outreach3} alt="Icon"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessOutreach;
