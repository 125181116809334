import React, {Fragment, useEffect} from "react";
import classes from "./SolutionCardV2.module.scss";
import tikIcon from "../../assets/images/Icons/blue-tick.svg";
import disableTikIcon from "../../assets/images/Icons/disable-tick.svg";

import SolutionButton from "../SolutionButton/SolutionButton";
import {useSelector} from "react-redux";


const SolutionCardV2 = ({
                            solutionItem,
                            onClickHandler,
                        }) => {
    const userCompany = useSelector((state) => state.user.userCompany);

    useEffect(() => {
    }, [userCompany]);
    const selectedSolutionHandler = () => {
        onClickHandler(solutionItem.id)
    };

    return (
        <div className={`${classes.card}`}>
            <div className={classes["solution-title"]}>
                {solutionItem.discount > 0 && (
                    <span className={classes.off}>{solutionItem.discount}% OFF</span>
                )}

                <div className={classes.title}>
                    <div className={classes.name}>
                        {solutionItem.title}
                    </div>
                </div>
                {/* {selectedSolutionTitle === solutionItem.title &&
          solutionItem.title !== "Enterprise Solution" && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                dispatchResetSolution();
              }}
            >
              <i
                style={{ color: `${solutionItem.circle_color}` }}
                className={`fas fa-times-circle ${classes.cancel}`}
              ></i>
            </span>
          )} */}
            </div>
            <div className={classes.titleprice}>

                <span>
                    {solutionItem.price > 0 ? (
                        <Fragment>
                            <span className={`${classes.sign}`}>
                                €
                            </span>
                            <span className={classes.price}>{parseInt(solutionItem.price)}</span><br/>
                            <span className={classes.month}>
                      &nbsp;/ month
                    </span>
                        </Fragment>

                    ) : (
                        <span className={classes.price}>Free</span>
                    )}

                  </span></div>
            <div className={classes.line}></div>
            <SolutionButton
                text={solutionItem.type_display === 'Trial' ? 'Start' : 'Choose Plan'}
                isDisabled={userCompany !== null && solutionItem.type_display === 'Trial' ? userCompany.used_trial : false}
                onClickHandler={selectedSolutionHandler}
                className={solutionItem.type_display !== 'Trial' ? classes['plan-btn-card'] : ''}
            />
            <div className={classes["solution-box"]}>
                <ul className={`${classes.list}`}>
                    {solutionItem.solution_features.map((item) => (

                        <li key={item} className={`d-flex ${classes.item}`}>
                            <img src={tikIcon} alt="tick"/>
                            <span
                                className={classes.txt}
                            >
                {item}
              </span>
                        </li>
                    ))}
                    {solutionItem.solution_missed_features.map((item) => (
                        <li key={item} className={`d-flex ${classes.item}`}>
                            <img src={disableTikIcon} alt="tick"/>
                            <span
                                className={classes['txt-disable']}
                            >
                {item}
              </span>
                        </li>
                    ))}
                </ul>

            </div>
        </div>
    );
};

export default SolutionCardV2;
