import React from "react";

import downloadIcon from "../../../../assets/images/Icons/download-button.svg";
import classes from "./InvoicesTable.module.scss";

const InvoicesTable = ({ invoicesList, limit, currentPage }) => {
  return (
    <table className={`table table-borderless ${classes["invoice-table"]}`}>
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Invoice Number</th>
          <th scope="col">Payment Day</th>
          <th scope="col">Amount</th>
          <th scope="col">PDF</th>
        </tr>
      </thead>
      <tbody className={`${classes["invoices-body"]}`}>
        {invoicesList.map((invoice, index) => {
          return (
            <tr key={invoice.invoice_number}>
              <td className="col-1" scope="col">
                {`${limit * (currentPage - 1) + index + 1}.`}
              </td>
              <td className="col-3" scope="col">
                {invoice.invoice_number}
              </td>
              <td className="col-3" scope="col">
                {invoice.invoice_date}
              </td>
              <td className="col-4" scope="col">
                {invoice.invoice_amount}
              </td>
              <td className="col-1" scope="col">
                <a href={invoice.invoice_pdf} target="_blank" rel="noreferrer">
                  <img src={downloadIcon} alt="Download Invoice" />
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default InvoicesTable;
