import { useCallback, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import classes from "./CompanyDetails.module.scss";
import SimpleInput from "../../UI/SimpleInput/SimpleInput";
import DropDownInputSearchable from "../../UI/DropDownInputSearchable/DropDownInputSearchable";
import useHttp from "../../../hooks/use-http";
import { API_TAGS_URL, API_GENERAL_URL } from "../../../constants/API";
import Card from "../../../components/UI/Card/Card";
import leftArrow from "../../../assets/images/arrow/left-arrow.svg";
import imgLinkedin from "../../../assets/images/Icons/linkdin.svg";
import imgInstagram from "../../../assets/images/Icons/youtube.svg";
import imgXing from "../../../assets/images/Icons/xing.svg";
import imgTwitter from "../../../assets/images/Icons/twitter.svg";
import pngTwitter from "../../../assets/images/Icons/twitter-x-logo-100x100.png";
import imgFacebook from "../../../assets/images/Icons/facebook.svg";
import SocialInput from "../../UI/SocialInput/SocialInput";

const CompanyDetails = ({
  OnUpdateActiveStep,
  onGetCompanyDetails,
  companyDetails,
  activeIndex,
  companyDetailErrors,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: companyDetails
      ? {
        administratorName: companyDetails["administratorName"],
        administratorSurname: companyDetails["administratorSurname"],
        code: companyDetails["code"],
        administratorPhone: companyDetails["administratorPhone"],
        administratorEmail: companyDetails["administratorEmail"],
        generalCompanyEmail: companyDetails["generalCompanyEmail"],
        website: companyDetails["website"],
        companyStreet: companyDetails["companyStreet"],
        companyZipCode: companyDetails["companyZipCode"],
        companyTown: companyDetails["companyTown"],
        companyCountry: companyDetails["companyCountry"],
        linkedin: companyDetails["linkedin"],
        xing: companyDetails["xing"],
        instagram: companyDetails["instagram"],
        twitter: companyDetails["twitter"],
        facebook: companyDetails["facebook"],
      }
      : {},
  });
  const { sendRequest, error, isLoading } = useHttp();
  const [countriesList, setCountriesList] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState([]);

  const getCountriesList = (countries) => {
    setCountriesList(countries);
  };

  const getCountryCodesList = (codes) => {
    setCountryCodeList(codes);
  };

  const fetchCountries = useCallback(() => {
    sendRequest(
      {
        url: `${API_TAGS_URL}/countries/`,
      },
      getCountriesList
    );
  }, [sendRequest]);

  const fetchCountryCodes = useCallback(() => {
    sendRequest(
      {
        url: `${API_GENERAL_URL}country-codes/`,
      },
      getCountryCodesList
    );
  }, [sendRequest]);

  const onSubmit = (submittedForm) => {
    onGetCompanyDetails(submittedForm);
    OnUpdateActiveStep(3);
    window.scrollTo(0, 0);
  };

  const backButton = () => {
    OnUpdateActiveStep(1);
  };

  useEffect(() => {
    fetchCountries();
    fetchCountryCodes();
  }, [fetchCountries, fetchCountryCodes]);

  useEffect(() => {
    if (companyDetailErrors && companyDetailErrors.data.errors) {
      setError("administratorEmail", {
        type: "custom",
        message: companyDetailErrors.data.errors.owner_email,
      });
    }
    
  }, [companyDetailErrors, setError]);

  useEffect(() => {
    if (companyDetails) {
      console.log(companyDetails, 'companyDetails')
      setValue("administratorEmail", companyDetails.administratorEmail);
      setValue("administratorName", companyDetails.administratorName);
      setValue("administratorPhone", companyDetails.administratorPhone);
      setValue("administratorSurname", companyDetails.administratorSurname);
      setValue("code", companyDetails.code);
      setValue("companyCountry", companyDetails.companyCountry);
      setValue("companyStreet", companyDetails.companyStreet);
      setValue("companyTown", companyDetails.companyTown);
      setValue("companyZipCode", companyDetails.companyZipCode);
      setValue("facebook", companyDetails.facebook);
      setValue("generalCompanyEmail", companyDetails.generalCompanyEmail);
      setValue("instagram", companyDetails.instagram);
      setValue("linkedin", companyDetails.linkedin);
      setValue("twitter", companyDetails.twitter);
      setValue("website", companyDetails.website);
      setValue("xing", companyDetails.xing);
    }
  }, [companyDetails, setValue]);

  return (
    <div style={{ display: activeIndex === 2 ? "block" : "none" }}>

      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <Card className={classes["step-card"]}>
          <div className="row">
            <div className="col-12">
              <div className={classes["section-title"]}>
                About Company
              </div>
              <div className={classes["section-subtitle"]}>
                This information will be publicly displayed on your profile
              </div>
            </div>
            <div className={`${classes["input-container"]} row`}>
              <div className="col-md-6 col-12">
                <SimpleInput
                  validation={{
                    required: true,
                  }}
                  input={{
                    type: "text",
                    label: "Administrator Name*",
                    name: "administratorName",
                    placeholder: "Administrator Name",
                    id: "Administrator Name",
                    autoComplete: "off",
                  }}
                  errors={errors}
                  register={register}
                />
              </div>
              <div className="col-md-6 col-12">
                <SimpleInput
                  validation={{
                    required: true,
                  }}
                  input={{
                    type: "text",
                    label: "Administrator Surname*",
                    name: "administratorSurname",
                    placeholder: "Administrator Surname",
                    id: "Administrator Surname",
                    autoComplete: "off",
                  }}
                  errors={errors}
                  register={register}
                />
              </div>
              <div className={`${classes["admin-phone-code"]} col-md-2 col-12`}>
                <DropDownInputSearchable
                  validation={{
                    required: true,
                  }}
                  input={{
                    type: "text",
                    label: "Code",
                    name: "code",
                  }}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  dropDownList={countryCodeList}
                />
                <div style={{ height: 12 }}></div>
              </div>
              <div className={`${classes["admin-phone-container"]} col-md-4 col-12`}>
                <SimpleInput
                  validation={{
                    required: true,
                  }}
                  input={{
                    type: "text",
                    label: "Administrator Phone*",
                    name: "administratorPhone",
                    placeholder: "Administrator Phone",
                    id: "Administrator Phone",
                    autoComplete: "off",
                  }}
                  errors={errors}
                  register={register}
                />
              </div>
              <div className="col-md-6 col-12">
                <SimpleInput
                  validation={{
                    required: true,
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email",
                    },
                  }}
                  input={{
                    type: "text",
                    label: "Administrator Email*",
                    name: "administratorEmail",
                    placeholder: "Administrator Email",
                    id: "Administrator Email",
                    autoComplete: "off",
                  }}
                  errors={errors}
                  register={register}
                />
              </div>
              {/* <div className="col-md-6 col-12">
                <SimpleInput
                  validation={{
                    required: true,
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email",
                    },
                  }}
                  input={{
                    type: "text",
                    label: "General Company Email",
                    name: "generalCompanyEmail",
                    placeholder: "General Company Email",
                    id: "General Company Email",
                    autoComplete: "off",
                  }}
                  errors={errors}
                  register={register}
                />
              </div> */}

              {/* <div className="col-12">
                <h5 className={classes["detail-title"]}>Address</h5>
              </div>
              <div className="col-md-6 col-12">
                <SimpleInput
                  validation={{
                    required: true,
                  }}
                  input={{
                    type: "text",
                    label: "Company Street*",
                    name: "companyStreet",
                    placeholder: "Company Street",
                    id: "Company Street",
                    autoComplete: "off",
                  }}
                  errors={errors}
                  register={register}
                />
              </div>
              <div className="col-md-6 col-12">
                <SimpleInput
                  validation={{
                    required: true,
                    minLength: {
                      value: 4,
                      message: "Please enter a valid Zip Code.",
                    },
                  }}
                  input={{
                    type: "text",
                    label: "Company ZIP Code*",
                    name: "companyZipCode",
                    placeholder: "Company ZIP Code",
                    id: "Company ZIP Code",
                    autoComplete: "off",
                  }}
                  errors={errors}
                  register={register}
                />
              </div>
              <div className="col-md-6 col-12">
                <SimpleInput
                  validation={{
                    required: true,
                  }}
                  input={{
                    type: "text",
                    label: "Company Town",
                    name: "companyTown",
                    placeholder: "Company Town",
                    id: "Company Town",
                    autoComplete: "off",
                  }}
                  errors={errors}
                  register={register}
                />
              </div>
              <div className="col-md-6 col-12">
                {countriesList.length > 0 && (
                  <DropDownInput
                    validation={{
                      required: true,
                    }}
                    input={{
                      type: "text",
                      label: "Company Country*",
                      name: "companyCountry",
                    }}
                    errors={errors}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    dropDownList={countriesList}
                  />
                )}
              </div> */}
            </div>

            <div className={classes["line"]}></div>

            <div className="col-12">
              <div className={classes["section-title"]}>
                Social Media
              </div>
              <div className={classes["section-subtitle"]}>
                This information will  be publicly displayed on your profile
              </div>
            </div>
            <div className={`${classes["input-container"]} ${classes["input-container-social"]} row`}>
              <div className="col-md-12 col-12">
                <SimpleInput
                  validation={{
                    required: false,
                    pattern: {
                      value:
                        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                      message: "Please enter a valid url",
                    },
                  }}
                  input={{
                    type: "text",
                    label: "Website",
                    name: "website",
                    placeholder: "Website",
                    id: "Website",
                    autoComplete: "off",
                  }}
                  errors={errors}
                  register={register}
                />
              </div>
              <div className="col-md-6 col-12">
                <SocialInput
                  validation={{
                    pattern: {
                      value:
                        /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|company|school)\/[a-zA-Z0-9-_,~]+\/?$/,
                      message: "Please enter a valid LinkedIn profile",
                    },
                  }}
                  input={{
                    type: "text",
                    label: "LinkedIn link",
                    name: "linkedIn",
                    placeholder: "linkedIn",
                    id: "linkedinLink",
                    autoComplete: "off",
                  }}
                  errors={errors}
                  register={register}
                  icon={imgLinkedin}
                />
              </div>
              <div className="col-md-6 col-12">
                <SocialInput
                  validation={{
                    pattern: {
                      value:
                        /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/[A-Za-z0-9_]{1,15}\/?$/,
                      message: "Please enter a valid X profile",
                    },
                  }}
                  input={{
                    type: "text",
                    label: "X link",
                    name: "X",
                    placeholder: "X",
                    id: "twitterLink",
                    autoComplete: "off",
                  }}
                  errors={errors}
                  register={register}
                  icon={pngTwitter}
                />
              </div>
            </div>

            {/* <div className="col-md-5 col-12">
              <ul className="nav  mb-3 mt-1" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link  ${classes["nav-link"]} ${classes["icon-border"]} active`}
                    id="linkedin-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#linkedin"
                    type="button"
                    role="tab"
                    aria-controls="linkedin"
                    aria-selected="true"
                  >
                    <span
                      className={`${classes["social-icon"]}`}
                    >
                      <img src={imgLinkedin} alt="linkedin" />
                    </span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link  ${classes["nav-link"]} ${classes["icon-border"]}`}
                    id="xing-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#xing"
                    type="button"
                    role="tab"
                    aria-controls="xing"
                    aria-selected="false"
                  >
                    <span
                      className={`${classes["social-icon"]}`}
                    >
                      <img src={imgXing} alt="linkedin" />
                    </span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link  ${classes["nav-link"]}`}
                    id="instagram-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#instagram"
                    type="button"
                    role="tab"
                    aria-controls="instagram"
                    aria-selected="false"
                  >
                    <span
                      className={`${classes["social-instagram"]}`}
                    >
                      <img src={imgInstagram} alt="linkedin" />
                    </span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link  ${classes["nav-link"]} ${classes["icon-border"]}`}
                    id="twitter-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#twitter"
                    type="button"
                    role="tab"
                    aria-controls="twitter"
                    aria-selected="false"
                  >
                    <span
                      className={`${classes["social-icon"]}`}
                    >
                      <img src={imgTwitter} alt="linkedin" />
                    </span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link  ${classes["nav-link"]} ${classes["icon-border"]}`}
                    id="facebook-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#facebook"
                    type="button"
                    role="tab"
                    aria-controls="facebook"
                    aria-selected="false"
                  >
                    <span
                      className={`${classes["social-icon"]}`}
                    >
                      <img src={imgFacebook} alt="linkedin" />
                    </span>
                  </button>
                </li>
              </ul>
            </div> */}
            {/* <div className="col-md-7 col-12">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="linkedin"
                  role="tabpanel"
                  aria-labelledby="linkedin-tab"
                >
                  <SimpleInput
                    validation={{
                      pattern: {
                        value:
                          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                        message: "Please enter a valid url",
                      },
                    }}
                    input={{
                      type: "text",
                      label: "Linkedin link",
                      name: "linkedin",
                      placeholder: "linkedin",
                      id: "linkedinLink",
                      autoComplete: "off",
                    }}
                    errors={errors}
                    register={register}
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="xing"
                  role="tabpanel"
                  aria-labelledby="xing-tab"
                >
                  <SimpleInput
                    validation={{
                      pattern: {
                        value:
                          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                        message: "Please enter a valid url",
                      },
                    }}
                    input={{
                      type: "text",
                      label: "Xing link",
                      name: "xing",
                      placeholder: "xing",
                      id: "xingLink",
                      autoComplete: "off",
                    }}
                    errors={errors}
                    register={register}
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="instagram"
                  role="tabpanel"
                  aria-labelledby="instagram-tab"
                >
                  <SimpleInput
                    validation={{
                      pattern: {
                        value:
                          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                        message: "Please enter a valid url",
                      },
                    }}
                    input={{
                      type: "text",
                      label: "Instagram link",
                      name: "instagram",
                      placeholder: "xing",
                      id: "instagramLink",
                      autoComplete: "off",
                    }}
                    errors={errors}
                    register={register}
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="twitter"
                  role="tabpanel"
                  aria-labelledby="twitter-tab"
                >
                  <SimpleInput
                    validation={{
                      pattern: {
                        value:
                          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                        message: "Please enter a valid url",
                      },
                    }}
                    input={{
                      type: "text",
                      label: "Twitter link",
                      name: "twitter",
                      placeholder: "twitter",
                      id: "twitterLink",
                      autoComplete: "off",
                    }}
                    errors={errors}
                    register={register}
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="facebook"
                  role="tabpanel"
                  aria-labelledby="facebook-tab"
                >
                  <SimpleInput
                    validation={{
                      pattern: {
                        value:
                          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                        message: "Please enter a valid url",
                      },
                    }}
                    input={{
                      type: "text",
                      label: "Facebook link",
                      name: "facebook",
                      placeholder: "facebook",
                      id: "facebookLink",
                      autoComplete: "off",
                    }}
                    errors={errors}
                    register={register}
                  />
                </div>
              </div>
            </div> */}
            <div className="col-12 d-flex justify-content-between">
              <button
                onClick={backButton}
                className={` ${classes.back}`}
                type="button"
              >
                <img src={leftArrow} alt="arrow" />
                Back
              </button>
              <button className={`${classes.submit}`} type="submit">
                Continue
              </button>
            </div>
          </div>
        </Card>

      </form>

    </div>
  );
};

export default CompanyDetails;

// <button
// onClick={() => {
//   OnUpdateActiveStep(3);
// }}
// >
// ttt
// </button>
