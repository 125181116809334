import classes from "./FastAndEfficient.module.scss";

import Background from "../../assets/images/home/fast-and-efficient-new.png";

import { Link } from "react-router-dom";

const FastAndEfficient = () => {
	return (
		<section className={`${classes["fast-section"]} container my-5`}>
			<div
				className={`${classes["fast-container"]} flex-column flex-md-row row gap-1`}
			>
				<div
					className={`${classes["info"]} col-11 col-md-5 d-flex flex-column gap-1 gap-md-4`}
				>
					<h2>Fast and Efficient</h2>
					<p>
						Save time and resources by posting your project details on
						Pharmatching. Receive applications from qualified providers who meet
						your requirements.
					</p>
					<Link to="/request?ordering=-created_at">Explore Requests</Link>
				</div>
				<div className={`${classes["image"]} col-11 col-md-6`}>
					<img src={Background} alt="fast-and-efficient" />
				</div>
			</div>
		</section>
	);
};

export default FastAndEfficient;
