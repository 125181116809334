import { useSelector } from "react-redux";
import classes from "./CreateProfile.module.scss";
import Card from "../../../components/UI/CardV2/CardV2";
import EditProfilePicture from "../../../components/EditProfilePicture/EditProfilePicture";
import EditProfileInterests from '../../../components/EditProfileInterests/EditProfileInterests';
import { WARNING_LETTER_LIST } from '../../../constants/WarningLetterList';

const CreateProfile = () => {
  const userProfile = useSelector((state) => state.user.userProfile);
  return (
    <section className={classes.section}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12 col-lg-11 col-12">
            <Card className={classes.card}>
              <div className={`row align-items-center `}>
                <div className={`col-xl-1 col-2 position-relative `}>
                  <EditProfilePicture profile={userProfile} />
                </div>
                <div className={`col-11 ${classes["image-edit"]}`}>
                  <div className={classes["user-name"]}>
                    {userProfile && <span>{userProfile.user.first_name}</span>}{" "}
                    {userProfile && <span>{userProfile.user.last_name}</span>}{" "}
                  </div>
                  <div className={classes["user-email"]}>
                    {" "}
                    <span>
                      {userProfile && <span>{userProfile.user.email}</span>}{" "}
                    </span>
                    <i
                      className={`${classes["verify-icon"]} fas fa-check-circle`}
                    ></i>
                  </div>
                </div>
              </div>
            </Card>
            <EditProfileInterests
            componentUsage = "createProfile"
            initialServiceCategories = {[]}
            initialKeyServices = {[]}
            favouriteWarningLetter={WARNING_LETTER_LIST}
          />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateProfile;
