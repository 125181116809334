import React from 'react';
import classes from './CompanyPlans.module.scss';
import CardV2 from '../../../components/UI/CardV2/CardV2';
import CurrentPlanDetails from '../../../components/CurrentPlanDetails/CurrentPlanDetails';
import LatestInvoice from '../../../components/LatestInvoice/LatestInvoice';
import PaymentInfo from '../../../components/PaymentInfo/PaymentInfo';
import PaymentAccount from "../../../components/PaymentAccount/PaymentAccount";

const CompanyPlans = () => {
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-xl-6 col-12'>
                    <CardV2 className={`${classes['card']} ${classes['bg-left']}`}>
                        <CurrentPlanDetails />
                    </CardV2>
                </div>
                <div className='col-xl-6 col-12'>
                    <CardV2 className={`${classes['card']} ${classes['bg-right']}`} >
                        <LatestInvoice />
                    </CardV2>
                </div>
            </div>
            <div className='row mb-5'>
                <div className='col-12'>
                <CardV2 className={`${classes['card']} px-0`} >
                    <PaymentInfo />
                </CardV2>
                </div>
                <div className='col-12'>
                <CardV2 className={`${classes['card']} px-0`} >
                    <PaymentAccount />
                </CardV2>
                </div>
            </div>
        </div>

    );
};

export default CompanyPlans;