import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import classes from "./NewsSingle.module.scss";
import NewsDetail from "../../components/NewsDetail/NewsDetail";
import NewsSlider from "../../components/NewsSlider/NewsSilder";
import ExploreMoreNews from "../../components/NewsExploreMore/NewsExploreMore";
import useHttp from "../../hooks/use-http";
import { API_NEWS_URL } from "../../constants/API";
import Loading from "../../components/UI/Loading/Loading";

import { getAccessToken } from "../../utils/authentication";

const NewsSingle = () => {
  const [news, setnews] = useState();
  const [feedbackFilled, setFeedbackFilled] = useState(false);
  const [latestNewsList, setLatestNewsList] = useState([]);
  const { id } = useParams();
  const { sendRequest, error, isLoading } = useHttp();

  const getNewsDetail = (news) => {
    setFeedbackFilled(news.feedback_filed)
    setnews(news);
    window.scrollTo(0, 0);
  };

  const getLatestNewsList = (newsList) => {
    let filteredNewsList = newsList.results.filter(news => news.id !== Number(id));
    let latest = filteredNewsList.slice(0, 2);
    setLatestNewsList(latest);
  };

  useEffect(() => {
    const token = getAccessToken();

    sendRequest(
      {
        url: `${API_NEWS_URL}${id}`,
        headers: { Authorization: token && `Bearer ${token}` }
      },
      getNewsDetail
    );
    sendRequest(
      {
        url: `${API_NEWS_URL}?limit=3&sort=-created_at`,

      },
      getLatestNewsList
    );
  }, [sendRequest, id]);

  return (
    <div className={`${classes["main-background"]}`}>
      <section className={`container  ${classes["news-container"]}`}>
        {isLoading && (
          <div
            className={`row align-items-center justify-content-center ${classes["min-h"]}`}
          >
            <Loading className={classes.loading} />
          </div>
        )}
        {news && news.text && !isLoading && <NewsDetail newsId={id} news={news} feedbackFilled={feedbackFilled} />}
        {/*{latestNewsList.length > 0 && !isLoading && (*/}
        {/*  <div className={`row ${classes["latest-news"]}`}>*/}
        {/*    <div className="col-sm-6 col-12 align-self-end">*/}
        {/*      <h4>Latest News</h4>*/}
        {/*    </div>*/}
        {/*    <div className="col-sm-6 col-12 text-end align-self-end">*/}
        {/*      <Link className={classes["explore-All"]} to="/news">*/}
        {/*        Explore all*/}
        {/*      </Link>*/}
        {/*    </div>*/}
        {/*    <div className="col-xl-12 col-sm-11 col-10 mt-4">*/}
        {/*      <NewsSlider latestNewsList={latestNewsList} />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </section>
      {latestNewsList.length > 0 && !isLoading && (
          <ExploreMoreNews latestNewsList={latestNewsList} />
        )}
    </div>
  );
};

export default NewsSingle;
