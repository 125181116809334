import {Link} from "react-router-dom";
import classes from "./FeaturedNewsItem.module.scss";
import Card from "../UI/CardV2/CardV2";
import pharmaNewsLogo from "../../assets/images/pharmatching-news.png";
import {dateFormmater} from "../../utils/common";

const FeaturedNewsItem = (props) => {
    const {news, className} = props;
    return (
        <div className={className}>
            <Link to={`/news/${news.id}`}>
                <Card
                    className={`row justify-content-lg-start justify-content-center  align-items-center ${classes.card}`}>
                    <div className={`col-lg-4 col-md-4 col-4 ${classes["news-box"]}`}>
                        <div style={{backgroundImage: `url(${news.image_url ? news.image_url : pharmaNewsLogo})`}}
                             className={classes["news-image"]}></div>
                    </div>
                    <div className={`col-lg-8 col-md-8 col-8 ${classes["news-details"]}`}>
                        <div className={classes["news-header-box"]}>
                            <div className={classes["news-category"]}>{news.source_name}</div>
                        </div>
                        <div className={classes.title}>
                            <span>{news.title}</span>
                        </div>
                        <p className={classes.decription}>
                            {news.text}
                        </p>

                    </div>
                </Card>
            </Link>

        </div>
    );
};

export default FeaturedNewsItem;
