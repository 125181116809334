import { useState, useEffect } from "react";
import classes from "./Toast.module.scss";
import { useSelector, useDispatch } from "react-redux";
import closeBtn from "../../../assets/images/Icons/close-button-toast.svg";
import { deleteToast } from "../../../redux/Toast/toastAction";

const Toast = () => {
  // const [toastList, setToastList] = useState([]);
  const list = useSelector((state) => state.toast.toastList);
  const dispatch = useDispatch();

  const removeToast = (toastId) => {
    dispatch(deleteToast(toastId));
  };
  // useEffect(() => {
  //   setToastList(list);
  // }, [list]);

  useEffect(() => {
    console.log(list);
    const interval = setInterval(() => {
      if (list.length > 0) {
        dispatch(deleteToast(list[0].id));
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch, list]);

  return (
    <div className={` ${classes["toast-container"]} ${classes["bottom-left"]}`}>
      {list.map((toast, i) => (
        <div
          style={{
            backgroundColor:
              toast.status === "success"
                ? "#EAF7EE"
                : toast.status === "error"
                ? "#FCEDE9"
                : "",
            borderColor:
              toast.status === "success"
                ? "#C2DCC7"
                : toast.status === "error"
                ? "#E9CFCA"
                : "",
          }}
          key={i}
          className={`${classes.toast} ${classes["bottom-left"]}`}
        >
          <div className="d-flex align-items-center w-100">
            <div
              style={{
                backgroundColor:
                  toast.status === "success"
                    ? "#2FCF6F"
                    : toast.status === "error"
                    ? "#E10000"
                    : "",
              }}
              className={classes["icon-container"]}
            >
              {toast.status === "success" && (
                <span>
                  <i className={`${classes.icon} fas fa-check-circle`}></i>
                </span>
              )}
              {toast.status === "error" && (
                <span>
                  <i
                    className={`${classes.icon} fas fa-exclamation-triangle`}
                  ></i>
                </span>
              )}
            </div>
            <p className={`${classes["toast-message"]}`}>{toast.description}</p>
          </div>
          <button
            onClick={() => removeToast(toast.id)}
            className={`btn  ${classes["close-btn"]}`}
          >
            <img src={closeBtn} alt="close" />
          </button>
        </div>
      ))}
    </div>
  );
};

export default Toast;
