import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import classes from "./CreateCompany.module.scss";
import Card from "../../../components/UI/Card/Card";
import StepTitle from "../../../components/Stepper/StepTitle/StepTitle";
import GeneralInfo from "../../../components/Stepper/GeneralInfo/GeneralInfo";
import CompanyExperties from "../../../components/Stepper/CompanyExperties/CompanyExperties";
import CompanyDetails from "../../../components/Stepper/CompanyDetails/CompanyDetails";
import useHttp from "../../../hooks/use-http";
import { API_COMPANIES_URL } from "../../../constants/API";
import { getAccessToken } from "../../../utils/authentication";
import { addToast } from "../../../redux/Toast/toastAction";
import { createRandomId } from "../../../utils/common";
import { updateUserCompany } from '../../../redux/User/userAction';
import { updateIsCompanyChanged } from "../../../redux/Company/companyAction";

const CreateCompany = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [generalInfo, setGeneralInfo] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [companyExperties, setCompanyExperties] = useState({
    keyServices: [],
    categories: [],
    regions: [],
    countries: [],
    specialities: [],
  });
  const { sendRequest, error, isLoading } = useHttp();
  const dispatch = useDispatch();
  let history = useHistory();

  //------------------------------------------------------------------------------------------

  const UpdateActiveStep = (index) => {
    setActiveIndex(index);
  };

  // set states of steps------------------------------------------------------------------------
  const getGeneralInfo = (info) => {
    setGeneralInfo(info);
  };

  const getCompanyDetails = (detail) => {
    setCompanyDetails(detail);
  };

  const getCompanyExperties = (keyItem, experties) => {
    setCompanyExperties((previousState) => {
      return {
        ...previousState,
        [keyItem]: experties,
      };
    });
  };
  // create request object----------------------------------------------------------------------

  const setCreateCompanyRequest = () => {
    return {
      title: generalInfo.companyName,
      slug: generalInfo.companyName.replace(/ /g, "_"),
      introduction: generalInfo.companyIntroduction,
      // service_description: generalInfo.serviceDescription,
      //specialities: [generalInfo.lifeScienceSector],
      size: generalInfo.companySize,
      // vat_id: generalInfo.vat_id,
      // vat_region: generalInfo.vat_region,
      owner_name: companyDetails.administratorName,
      owner_sure_name: companyDetails.administratorSurname,
      tel_code: companyDetails.code,
      owner_tel: companyDetails.administratorPhone,
      owner_email: companyDetails.administratorEmail,
      // email: companyDetails.generalCompanyEmail,
      email: '',
      website: companyDetails.website,
      // address: companyDetails.companyStreet,
      address: '',
      // town: companyDetails.companyTown,
      town: '',
      // zip_code: companyDetails.companyZipCode,
      zip_code: '',
      // country: companyDetails.companyCountry,
      country: [],
      twitter: companyDetails.twitter,
      // instagram: companyDetails.instagram,
      // facebook: companyDetails.facebook,
      linkedin: companyDetails.linkedin,
      // xing: companyDetails.xing,
      instagram: '',
      facebook: '',
      xing: '',
      service_categories: companyExperties.categories,
      key_services: companyExperties.keyServices,
      regions: companyExperties.regions,
      countries: companyExperties.countries,
      specialities: companyExperties.specialities,
      company_type: 1,
    };
  };
  //------------------------------------------------------------------------------------------
  const getCreatedCompanyRes = (res) => {
    //uploadCreateCompanyFiles(res.id);
    //console.log('createdCompany:', res);
    history.push("/welcome-pharmatching");
    
    dispatch(updateUserCompany(res));
    // dispatch(
    //   addToast({
    //     id: createRandomId(),
    //     status: "success",
    //     description: "Your company has been created successfully"
    //   })
    // );
    window.location.reload();
  };

  const sumbitCreateCompany = (submittedForm) => {
    const token = getAccessToken();
    const companyRequest = setCreateCompanyRequest();
    const formData = new FormData();
    formData.append('title', companyRequest.title);
    formData.append('slug', companyRequest.slug);
    formData.append('description', companyRequest.introduction);
    // formData.append('service_description', companyRequest.service_description);
    // formData.append('specialities', companyRequest.specialities);
    formData.append('size', companyRequest.size);
    formData.append('owner_name', companyRequest.owner_name);
    formData.append('owner_sure_name', companyRequest.owner_sure_name);
    formData.append('tel_code', companyRequest.tel_code);
    formData.append('owner_tel', companyRequest.owner_tel);
    formData.append('owner_email', companyRequest.owner_email);
    formData.append('email', companyRequest.email);
    formData.append('website', companyRequest.website);
    formData.append('address', companyRequest.address);
    formData.append('town', companyRequest.town);
    formData.append('zip_code', companyRequest.zip_code);
    formData.append('country', companyRequest.country);
    formData.append('twitter', companyRequest.twitter);
    formData.append('instagram', companyRequest.instagram);
    formData.append('facebook', companyRequest.facebook);
    formData.append('linkedin', companyRequest.linkedin);
    formData.append('xing', companyRequest.xing);
    // formData.append('specialities', companyRequest.specialities);
    let specialitiesArray = companyRequest.specialities.map((i) => Number(i));
    for (var i = 0; i < specialitiesArray.length; i++) {
      formData.append('specialities', specialitiesArray[i]);
    }
    //formData.append('service_categories',companyRequest.service_categories); 
    let categoryArray = companyRequest.service_categories.map((i) => Number(i));
    for (var i = 0; i < categoryArray.length; i++) {
      formData.append('service_categories', categoryArray[i]);
    }
    //formData.append('key_services', companyRequest.key_services);
    let keyServiceArray = companyRequest.key_services.map((i) => Number(i));
    for (var i = 0; i < keyServiceArray.length; i++) {
      formData.append('key_services', keyServiceArray[i]);
    }
    //formData.append('regions', companyRequest.regions);
    let regionsArray = companyRequest.regions.map((i) => Number(i));
    for (var i = 0; i < regionsArray.length; i++) {
      formData.append('regions', regionsArray[i]);
    }
    //formData.append('countries', companyRequest.countries);
    let countrieArray = companyRequest.countries.map((i) => Number(i));
    for (var i = 0; i < countrieArray.length; i++) {
      formData.append('countries', countrieArray[i]);
    }
    formData.append('company_type', 1);
    if (typeof submittedForm.attachFiles === "object") {
      formData.append("file", submittedForm.attachFiles);
    }
    if (typeof generalInfo.profilePicture === "object") {
      formData.append("logo", generalInfo.profilePicture);
    }
    sendRequest(
      {
        url: `${API_COMPANIES_URL}/company/`,
        method: "POST",
        body: formData,
        headers: { Authorization: `Bearer ${token}` },
      },
      getCreatedCompanyRes
    );
  };
  // upload file and company picture after creating company(second call)-------------------------
  const getUploadedCreatedCompanyFile = (data) => {
    dispatch(updateUserCompany(data));
    history.push("/");
  };

  const uploadCreateCompanyFiles = (id) => {
    console.log(generalInfo.profilePicture);
    console.log(generalInfo.attachFiles);
    const formData = new FormData();
    formData.append("logo", generalInfo.profilePicture);
    formData.append("file", generalInfo.attachFiles);
    const token = getAccessToken();
    sendRequest(
      {
        url: `${API_COMPANIES_URL}/company/${id}`,
        method: "PATCH",
        body: formData,
        headers: { Authorization: `Bearer ${token}` },
      },
      getUploadedCreatedCompanyFile
    );
  };
  //------------------------------------------------------------------------------------------
  useEffect(() => {
    console.log(error);
    if (error && error.status === 400) {
      dispatch(
        addToast({
          id: createRandomId(),
          status: "error",
          description: error.data.detail
            ? error.data.detail
            : 'There is a problem!',
        })
      );
    }
  }, [dispatch, error]);

  return (
    <section className={classes.section}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-2 col-lg-11 col-12 d-md-block d-none">
            <Card className={classes["title-card"]}>
              <StepTitle
                index="1"
                activeIndex={activeIndex}
                title="General Info"
              />
              <div
                style={{ background: activeIndex > 1 ? "#1E4C90" : "#7894BC" }}
                className={classes.line}
              ></div>
              <StepTitle
                index="2"
                activeIndex={activeIndex}
                title="Company Details"
              />
              <div
                style={{ background: activeIndex > 2 ? "#1E4C90" : "#7894BC" }}
                className={classes.line}
              ></div>
              <StepTitle
                index="3"
                activeIndex={activeIndex}
                title="Company Expertise"
              />
            </Card>
          </div>
          <div className="col-xl-10 col-lg-11 col-12">
            {/* <Card className={classes["step-card"]}> */}
            <GeneralInfo
              OnUpdateActiveStep={UpdateActiveStep}
              onGetGeneralInfo={getGeneralInfo}
              generalInfo={generalInfo}
              activeIndex={activeIndex}
              generalInfoErrors={error}
              isCreateCompany={true}
            />

            <CompanyDetails
              OnUpdateActiveStep={UpdateActiveStep}
              onGetCompanyDetails={getCompanyDetails}
              companyDetails={companyDetails}
              activeIndex={activeIndex}
              companyDetailErrors={error}
            />

            <CompanyExperties
              onSumbitCreateCompany={sumbitCreateCompany}
              OnUpdateActiveStep={UpdateActiveStep}
              onGetCompanyExperties={getCompanyExperties}
              companyExperties={companyExperties}
              activeIndex={activeIndex}
              activeLoading={isLoading}
              submitButtonText='Create My Company'
              generalInfo={generalInfo}
            />
            {/* </Card> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateCompany;
