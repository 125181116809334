import { useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import classes from "./EditPersonalInformation.module.scss";
import Card from "../UI/CardV2/CardV2";
import EditProfilePicture from "../EditProfilePicture/EditProfilePicture";
import { useForm } from "react-hook-form";
import SimpleInput from "../UI/SimpleInput/SimpleInput";
import useHttp from "../../hooks/use-http";
import { getAccessToken } from "../../utils/authentication";
import { API_AUTH_URL } from "../../constants/API";
import { addToast } from "../../redux/Toast/toastAction";
import { createRandomId } from "../../utils/common";
import { updateIsProfileChange } from "../../redux/User/userAction";
import alertYellow from "../../assets/images/Icons/alert-yellow.svg";

const EditPersonalInformation = ({ profile, backButtonHandler }) => {
  const [displayChangeEmail, setDisplayChangeEmail] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: profile.user.email,
    },
  });

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm();

  const { sendRequest, error, isLoading } = useHttp();
  const dispatch = useDispatch();

  const changePasswordResponse = (res) => {
    console.log(res);
    displayUpdatedPasswordToast();
  };

  const changeEmailResponse = (res) => {
    console.log(res);
    dispatch(updateIsProfileChange(true));
  };

  const changeEmail = (submittedForm) => {
    console.log(submittedForm);
    const token = getAccessToken();
    const changeEmailRequest = {
      email: submittedForm.email,
    };
    sendRequest(
      {
        url: `${API_AUTH_URL}change-email/`,
        method: "POST",
        body: changeEmailRequest,
        headers: { Authorization: `Bearer ${token}` },
      },
      changeEmailResponse
    );
  };

  const changeEmailHandler = (e) => {
    if (profile.pending_email) {
      if (e.target.value !== profile.user.email) {
        setDisplayChangeEmail(true);
      } else {
        setDisplayChangeEmail(false);
      }
    }
  };

  const changePassword = (submittedForm) => {
    const token = getAccessToken();
    const changePasswordRequest = {
      old_password: submittedForm.currentPassword,
      password: submittedForm.newPassword,
      confirm_password: submittedForm.confirmPassword,
    };
    sendRequest(
      {
        url: `${API_AUTH_URL}change-password/`,
        method: "POST",
        body: changePasswordRequest,
        headers: { Authorization: `Bearer ${token}` },
      },
      changePasswordResponse
    );
  };

  const displayUpdatedPasswordToast = useCallback(() => {
    dispatch(
      addToast({
        id: createRandomId(),
        status: "success",
        description: "Your password was successfully changed!",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    console.log(error);
    if (error && error.status === 400) {
      dispatch(
        addToast({
          id: createRandomId(),
          status: "error",
          description: error.data.errors.old_password
            ? error.data.errors.old_password
            : error.data.errors.email
              ? error.data.errors.email
              : error.data.errors["non_field_errors"],
        })
      );
    }
  }, [dispatch, error]);

  useEffect(() => {
    if (profile.pending_email) {
      setDisplayChangeEmail(false);
    }
  }, [profile.pending_email]);

  return (
    <div className="align-items-center">
      <Card className={`${classes.card} ${classes["card-margin"]}`}>
        <form onSubmit={handleSubmit(changeEmail)}>
          <div className="row align-items-end">
            <div className={`col-lg-2 col-md-3 col-12 position-relative ${classes["avatar-box"]}`}>
              <EditProfilePicture profile={profile} />
            </div>
            <div className="col-lg-4 col-md-5">
              <div className={classes["single-input-box"]}>
                <input
                  autoComplete="off"
                  placeholder="Email"
                  type="email"
                  style={{ borderColor: errors["email"] ? "#E10000" : "" }}
                  className={classes["single-input"]}
                  {...register("email", { required: true })}
                  onChange={(e) => changeEmailHandler(e)}
                />
                <label
                  style={{ color: errors["email"] ? "#E10000" : "" }}
                  htmlFor="email"
                  className={classes["single-input-label"]}
                >
                  Email
                </label>
                {errors["email"] && errors["email"].type === "required" && (
                  <span className={classes["single-input-error"]}>
                    This is required
                  </span>
                )}
                {errors["email"] && errors["email"].message && (
                  <span className={classes["single-input-error"]}>
                    {" "}
                    {errors["email"].message}
                  </span>
                )}
                <span>
                  <i
                    className={`${classes["verify-icon"]} fas fa-check-circle`}
                  ></i>
                </span>
              </div>
            </div>
            {profile.pending_email && !displayChangeEmail && (
              <div className="col-12 mt-3">
                <Card className={classes["card-email-alert"]}>
                  <div className={classes["email-alert-image"]}>
                    <img src={alertYellow} alt="alert" />
                  </div>
                  <p className={classes["email-txt"]}>
                    You’ve made a change to your email address. We’ll send a
                    verification email to the new email address you entered.
                    Please click the verification button in that email to make the
                    change effective.
                  </p>
                </Card>
              </div>
            )}
            {displayChangeEmail && (
              <div className="col-lg-6 col-md-4 h-70 text-end">
                <button
                  className={`btn ${classes["confirm-btn"]} ${classes.button}`}
                  type="submit"
                >
                  Change Email
                </button>
              </div>
            )}
          </div>
        </form>
      </Card>
      <Card className={classes.card}>
        <form onSubmit={handleSubmit2(changePassword)}>
          <div className="row">
            <div className="col-md-4 col-12">
              <SimpleInput
                validation={{
                  required: true,
                }}
                input={{
                  type: "password",
                  label: "Current Password",
                  name: "currentPassword",
                  placeholder: "Current Password",
                  id: "currentPassword",
                  autoComplete: "off",
                  hasappend: "true"
                }}
                errors={errors2}
                register={register2}
              />
            </div>
            <div className="col-md-4 col-12">
              <SimpleInput
                validation={{
                  required: true,
                }}
                input={{
                  type: "password",
                  label: "New Password",
                  name: "newPassword",
                  placeholder: "New Password",
                  id: "newPassword",
                  autoComplete: "off",
                  hasappend: "true"
                }}
                errors={errors2}
                register={register2}
              />
            </div>
            <div className="col-md-4 col-12">
              <SimpleInput
                validation={{
                  required: true,
                }}
                input={{
                  type: "password",
                  label: "Confirm Password",
                  name: "confirmPassword",
                  placeholder: "Password",
                  id: "confirmPassword",
                  autoComplete: "off",
                  hasappend: "true"
                }}
                errors={errors2}
                register={register2}
              />
            </div>
            <div className="col-12 text-end">
              <button
                className={`btn ${classes["confirm-btn"]} ${classes.button}`}
                type="submit"
              >
                Change Password
              </button>
            </div>
          </div>
        </form>
      </Card>
      <div className="d-flex">
        <button
          onClick={backButtonHandler}
          className={`btn ${classes.back} ${classes.button}`}
          type="button"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default EditPersonalInformation;
