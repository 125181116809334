import {
  SHOW_CREATE_REQUEST_MODAL,  
  SHOW_EDIT_REQUEST_MODAL,
  SET_REQUEST_CURRENT_ID,
  SHOW_EDIT_COMPANY_INFO,
  SHOW_EDIT_COMPANY_EXPERTISE,  
} from "./modalActionType";

export const showCreateRequestModal = (isOpen) => {
  return {
    type: SHOW_CREATE_REQUEST_MODAL,
    payload: isOpen,
  };
};
export const showEditRequestModal = (isOpen) => {
  return {
    type: SHOW_EDIT_REQUEST_MODAL,
    payload: isOpen,
  };
};
export const setRequestCurrentId = (isOpen) => {
  return {
    type: SET_REQUEST_CURRENT_ID,
    payload: isOpen,
  };
};
export const showEditCompanyInfo = (isOpen) => {
  return {
    type: SHOW_EDIT_COMPANY_INFO,
    payload: isOpen,
  };
};
export const showEditCompanyExpertise = (isOpen) => {
  return {
    type: SHOW_EDIT_COMPANY_EXPERTISE,
    payload: isOpen,
  };
};
