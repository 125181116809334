import classes from "./WarningLetterDropdownFilter.module.scss";
import { useAccordion } from "../../../hooks/use-accordion";
import { useForm } from "react-hook-form";
import SimpleCheckBoxFilterBox from "../SimpleCheckBoxFilterBox/SimpleCheckBoxFilterBox";
const WarningLetterDropdownFilter = ({
  filterItem,
  dispatchFilterItem,
  dropdownItems,
  dropdownTitle,
}) => {
  const getSortItemHandler = (item) => {
    dispatchFilterItem(dropdownItems[item]);
  };
  const { register, handleSubmit, setValue, reset, getValues } = useForm();
  const [accordionRef, isOpen, setIsOpen] = useAccordion();
  const findSortItem = () => {
    let result = Object.keys(dropdownItems).find(
      (key) => dropdownItems[key] === filterItem
    );
    return result ? result : dropdownTitle;
  };

  return (
    <div className={`dropdown ${classes.accordion}`}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`${classes["accordion-button"]}`}
        type="button"
        aria-expanded={isOpen}
      >
        {/* <span>{findSortItem()}</span> */}
        Source
      </button>
      {isOpen && (
        <div dir="rtl" className={classes["accordion-list"]}>          
          <div dir="ltr" className={classes['accordion-items']}>
            {Object.keys(dropdownItems).map((key) => (              
              <div
                key={key}
                className={`${classes.items}`}
                onClick={() => getSortItemHandler(key)}
                style={{  background: filterItem === dropdownItems[key] ? "#dee4ee" : "",
                color: filterItem === dropdownItems[key] ? "#1E4C90" : "#85898C",
              }}
              >
                <span>{key}</span>
              </div>

            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default WarningLetterDropdownFilter;
