import { useState, useEffect } from "react";
import classes from "./CompaniesFilterBox.module.scss";
import SimpleFormGroup from "../UI/SimpleFormGroup/SimpleFormGroup";
import DropdownFilter from "../UI/DropdownFilter/DropdownFilter";
import SearchableDropdownFilter from "../UI/SearchableDropdownFilter/SearchableDropdownFilter";
import AccordionFilterSearchable from "../UI/AccordionFilterSearchable/AccordionFilterSearchable";
import { API_TAGS_URL } from "../../constants/API";
import useHttp from "../../hooks/use-http";
import Card from "../UI/CardV2/CardV2";
import filterIcon from '../../assets/images/Icons/filter.svg';
import clearIcon from '../../assets/images/Icons/clear.svg';

const CompaniesFilterBox = (props) => {
  const {
    onRegionsId,
    onCountryId,
    onCompanySizeId,
    onSearchCompany,
    onSpecialities,
    onServiceCategories,
    onKeyServices,
    searchItem,
    companySizeId,
    countryId,
    regionsId,
    initialSpecialities,
    initialServiceCategories,
    initialKeyServices,
    onResetFilter
  } = props;
  const [countriesList, setCountriesList] = useState([]);
  const [companySizeList, setCompanySizeList] = useState([]);
  const [regionsList, SetRegionsList] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [keyServices, setKeyServices] = useState([]);

  const { sendRequest, error, isLoading } = useHttp();


  const getCountriesList = (countries) => {
    setCountriesList(countries);
  };
  const getCompanySizeList = (companySize) => {
    setCompanySizeList(companySize);
  };
  const getRegionsList = (regions) => {
    SetRegionsList(regions);
  };

  const getSpecialities = (specialities) => {
    setSpecialities(specialities);
  };

  const getServiceCategories = (serviceCategories) => {
    setServiceCategories(serviceCategories);
  };

  const getKeyServices = (keyServices) => {
    setKeyServices(keyServices);
  };

  const handleOnSearchCompany = (searchItem) => {
    onSearchCompany(searchItem);
  };

  const handleOnCompanySizeId = (size) => {
    onCompanySizeId(size);
  };
  const handleOnCountryId = (country) => {
    onCountryId(country);
  };
  const handleOnRegionsId = (region) => {
    onRegionsId(region);
  };
  const handleSpecialities = (specialities) => {
    onSpecialities(specialities)
  }
  const handleServiceCategories = (serviceCategories) => {
    onServiceCategories(serviceCategories)
  }
  const handleKeyServices = (keyServices) => {
    onKeyServices(keyServices)
  }

  useEffect(() => {
    sendRequest(
      {
        url: `${API_TAGS_URL}/countries/`,
      },
      getCountriesList
    );
    sendRequest(
      {
        url: `${API_TAGS_URL}/regions/`,
      },
      getRegionsList
    );
    sendRequest(
      {
        url: `${API_TAGS_URL}/sizes/`,
      },
      getCompanySizeList
    );
    sendRequest(
      {
        url: `${API_TAGS_URL}/specialities/`,
      },
      getSpecialities
    );
    sendRequest(
      {
        url: `${API_TAGS_URL}/service-categories/`,
      },
      getServiceCategories
    );
    sendRequest(
      {
        url: `${API_TAGS_URL}/key-services/`,
      },
      getKeyServices
    );
  }, [sendRequest]);

  return (
    <Card className={classes.card}>
      <div className="container">
        <div className="row">

          <div className="col-12">
            <div className={classes["filter-box-header"]}>
              <div className={classes["filter-box-title"]}>
                <img src={filterIcon} alt="filter" />
                <span>Filters</span>
              </div>
              <div className={classes["filter-box-reset"]}>
                <div
                  onClick={onResetFilter}
                  className={classes["reset-button"]}>
                  Clear All
                </div>
                <img src={clearIcon} alt="clear" />

              </div>
            </div>
            <div className={classes["line"]}></div>
            <AccordionFilterSearchable
              filterName="Company Size"
              initialFilters={companySizeId}
              filterList={companySizeList}
              dispatchFilterList={handleOnCompanySizeId}
            />
            <div className={classes["line"]}></div>
            {/* <div className="col-lg-3 col-md-4 col-12">
            <DropdownFilter
              filterName = "Country"
              filterId={countryId}
              filterList={countriesList}
              dispatchFilterItem={handleOnCountryId}
            />
          </div> */}
            {/* <div className="col-lg-3 col-md-4 col-12">
            <DropdownFilter
              filterName="Regions"
              filterId={regionsId}
              filterList={regionsList}
              dispatchFilterItem={handleOnRegionsId}
            />
          </div> */}

            <AccordionFilterSearchable
              className={classes["left-0px"]}
              filterName="Life Science Sector"
              initialFilters={initialSpecialities}
              filterList={specialities}
              dispatchFilterList={handleSpecialities}
            />
            <div className={classes["line"]}></div>

            <AccordionFilterSearchable
              filterName="Categories"
              initialFilters={initialServiceCategories}
              filterList={serviceCategories}
              dispatchFilterList={handleServiceCategories}
            />

            <div className={classes["line"]}></div>
            <AccordionFilterSearchable
              className={classes["right-0px"]}
              filterName="Key Services"
              initialFilters={initialKeyServices}
              filterList={keyServices}
              dispatchFilterList={handleKeyServices}
            />
          </div>
        </div>
      </div>
    </Card >
  );
};

export default CompaniesFilterBox;
