import React, { Fragment } from "react";
import classes from "./SolutionBanner.module.scss";

const SolutionBanner = ({ children }) => {
    return (
        <section
            className={`${classes["banner-section"]} d-flex flex-column align-items-center`}
        >

            <div
                className={`${classes["banner-container"]} d-flex justify-content-center`}
            >
                <div
                    className={`${classes["title"]} d-flex flex-column gap-1 gap-md-2 justify-content-center`}
                >
                    <h1>
                        Select the Plan that Best Suits Your Needs
                    </h1>
                    <p className={classes["sub-title"]}>
                    Unlock advanced features designed to enhance your connectivity and collaborations within the life sciences community.
                    </p>
                </div>
            </div>
            {children}
        </section>
    );
};

export default SolutionBanner;
