import { useState, useEffect } from "react";
import classes from "./DoneAuthentication.module.scss";
import useHttp from "../../../hooks/use-http";
import { API_AUTH_URL } from "../../../constants/API";
import { useHistory } from "react-router-dom";

import { ReactComponent as Icon } from "../../../assets/images/register-icon.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const DoneAuthentication = ({ emailAddress }) => {
	const [currentTime, setCurrentTime] = useState(59);
	const [isDisabled, setIsDisabled] = useState(false);
	const [httpUrl, setHttpUrl] = useState(null);
	const [activatedRoute, setActivatedRoute] = useState(null);
	const { sendRequest, isLoading } = useHttp();
	const history = useHistory();

	const getResendEmailResponse = (data) => {};

	const resendEmailCounter = () => {
		let count = 59;
		setIsDisabled(true);
		const interval = setInterval(() => {
			count = count - 1;
			if (count > 0) {
				setCurrentTime(count);
			} else if (count === 0) {
				clearInterval(interval);
				setIsDisabled(false);
				setCurrentTime(59);
			}
		}, 1000);
	};

	useEffect(() => {
		resendEmailCounter();
	}, []);

	const resendEmail = () => {
		if (emailAddress) {
			const resendRequest = {
				email: emailAddress,
			};
			sendRequest(
				{
					url: httpUrl,
					method: "POST",
					body: resendRequest,
				},
				getResendEmailResponse
			);
		} else {
			if (activatedRoute.includes("registration")) {
				history.push("/authentication/resend-verification");
			} else if (activatedRoute.includes("forget")) {
				history.push("/authentication/forget-password");
			}
		}
	};

	useEffect(() => {
		if (history.location.pathname.includes("registration")) {
			setActivatedRoute("registration");
			setHttpUrl(`${API_AUTH_URL}resend-verification/`);
		} else if (history.location.pathname.includes("forget")) {
			setActivatedRoute("forget");
			setHttpUrl(`${API_AUTH_URL}forget-password/`);
		}
	}, [history.location.pathname]);

	return (
		<section className={classes["section-container"]}>
			<div
				className={`container ${classes["form-container"]} mx-3 mx-lg-0 my-3 my-md-5`}
			>
				<div className={classes["icon"]}>
					<Icon />
				</div>
				<h2 className={classes["title"]}>Check your email</h2>
				<p className={classes["desc"]}>
					{activatedRoute && activatedRoute.includes("forget") ? (
						"We have sent a reset password link to your email."
					) : (
						<>
							<p className="my-0">Please confirm your email address by</p>
							<p>clicking the link we’ve just sent you.</p>
						</>
					)}
				</p>
				{isDisabled ? (
					<div className={`${classes["counter"]}`}>
						<div className="text-center">Did not receive it?</div>
						Please wait{' '}
						<span className={classes["countdown"]}>00:{currentTime}</span>{' '}
						to resend the email
					</div>
				) : (
					<div
						className={`${classes["resend"]} d-flex flex-column flex-md-row`}
					>
						<span>Not recieved Email?</span>
						<span
							className={classes["btn"]}
							role="button"
							tabIndex={-1}
							onClick={resendEmail}
						>
							Resend
						</span>
					</div>
				)}
				<div className={classes["account"]}>
					<span>Already have an account?</span>
					<Link to="/authentication/login">Login</Link>
				</div>
			</div>
		</section>
	);
};
export default DoneAuthentication;
