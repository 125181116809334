import { useState, useEffect } from "react";
import useHttp from "../../hooks/use-http";
import Card from "../UI/CardV2/CardV2";
import { API_TAGS_URL } from "../../constants/API";
import { REQUEST_STATUS } from "../../constants/RequestStatus";
import classes from "./RequestFilterBox.module.scss";
import SimpleFormGroup from "../UI/SimpleFormGroup/SimpleFormGroup";
import DropdownFilter from "../UI/DropdownFilter/DropdownFilter";
import SearchableDropdownFilter from '../UI/SearchableDropdownFilter/SearchableDropdownFilter';
import AccordionFilter from "../UI/AccordionFilter/AccordionFilter";
import AccordionFilterSearchable from "../UI/AccordionFilterSearchable/AccordionFilterSearchable";
import filterIcon from '../../assets/images/Icons/filter.svg';
import clearIcon from '../../assets/images/Icons/clear.svg';
const RequestFilterBox = (props) => {
  const { sendRequest, error, isLoading } = useHttp();
  const {
    requestStatusId,
    initialServiceCategories,
    initialKeyServices,
    onRequestStatusId,
    onServiceCategories,
    onKeyServices,
    onResetFilter,
  } = props;
  const [serviceCategories, setServiceCategories] = useState([]);
  const [keyServices, setKeyServices] = useState([]);
  const [requestStatusList, setRequestStatusList] = useState([]);

  const handleOnRequestStatusId = (status) => {
    onRequestStatusId(status);
  };
  const handleServiceCategories = (serviceCategories) => {
    onServiceCategories(serviceCategories)
  }
  const handleKeyServices = (keyServices) => {
    onKeyServices(keyServices)
  }
  // Approval Pending with id=2 isnt be part of filter
  const getRequestStatusList = () => {
    const result = REQUEST_STATUS.filter(status => status.id !== 2);
    setRequestStatusList(result);
  }
  const getServiceCategories = (serviceCategories) => {
    setServiceCategories(serviceCategories);
  };

  const getKeyServices = (keyServices) => {
    setKeyServices(keyServices);
  };

  useEffect(() => {
    sendRequest(
      {
        url: `${API_TAGS_URL}/service-categories/`,
      },
      getServiceCategories
    );
    sendRequest(
      {
        url: `${API_TAGS_URL}/key-services/`,
      },
      getKeyServices
    );
    getRequestStatusList()
  }, [sendRequest]);
  return (
    <Card className={classes.card}>
      <div className="container">
        <div className="row">
          <div className="col-12 p-0">
            <div className={classes["filter-box-header"]}>
              <div className={classes["filter-box-title"]}>
                <img src={filterIcon} alt="filter" />
                <span>Filters</span>
              </div>
              <div className={classes["filter-box-reset"]}>
                <div
                  onClick={onResetFilter}
                  className={classes["reset-button"]}>
                  Clear All
                </div>
                <img src={clearIcon} alt="clear" />

              </div>
            </div>
            <div className={classes["line"]}></div>
            <AccordionFilterSearchable
              filterName="Status"
              initialFilters={requestStatusId}
              filterList={requestStatusList}
              dispatchFilterList={handleOnRequestStatusId}
            />
            <div className={classes["line"]}></div>
            <AccordionFilterSearchable
              filterName="Categories"
              initialFilters={initialServiceCategories}
              filterList={serviceCategories}
              dispatchFilterList={handleServiceCategories}
            />
            <div className={classes["line"]}></div>
            <AccordionFilterSearchable
              className={classes["right-0px"]}
              filterName="Key Services"
              initialFilters={initialKeyServices}
              filterList={keyServices}
              dispatchFilterList={handleKeyServices}
            />
          </div>

        </div>
      </div>
    </Card>
  );
};
export default RequestFilterBox;
