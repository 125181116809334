import { Fragment, useState } from "react";
import { Route } from "react-router-dom";
import Login from "./Login/Login";
import Register from "./Register/Register";
import DoneAuthentication from "./DoneAuthentication/DoneAuthentication";
import ResendEmailVerification from "./ResendEmailVerification/ResendEmailVerification";
import ForgetPassword from "./ForgetPassword/ForgetPassword";
import NewPassword from "./NewPassword/NewPassword";
import VerifySuccessful from "./VerifySuccessful/VerifySuccessful";

const Authentication = () => {
  const [resendEmail, setResendEmail] = useState(null);
 
  const resendEmailHandler = (email) => {
    setResendEmail(email);
  };
  return (
    <Fragment>
        <Route exact path="/authentication/login">
          <Login />
        </Route>
        <Route exact path="/authentication/register">
          <Register onResendEmail={resendEmailHandler} />
        </Route>
        <Route exact path="/authentication/done-registration">
          <DoneAuthentication emailAddress={resendEmail} />
        </Route>
        <Route exact path="/authentication/done-forget-password">
          <DoneAuthentication emailAddress={resendEmail} />
        </Route>
        <Route exact path="/authentication/resend-verification">
          <ResendEmailVerification onResendEmail={resendEmailHandler} />
        </Route>
        <Route path="/authentication/forget-password">
          <ForgetPassword onResendEmail={resendEmailHandler} />
        </Route>
        <Route path="/authentication/new-password">
          <NewPassword />
        </Route>
        <Route path="/authentication/verify-successful">
          <VerifySuccessful />
        </Route>
    </Fragment>
  );
};

export default Authentication;
