import { useState, useRef, useEffect } from "react";
import classes from "./UploadImageInput.module.scss";
import addPicture from "../../../assets/images/Icons/add-picture.svg";
import addPhoto from "../../../assets/images/Icons/add-photo.svg";
import CropperModal from "../../Stepper/CroperModal/CroperModal";

const UploadImageInput = ({
  validation,
  input,
  errors,
  register,
  getValues,
  setValue,
  previewdValue,
}) => {
  const [displayCropper, setDisplayCropper] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const ref = useRef();

  const closeCropperModal = () => {
    setDisplayCropper(false);
  };

  const createProfilePicture = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
    setDisplayCropper(true);
  };

  const cropSelectedImage = (previewCroppedImage, croppedFile) => {
    setPreviewImage(previewCroppedImage);
    setValue(input.name, croppedFile);
  };

  useEffect(() => {
    if (typeof previewdValue !== "object") {
      setPreviewImage(previewdValue);
    }
  }, [previewdValue]);
  return (
    <div className={classes["upload-image-box"]}>
      {displayCropper && (
        <CropperModal
          onCloseCropperModal={closeCropperModal}
          companyImage={selectedImage}
          onCropCompanyImage={cropSelectedImage}
        />
      )}
      <input
        accept="image/x-png,image/gif,image/jpeg"
        {...input}
        {...register(input.name, validation)}
        ref={ref}
        onChange={($event) => createProfilePicture($event)}
        hidden
      />
      {(!getValues(input.name) && !previewImage) && (
        // <button
        //   className={`btn ${classes["upload-picture"]}`}
        //   type="button"
        //   onClick={() => ref.current.click()}
        // >
        //   <img className={classes.picture} src={addPicture} alt="edit avatar" />
        //   <span>Upload Profile Picture</span>
        // </button>
        <div
          onClick={() => ref.current.click()}
          className={classes["dv-upload"]}
        >
          <img
            className={classes["logo-preview"]}  
            src={addPhoto}          
            alt="logo"
          />
          <div className={classes.change}>Company <br /> Logo</div>
        </div>
      )}

      {(getValues(input.name) || previewdValue) && (
        <div
          onClick={() => ref.current.click()}
          className={classes["preview-box"]}
        >
          <img
            className={classes["logo-preview"]}
            src={previewImage}
            alt="logo"
          />
          <div className={classes.change}>Change Company Logo</div>
        </div>
      )}
      {errors[input.name] && !getValues(input.name) && (
        <span className={classes["single-input-error"]}>This is required</span>
      )}
    </div>
  );
};

export default UploadImageInput;
