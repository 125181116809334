import classes from "./DashboardSearchFilterBox.module.scss";
import Card from "../UI/CardV2/CardV2";
import SimpleFormGroup from "../UI/SimpleFormGroup/SimpleFormGroup";
import SortItems from "../UI/SortBox/SortItems/SortItems";
import { STATUS_ITEMS } from "../../constants/SortItem";
import ButtonSortItems from "../UI/ButtonSortItems/ButtonSortItems";
import { REQUEST_STATUS } from "../../constants/RequestStatus";

const DashboardSearchFilterBox = ({
  searchItem, onSearchItem,
  sentAppStatus, dispatchSentAppStatus,
}) => {
  const handleOnSearch = (search) => {
    onSearchItem(search);
    console.log(search);
  };
  const onGetStatusItem = (item) => {
    dispatchSentAppStatus(item);
  };

  return (
    <Card className={classes.card}>
      <div className="row">
        <div className="col-lg-4 col-12">
          <SimpleFormGroup
            className="mb-20"
            label="Search Companies"
            inputValue={searchItem}
            dispatchInputValue={handleOnSearch}
          />
        </div>
        <div className="col-lg-3 col-12">

        </div>
        <div className="col-lg-5 col-12">
          <ButtonSortItems
            sortItem={sentAppStatus}
            dispatchSortItem={onGetStatusItem}
            className="mr-auto"
            dropdownItems={STATUS_ITEMS}
            dropdownTitle="Status"
          />
        </div>
      </div>
    </Card>
  );
};

export default DashboardSearchFilterBox;
