import React, { useCallback } from 'react';
import { useParams } from "react-router";
import classes from './CompanyRequestList.module.scss';
import { useState, useEffect } from 'react';
import RequestItem from '../RequestItem/RequestItemV2';
import useHttp from "../../hooks/use-http";
import { Link } from 'react-router-dom';
import DropDownFilter from '../../components/UI/DropdownFilter/DropdownFilter';
import { REQUEST_STATUS } from "../../constants/RequestStatus";
import NotFoundItems from '../UI/NotFoundItems/NotFoundItems';
import {  API_REQUEST_URL } from "../../constants/API";
const CompanyRequestList = ({    
    companyName,
}) => {
    console.log("getrequest");
    const [requestStatusList, setRequestStatusList] = useState([]);
    const [requestStatus, setRequestStatus] = useState();
    const [companyRequests, setCompanyRequests] = useState(null);
    const { sendRequest, error, isLoading } = useHttp();
    const { id } = useParams();
    const getRequestStatusList = () => {
        const result = REQUEST_STATUS.filter(status => status.id !== 2);
        setRequestStatusList(result);

    }
    const getCompanyRequests = (data) => {
        setCompanyRequests(data);
      };
    const handleOnStatusId = (data) => {
        setRequestStatus(data);
        sendRequest(
            { url: `${API_REQUEST_URL}?company_id=${id}&limit=4&offset=0&status=${data}` },
            getCompanyRequests
        );
    }
    const getStatusList = useCallback(() => {
        getRequestStatusList();
    }, [requestStatusList]);
    useEffect(() => {
        getStatusList();
    }, []);

    const fetchCompaniesRequests = useCallback(() => {
        sendRequest(
          { url: `${API_REQUEST_URL}?company_id=${id}&limit=4&offset=0&status=` },
          getCompanyRequests
        );
      }, [id, sendRequest]);
      useEffect(() => {        
        fetchCompaniesRequests();
      }, [fetchCompaniesRequests]);

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.title}>
                    Company Request List
                    <span>
                        <Link to={`/request?ordering=-created_at&page=1&company_id=${id}`}>
                            View all
                        </Link>
                    </span>
                </div>
                <div className={classes.filter}>
                    <DropDownFilter
                        filterName="Status"
                        filterId={requestStatus}
                        filterList={requestStatusList}
                        dispatchFilterItem={handleOnStatusId}
                    />
                </div>
            </div>


            <div className='row'>
                {companyRequests &&  companyRequests.results.length > 0 && companyRequests.results.filter(item => item.company !== "anonymous").map((req) => (
                    <div key={req.id} className='col-lg-3 col-md-6 col-11'>
                        <RequestItem requestItem={req} />
                    </div>
                ))}
                {companyRequests && companyRequests.results.length === 0 && (
                    <div>
                        <NotFoundItems className={classes["not-found"]} item="request" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default CompanyRequestList;