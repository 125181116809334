import React from 'react';
import classes from "./CookiesPolicy.module.scss";

const CookiesPolicy = () => {
    return (
        <div className={`${classes["main-background"]}`}>
            <div className={`container`}>
                <div className={`row ${classes["static-page"]}`}>
                    <div className={`col-12`}>
                        <div className={classes.title}>
                            <h1>Cookies Policy</h1>
                            <h3>www.pharmatching.com</h3>
                        </div>
                        <div className={classes.content}>
                            <div className={classes.section}>
                                <p className={classes.text}>
                                    Please read the following notices carefully to understand our policies and practices regarding and how we handle your personal information. By using or accessing the PHARMATCHING website, you agree to the terms of this cookie policy. This policy may change from time to time and its use will continue. Our website assumes that you accept such changes. Therefore, check the policy regularly for updates.
                            </p>
                                <div className={classes.title}>
                                    1. ABOUT COOKIES
                            </div>
                                <p className={classes.text}>
                                    A cookie is a small text file with a unique identification number that a website server stores on your computer's hard drive and enables the web server to identify your web browser and passively track your activities on this website. A cookie does not reveal any personal data that you have not otherwise disclosed. Cookies do not contain any executable code and cannot be used to infect your computer with a virus. Most web browsers automatically accept cookies, but generally offer the option of configuring your browser so that it accepts all cookies, rejects all cookies or notifies you when a cookie is set. You can change your settings in the settings or in the options menu of your browser change. If you reject cookies, certain functions of our website may not be available to you.
                            </p>
                                <div className={classes.title}>
                                    2. HOW DOES PHARMATCHING USE COOKIES?
                            </div>
                                <p className={classes.text}>
                                    2.1: In accordance with standard industry practice, we use cookies to track the use of the website. PHARMATCHING uses cookies that are absolutely necessary for you to be able to move around its website. PHARMATCHING also uses cookies to improve your experience by remembering your details so that you do not have to re-enter them while browsing our website.
                            <br />
                            2.2 This website uses cookies, among other things, to ensure that people are not counted twice. This analysis cookie does not store any personal data such as your name or your email address. It's only there to help us figure out what's most popular with website visitors.
                            <br />
                            PHARMATCHING collects personal information when you request information about our services or otherwise voluntarily provide such information through our site.
                            </p>
                                <div className={classes.title}>
                                    3. COOKIES USED BY PHARMATCHING
                            </div>
                                <p className={classes.text}>
                                    3.1 PHARMATCHING uses some services to understand how people use our website and what problems they may have while using it.
                            <br />
                            3.1.1 Google Analytics: We use Google Analytics (which uses cookies) to understand what actions users take when using the website and what information about the user's device is used to access the website. The data we collect in Google Analytics is anonymous and does not contain any personal data. You can use this Google deactivation tool to deactivate our use of Google Analytics. Google has more information about the cookies they use and how you know what is being tracked.
                            <br />
                            3.2 PHARMATCHING uses cookies for the following purposes:
                            <br />
                            (a) Authentication: We use cookies to identify you when you visit our website and when you surf our website;
                            <br />
                            (b) Status - We use cookies to determine whether you are logged into our website;
                            <br />
                            (c) Personalization: We use cookies to store information about your preferences and to personalize the website for you;
                            <br />
                            (d) Security: We use cookies as an element of security measures to protect users.

                            </p>
                                <div className={classes.title}>
                                    4. GENERAL DATA PROTECTION REGULATIONS
                            </div>
                                <p className={classes.text}>
                                    As a user of PHARMATCHING you are entitled to the following rights:
                            <ul>
                                        <li>The right to be informed;</li>
                                        <li>The right of access;</li>
                                        <li>The right to rectification;</li>
                                        <li>The right to erasure;</li>
                                        <li>The right to restrict processing;</li>
                                        <li>The right to data portability;</li>
                                        <li>The right to object.</li>
                                    </ul>
                                </p>
                                <div className={classes.title}>
                                    5. HOW TO DELETE COOKIES
                            </div>
                                <p className={classes.text}>
                                    You can control, delete and reject your cookies in your browser settings.
                            </p>
                                <div className={classes.title}>
                                    6. ACCEPTANCE
                            </div>
                                <p className={classes.text}>
                                    Your access to and use of PHARMATCHING is subject to your acceptance and compliance with this cookie policy. These terms apply to all visitors, users and others who access or use the website. In accordance with this policy, you consent to our use of cookies in accordance with the terms and conditions set out herein.
                            </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookiesPolicy;