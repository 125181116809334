import classes from "./CompanyViewSummary.module.scss";
import Card from "../UI/Card/Card";
import youtube from "../../assets/images/Icons/youtube.png";
import facebook from "../../assets/images/Icons/facebook.png";
import linkedin from "../../assets/images/Icons/link.png";
import twitter from "../../assets/images/Icons/twitter.png";
import NoData from "../UI/NoData/NoData";
import defaultLogo from "../../assets/images/placeholder.svg";
import { Fragment } from "react";
const CompanyViewSummary = (props) => {
  const { comapnyInfo } = props;
  let srcLogo = comapnyInfo.logo ? comapnyInfo.logo : defaultLogo;
  return (
    <Fragment>
      <div className={classes["header-card"]}>
        <div
          style={{ backgroundImage: `url(${srcLogo})` }}
          className={classes["company-logo"]}
        ></div>
        {/* <div className={classes["company-status"]}>
          <span> {comapnyInfo.status}</span>
          <span>company</span>
        </div> */}
      </div>
      <div className={classes.title}>
        <span>{comapnyInfo.title}</span>
      </div>
      {/* <div className={classes["card-info"]}>
        <div className={classes.item}>
          <span className={classes.key}>Address:&nbsp;</span>
          {comapnyInfo.address ? (
            <span className={classes.value}>{comapnyInfo.address}</span>
          ) : (
            <NoData className={classes["no-data"]} />
          )}
        </div>
        <div className={classes.item}>
          <span className={classes.key}>Website:&nbsp;</span>
          {comapnyInfo.website ? (
            <span className={classes.value}>{comapnyInfo.website}</span>
          ) : (
            <NoData className={classes["no-data"]} />
          )}
        </div>
        <div className={classes.item}>
          <span className={classes.key}>Country(s):&nbsp;</span>
          {comapnyInfo.countries_data.length > 0 ? (
            comapnyInfo.countries_data.map((country, index) => (
              <div key={country.id} className="d-inline-block me-1">
                <span className={classes.value}>{country.title}</span>
                {index !== comapnyInfo.countries_data.length - 1 ? (
                  <span>,</span>
                ) : (
                  ""
                )}
              </div>
            ))
          ) : (
            <NoData className={classes["no-data"]} />
          )}
        </div>
        <div className={classes.item}>
          <span className={classes.key}>Supported Region(s):&nbsp;</span>
          {comapnyInfo.regions_data.length > 0 ? (
            comapnyInfo.regions_data.map((region) => (
              <span key={region.id} className={classes.region}>
                {region.title}
              </span>
            ))
          ) : (
            <NoData className={classes["no-data"]} />
          )}
        </div>
      </div>
      <div className={classes["social-media"]}>
        <ul className={classes.list}>
          {comapnyInfo.twitter && (
            <li>
              <a href={comapnyInfo.twitter}>
                <img className={classes.logo} src={twitter} alt="twitter" />
              </a>
            </li>
          )}
          {comapnyInfo.facebook && (
            <li>
              <a href={comapnyInfo.facebook}>
                <img className={classes.logo} src={facebook} alt="facebook" />
              </a>
            </li>
          )}
          {comapnyInfo.linkdin && (
            <li>
              <a href={comapnyInfo.linkdin}>
                <img className={classes.logo} src={linkedin} alt="linkdin" />
              </a>
            </li>
          )}
          {comapnyInfo.youtube && (
            <li>
              <a href={comapnyInfo.youtube}>
                <img className={classes.logo} src={youtube} alt="youtube" />
              </a>
            </li>
          )}
        </ul>
      </div> */}
    </Fragment>
  );
};

export default CompanyViewSummary;
