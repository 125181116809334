import { useState, useEffect, useCallback } from "react";
import classes from "./HomeWarningLetters.module.scss";

import useHttp from "../../hooks/use-http";
import { API_NEWS_URL } from "../../constants/API";
import {
	resetQueryParams,
	updateUniqueApiParams,
} from "../../utils/QueryParams";
import HomeTabButton from "../HomeTabButton/HomeTabButton";
import Loading from "../UI/Loading/Loading";
import NotFoundItems from "../UI/NotFoundItems/NotFoundItems";
import { HOME_TAB_BUTTON, HOME_TAB_CONTENT } from "../../constants/HomeTab";
import { Link } from "react-router-dom";

import { dateFormmater } from "../../utils/common";

import FDA from "../../assets/images/warning-letters/FDA.png";
import { ReactComponent as UK } from "../../assets/images/warning-letters/UK.svg";
import { ReactComponent as FourEightThree } from "../../assets/images/warning-letters/483.svg";
import Eudramed from "../../assets/images/warning-letters/Eudramed.png";

const HomeWarningLetters = () => {
	const [warningLetterList, setWarningLetterList] = useState([]);
	const [activatedWarningLetter, SetactivatedWarningLetter] = useState("All");
	const [queryParams, setQueryParams] = useState("?limit=3&offset=0");
	const { sendRequest, isLoading } = useHttp();

	const getWarningLetterList = (data) => {
		console.log(data);
		setWarningLetterList(data.results);
	};

	const changeTab = (tab) => {
		if (activatedWarningLetter === tab.target.id) return;
		setWarningLetterList([]);
		SetactivatedWarningLetter(tab.target.id);
		let result;
		switch (tab.target.id) {
			case "FDA":
				result = updateUniqueApiParams("source", "fda", queryParams);
				setQueryParams(result);
				break;
			case "483":
				result = updateUniqueApiParams("source", "fda_483", queryParams);
				setQueryParams(result);
				break;
			case "UK":
				result = updateUniqueApiParams("source", "uk", queryParams);
				setQueryParams(result);
				break;
			case "Eudramed":
				result = updateUniqueApiParams("source", "eudra", queryParams);
				setQueryParams(result);
				break;
			case "All":
				result = resetQueryParams("source", queryParams);
				setQueryParams(result);
				break;
			default:
				break;
		}
	};

	const fetchNewsList = useCallback(() => {
		sendRequest(
			{
				url: `${API_NEWS_URL}warning_letters/${queryParams}`,
			},
			getWarningLetterList
		);
	}, [queryParams, sendRequest]);

	useEffect(() => {
		fetchNewsList();
		return () => {
			setWarningLetterList([]);
		};
	}, [fetchNewsList, queryParams]);

	const handleIcon = (type) => {
		switch (type) {
			case "FDA Warning Letters":
				return <img src={FDA} alt="fda" />;
			case "UK Warning Letters":
				return <UK />;
			case "FDA 483":
				return <FourEightThree />;
			case "eudra":
				return <img src={Eudramed} alt="Eudramed" />;
			default:
				return "";
		}
	};

	return (
		<section className={`${classes["section-container"]}`}>
			<div className="container">
				<h4 className={classes["title"]}>Latest Warning Letters</h4>
				<p>Stay informed with the latest warning letters and updates from authorities around the world.</p>
				<ul className={`${classes["tab-list"]} gap-3 gap-md-4`} id="pills-tab" role="tablist">
					{HOME_TAB_BUTTON.map((item) => (
						<li
							key={item.id}
							className={classes["tab-list-item"]}
							role="presentation"
						>
							<HomeTabButton
								ariaSelected={item.ariaSelected}
								ariaControls={item.ariaControls}
								dataBsToggle={item.dataBsToggle}
								id={item.id}
								title={item.title}
								className={item.className}
								onChangeTab={changeTab}
							/>
						</li>
					))}
				</ul>
				{isLoading && (
					<div className={classes["loading-box"]}>
						<Loading className={classes["loading"]} />
					</div>
				)}
				{warningLetterList && warningLetterList.length === 0 && !isLoading && (
					<div className={classes["loading-box"]}>
						<NotFoundItems item={activatedWarningLetter} />
					</div>
				)}
				<div className={`${classes["tab-content"]} row gap-3 gap-lg-0 justify-content-center`}>
					{warningLetterList && warningLetterList.map((item) => (
						<div
							key={item.id + item.url}
							className={`col-12 col-md-5 col-lg-4`}
						>
							<a
								href={item.url}
								target="_blank"
								rel="noreferrer"
								className={`${classes["content-card"]}`}
								key={item.id}
							>
								<div>{handleIcon(item.source)}</div>
								<h2 className={classes["subject"]}>{item.subject}</h2>
								<h2 className={classes["company-name"]}>{item.company_name}</h2>
								<p className={classes["date"]}>
									{item.issued_at && dateFormmater(item.issued_at)}
								</p>
							</a>
						</div>
					))}
				</div>
				<Link to="/warning-letter" className={`${classes["explore-link"]} p-4`}>
					Explore All
				</Link>
			</div>
		</section>
	);
};

export default HomeWarningLetters;
