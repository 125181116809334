import classes from "./SolidCheckBox.module.scss";

const SolidCheckBox = ({ input, register }) => {
  return (
    <div key={input.name} className={classes["checkbox-custom-group"]}>
      <input
        id={input.name}
        className={classes["checkbox-custom"]}
        name={input.name}
        type="checkbox"
        {...register(input.name)}
      />
      <label htmlFor={input.name} className={classes["checkbox-custom-label"]}>
        {input.label}
      </label>
    </div>
  );
};

export default SolidCheckBox;
