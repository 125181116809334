import { useState, useEffect } from "react";
import { format, startOfMonth, endOfMonth, startOfDay, parseISO, parse } from "date-fns";
import axios from "axios"; // Assuming you're using axios for HTTP requests
import classes from "./BlogDateFilterBox.module.scss";
import {API_BLOG_URL} from "../../constants/API";

const BlogDateFilterBox = ({ onFilter }) => {
    const [showMore, setShowMore] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [months, setMonths] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDates = async () => {
            try {
                const response = await axios.get(`${API_BLOG_URL}blog-dates/`);
                setMonths(response.data.dates);
                setLoading(false);
            } catch (err) {
                setError("Failed to load dates");
                setLoading(false);
            }
        };

        fetchDates();
    }, []);

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

const handleDateFilter = (date) => {
    try {
        // Correct the date string if necessary
        const correctedDate = date.trim();

        // Parse the date string using `date-fns` with the format "MMMM yyyy"
        const dateObj = parse(correctedDate, 'MMMM yyyy', new Date());

        if (isNaN(dateObj)) {
            console.error('Invalid date format');
            return;
        }

        const selectedDateStartOfDay = selectedDate ? startOfDay(selectedDate).getTime() : null;
        const dateStartOfDay = startOfDay(dateObj).getTime();

        if (selectedDateStartOfDay && selectedDateStartOfDay === dateStartOfDay) {
            setSelectedDate(null);
            onFilter(null);
        } else {
            setSelectedDate(dateObj);
            const from_date = format(startOfMonth(dateObj), "yyyy-MM-dd");
            const to_date = format(endOfMonth(dateObj), "yyyy-MM-dd");
            onFilter({ from_date, to_date });
        }
    } catch (error) {
        console.error('Error handling date filter:', error);
    }
};


    if (error) return <div>{error}</div>;

    const monthsToShow = showMore ? months.length : 4;

    return (
        <div className={classes.dateFilterBox}>
            <h3>Post Publication Date</h3>
            {months.slice(0, monthsToShow).map((month, index) => (
                <div
                    key={index}
                    onClick={() => handleDateFilter(month)}
                    className={`${classes.dateItem} ${
                        selectedDate && startOfDay(selectedDate).getTime() === startOfDay(parse(month, 'MMMM yyyy', new Date())).getTime()
                            ? classes.selected
                            : ""
                    }`}
                >
                    {month}
                </div>
            ))}
            {months.length > 4 && (
                <div onClick={handleShowMore} className={classes.showMore}>
                    {showMore ? "Show Less" : "Show Older"}
                </div>
            )}
        </div>
    );
};

export default BlogDateFilterBox;
