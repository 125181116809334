import React from "react";
import classes from "./GreatNews.module.scss"; // Assuming you'll use an external CSS file for styling
import GreatNewsSvg from "../../assets/images/background/greatnews.svg"
import Post from "../../assets/images/background/greatnews-post.svg"
import Create from "../../assets/images/background/greatnews-create.svg"
import Apply from "../../assets/images/background/greatnews-apply2.svg"

const GreatNews = () => {
  return (
    <div className={classes["free-banner"]} id="great-news">
      {/* Header */}
      <h2 className={classes["banner-title"]}>Great News!</h2>
      <p className={classes["banner-text"]}>
        Pharmatching is Now <strong className={classes["highlighted"]}>Completely Free</strong> for Everyone
      </p>

      {/* Feature List */}
      <div className={classes["features"]}>
        <div className={classes["feature-item"]}>
          <span className={classes["icon"]}><img src={Create}/></span>
          <span>Create Requests</span>
          <span className={classes["free-tag"]}>Free</span>
        </div>
        <div className={classes["feature-item"]}>
          <span className={classes["icon"]}><img src={Post}/></span>
          <span>Post Requests</span>
          <span className={classes["free-tag"]}>Free</span>
        </div>
        <div className={classes["feature-item"]}>
          <span className={classes["icon"]}><img src={Apply}/></span>
          <span>Apply to Requests</span>
          <span className={classes["free-tag"]}>Free</span>
        </div>
      </div>

      {/* Free graphic on the right */}
      <div className={classes["free-graphic"]}>
        
          <img src={GreatNewsSvg}/>
        
      </div>

      {/* Footer text */}
      <p className={classes["footer-text"]}>No credit card required and absolutely no hidden fees!</p>
    </div>
  );
};

export default GreatNews;
