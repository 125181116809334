import { useState, useEffect } from "react";
import classes from "./BlogSearchBox.module.scss";
import Card from "../UI/Card/Card";
import SimpleFormGroupLtr from "../UI/SimpleFormGroupLtr/SimpleFormGroupLtr";
import useHttp from "../../hooks/use-http";
import { API_TAGS_URL } from "../../constants/API";

const BlogSearchBox = (props) => {
  const {
    onSearchNews,
    searchItem
  } = props;
  const handleOnSearchNews = (searchItem) => {
    onSearchNews(searchItem);
  };

  return (
    <Card className={classes.card}>
      <div className="container">
        <div>
          <div>
            <SimpleFormGroupLtr
              className="mb-20"
              label="Search Blogs"
              inputValue={searchItem}
              dispatchInputValue={handleOnSearchNews}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default BlogSearchBox;
