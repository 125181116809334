import React, { useRef } from 'react';
import classes from './SingleTextRichArea.module.scss';
import './SingleTextRichArea.scss';
import { Editor } from '@tinymce/tinymce-react';

const SingleTextRichArea = ({ validation, input, errors, register, getValues, setValue }) => {
    const editorRef = useRef(null);
    
    return (
        <div className={classes["single-input-box"]}>
             <label
                style={{ color: errors[input.name] ? "#E10000" : "" }}
                htmlFor={input.name}
                className={classes["single-input-label"]}
            >{input.label}{" "}</label>
            <Editor
                apiKey='9cz90yrxa5azvv5pfrcgva75m16fvwuau7rzyl9q0gczgmsg'
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={getValues(input.name)}
                onKeyUp={()=>setValue(input.name, editorRef.current.getContent())}
                onChange={()=>setValue(input.name, editorRef.current.getContent())}
                init={{
                    height: 170,
                    menubar: false,
                    plugins: 'lists advlist',
                    toolbar: 'undo redo bold italic| alignleft aligncenter alignright alignjustify| ' +                       
                        ' | bullist numlist outdent indent | ',
                    
                }}
            />
            <textarea
                style={{ borderColor: errors[input.name] ? "#E10000" : "" }}
                className={classes["single-input"]}
                {...input}
                {...register(input.name, validation)}
            />

            
            {errors[input.name] && errors[input.name].type === "required" && (
                <span className={classes["single-input-error"]}>This is required</span>
            )}
            {errors[input.name] && errors[input.name].message && (
                <span className={classes["single-input-error"]}>
                    {" "}                    
                    {errors[input.name].message}
                </span>
            )}
            {errors[input.name] && errors[input.name].type === "positive" && (
                <span className={classes["single-input-error"]}>
                    To ensure the best possible result, we strongly recommend to be more precise with your request description and provide as much information as possible for potential service provider.
                </span>
            )}
        </div>
    );
};

export default SingleTextRichArea;