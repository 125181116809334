import classes from "./Appointment.module.scss";
import appointmentPhoto from "../../assets/images/home/appointment.webp"
import React from "react";

const Appointment = ({title="Book a 15 min FREE demo", link, text, buttonText}) => {
    return (
        <section className={`${classes["appointment"]}`}>
            <div className='container'>
                <div className='row'>
                    <div className={`${classes["appointment-col"]} col-lg-6 col-md-12 d-flex align-items-center justify-content-center`}>
                        <div className={classes.contact_content}>
                            <div className={`row justify-content-center`}>
                                <div className="col-12">
                                    <div className={classes.contact}>
                                        <h3>
                                            {title}
                                        </h3>
                                        <p>
                                            {text}
                                        </p>
                                    </div>
                                </div>
                                <div className={`${classes['contact-button']} col-12`}>
                                    <div
                                        className={classes["select-btn"]}
                                        onClick={() => window.open(link, '_blank')}
                                    >
                                        {buttonText}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes["appointment-col"]} col-lg-6 col-md-12 d-flex align-items-center justify-content-center mt-lg-0 mt-5`}>
                        <img src={appointmentPhoto} alt="appointment"/>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default Appointment;
