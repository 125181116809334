import React from 'react';
import classes from './AllDrafts.module.scss';
import { Link } from 'react-router-dom';
import leftArrow from '../../../assets/images/arrow/left-arrow.svg';
import CardV2 from '../../../components/UI/CardV2/CardV2';
import { useState, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import Pagination from "../../../components/UI/Pagination/Pagination";
import { getAccessToken } from "../../../utils/authentication";
import { API_REQUEST_URL } from "../../../constants/API";
import NotFoundItems from "../../../components/UI/NotFoundItems/NotFoundItems";
import Loading from "../../../components/UI/Loading/Loading";
import { useDispatch } from "react-redux";
import { addToast } from "../../../redux/Toast/toastAction";
import { createRandomId } from "../../../utils/common";
import { updateIsAssetsChnage } from "../../../redux/User/userAction";
import { LIMIT_ITEM_TABLE } from '../../../constants/Common';
import {
    updateUniqueApiParams,
    updateUniqueUrlParams,
    splitQueryParams,
    resetQueryParams,
} from "../../../utils/QueryParams";
import calculateOffset from "../../../utils/calculateOffset";
import SortableTableDrafts from '../../../components/UI/SortableTableDrafts/SortableTableDrafts';

const AllDrafts = () => {
    const [drafttList, setDraftsList] = useState([]);
    const [count, setCount] = useState(null);
    const [searchItem, setSearchItem] = useState(null);
    const [sortItem, setSortItem] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const { sendRequest, error, isLoading } = useHttp();
    const [apiQueryParams, setApiQueryParams] = useState(
        `?limit=${LIMIT_ITEM_TABLE}`
    );
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const getDraftstList = (data) => {
        setDraftsList(data.results);
        setCount(data.count);
        window.scrollTo(0, 0);
    };

    const splitUrlQueryparams = useCallback(() => {
        const queryParams = window.location.search;
        const splitedQueryParams = splitQueryParams(queryParams);
        console.log(splitedQueryParams);
        setDraftStates(splitedQueryParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setDraftStates = (params) => {
        params.page ? setCurrentPage(parseInt(params.page)) : setCurrentPage(1);
        params.ordering ? setSortItem(params.ordering) : setSortItem(null);
        params.search ? setSearchItem(params.search) : setSearchItem(null);
        createOffset(params);
    };

    const createOffset = (params) => {
        let offset;
        // if page is not equal 1
        if (params.page) {
            offset = calculateOffset(parseInt(params.page), LIMIT_ITEM_TABLE);
            delete params.page;
        } // page =1 (page load for first time)
        else {
            offset = calculateOffset(1, LIMIT_ITEM_TABLE);
        }
        params["offset"] = offset;
        createApiQueryParams(params);
    };

    const createApiQueryParams = (params) => {
        let updatedQueryParams = apiQueryParams;
        for (let key in params) {
            updatedQueryParams = updateUniqueApiParams(
                `${key}`,
                params[key],
                updatedQueryParams
            );
        }
        setApiQueryParams(updatedQueryParams);
        fetchDrafttList(updatedQueryParams);
    };

    const fetchDrafttList = (updatedQueryParams) => {
        const accessToken = getAccessToken();
        setCount(null);
        sendRequest(
            {
                url: `${API_REQUEST_URL}draft-user-requests/${updatedQueryParams}`,
                headers: { Authorization: `Bearer ${accessToken}` },
            },
            getDraftstList
        );
    };

    const resetPage = () => {
        setCurrentPage(1);
        let offset = calculateOffset(1, LIMIT_ITEM_TABLE);
        let updatedQueryParams = updateUniqueApiParams(
            "offset",
            offset,
            apiQueryParams
        );
        setApiQueryParams(updatedQueryParams);
        return updatedQueryParams;
    };

    const updateUniqueUrlPage = (result) => {
        history.push({
            pathname: "/dashboard/all-drafts",
            search: result,
        });
    };

    const getDraftListByUniqueFilters = (newkey, newValue) => {
        let updatedQueryParams;
        updatedQueryParams = resetPage();
        // choose new filter
        if (newValue) {
            updatedQueryParams = updateUniqueApiParams(
                newkey,
                newValue,
                updatedQueryParams
            );
            const urlParams = {
                page: 1,
                [newkey]: newValue,
            };
            let result = updateUniqueUrlParams(urlParams);
            updateUniqueUrlPage(result);
        } // choose All item and remove it from url and API
        else {
            // reset page number to 1---------------------------------------
            const urlParams = { page: 1 };
            let result = updateUniqueUrlParams(urlParams);
            result = resetQueryParams(newkey, result);
            // remover filter from params of page---------------------------
            updateUniqueUrlPage(result);
            // remove filter from params of api----------------------------
            updatedQueryParams = resetQueryParams(newkey, updatedQueryParams);
        }
        setApiQueryParams(updatedQueryParams);
    };

    const getSearchDraft = (searchItem) => {
        getDraftListByUniqueFilters("search", searchItem);
        setSearchItem(searchItem);
    };
    const getSortItem = (item) => {
        getDraftListByUniqueFilters("ordering", item);
        setSortItem(item);
    };

    const getActivatedPage = (page) => {
        if (currentPage !== page) {
            setCurrentPage(page);
            let offset = calculateOffset(page, LIMIT_ITEM_TABLE);
            let updatedQueryParams = updateUniqueApiParams(
                "offset",
                offset,
                apiQueryParams
            );
            setApiQueryParams(updatedQueryParams);
            const urlParams = { page };
            let result = updateUniqueUrlParams(urlParams);
            updateUniqueUrlPage(result);
        }
    };
    const displayDeleteDraftToast = useCallback(() => {
        dispatch(
            addToast({
                id: createRandomId(),
                status: "success",
                description: "Your draft was successfully deleted and is no longer available. ",
            })
        );
    }, [dispatch]);
    const afterDraftDelete = () => {
        displayDeleteDraftToast();
        fetchDrafttList(apiQueryParams);
        dispatch(updateIsAssetsChnage(true));
    }

    useEffect(() => {
        splitUrlQueryparams();
    }, [location, splitUrlQueryparams]);

    useEffect(() => {
        if (error && error.status === 400) {
            dispatch(
                addToast({
                    id: createRandomId(),
                    status: "error",
                    description: error.data.errors["0"],
                })
            );
        }
    }, [dispatch, error]);
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-12 mt-5'>
                    <Link to="/dashboard/company-dashboard" className=''>
                        <div className={classes['back-to-dashboard']}>
                            <img src={leftArrow} alt="arrow" />
                            <span>Back to Dashboard</span>
                        </div>
                    </Link>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <CardV2 className={classes.card}>
                        <div className={classes['title']}>
                            Drafts
                        </div>
                        {drafttList.length !== 0 && !isLoading && (
                            <SortableTableDrafts
                                source={drafttList}
                                onSortItem={getSortItem}
                                afterDraftDelete={afterDraftDelete}
                            />
                        )}
                        {isLoading  ? (
                            <div
                                className={`row justify-content-center  align-items-center ${classes["min-h"]}`}
                            >
                                <div className={classes['loading-wrapper']}>
                                    <Loading className={classes.loading} />
                                </div>
                               
                            </div>
                        ) : (
                            ""
                        )}
                        {(drafttList.length === 0 && !isLoading) && (
                            <NotFoundItems className={classes["not-found"]} item="Draft" />
                        )}

                    </CardV2>
                    {count > LIMIT_ITEM_TABLE && (
                        <Pagination
                            onActivatedPage={getActivatedPage}
                            count={count}
                            limit={LIMIT_ITEM_TABLE}
                            currentPage={currentPage}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AllDrafts;