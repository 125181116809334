import classes from "./HowPharmatchingWork.module.scss";
import HowWork1 from "../../assets/images/home/howwork1.svg";
import HowWork2 from "../../assets/images/home/howwork2.svg";
import HowWork3 from "../../assets/images/home/howwork3.svg";
import how from "../../assets/images/home/experience-background.svg";
import React, {useEffect, useState, useRef, Fragment} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/all";
import {showCreateRequestModal} from "../../redux/Modal/modalAction";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

const HowPharmatchingWork = () => {
    const [active, setActive] = useState(0);
    const ScrollingSection = useRef();
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const userRole = useSelector((state) => state.user.userRole);
    const requestmodal = useSelector((state) => state.modal.requestModal);
    const dispatch = useDispatch();
    const history = useHistory();
    const handleActive = (id) => {
        setActive(id);
    };

    const steps = [
        {
            title: "Initiate a request",
            desc: "Post your project tendering details using specialized keywords. Your requirements reach matching providers instantly.",
        },
        {
            title: "Evaluate Applications",
            desc: "Manage and review the applications that start flowing in. Service providers interested in your project will apply directly to your request.",
        },
        {
            title: "Match made",
            desc: "Select the best-fit applicant, receive their contact info for continued collaboration. ",
        },
    ];
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        let mm = gsap.matchMedia();
        mm.add("(min-width: 768px)", () => {
            const section = document.getElementById("experience-section");
            const sectionHeight = section.offsetHeight;
            setTimeout(() => {
                gsap.timeline({
                    scrollTrigger: {
                        id: "experience-section-timeline",
                        trigger: ScrollingSection.current,
                        start: `center center`,
                        end: () =>
                            531 * 3 + "px",
                        pin: true,
                        scrub: true,
                        toggleActions: "play play none reverse",
                        onUpdate: () => {
                            if (
                                section.offsetTop < window.scrollY &&
                                sectionHeight + section.offsetTop > window.scrollY
                            ) {
                                !active && setActive(0);
                            } else if (
                                section.offsetTop + sectionHeight < window.scrollY &&
                                sectionHeight * 1.5 + section.offsetTop > window.scrollY
                            ) {
                                active !== 1 && setActive(1);
                            } else {
                                active !== 2 && setActive(2);
                            }
                        },
                    },
                });
            }, 500)
        });
        // mm.add("(max-width: 767px)", () => {
        // 	const section = document.getElementById("experience-section");
        // 	const sectionHeight = section.offsetHeight;
        // 	const tlMobile = gsap.timeline({
        // 		scrollTrigger: {
        // 			id: "experience-steps",
        // 			trigger: "#experience-steps",
        // 			start: `top top`,
        // 			end: () =>
        // 				document.getElementById("experience-steps").offsetHeight * 3 + "px",
        // 			pin: true,
        // 			scrub: true,
        // 			toggleActions: "play play none reverse",
        // 			markers: true,
        // 			onUpdate: () => {
        // 				if (
        // 					section.offsetTop < window.scrollY &&
        // 					sectionHeight + section.offsetTop > window.scrollY
        // 				) {
        // 					!active && setActive(0);
        // 				} else if (
        // 					section.offsetTop + sectionHeight < window.scrollY &&
        // 					sectionHeight * 1.5 + section.offsetTop > window.scrollY
        // 				) {
        // 					active !== 1 && setActive(1);
        // 				} else {
        // 					active !== 2 && setActive(2);
        // 				}
        // 			},
        // 		},
        // 	});
        // });
        return () => {
            mm.revert();
        };
    }, []);

    return (
        <section
            className={`${classes["experience-section"]} my-5 my-md-4`}
            id="experience-section"
        >
            <div
                id="section-scroller"
                ref={ScrollingSection}
                className={`${classes["experience-container"]} container  my-md-5`}
            >
                <h2>How it works?</h2>
                <p>Here is how you can start your collaboration to accelerate your project with experts.</p>
                <div className={`${classes["steps-container"]} row`}>
                    <div
                        className={`${classes["image"]} col-12 col-md-6 d-flex align-items-center`}
                    >
                        <img
                            src={active === 0 ? HowWork1 : (active === 1 ? HowWork2 : HowWork3)}
                            alt="image1"/>
                        {/* {active === 0 && (<img src={HowWork1} alt="image1" />)}
						{active === 1 && (<img src={HowWork1} alt="image2" />)}
						{active === 2 && (<img src={HowWork1} alt="image3" />)} */}
                    </div>
                    <ul
                        id="experience-steps"
                        className={`${classes["steps-list"]} col-12 col-md-6 mt-5 my-md-0 px-2 px-md-0 justify-content-center`}
                    >
                        <li
                            id={`home-step-0`}
                            onClick={() => handleActive(0)}
                            className={active === 0 ? classes["active"] : ''}
                        >
                            <span>1</span>
                            <h3>{steps[0].title}</h3>
                            <p>{steps[0].desc}</p>
                        </li>
                        <li
                            id={`home-step-1`}
                            onClick={() => handleActive(1)}
                            className={active === 1 ? classes["active"] : ''}
                        >
                            <span>2</span>
                            <h3>{steps[1].title}</h3>
                            <p>{steps[1].desc}</p>
                        </li>
                        <li
                            id={`home-step-2`}
                            onClick={() => handleActive(2)}
                            className={active === 2 ? classes["active"] : ''}
                        >
                            <span>3</span>
                            <h3>{steps[2].title}</h3>
                            <p>{steps[2].desc}</p>
                        </li>
                    </ul>
                </div>
                <div className={`${classes["create-request-link"]} ${classes["how-it-works-btn"]}`}>
                    <Fragment>
                        <div
                            className={`${classes["select-btn"]}`}
                            onClick={() => {
                                isLoggedIn && (userRole === "company" || userRole === "freelancer") ? dispatch(showCreateRequestModal(true)) : history.push("/authentication/login");
                            }}
                        >
                            Place your Request for FREE
                        </div>
                    </Fragment>
                </div>
            </div>
        </section>
    );
};

export default HowPharmatchingWork;
