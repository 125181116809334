import classes from "./SelectableButton.module.scss";
import tick from "../../../assets/images/Icons/tick-icon.svg";

const SelectableButton = ({
    item,
    dispatchSelectedItem,
    selectedId

}) => {
    let selectedCard =
        item.id == selectedId
            ? true
            : false;
    const selectedItemHandler = () => {
        dispatchSelectedItem(item.id);
    };
    return (
        <div
            className={` ${classes.card} ${selectedCard ? classes.selected : ""} `}
            onClick={selectedItemHandler}
        >   
        {selectedCard && (
            <div className={classes["radio-icon"]}>
                <img src={tick} />
            </div>
        )}        
            <div className={classes.title}>
                {item.title}
            </div>
        </div>
    );

};

export default SelectableButton;