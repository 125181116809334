
export const SORT_ITEMS =  {
   "Newest": '-created_at' ,
    "Oldest": 'created_at',
    "A-Z" : 'text',
    "Z-A": '-text',
};

export const SORT_ITEMS_REQUEST = {
    "Newest": '-created_at' ,
    "Oldest": 'created_at',
    "A-Z" : 'title',
    "Z-A": '-title',
};

export const SORT_ITEMS_TIME = {
    "Newest": '-created_at' ,
    "Oldest": 'created_at',
}

export const STATUS_ITEMS = {
    "All" : "",
    "Waiting" : "waiting",
    "Rejected" : "rejected",
    "Matched" : "matched"
}
