import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./App.scss";
import Routes from "./Routes";
import Header from "./components/Layout/Header/Header";
import Footer from "./components/Layout/Footer/Footer";
import { isLoggedIn } from "./redux/User/userAction";
import { getAccessToken } from "./utils/authentication";
import Toast from "./components/UI/Toast/Toast";
import ModalRoute from "./components/UI/ModalRoute/ModalRoute";
import CookieBanner from "./components/CookieBanner/CookieBanner";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken) {
      dispatch(isLoggedIn(true));
    }
  }, [dispatch]);

  return (
    <div className="App">
      <ModalRoute />
      <Header />
      <main>
        <Routes />
      </main>
      <Toast />
      <Footer />    
      <CookieBanner />  
    </div>
  );
}

export default App;
