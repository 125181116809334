import { useState, useCallback, useEffect } from "react";
import classes from "./SentRequests.module.scss";
import DashboardSearchBox from "../../../components/DashboardSearchBox/DashboardSearchBox";
import DashboardSearchFilterBoxRequest from "../../../components/DashboardSearchFilterBoxRequest/DashboardSearchFilterBoxRequest";
import SentRequestItem from "../../../components/SentRequestItem/SentRequestItem";
import useHttp from "../../../hooks/use-http";
import NotFoundItems from "../../../components/UI/NotFoundItems/NotFoundItems";
import Loading from "../../../components/UI/Loading/Loading";
import Pagination from "../../../components/UI/Pagination/Pagination";
import {
  updateUniqueApiParams,
  updateUniqueUrlParams,
  splitQueryParams,
  resetQueryParams,
} from "../../../utils/QueryParams";
import calculateOffset from "../../../utils/calculateOffset";
import { useHistory, useLocation } from "react-router-dom";
import { getAccessToken } from "../../../utils/authentication";
import { API_REQUEST_URL } from "../../../constants/API";
import DashboardRequestFilterSort from "../../../components/DashboardRequestFilterSort/DashboardRequestFilterSort";
import { LIMIT_ITEM } from '../../../constants/Common';

const SentRequests = () => {
  const [sentRequestList, setSentRequestList] = useState([]);
  const [count, setCount] = useState(null);
  const [searchItem, setSearchItem] = useState(null);
  const [sentReqStatus, setSentReqStatus] = useState(null);
  const [sortItem, setSortItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const { sendRequest, isLoading } = useHttp();
  const [apiQueryParams, setApiQueryParams] = useState(`?limit=${LIMIT_ITEM}`);
  const history = useHistory();
  const location = useLocation();

  const getSentRequestList = (data) => {
    console.log(data);
    setSentRequestList(data.results);
    setCount(data.count);
    window.scrollTo(0, 0);
  };

  const splitUrlQueryparams = useCallback(() => {
    const queryParams = window.location.search;
    const splitedQueryParams = splitQueryParams(queryParams);
    console.log(splitedQueryParams);
    setSentReqStates(splitedQueryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSentReqStates = (params) => {
    params.page ? setCurrentPage(parseInt(params.page)) : setCurrentPage(1);
    params.ordering ? setSortItem(params.ordering) : setSortItem(null);
    params.search ? setSearchItem(params.search) : setSearchItem(null);
    params.status ? setSentReqStatus(params.status) : setSentReqStatus(null);
    createOffset(params);
  };

  const createOffset = (params) => {
    let offset;
    // if page is not equal 1
    if (params.page) {
      offset = calculateOffset(parseInt(params.page), LIMIT_ITEM);
      delete params.page;
    } // page =1 (page load for first time)
    else {
      offset = calculateOffset(1, LIMIT_ITEM);
    }
    params["offset"] = offset;
    createApiQueryParams(params);
  };

  const createApiQueryParams = (params) => {
    let updatedQueryParams = apiQueryParams;
    for (let key in params) {
      updatedQueryParams = updateUniqueApiParams(
        `${key}`,
        params[key],
        updatedQueryParams
      );
    }
    setApiQueryParams(updatedQueryParams);
    fetchSentRequestList(updatedQueryParams);
  };

  const fetchSentRequestList = (updatedQueryParams) => {
    const accessToken = getAccessToken();
    setCount(null);
    sendRequest(
      {
        url: `${API_REQUEST_URL}user-requests/${updatedQueryParams}`,
        headers: { Authorization: `Bearer ${accessToken}` },
      },
      getSentRequestList
    );
  };

  const resetPage = () => {
    setCurrentPage(1);
    let offset = calculateOffset(1, LIMIT_ITEM);
    let updatedQueryParams = updateUniqueApiParams(
      "offset",
      offset,
      apiQueryParams
    );
    setApiQueryParams(updatedQueryParams);
    return updatedQueryParams;
  };

  const updateUniqueUrlPage = (result) => {
    history.push({
      pathname: "/dashboard/sent-requests",
      search: result,
    });
  };

  const getSentReqListByUniqueFilters = (newkey, newValue) => {
    let updatedQueryParams;
    updatedQueryParams = resetPage();
    // choose new filter
    if (newValue) {
      updatedQueryParams = updateUniqueApiParams(
        newkey,
        newValue,
        updatedQueryParams
      );
      const urlParams = {
        page: 1,
        [newkey]: newValue,
      };
      let result = updateUniqueUrlParams(urlParams);
      updateUniqueUrlPage(result);
    } // choose All item and remove it from url and API
    else {
      // reset page number to 1---------------------------------------
      const urlParams = { page: 1 };
      let result = updateUniqueUrlParams(urlParams);
      result = resetQueryParams(newkey, result);
      // remover filter from params of page---------------------------
      updateUniqueUrlPage(result);
      // remove filter from params of api----------------------------
      updatedQueryParams = resetQueryParams(newkey, updatedQueryParams);
    }
    setApiQueryParams(updatedQueryParams);
  };

  const getSearchSentReq = (searchItem) => {
    getSentReqListByUniqueFilters("search", searchItem);
    setSearchItem(searchItem);
  };
  const getSentReqStatus = (status) => {
    getSentReqListByUniqueFilters("status", status);
    setSentReqStatus(status);
  };
  const getSortItem = (item) => {
    getSentReqListByUniqueFilters("ordering", item);
    setSortItem(item);
  };

  const getActivatedPage = (page) => {
    if (currentPage !== page) {
      setCurrentPage(page);
      let offset = calculateOffset(page, LIMIT_ITEM);
      let updatedQueryParams = updateUniqueApiParams(
        "offset",
        offset,
        apiQueryParams
      );
      setApiQueryParams(updatedQueryParams);
      const urlParams = { page };
      let result = updateUniqueUrlParams(urlParams);
      updateUniqueUrlPage(result);
    }
  };

  useEffect(() => {
    splitUrlQueryparams();
  }, [location, splitUrlQueryparams]);

  return (
    <div className={`container ${classes["sent-request"]}`}>
      <div className={classes["profile-page-title"]}>
        Sent Requests
			</div>	
      {/* <DashboardSearchFilterBoxRequest
        searchItem={searchItem}
        onSearchItem={getSearchSentReq}
        sentReqStatus={sentReqStatus}
        dispatchSentReqStatus={getSentReqStatus}
      /> */}
      <DashboardSearchBox
        searchItem={searchItem}
        onSearchItem={getSearchSentReq}
        />
      <DashboardRequestFilterSort
        sentReqStatus={sentReqStatus}
        sortItem={sortItem}
        dispatchSentReqStatus={getSentReqStatus}
        dispatchSortItem={getSortItem}
      />
      {sentRequestList.length !== 0 && !isLoading && (
        <div
          className={`row justify-content-lg-start justify-content-center  align-items-center`}
        >
          {sentRequestList.map((sentReq) => (
            <SentRequestItem key={sentReq.id} sentReq={sentReq} />
          ))}
        </div>
      )}
      {isLoading || (sentRequestList.length === 0 && !isLoading) ? (
        <div
          className={`row justify-content-center  align-items-center ${classes["min-h"]}`}
        >
          {isLoading && <Loading className={classes.loading} />}
          {sentRequestList.length === 0 && !isLoading && (
            <NotFoundItems
              className={classes["not-found"]}
              item="Request"
            />
          )}
        </div>
      ) : (
        ""
      )}
      {count > LIMIT_ITEM && (
        <Pagination
          onActivatedPage={getActivatedPage}
          count={count}
          limit={LIMIT_ITEM}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default SentRequests;
