import { useState, useEffect } from "react";
import classes from "./HomeLatestNews.module.scss";
// import HomeNewsCard from "../HomeNewsCard/HomeNewsCard";
// import HomePageExploreAll from "../UI/HomePageExploreAll/HomePageExploreAll";
import useHttp from "../../hooks/use-http";
import { API_NEWS_URL } from "../../constants/API";
import Loading from "../UI/Loading/Loading";
import { Link } from "react-router-dom";
import { dateFormmater } from "../../utils/common";
import pharmaNewsLogo from "../../assets/images/pharmatching-news-home.webp";

const HomeLatestNews = () => {
	const [newsListRes, setNewsListRes] = useState([]);
	const { sendRequest, isLoading } = useHttp();

	const getNewsList = (data) => {
		setNewsListRes(data.results);
	};

	const fetchNewsList = () => {
		sendRequest(
			{ url: `${API_NEWS_URL}?limit=4&sort=-created_at` },
			getNewsList
		);
	};

	useEffect(() => {
		fetchNewsList();
		return () => {
			setNewsListRes([]);
		};
	}, []);
	return (
		<section className={classes["news-container"]}>
			<div className="container">
				<h4 className={classes["title"]}>Latest News</h4>
				<p>
					Keep up-to-date with the latest news and updates from the
					pharmaceutical and life science industry.
				</p>
				{isLoading && (
					<div className={classes["loading-box"]}>
						<Loading className={classes.loading} />
					</div>
				)}
				{!isLoading && newsListRes && newsListRes.length > 0 && (
					<div
						className={`${classes["news-box"]} row justify-content-center justify-content-lg-between gap-4 gap-lg-0`}
					>
						{newsListRes.map((news) => (
							<div key={news.title} className="col-12 col-md-5 col-lg-3">
								<Link to={`/news/${news.id}`}>
									<div>
										<img src={news.image_url ? news.image_url : pharmaNewsLogo} alt={news.title} />
									</div>
									<div className={classes["items"]}>
										<h2>{news.title}</h2>
										<h4>{news.source_name}</h4>
										<p>{news.crawled_at && dateFormmater(news.crawled_at)}</p>
									</div>
								</Link>
							</div>
						))}
					</div>
				)}

				<Link to="/news" className={`${classes["explore-link"]}`}>
					Explore All
				</Link>
				{/* <div className={`col-12 ${classes["explore-all-box"]} d-block d-lg-none`}>
        <HomePageExploreAll link="/news" />
      </div> */}
			</div>
		</section>
	);
};

export default HomeLatestNews;
