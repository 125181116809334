import React from 'react';
import classes from "./TermsAndConditions.module.scss";

const TermsAndConditions = () => {
    return (
        <div className={`${classes["main-background"]}`}>

       
        <div className={`container`}>
            <div className={`row ${classes["static-page"]}`}>
                <div className={`col-12`}>
                    <div className={classes.title}>
                        <h1>Terms and Conditions</h1>
                        <h3>www.pharmatching.com</h3>
                    </div>
                    <div className={classes.content}>
                        <div className={classes.section}>
                            <p className={classes.text}>
                                Pharmatching (“ Pharmatching” , “us” , or “we”) provides an internet portal located at http://www.pharmatching.com (the “Site”) with information on services of Service Providers, on current specific  service requests of Service Buyers and on other matters of general interest in the Life Science sector. The services of Pharmatching requiring registration including the Online Matching Tool (the “Services”) support Users in establishing contact with potential new business partners in the Life Science sector, however, contracts between the potential business partners are not concluded via Pharmatching.
                            </p>
                            <p className={classes.text}>
                                These Terms and Conditions (“Terms”), including the Pharmatching Privacy Policy which is hereby incorporated into these Terms by reference, regulate legally binding the contractual relationship between the Visitor or User and PHARMATCHING. By accessing and using the Services, you agree to comply with and be bound by these Terms, whether you are visiting the Site (“Visitor”) or you are a “User” (which means that you have registered on the Site). Please read these Terms carefully before using the Services.
                            </p>
                            <p className={classes.text}>
                                The User’s Terms do not apply; not even if the User transmits it any point of time and PHARMATCHING does not object to them.
                            </p>
                            <div className={classes.title}>
                                1. DEFINITIONS
                            </div>
                            <p className="text-bold">For these Terms the following definitions apply:</p>
                            <p className={classes.text}>
                                Account means the User account containing all of the User’s profile information and usage activities.
                                <br />
                                <br />
                                Archive is an account kept separately for each User, where each User may permanently archive all completed activities.
                                <br />
                                <br />
                                Company is an association formed and organized to carry on a business. Types of companies include but are not limited to sole proprietorship, partnership, limited liability, corporation, public limited company or other company forms established according to the applicable law. In the portal, the definition also includes academic institutions and individuals who work self-employed on a freelance basis.
                                <br />
                                <br />
                                Content means any work of authorship or information, including services of Service Providers, Ratings, comments, opinions, postings, messages, text, files, images, photos, works of authorship, e-mail, or other materials.
                                <br />
                                <br />
                                Personal Data refers to the individual information on personal and factual situation of a specific or determinable natural person.
                                <br />
                                <br />
                                PHARMATCHING Services refers to the services offered in the Pharmatching Web Sites requiring registration.
                                <br />
                                <br />
                                PHARMATCHING Web Sites is the generic term for all Web Sites, where PHARMATCHING offers its services.
                                <br />
                                <br />
                                Profile refers to the pages of PHARMATCHING’s Web Sites, where PHARMATCHING displays information on services and service-related information on service providing Companies.
                                <br />
                                <br />
                                Ranking refers to the position at which a specific company appears in the search or filter results list and in the regularly displayed listing of companies established based on the Visitor and User ratings.
                                <br />
                                <br />
                                Rating refers to the feedback inserted by Visitors and Users into pharmatching.com on their experience with companies’ services.
                                <br />
                                <br />
                                Registration Data refers to the personal data the User is obliged to enter in order to register for PHARMATCHING Services. Specific fields are considered obligatory.
                                <br />
                                <br />
                                Service Buyer is a User, who posts specific current service requests in PHARMATCHING.
                                <br />
                                <br />
                                Service Provider is a User or a Company providing services in the life science sector for which a Profile can be shown at PHARMATCHING. The Service Provider can apply to service requests.
                                <br />
                                <br />
                                Service Request is a request for services directed towards Service Providers.
                                <br />
                                <br />
                                User refers to a person who is registered for the services offered by PHARMATCHING. A User can register to both User types, i.e. as a Service Buyer and/or Service Provider.
                                <br />
                                <br />
                                User Data refers to the information required for the usage of the PHARMATCHING Services and billing purposes and to the information regarding the scope of the respective usage, its commencement and ending.
                                <br />
                                <br />
                                Visitor refers to a person using the parts available at PHARMATCHING Web Sites without registration.
                            </p>
                            <div className={classes.title}>
                                2. USE OF THE SERVICES AND INFORMATION
                            </div>
                            <p className={classes.text}>
                                The information and content available on the PHARMATCHING website is protected by copyright laws throughout the world. Subject to the Terms, PHARMATCHIN grants you a limited license to access the content and information for the sole purpose of using the Services for your personal or internal business purposes. Unless otherwise specified by PHARMATCHING in a separate license, your right to use any of the content and information is subject to the Terms.
                            </p>
                            <p className={classes.text}>
                                The rights granted to you in the Terms are subject to the following restrictions:
                           
                            </p>
                            <ol style={{ "listStyleType": "lower-alpha" }}>
                                    <li>you shall not license, sell, rent, lease, transfer, assign, reproduce, distribute, host, or otherwise commercially exploit PHARMATCHING content and information, including the Website,</li>
                                    <li>you shall not use any metatags or other “hidden text” using PHARMATCHING’S name or trademarks;</li>
                                    <li>you shall not modify, translate, adapt, merge, make derivative works of, disassemble, decompile, reverse compile, or reverse engineer any part of the website except to the extent the foregoing restrictions are expressly prohibited by applicable law;</li>
                                    <li>access PHARMATCHING in order to build a similar or competitive website, application, or service.</li>
                            </ol>
                            <div className={classes.title}>
                                3. REGISTRATION
                            </div>
                            <p>
                                3.1 Registering Your Account
                            </p>
                            <p className={classes.text}>
                                In order to access certain features of the PHARMATCHING website, you may be required to become a Registered User. For purposes of the Terms, a “Registered User” is a User who has registered an account on the Website (“Account”).
                            </p>
                            <p>
                                3.2 Registration Data
                            </p>
                            <p className="text-bold">
                                In registering for the Services, you agree to:
                            </p>
                            
                                <ol style={{ "listStyleType": "decimal" }}>
                                    <li>
                                        provide true, accurate, current and complete information about yourself as prompted by the Services’ registration form (the “Registration Data”); and
                                    </li>
                                    <li>
                                        maintain and promptly update the Registration Data to keep it true, accurate, current and complete.
                                    </li>
                                </ol>
                            
                            <p className="text-bold">
                                You represent that you are:
                            </p>
                           
                                <ol style={{ "listStyleType": "decimal" }}>
                                    <li>
                                        at least eighteen (18+) years old;
                                    </li>
                                    <li>
                                        maintain and promptly update the Registration Data to keep it true, accurate, current and complete.
                                    </li>
                                </ol>
                          
                            <p className="text-bold">
                                You are responsible for all activities that occur under your Account. You agree that you shall monitor your Account to restrict use by minors, and you will accept full responsibility for any unauthorized use of the same by minors. You may not share your Account or password with anyone, and you agree to:
                            </p>
                         
                                <ol style={{ "listStyleType": "decimal" }}>
                                    <li>
                                        notify PHARMATCHING immediately of any unauthorized use of your password or any other breach of security; and
                                    </li>
                                    <li>
                                        exit from your Account at the end of each session. If you provide any information that is untrue, inaccurate, not current or incomplete, or we have reasonable    grounds to suspect that such information is untrue, inaccurate, not current or incomplete, PHARMATCHING has the right to suspend or terminate your Account.
                                    </li>
                                </ol>
                       
                            <div className={classes.title}>
                                4. RELEASE
                            </div>
                            <p className={classes.text}>
                                PHARMATCHING expressly disclaims any liability that may arise between Users of its Services. The Services are only a venue for connecting Users. Because PHARMATCHING is not involved in the actual contract between Users or in the completion of the contract, in the event that you have a dispute with one or more Users, you release PHARMATCHING (and our officers, directors, agents, investors, subsidiaries, and employees) from any and all claims, demands, or damages (actual or consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes.
                            </p>
                            <div className={classes.title}>
                                5. USER CONDUCT
                            </div>
                            <p className="text-bold">
                                While using or accessing the PHARMATCHING website you agree that you will not, under any circumstances:
                            </p>
                         
                                <ol style={{ "listStyleType": "lower-alpha" }}>
                                    <li>
                                        Breach or circumvent any laws, third party rights or our systems, policies, or determinations of your Account status;
                                    </li>
                                    <li>
                                        Interfere with or damage our website, including, without limitation, through the use of viruses, cancel bots, Trojan horses, harmful code, flood pings, denial-of-service attacks, packet or IP spoofing, forged routing or electronic mail address information, or similar methods or technology;
                                    </li>
                                    <li>
                                        Post false, inaccurate, misleading, defamatory, or libelous content;
                                    </li>
                                    <li>
                                        Transfer your Account and username to another party without our consent;
                                    </li>
                                    <li>
                                        Bypass our robot exclusion headers, interfere with the working of the PHARMATCHING website, or impose an unreasonable or disproportionately large load on our infrastructure;
                                    </li>
                                    <li>
                                        Upload, post, email, transmit or otherwise make available any unsolicited or unauthorized advertising, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other form of solicitation.
                                    </li>
                                </ol>
                     
                            <div className={classes.title}>
                                6. OWNERSHIP
                            </div>
                            <p className={classes.text}>
                                6.1 Trademarks
                                <br />
                                <br />
                                PHARMATCHING and other related graphics, logos, service marks, and trade names used on or in connection with the website are the trademarks of PHARMATCHING and may not be used without our prior written consent in connection with any third-party products or services.

                                <br />
                                <br />
                                6.2 Your Content
                                <br />
                                <br />
                                PHARMATCHING does not claim ownership of Your Content. However, when you as a User post or publish Your Content on website, you represent that you own and/or have a royalty-free, perpetual, irrevocable, worldwide, non-exclusive right (including any moral rights) and license to use, license, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, derive revenue or other remuneration from, and communicate to the public, perform, and display Your Content (in whole or in part) worldwide and to incorporate it in other works in any form, media, or technology now known or later developed, for the full term of any worldwide intellectual property rights that may exist in Your Content.
                                <br />
                                <br />


                                6.3 License to Your Content
                                <br />
                                <br />
                                Subject to any applicable account settings that you select, you grant PHARMATCHING a fully paid, royalty-free, perpetual, irrevocable, worldwide, royalty-free, non-exclusive, and fully sublicensable right (including any moral rights) and license to use, license, distribute, reproduce, modify, adapt, publicly perform, and publicly display, Your Content (in whole or in part) for the purposes of operating and providing the website services to you and to our other Users.
                                <br />
                                <br />

                                6.4 Your Account
                                <br />
                                <br />
                                Notwithstanding anything to the contrary herein, you acknowledge and agree that you shall have no ownership or other property interest in your Account, and you further acknowledge and agree that all rights in and to your Account are and shall forever be owned by and inure to the benefit of PHARMATCHING.
                            </p>
                            <div className={classes.title}>
                                7. USAGE FEES
                            </div>
                            <p className={classes.text}>
                                7.1 Type and Amount of Fees
                            </p>
                            <p className="text-bold">
                                Auto-Renewal; Automatic Card Payment:
                            </p>
                            <p className={classes.text}>
                                Subscriptions are automatically renewed, until you terminate or cancel the subscription. When you purchase a subscription, your Payment Method will continue to be billed monthly in advance within 30 business days of the date of the initial purchase at the price you agreed to when initially subscribing. Your card payment information will be stored and subsequently used for the automatic card payments in accordance with the Agreement.
                                <br />
                                <br />
                                After each payment you will receive a receipt by email. Objections to a payment already made, should be directed to Customer support if you were billed directly by PHARMATCHING or the relevant third party account. You are also be able to object by contacting your bank or payment provider, who can provide further information on your rights as well as applicable time limits.
                                You may unconditionally withdraw your consent to automatic card payments at any time by going to settings on Pharmatching or the relevant third party account but be advised that you are still obligated to pay any outstanding amounts.
                                <br />
                                <br />
                                The pricing chart can freely be viewed under the section “Subscribe plans “of the PHARMATCHING website. Fees are due prior to the respective usage of PHARMATCHING Services.
                            </p>                          
                            <p className={classes.text}>
                                7.2 Billing
                                <br />
                                <br />
                                The payment process of PHARMATCHING usage fees is handled by an external company specialized in payment transactions. The User may pay the usage fees with credit cards accepted by PHARMATCHING. The User exempts PHARMATCHING from all costs arising through return debit notes, objections from credit card payments or similar complications during the payment process, for which PHARMATCHING is not responsible.
                                <br />
                                <br />
                                PHARMATCHING sends Users invoices for the fees within a few days by electronic communication.
                                <br />
                                <br />
                                7.3 Subscription
                                <br />
                                <br />
                                Freelancers or companies have to subscribe to a subscription first, before they can get active. A monthly payment is automatically triggered with the subscription.  All payments are currently handled via the financial service company STRIPE Requests and Service Providers can apply. After the subscription it is possible to purchase Request or Applications as freelancer or company assets.
                                <br />
                                <br />
                                Companies can invite users to their profile which enables Users to use the company assets (Requests or Applications) freely. A subscription model can be changed at any time and payments will be updated immediately based on the day of the change. A subscription can be cancelled at any time through our platform or through STRIPE. A cancellation will lead to no new payment, but there will be no refund.
                                <br />
                                <br />
                                If a company invites users, the company agrees that the user can spend all company assets. If a match was made (Requestor select an applicant as “winner” it is the responsibility of the companies to proceed with the next steps between Requestor and Service provider.
                            </p>
                            <div className={classes.title}>
                                8. LIABILITY, RESPONSIBILITY
                            </div>
                            <p className={classes.text}>
                                8.1 Responsibility for Contents and Conclusion of Contracts
                                <br />
                                <br />
                                Users or Visitors are responsible for all information which they, their employees or any third party using their User license transmit via the PHATMATCHING Web site, post on the PHARMATCHING Web Site and use in the database.
                                <br />
                                <br />
                                The User is obliged to only enter lawful contents and to verify lawfulness in any respect, in particular as regards laws on competition, cartel law, copyright and pharmaceutical law.
                                <br />
                                <br />
                                The contracting parties are solely responsible for the implementation of the contract between Users.
                                <br />
                                <br />
                                8.2 Unlimited Liability
                                <br />
                                <br />
                                PHARMATCHING is liable without limitation for all damages that are caused intentionally or negligently by PHARMATCHING, its legal representatives or employees. Claims arising due to life injury, personal, bodily or health injuries, which are caused by negligent breach of duty, as well as property damage pursuant to the Product Liability Act are not limited. Furthermore, guarantees given are not limited. In all other cases, limitations on liability as described in the subsequent Sections apply.
                                <br />
                                <br />

                                8.3 Liability for the Infringement of Material Contractual Obligations
                                <br />
                                <br />
                                PHARMATCHING’s liability for damages caused by the infringement of material contractual obligations that arise out of the nature of the contract, and whose compliance constitute a significant reason for the Users or Visitors to conclude the contract, is limited to the direct damages reasonably foreseeable upon conclusion of the contract and typical for this kind of contract.
                                <br />
                                <br />


                                8.4 Exemption from Liability
                                <br />
                                <br />
                                PHARMATCHING is exempt from liability for all other damages (not described in Section 2 and 3).
                                <br />
                                <br />


                                8.5 Limitation on Liability for Damages typical for this kind of Contract
                                <br />
                                <br />
                                PHARMATCHING limits its liability for damages reasonably foreseeable upon conclusion of the contract and typical for this kind of contract according to Section 3 to € 100,000. As far as a User has evidence that a higher damage is to be anticipated due to the User’s business model or other circumstances, the User is obliged to inform PHARMATCHING and to jointly find a solution for securing these additional risks.
                                <br />
                                <br />

                                8.6 Liability Insurance
                                <br />
                                <br />
                                The limitations on liability do not apply as far as the damages are covered by public liability insurance and the insurer has paid PHARMATCHING. PHARMATCHING commits itself to maintain the cover existing at the conclusion of the contract. As far as specific risks cannot be insured, or may only be insured for higher fees, PHARMATCHING is entitled to exclude these from the contract.


                            </p>
                            <div className={classes.title}>
                                9. INDEMNITY OF PHARMATCHING, BLOCKING OF CONTENT
                            </div>
                            <p className={classes.text}>
                                The Users and the Visitors indemnify PHARMATCHING – upon first demand – from any liability which may ensure from registration or participation in PHARMATCHING Services. This applies to all claims raised against PHARMATCHING on the grounds of an act by a User or Visitor. In this case, PHARMATCHING leaves the defense against the raised claims to the User or Visitor, as far as this is permitted by statute.
                                <br />
                                <br />
                                PHARMATCHING will not without the prior consultation of the User or Visitor make any concessions or, particularly, settlements with the claimant. The User or the Visitor is obliged – upon first demand – to reimburse all fees, costs (including legal expenses) and damages arising through the action objected to by third parties.
                                <br />
                                <br />
                                If the suspicion arises that content is unlawful, in particular that it infringes third party rights, PHARMATCHING is entitled to block such content until its lawfulness has been ascertained incontestably. PHARMATCHING is entitled to restrict or delete Ratings that may harass, abuse, or harm another person or that seem to contact, advertise to, solicit, or sell to any User without their prior explicit consent.
                            </p>
                            <div className={classes.title}>
                                10. TERMINATION AND SUSPENSION
                            </div>
                            <p className={classes.text}>
                                PHARMATCHING may terminate or suspend your right to use the website at any time for any or no reason by providing you with written or email notice of such termination, and termination will be effective immediately upon delivery of such notice. We may cancel unconfirmed Accounts or Accounts that have been inactive for a long time, or modify or discontinue our Services. Without limitation, PHARMATCHING may terminate or suspend your right to use the website if you breach any provision of the Terms or any policy of PHARMATCHING posted through the website from time to time; if PHARMATCHING otherwise finds that you have engaged in inappropriate and/or offensive behavior; if PHARMATCHING believes you are creating problems or possible legal liabilities; if PHARMATCHING believes such action will improve the security of our community or reduce another User’s exposure to financial liabilities; if PHARMATCHING believes you are infringing the rights of third parties; if PHARMATCHING believes you are acting inconsistently with the spirit of these Terms; if despite our reasonable endeavors, PHARMATCHING is unable to verify or authenticate any information you provide. In addition to terminating or suspending your Account, PHARMATCHING reserves the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress. Even after your right to use the Services is terminated or suspended, these Terms will remain enforceable against you.
                            </p>
                            <div className={classes.title}>
                                11. REMEDIES
                            </div>
                            <p className={classes.text}>
                                If PHARMATCHING becomes aware of any possible violations by you of the Terms, PHARMATCHING reserves the right to investigate such violations. If, as a result of the investigation, PHARMATCHING believes that criminal activity has occurred, PHARMATCHING reserves the right to refer the matter to, and to cooperate with, any and all applicable legal authorities. PHARMATCHING is entitled, except to the extent prohibited by applicable law, to disclose any information or materials on or in the website, including Your Content, to
                            </p>
                            <ol style={{ "listStyleType": "decimal" }}>
                                <li>
                                    comply with applicable laws, legal process or governmental request;
                                </li>
                                <li>
                                    enforce the Terms,
                                </li>
                                <li>
                                    respond to any claims that Your Content violates the rights of third parties,
                                </li>
                                <li>
                                    respond to your requests for customer service, or
                                </li>
                                <li>
                                    respond to your requests for customer service, or
                                </li>
                                <li>
                                    protect the rights, property or personal safety of PHARMATCHING, its Users or the public, and all enforcement or other government officials.
                                </li>
                            </ol>
                            <p className={classes.text}>
                                11.2 Breach
                                <br />
                                <br />
                                In the event that PHARMATCHING determines, in its sole discretion, that you have breached any portion of the Terms, or have otherwise demonstrated conduct inappropriate for the website, PHARMATCHING reserves the right to:
                            </p>
                           
                                <ol style={{ "listStyleType": "lower-alpha" }}>
                                    <li>
                                        Warn you via email that you have violated the Terms;
                                    </li>
                                    <li>
                                        Delete any of Your Content provided by you;
                                    </li>
                                    <li>
                                        Discontinue your registration(s);
                                    </li>
                                    <li>
                                        Notify and/or send Content to and/or fully cooperate with the proper law enforcement authorities for further action; and/or
                                    </li>
                                    <li>
                                        Pursue any other action which PHARMATCHING deems to be appropriate.
                                    </li>
                                </ol>
                           
                            <div className={classes.title}>
                                12. DISCLAIMER AND LIMITATION OF LIABILITY
                            </div>
                            <p className={classes.text}>
                                12.1 You expressly agree that use of the services is at your sole risk. The services are provided on an "as is" and "as available" basis.
                                <br />
                                <br />
                                12.2 In no event will PHARMATCHING be liable to you for any indirect, incidental, special, consequential or punitive damages (including damages for loss of profits, goodwill, or any other intangible loss) arising out of or relating to your access to or use of, or your inability to access or use, the website or any materials or content available through the website, whether based on warranty, contract, tort (including negligence), statute, or any other legal theory, and whether or not PHARMATCHING has been informed of the possibility of damage.
                            </p>
                            <div className={classes.title}>
                                13. INDEMNIFICATION
                            </div>
                            <p className={classes.text}>
                                You agree that you will be responsible for your use of the website, and you agree to defend and indemnify PHARMATCHING from and against every claim, liability, damage, loss, and expense, including reasonable attorneys’ fees and costs, arising out of or in any way connected with: (i) your access to, use of, or alleged use of, the website; (ii) your violation of any portion of these Terms, any representation, warranty, or agreement referenced in these Terms, or any applicable law or regulation; (iii) your violation of any third party right, including any intellectual property right or publicity, confidentiality, other property, or privacy right; or (iv) any dispute or issue between you and any third party.

                            </p>
                            <div className={classes.title}>
                                14. GENERAL PROVISIONS
                            </div>
                            <p className={classes.text}>
                                14.1 Assignment
                                <br />
                                <br />
                                The Terms, and your rights and obligations hereunder, may not be assigned, subcontracted, delegated, or otherwise transferred by you without PHARMATCHING’S prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void.

                                <br />
                                <br />
                                14.2 Waiver
                                <br />
                                <br />
                                Any waiver or failure to enforce any provision of the Terms on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion.

                                <br />
                                <br />

                                14.3 Severability
                                <br />
                                <br />
                                If any provision of the Terms is, for any reason, held to be invalid or unenforceable, the other provisions of the Terms will remain enforceable, and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law.

                                <br />
                                <br />

                                14.4 No Guarantee
                                <br />
                                <br />
                                PHARMATCHING does not guarantee any kind of success just because a match was made since PHARMATCHING has made no impact on the future cooperation of the entities.
                            </p>








                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default TermsAndConditions;