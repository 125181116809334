import classes from "./DashboardSearchBox.module.scss";
import Card from "../UI/CardV2/CardV2";
import SimpleFormGroup from "../UI/SimpleFormGroup/SimpleFormGroup";

const DashboardSearchBox = ({ searchItem, onSearchItem }) => {
  const handleOnSearch = (search) => {
    onSearchItem(search);
    console.log(search);
  };

  return (
    <Card className={classes.card}>
      <div className="row">
        <div className="col-lg-4 col-12">
          <SimpleFormGroup
            className="mb-20"
            label="Search Companies"
            inputValue={searchItem}
            dispatchInputValue={handleOnSearch}
          />
        </div>
      </div>
    </Card>
  );
};

export default DashboardSearchBox;
