import { useState, useCallback, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import classes from "./MySentApplication.module.scss";
import Loading from "../../components/UI/Loading/Loading";
import RequesViewSummary from '../../components/RequestViewSummary/RequstViewSummary';
import ApplicationDetail from '../../components/ApplicationDetail/ApplicationDetail';
import ConfirmModal from '../../components/UI/ConfirmModal/ConfirmModal';
import { API_APPLICATION_URL } from '../../constants/API';
import useHttp from '../../hooks/use-http';
import { getAccessToken } from '../../utils/authentication';
import {updateIsAssetsChnage} from "../../redux/User/userAction";
import { useDispatch } from "react-redux";

const MySentApplication = () => {
  const [applicationInfo, setApplicationInfo] = useState(null);
  const [isDisplayModal, setIsDisplayModal] = useState(null);
  const history = useHistory();
  const { id } = useParams();
  const { sendRequest, isLoading } = useHttp();

  const getRequestInfo = (data) => {
    console.log(data,'info');
    setApplicationInfo(data);
    window.scrollTo(0, 0);
  };
  const dispatch = useDispatch();
  const fetchApplicationInfo = useCallback(() => {
    const token = getAccessToken();
    sendRequest(
      {
        url: `${API_APPLICATION_URL}${id}`,
        headers: { Authorization: `Bearer ${token}` },
      },
      getRequestInfo
    );
  }, [id, sendRequest]);

  const getDeletedApplicationRes = (data) => {
    dispatch(updateIsAssetsChnage(true));
    history.push("/dashboard/sent-applications");
  };

  const deleteApplication = (str) => {
    setIsDisplayModal(false);
    if (str === "confirm") {
      const token = getAccessToken();
      sendRequest(
        {
          url: `${API_APPLICATION_URL}${id}`,
          headers: { Authorization: `Bearer ${token}` },
          method: "DELETE",
        },
        getDeletedApplicationRes
      );
    }
  };

  const confirmDeleteApplication = () => {
    setIsDisplayModal(true);
  };

  useEffect(() => {
    fetchApplicationInfo();
  }, [fetchApplicationInfo]);

  return (
    <section
      className={`container  flex-grow position-relative ${classes.section}  ${classes["min-h"]}`}
    >
      {isDisplayModal && (
        <ConfirmModal
          modalButtonText="Delete"
          modalType="warning"
          modalText="Are you sure to delete this application?"
          modalHeader="Delete"
          onConfirm={deleteApplication}
        />
      )}
      {isLoading && <Loading className={classes.loading} />}
      {applicationInfo && !isLoading && (
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-5 col-md-10 col-12">
            <RequesViewSummary
              requestInfo={applicationInfo.request_data}
              applicationStatus={applicationInfo.status}
              applicationSender={applicationInfo.company}
              onConfirmDelete={confirmDeleteApplication}
              isSentApplication = {true}
              isApplicationOwner = {applicationInfo.is_owner}
            />
          </div>
          <div className="col-xl-8 col-lg-7 col-md-10 col-12">
            <ApplicationDetail applicationInfo={applicationInfo} isSentApplication = {true} />
          </div>
        </div>
      )}
    </section>
  );
};

export default MySentApplication;
