import { useState, useEffect } from "react";

const detectIsDisplay = () => [(window.innerWidth >= 992 ? true : false) ,window.innerWidth ];

function useDetectDisplay() {
  let [isDisplayDesktop, setIsDisplayDesktop] = useState(detectIsDisplay());
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setIsDisplayDesktop(detectIsDisplay()), 150);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return isDisplayDesktop;
}

export default useDetectDisplay;
