export const SENT_APPLICATION_STATUS = [
  {
    id: 1,
    title: "waiting",
    cardTitle: "Wating",
    color: "#FFB800",
    backgroundColor: '#FFF8E5',
  },
  { id: 2, title: "matched", cardTitle: "Successful", color: "#1E4C90", backgroundColor: '#21bce733', },
  { id: 3, title: "rejected", cardTitle: "Rejected", color: "#E10000", backgroundColor: '#FFE5E6', },
];
