import React, { useState, Fragment } from "react";
import classes from './SortableTableDrafts.module.scss';
import useSortableData from '../../../hooks/useSortableData';
import { REQUEST_STATUS } from "../../../constants/RequestStatus";
import orderArrow from '../../../assets/images/arrow/order-arrow.svg';
import { dateFormmaterNormal } from "../../../utils/common";
import actionEdit from '../../../assets/images/Icons/actionEdit.svg';
import removeDraft from '../../../assets/images/Icons/remove-draft.svg';
import { setRequestCurrentId, showEditRequestModal } from "../../../redux/Modal/modalAction";
import { useDispatch } from "react-redux";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { getAccessToken } from "../../../utils/authentication";
import useHttp from "../../../hooks/use-http";
import { API_REQUEST_URL } from "../../../constants/API";

const SortableTableDrafts = ({ source, onSortItem, afterDraftDelete }) => {
    const dispatch = useDispatch();
    const [displayModal, setDisplayModal] = useState(null);
    const [currentrequestId, setCurrentRequestId] = useState(0);
    const { sendRequest } = useHttp();
    // const { items, requestSort, sortConfig } = useSortableData(props.source);
    const items = source;
    const getClassNamesFor = (name) => {
        // if (!sortConfig) {
        //     return;
        // }
        // return sortConfig.key === name ? sortConfig.direction : undefined;
    };  
   
    const deleteRequest = (userResp) => {
		if (userResp === "confirm") {
			const token = getAccessToken();
			sendRequest(
				{
					url: `${API_REQUEST_URL}${currentrequestId}`,
					headers: { Authorization: `Bearer ${token}` },
					method: "DELETE",
				},
				afterDraftDelete
			);
			setDisplayModal(false);
		} else if (userResp === "cancel") {
			setDisplayModal(false);
			console.log(userResp, "Canceled");
		}
	};
    const returnDash = (val) => {
        if (val == '')
            return '-';
        else
            return val;
    };
    const handleClickremove = (id) => {
        setCurrentRequestId(id);
        setDisplayModal(true);
    };
    const handleClickEdit = (id) => {
        dispatch(setRequestCurrentId(id));
        dispatch(showEditRequestModal(true));
    };
    return (
        <Fragment>
            {displayModal && (
                <ConfirmModal
                    modalButtonText="Delete"
                    modalType="warning"
                    modalText="Are you sure to delete the draft?"
                    modalHeader="Delete"
                    onConfirm={deleteRequest}
                />
            )}

            <table className={classes['table-requests']}>
                <thead>
                    <tr>
                        <th
                            onClick={() => onSortItem('title')}
                        // className={classes[getClassNamesFor('title')]}
                        >
                            Title
                            <img src={orderArrow} alt='arrow' />
                        </th>
                        <th
                            onClick={() => onSortItem('-created_at')}
                        // className={classes[getClassNamesFor('created_at')]}
                        >
                            Creation Date
                            <img src={orderArrow} alt='arrow' />
                        </th>
                        <th
                        // onClick={() => requestSort('due_date')}
                        // className={classes[getClassNamesFor('due_date')]}
                        >
                            Expiring Date
                            {/* <img src={orderArrow} alt='arrow' /> */}
                        </th>
                        <th
                        // onClick={() => requestSort('company_title')}
                        // className={classes[getClassNamesFor('company_title')]}
                        >
                            Creator
                            {/* <img src={orderArrow} alt='arrow' /> */}
                        </th>
                        <th>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((request) => (
                        <tr key={request.id}>
                            <td>{returnDash(request.title)}</td>
                            <td>
                                {dateFormmaterNormal(request.created_at)}
                                {/* {request.created_at} */}
                            </td>
                            <td>
                                {/* {dateFormmaterNormal(request.due_date)} */}
                                {request.due_date == null ? '-' : dateFormmaterNormal(request.due_date)}
                            </td>
                            <td>{request.creator}</td>
                            <td>
                                <button
                                    onClick={() =>handleClickremove(request.id)}
                                    className='btn'>
                                    <img src={removeDraft} />
                                </button>
                                <button
                                    onClick={() => handleClickEdit(request.id)}
                                    className='btn'>
                                    <img src={actionEdit} />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Fragment>
    );
};

export default SortableTableDrafts;