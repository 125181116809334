import ShowMoreText from "react-show-more-text";
import classes from "./ApplicationDetail.module.scss";
import Card from "../UI/CardV2/CardV2";
import downloadIcon from "../../assets/images/Icons/download.svg";
import NoData from "../UI/NoData/NoData";
import { Fragment, useEffect } from "react";

const ApplicationDetail = ({
  applicationInfo,
  requestInfo,
  isApplicationView,
  isSentApplication,
  onDeclineApplication,
  onAcceptApplication
}) => {

  useEffect(() => {
    console.log("appinfo", applicationInfo);
    console.log("reqinfo", requestInfo);
  }, []);
  return (
    <Card className={classes.card}>
      <h6 className={classes["application-title"]}>
        {applicationInfo.company_title}
      </h6>
      <div className={classes["title-section"]}>
        Description
      </div>
      {/* <ShowMoreText       
        lines={10}
        more="Show more"
        less="Show less"
        className={classes["application-desc"]}
        anchorClass={classes["more-less"]}
        expanded={false}
        truncatedEndingComponent={"... "}
      >
        {applicationInfo.text}
      </ShowMoreText> */}
      <div dangerouslySetInnerHTML={{ __html: applicationInfo?.text }}
        className="application-desc">
      </div>

      <div className={classes["title-section"]}>
        Attachments
      </div>
      <div>
        {applicationInfo.attachments.length > 0 &&
          applicationInfo.attachments.map((item) => (
            <a
              key={item}
              href={item}
              className={`${classes.link}`}
              target="_blank"
              rel="no noreferrer"
            >
              <span>File</span>
              <div className={classes.download}>
                <img src={downloadIcon} alt="download" />
              </div>
            </a>
          ))}
        {applicationInfo.attachments.length === 0 && (
          <NoData className={classes["no-data"]} />
        )}
      </div>
      {isSentApplication && applicationInfo.status === "waiting" && (
        <p className={classes["waiting-text"]}>
          Your application is under investigation
        </p>
      )}
      {isApplicationView &&
        applicationInfo.status === "waiting" &&
        // applicationInfo.is_owner === true &&
        (requestInfo.status === 3 || requestInfo.status === 4) && (
          <Fragment>
            <div className={classes["seprate-line"]}></div>
            <div className="text-end">
              <button onClick={() => onDeclineApplication()} className={`btn ${classes["decline"]}`}>Decline</button>
              <button onClick={() => onAcceptApplication()} className={`btn ${classes["accept"]}`}>Accept</button>
            </div>
          </Fragment>
        )}
    </Card>
  );
};

export default ApplicationDetail;
