import React from 'react';
import { Fragment, useRef, useState, useEffect, useCallback} from "react";
import classes from './ExtendDueDate.module.scss';
import DatePicker from "react-datepicker";
import { API_REQUEST_URL } from "../../constants/API";
import { useDispatch } from "react-redux";
import { addToast } from "../../redux/Toast/toastAction";
import { createRandomId } from "../../utils/common";
import { getAccessToken } from "../../utils/authentication";
import useHttp from "../../hooks/use-http";
import Moment from 'moment';
import { useHistory } from "react-router-dom";

const ExtendDueDtae = ({previewdValue, requestId}) => {
    const [openDatePicker, setOpenDatePicker] = useState(0);
    const [dueDate, setDueDate] = useState();
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxdate] = useState();
    let close = 1;
    const { sendRequest, error, isLoading } = useHttp();
    const dispatch = useDispatch();
    const history = useHistory();


    const displayExtendDueDateToast = useCallback(() => {
        dispatch(
          addToast({
            id: createRandomId(),
            status: "success",
            description: "Your Request Due Date Extended Successful.",
          })
        );
      }, [dispatch]);
    const extendDueDateResponse = () => {
        displayExtendDueDateToast();
        history.push("/dashboard/sent-requests?page=1&status=4&ordering=-created_at");
    };
    const callExtendDueDate = (date)=>{
        const token = getAccessToken();
        const requestBody = {
          due_date:  Moment(new Date(date)).format('YYYY-MM-DD'),
          status: 4
        };
        sendRequest(
          {
            url: `${API_REQUEST_URL}${requestId}/`,
            method: "PATCH",
            body: requestBody,
            headers: { Authorization: `Bearer ${token}` },
          },
          extendDueDateResponse
        );
    };
    useEffect(() => {        
        if (previewdValue) {  
          let myMinDate = new Date(previewdValue);
          let myMaxDate = new Date(previewdValue);
          myMinDate.setDate(myMinDate.getDate() + 1);
          myMaxDate.setDate(myMinDate.getDate() + 56);
          setDueDate(new Date(myMinDate));  
          setMinDate(new Date(myMinDate));
          setMaxdate(new Date(myMaxDate));             
        }
      }, [previewdValue]);

      useEffect(() => {
        if (error && error.status === 400) {          
          dispatch(
            addToast({
              id: createRandomId(),
              status: "error",
              description: error.data.errors["0"],
            })
          );
        }
      }, [dispatch, error]);
    return (
        <div className={classes["extend-due-date"]}>
            <span className={classes["btn-extend"]}
             onClick={() => {         
                if(openDatePicker === 0 && close ===1 )
                  setOpenDatePicker(1);                
              }}      
            >Extend Due Date</span>
            <div className={classes["extend-due-date-date-picker"]}>
            <DatePicker 
                readOnly={true}                
                open={openDatePicker}
                //onClick={() => openDatePicker === 1 ? setOpenDatePicker(1) : setOpenDatePicker(0)}
                onClickOutside={() => { setOpenDatePicker(0) }}
                autoComplete="off"
                onMonthChange={console.log}
                selected={dueDate}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(date) => {
                    setDueDate(date);                    
                    setOpenDatePicker(0);
                    close = 0;
                    callExtendDueDate(date);
                }}
            />
            </div>
        </div>
    );
};

export default ExtendDueDtae;