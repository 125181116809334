import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import classes from "./ResponsiveSidebarMenu.module.scss";
import avatar from "../../../assets/images/Icons/avatar.svg";
import logout from "../../../assets/images/Icons/logout.svg";
import userIcon from "../../../assets/images/Icons/user.svg";
import myCompanyIcon from "../../../assets/images/Icons/my-company.svg";
import sendAppIcon from "../../../assets/images/Icons/send-Application.svg";
import sendReqIcon from "../../../assets/images/Icons/send-request.svg";
import recievedAppIcon from "../../../assets/images/Icons/recieved-application.svg";
import draftsIcon from "../../../assets/images/Icons/drafts.svg";
import wizardIcon from "../../../assets/images/Icons/wizard.svg";
import { removeAuthorization } from "../../../utils/authentication";
import { resetUser } from "../../../redux/User/userAction";
import { Fragment } from "react";
const ResponsiveSidebarMenu = ({
  profile,
  responsiveClassName,
  windowWidth,
  receivedApplicationCount,
  sentApplicationCount,
  sentRequestCount,
  draftCount,  
}) => {
  const userRole = useSelector((state) => state.user.userRole);
  const userCompany = useSelector((state) => state.user.userCompany);
  const userAssets = useSelector((state) => state.user.userAssets);
  const dispatch = useDispatch();
  const history = useHistory();
  const logOut = () => {
    removeAuthorization();
    dispatch(resetUser());
    history.push("/");
    window.location.reload();
  };
  return (
    <div
      className={`${classes["menu-container"]} dropdown-menu ${responsiveClassName}`}
      aria-labelledby="dropdownMenu"
      style={{
        width: windowWidth ? windowWidth : 360,
        marginTop: windowWidth ? 0 : 15,
        boxShadow: windowWidth ? "none" : "0px 0px 8px rgba(0, 0, 0, 0.08)",
      }}
    >
      <div className={classes["user-info"]}>
        <div
          style={{
            backgroundImage: `url(${profile.image ? profile.image.file : avatar
              })`,
          }}
          className={classes["user-picture"]}
        ></div>
        <div>
          <div className={classes["user-name"]}>
            {profile.user.first_name} {profile.user.last_name}
          </div>
          <button onClick={logOut} className={`${classes["logout-box"]} btn`}>
            <img src={logout} alt="logout" />
            <span className={classes["logout-txt"]}>Logout</span>
          </button>
        </div>
      </div>
      {/* <div className={classes["seprate-line"]}></div> */}
      <ul className={`nav flex-column mb-auto ${classes["link-list"]}`}>
        <li className="nav-item">
          <NavLink
            to="/dashboard/profile"
            className={`nav-link ${classes.link}`}
            activeClassName={classes["active-link"]}
          >
            <div className="d-flex align-items-center">
              <div className={`${classes["icon-box"]} bi me-2 `}>
                <img className={classes.icon} src={userIcon} alt="user" />
              </div>
              <span className={classes.text}>My Profile</span>
            </div>
          </NavLink>
        </li>
        {userRole !== "guest" && (
          <Fragment>
            {(userRole === "freelancer" || userCompany) && 
              <li className="nav-item">
                <NavLink
                  to="/dashboard/company-dashboard"
                  className={`nav-link ${classes.link}`}
                  activeClassName={classes["active-link"]}
                >
                  <div className="d-flex align-items-center">
                    <div className={`${classes["icon-box"]} bi me-2 `}>
                      <img
                        className={classes.icon}
                        src={myCompanyIcon}
                        alt="user"
                      />
                    </div>
                    <span className={classes.text}> Company dashboard</span>
                  </div>
                </NavLink>
              </li>
            }
            {/* <div className={classes["seprate-line"]}></div> */}
            <li className="nav-item">
              <NavLink
                exact
                to="/dashboard/all-sent-applications?page=1&ordering=-created_at"
                className={`nav-link ${classes.link}`}
                activeClassName={classes["active-link"]}
              >
                <div className="d-flex align-items-center mt-3">
                  <div className={`${classes["icon-box"]} bi me-2 `}>
                    <img
                      className={classes.icon}
                      src={sendAppIcon}
                      alt="user"
                    />
                  </div>

                  <span className={classes.text}>Sent Applications</span>
                </div>
                {sentApplicationCount && sentApplicationCount !== 0 ? (
                  <span className={classes.badge}>{sentApplicationCount}</span>
                ) : (
                  ""
                )}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/dashboard/all-requests?page=1&ordering=-created_at"
                className={`nav-link ${classes.link}`}
                activeClassName={classes["active-link"]}
              >
                <div className="d-flex align-items-center">
                  <div className={`${classes["icon-box"]} bi me-2 `}>
                    <img
                      className={classes.icon}
                      src={sendReqIcon}
                      alt="user"
                    />
                  </div>

                  <span className={classes.text}>Posted Requests</span>
                </div>
                {userAssets && userAssets.exact_open_requests !== 0 ? (
                  <span className={classes.badge}>{userAssets.exact_open_requests}</span>
                ) : (
                  ""
                )}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/dashboard/all-recieved-applications?page=1&ordering=-created_at"
                className={`nav-link ${classes.link}`}
                activeClassName={classes["active-link"]}
              >
                <div className="d-flex align-items-center">
                  <div className={`${classes["icon-box"]} bi me-2 `}>
                    <img
                      className={classes.icon}
                      src={recievedAppIcon}
                      alt="user"
                    />
                  </div>

                  <span className={classes.text}>Recieved Applications</span>
                </div>
                {receivedApplicationCount && receivedApplicationCount !== 0 ? (
                  <span className={classes.badge}>
                    {receivedApplicationCount}
                  </span>
                ) : (
                  ""
                )}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/dashboard/all-drafts?page=1&ordering=-created_at"
                className={`nav-link ${classes.link}`}
                activeClassName={classes["active-link"]}
              >
                <div className="d-flex align-items-center">
                  <div className={`${classes["icon-box"]} bi me-2 `}>
                    <img className={classes.icon} src={draftsIcon} alt="user" />
                  </div>

                  <span className={classes.text}> Drafts</span>
                </div>
                {draftCount && draftCount !== 0 ? (
                  <span className={classes.badge}>
                    {draftCount}
                  </span>
                ) : (
                  ""
                )}
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                exact
                to="/"
                className={`nav-link ${classes.link}`}
                activeClassName={classes["active-link"]}
              >
                <div className="d-flex align-items-center">
                  <div className={`${classes["icon-box"]} bi me-2 `}>
                    <img className={classes.icon} src={wizardIcon} alt="user" />
                  </div>

                  <span className={classes.text}> Wizard</span>
                </div>
              </NavLink>
            </li> */}
          </Fragment>
        )}
      </ul>
    </div>
  );
};

export default ResponsiveSidebarMenu;
