export const createSelectedItemArray = (selectedArr, sourceArr) => {
  return sourceArr.filter((category) =>
    selectedArr.includes(JSON.stringify(category.id))
  );
};

export const removeFromSelectedItems = (selectedArr, selectedId) => {
  return selectedArr.filter((category) => category.id !== selectedId);
};

export const createSelectedIdArray = (selectedItems) => {
  return selectedItems.map((item) => {
    return JSON.stringify(item.id);
  });
};
