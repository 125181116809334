import React, {Fragment, useCallback, useEffect, useState} from 'react';
import classes from './LatestInvoice.module.scss';
import {Link, useHistory} from 'react-router-dom';
import {getAccessToken} from "../../utils/authentication";
import {API_SUBSCRIPTION} from "../../constants/API";
import {useDispatch, useSelector} from "react-redux";
import useHttp from "../../hooks/use-http";
import {addToast} from "../../redux/Toast/toastAction";
import {createRandomId} from "../../utils/common";
import emptyIcon from "../../assets/images/Icons/empty.svg";

const LatestInvoice = () => {
    const [invoices, setInvoices] = useState([]);
    const history = useHistory();
    const {sendRequest, error, isLoading} = useHttp();
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const userCompany = useSelector((state) => state.user.userCompany);
    const dispatch = useDispatch();
    const latestInvoice = invoices.length > 0 ? invoices[0] : null
    const onclickHandler = () => {
        window.location.href = latestInvoice.checkout_url
    }

    const getInvoices = (data) => {
        setInvoices(data);
    };
    const fetchInvoices = useCallback(() => {
        if (isLoggedIn) {
            const token = getAccessToken();
            sendRequest({
                url: `${API_SUBSCRIPTION}/invoices/`,
                headers: {Authorization: `Bearer ${token}`},
            }, getInvoices);
        }

    }, [sendRequest, isLoggedIn, userCompany]);

    useEffect(() => {
        fetchInvoices();
    }, [fetchInvoices]);

    useEffect(() => {
    }, [userCompany]);

    useEffect(() => {
        if (error) {
            if (error.status === 404 || error.status === 400) {
                dispatch(
                    addToast({
                        id: createRandomId(),
                        status: "error",
                        description: error.data.detail,
                    })
                );
            } else if (error.status === 500) {
                dispatch(
                    addToast({
                        id: createRandomId(),
                        status: "error",
                        description: "Unexpected Error occurred. Please try again.",
                    })
                );

            }

        }
    }, [dispatch, error]);


    return (
        <div>
            <div className={classes['title-wrapper']}>
                <div className={classes['title']}>
                    Latest Invoice
                </div>
                <div className={classes['history']}>
                    <Link to="/dashboard/invoices">
                        Invoice History
                    </Link>
                </div>
            </div>
            <div className='row px-5'>

                {
                    latestInvoice ? (
                        <Fragment>
                            <div className='col-12'>
                                <div className={classes['plan']}>
                                    Invoice {latestInvoice ? latestInvoice.invoice_number : 'N/A'}
                                </div>
                                <div className={classes['details']}>
                                    <div className={classes.key}>Generation Date</div>
                                    <div
                                        className={classes.val}>{latestInvoice ? latestInvoice.invoice_date : 'N/A'}</div>
                                </div>
                                <div className={classes['details']}>
                                    <div className={classes.key}>Amount</div>
                                    <div
                                        className={classes.val}>{latestInvoice ? `${latestInvoice.invoice_amount} €` : 'N/A'}</div>
                                </div>
                                <div className={`${classes['details']} m-0`}>
                                    <div className={classes.key}>Status</div>
                                    <div
                                        className={classes.val}>{latestInvoice ? latestInvoice.invoice_status : 'N/A'}</div>
                                </div>
                                <div className={classes['pay-btn-wrapper']}>
                                    {
                                        latestInvoice && latestInvoice.checkout_url ?
                                            <button
                                                onClick={onclickHandler}
                                                className={`${classes['pay-btn']} btn`}
                                            >
                                                Pay
                                            </button> :
                                            ''
                                    }

                                </div>
                            </div>

                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className='col-12 d-flex align-middle justify-content-center'>
                                <img src={emptyIcon} alt='no plan'/>
                            </div>
                            <div className='col-12 pt-4 d-flex align-middle justify-content-center'>
                                There is no invoice to display
                            </div>

                        </Fragment>
                    )

                }
            </div>
        </div>
    );
};

export default LatestInvoice;