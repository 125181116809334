import React from 'react';
import classes from './VerifySuccessful.module.scss';
import { ReactComponent as Icon } from "../../../assets/images/verify_success.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const VerifySuccessful = () => {
    const history = useHistory();
    return (
        <section className={`${classes["section-container"]}`}>
            <div className={`${classes["form-container"]} container mx-3 mx-lg-0 my-2 my-md-5`}>
                <div className={classes["icon"]}>
                    <Icon />
                </div>
                <h1 className={classes["title"]}>Congratulations!</h1>
				<p className={classes["desc"]}>Your account has ben verified successfully.
                    <br/>Login to your account and explore the Life Science world!</p>
                    <button
						className={`btn ${classes["home-btn"]}`}
                        onClick={() => history.push("/")}
					>
						Start matching
					</button>
            </div>
        </section>
    );
};




export default VerifySuccessful;