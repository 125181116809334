import React, { Fragment } from 'react';
import classes from './DashboardUserManagment.module.scss';
import CardV2 from '../UI/CardV2/CardV2';
import SimpleInput from "../UI/SimpleInput/SimpleInput";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import tikIcon from "../../assets/images/Icons/tick.svg";
import threeDots from "../../assets/images/Icons/3dot.svg";
import closeBtn from "../../assets/images/Icons/close-button-toast.svg";
import { dateFormmaterNormal } from "../../utils/common";

const DashboardUserManagment = ({ isAdmin,
    companyParticipants,
    invitedUsersList,
    joinRequestCompany,
    onInviteUserToCompany,
    onDeleteInvitation,
    onRemoveUserFromAdmin,
    onPromoteUserToAdmin,
    onDeleteUser,
    onDeactiveCompany,
    onDenyUser,
    onGrantAccessUser }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (submittedForm) => {
        console.log(submittedForm);
        onInviteUserToCompany(submittedForm.email);
    };
    return (
        <CardV2 className={classes.card}>
            <div className='row'>
                <div className='col-md-6 col-12 p-0'>
                    <div className={classes['card-title']}>
                        User Management
                    </div>
                </div>
                <div className='col-md-6 col-12 p-0 text-end'>
                    <div className='row'>
                        {isAdmin && (
                            <Fragment>
                                <div className='col-6'>
                                    <div className={classes['invite-label']}>Invite new user</div>
                                </div>
                                <div className='col-6'>

                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="position-relative">
                                            <SimpleInput
                                                validation={{
                                                    required: true,
                                                    pattern: {
                                                        value:
                                                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                        message: "Please enter a valid email",
                                                    },
                                                }}
                                                input={{
                                                    type: "text",
                                                    label: "User Email Address",
                                                    name: "email",
                                                    placeholder: "User Email Address",
                                                    id: "Email",
                                                    autoComplete: "off",
                                                }}
                                                errors={errors}
                                                register={register}
                                            />
                                            <button className={`btn ${classes.submit}`} type="submit">
                                                <img src={tikIcon} alt="tick" />
                                            </button>
                                        </div>
                                    </form>


                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>


            <div>
                <table className={` ${classes['table-users']}`}>
                    <thead>
                        <tr>
                            <th>
                                User Name
                            </th>
                            <th>
                                Email
                            </th>
                            <th>
                                Joined date
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                role
                            </th>
                            <th>
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {joinRequestCompany.map((item, index) => (
                            <tr key={item.id}>
                                <td>
                                    <div className="d-flex align-items-baseline">
                                        <span className={classes["user-name"]}>
                                            {item.first_name} {item.last_name}
                                        </span>
                                    </div>
                                </td>
                                <td>{item.email} </td>
                                <td>-</td>
                                <td>{item.status}</td>
                                <td>User</td>
                                <td className="">
                                    <div className="btn-group dropstart mx-2">
                                        <button
                                            type="button"
                                            className={`${classes["show-more-btn"]} btn p-0`}
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <img src={threeDots} alt="menu" />
                                        </button>
                                        <ul
                                            className={`${classes["admin-menu"]} dropdown-menu`}
                                        >
                                            <img
                                                src={closeBtn}
                                                alt="close"
                                                className={classes["close-btn"]}
                                            />
                                            <li>
                                                <button
                                                    className={`btn ${classes["deny-btn"]}`}
                                                    onClick={() => onDenyUser(item.id)}
                                                >
                                                    Deny
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className={`btn ${classes["grant-btn"]}`}
                                                    onClick={() => onGrantAccessUser(item.id)}
                                                >
                                                    Grant Access
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        {invitedUsersList.map((item, index) => (
                            <Fragment>
                                {item.status != "assigned" && (
                                    <tr key={item.id}>
                                        <td>
                                            <div className="d-flex align-items-baseline">
                                                <span className={classes["user-name"]}>
                                                    {item.first_name} {item.last_name}
                                                </span>
                                            </div>
                                        </td>
                                        <td>{item.email} </td>
                                        <td>-</td>
                                        {item.status === "sent" && <td>invitation Sent</td>}
                                        {item.status === "assigned" && <td>accepted</td>}
                                        <td>User</td>
                                        <td className="">
                                            {item.status === "sent" && (
                                                <div className="btn-group dropstart mx-2">
                                                    <button
                                                        type="button"
                                                        className={`${classes["show-more-btn"]} btn p-0`}
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <img src={threeDots} alt="menu" />
                                                    </button>
                                                    <ul
                                                        className={`${classes["admin-menu"]} dropdown-menu`}
                                                    >
                                                        <img
                                                            src={closeBtn}
                                                            alt="close"
                                                            className={classes["close-btn"]}
                                                        />
                                                        <li>
                                                            <button
                                                                onClick={() => {
                                                                    onDeleteInvitation(item.id);
                                                                }}
                                                                className={`btn ${classes["cencel-btn"]}`}
                                                            >
                                                                Cancel invitation
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        ))}
                        {companyParticipants.map((item, index) => (
                            <tr key={item.id}>
                                <td>
                                    <div className={`${classes["image-email"]} d-flex align-items-center`}>
                                        <div
                                            style={{
                                                backgroundImage: `url(${item.image ? item.image : ''
                                                    })`,
                                            }}
                                            className={classes["user-picture"]}
                                        ></div>
                                        <span className={classes["user-name"]}>
                                            {item.first_name} {item.last_name}
                                        </span>
                                    </div>
                                </td>
                                <td>{item.email} </td>
                                <td>{dateFormmaterNormal(item.created_at)}</td>
                                <td>Active</td>
                                <td>
                                    {item.is_admin ? (
                                        'Admin'
                                    ) : (
                                        'User'
                                    )}
                                </td>
                                {companyParticipants.length > 1 && isAdmin && (
                                    <td className="">
                                        <div className="btn-group dropstart mx-2">
                                            <button
                                                type="button"
                                                className={`${classes["show-more-btn"]} btn p-0`}
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <img src={threeDots} alt="menu" />
                                            </button>
                                            <ul
                                                className={`${classes["admin-menu"]} dropdown-menu`}
                                            >
                                                <img
                                                    src={closeBtn}
                                                    alt="close"
                                                    className={classes["close-btn"]}
                                                />

                                                {item.is_admin ? (
                                                    <li>
                                                        <button
                                                            className={`btn ${classes["remove-admin"]}`}
                                                            onClick={() => onRemoveUserFromAdmin(item.user)}
                                                        >
                                                            Remove Admin
                                                        </button>
                                                    </li>
                                                ) : (
                                                    <li>
                                                        <button
                                                            className={`btn ${classes["remove-admin"]}`}
                                                            onClick={() => onPromoteUserToAdmin(item.user)}
                                                        >
                                                            Make Admin
                                                        </button>
                                                    </li>
                                                )}

                                                <li>
                                                    <button
                                                        className={`btn ${classes["delete-user"]}`}
                                                        onClick={() => onDeleteUser(item.id)}
                                                    >
                                                        Delete User
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                )}
                                {companyParticipants.length === 1 && isAdmin && (
                                    <td>
                                        <button
                                            onClick={onDeactiveCompany}
                                            className={`${classes["deactive-company"]} btn`}
                                        >
                                            Deactivate Company
                                        </button>
                                    </td>
                                )}
                                {!isAdmin && (<td></td>)}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </CardV2>
    );
};

export default DashboardUserManagment;