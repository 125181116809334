import {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import classes from "./NewsList.module.scss";
import NewsItem from "../../components/NewsItem/NewsItem";
import SortBox from "../../components/UI/SortBox/SortBox";
import NewsFilterBox from "../../components/NewsFilterBox/NewsFilterBox";
import Pagination from "../../components/UI/Pagination/Pagination";
import {API_NEWS_URL} from "../../constants/API";
import useHttp from "../../hooks/use-http";
import Loading from "../../components/UI/Loading/Loading";
import NotFoundItems from "../../components/UI/NotFoundItems/NotFoundItems";
import {
    splitQueryParams,
    updateDuplicatedUrlParams,
    updateUniqueUrlParams,
    updateDuplicatedApiParams,
    updateUniqueApiParams,
    resetQueryParams,
} from "../../utils/QueryParams";
import calculateOffset from "../../utils/calculateOffset";
import {LIMIT_ITEM_NEWS} from '../../constants/Common';
import NewsFilterBoxAccordion from "../../components/NewsFilterBoxAccordion/NewsFilterBoxAccordion";
import FeaturedNewsItem from "../../components/FeaturedNewsItem/FeaturedNewsItem";
import NewsSearchBox from "../../components/NewsSearchBox/NewsSearchBox";

const NewsList = () => {
    const [newsListRes, setNewsListRes] = useState([]);
    const [currentPage, setCurrentPage] = useState(null);
    const [count, setCount] = useState(null);
    const [sortItem, setSortItem] = useState(null);
    const [searchItem, setSearchItem] = useState(null);
    const [keyServices, setKeyServices] = useState([]);
    const [timeFilter, setTimeFilter] = useState(null);
    const [apiQueryParams, setApiQueryParams] = useState(`?limit=${LIMIT_ITEM_NEWS}`);
    const history = useHistory();
    const {sendRequest, isLoading} = useHttp();

    const splitUrlQueryparams = () => {
        const queryParams = window.location.search;
        const splitedQueryParams = splitQueryParams(queryParams);
        setNewsStates(splitedQueryParams);
    };

    const setNewsStates = (params) => {
        params.page ? setCurrentPage(parseInt(params.page)) : setCurrentPage(1);
        params.sort ? setSortItem(params.sort) : setSortItem(null);
        params.text ? setSearchItem(params.text) : setSearchItem(null);
        params.key_services
            ? setKeyServices(params.key_services)
            : setKeyServices([]);
        params.when ? setTimeFilter(params.when) : setTimeFilter(null);
        createOffset(params);
    };

    const createOffset = (params) => {
        let offset;
        // if page is not equal 1
        if (params.page) {
            offset = calculateOffset(parseInt(params.page), LIMIT_ITEM_NEWS);
            delete params.page;
        } // page =1 (page load for first time)
        else {
            offset = calculateOffset(1, LIMIT_ITEM_NEWS);
        }
        params["offset"] = offset;
        createApiQueryParams(params);
    };

    const createApiQueryParams = (params) => {
        let updatedQueryParams = apiQueryParams;
        for (let key in params) {
            if (key === "key_services") {
                for (let index = 0; index < params[key].length; index++) {
                    updatedQueryParams = updateDuplicatedApiParams(
                        key,
                        params[key][index],
                        updatedQueryParams
                    );
                }
            } else {
                updatedQueryParams = updateUniqueApiParams(
                    `${key}`,
                    params[key],
                    updatedQueryParams
                );
            }
        }
        setApiQueryParams(updatedQueryParams);
        fetchNewsList(updatedQueryParams);
    };
    const resetPage = () => {
        setCurrentPage(1);
        let offset = calculateOffset(1, LIMIT_ITEM_NEWS);
        let updatedQueryParams = updateUniqueApiParams(
            "offset",
            offset,
            apiQueryParams
        );
        setApiQueryParams(updatedQueryParams);
        return updatedQueryParams;
    };
    const resetAll = () => {
        setCurrentPage(1);
        let offset = calculateOffset(1, LIMIT_ITEM_NEWS);
        let updatedQueryParams = updateUniqueApiParams(
            "offset",
            offset,
            ""
        );
        setTimeFilter(null);
        setApiQueryParams("");
        updateUniqueUrlPage(updatedQueryParams);
        getNewsListByUniqueFilters('', '');
    };
    const getNewsListByUniqueFilters = (newkey, newValue) => {
        let updatedQueryParams;
        updatedQueryParams = resetPage();
        // choose new filter
        if (newValue) {
            updatedQueryParams = updateUniqueApiParams(
                newkey,
                newValue,
                updatedQueryParams
            );
            const urlParams = {
                page: 1,
                [newkey]: newValue,
            };
            let result = updateUniqueUrlParams(urlParams);
            updateUniqueUrlPage(result);
        } // choose All item and remove it from url and API
        else {
            // reset page number to 1---------------------------------------
            const urlParams = {page: 1};
            let result = updateUniqueUrlParams(urlParams);
            result = resetQueryParams(newkey, result);
            // remover filter from params of page---------------------------
            updateUniqueUrlPage(result);
            // remove filter from params of api----------------------------
            updatedQueryParams = resetQueryParams(newkey, updatedQueryParams);
        }
        setApiQueryParams(updatedQueryParams);
        fetchNewsList(updatedQueryParams);
    };
    const getNewsListByDuplicatedFilters = (newkey, list) => {
        // update url of API-------------------------------------------
        let updatedQueryParams = resetPage();
        updatedQueryParams = resetQueryParams(newkey, updatedQueryParams);
        list.forEach((element) => {
            updatedQueryParams = updateDuplicatedApiParams(
                newkey,
                element,
                updatedQueryParams
            );
        });
        setApiQueryParams(updatedQueryParams);
        // update url of page----------------------------------------------
        const urlParams = {
            page: [1],
            [newkey]: list,
        };
        let result = updateDuplicatedUrlParams(urlParams);
        updateDuplicatedUrlPage(result);
        // fetch news list -------------------------------------------
        fetchNewsList(updatedQueryParams);
    };

    const getSortItem = (item) => {
        getNewsListByUniqueFilters("sort", item);
        setSortItem(item);
    };
    const getSearchNews = (searchItem) => {
        getNewsListByUniqueFilters("text", searchItem);
        setSearchItem(searchItem);
    };

    const getKeyServices = (keyServices) => {
        getNewsListByDuplicatedFilters("key_services", keyServices);
        setKeyServices(keyServices);
    };

    const getTimeFilter = (time) => {
        getNewsListByUniqueFilters("when", time);
        setTimeFilter(time);
    };

    const updateUniqueUrlPage = (result) => {
        history.push({
            pathname: "/news",
            search: result,
        });
    };
    const updateDuplicatedUrlPage = (result) => {
        history.push({
            pathname: "/news",
            search: result,
        });
    };

    const getActivatedPage = (page) => {
        if (currentPage !== page) {
            setCurrentPage(page);
            let offset = calculateOffset(page, LIMIT_ITEM_NEWS);
            let updatedQueryParams = updateUniqueApiParams(
                "offset",
                offset,
                apiQueryParams
            );
            setApiQueryParams(updatedQueryParams);
            const urlParams = {page};
            let result = updateUniqueUrlParams(urlParams);
            updateUniqueUrlPage(result);
            fetchNewsList(updatedQueryParams);
        }
    };

    const getNewsList = (data) => {
        setNewsListRes(data.results);
        setCount(data.total_count);
        window.scrollTo(0, 0);
    };

    const fetchNewsList = (updatedQueryParams) => {
        setCount(null);
        sendRequest({url: `${API_NEWS_URL}${updatedQueryParams}`}, getNewsList);
    };

    useEffect(() => {
        splitUrlQueryparams();
        return () => {
            setNewsListRes([]);
        };
    }, []);

    useEffect(() => {
        return history.listen((location) => {
            if (history.action === "POP") {
                splitUrlQueryparams();
            }
        });
    }, []);

    return (
        <section className={`container  flex-grow ${classes.section}`}>
            <div className="row">
                <div className={`col-9 ${classes.title_col}`}>
                    <div className={classes.title}>News</div>
                </div>
                <div className={`col-3 ${classes.search}`}>
                    <NewsSearchBox
                        onSearchNews={getSearchNews}
                        searchItem={searchItem}
                        onKeyServices={getKeyServices}
                        initialKeyServices={keyServices}
                        onGetTimeFilter={getTimeFilter}
                        initialTimeFilter={timeFilter}
                        componentUsage="news"
                    />
                </div>

                <div className="col-xl-9 col-12">

                    {newsListRes && newsListRes.length !== 0 && !isLoading && (
                        <div
                            className={`row justify-content-lg-start justify-content-center  align-items-center ${classes["min-h"]}`}
                        >
                            {newsListRes.map((news, index) => (
                                // <FeaturedNewsItem
                                //         key={news.id}
                                //         news={news}
                                //         className="col-lg-12 col-md-12 col-12"
                                //     />
                                <NewsItem
                                    key={news.id}
                                    news={news}
                                    className="col-lg-12 col-md-12 col-12"
                                />


                            ))}
                        </div>
                    )}
                    {isLoading || (newsListRes.length === 0 && !isLoading) ? (
                        <div
                            className={`row justify-content-center  align-items-center ${classes["min-h"]}`}
                        >
                            {isLoading && <Loading className={classes.loading}/>}
                            {newsListRes.length === 0 && !isLoading && (
                                <NotFoundItems className={classes["mt-50"]} item="News"/>
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                    {count > LIMIT_ITEM_NEWS && (
                        <Pagination
                            onActivatedPage={getActivatedPage}
                            count={count}
                            limit={LIMIT_ITEM_NEWS}
                            currentPage={currentPage}
                            noCount={true}
                        />
                    )}
                </div>
                <div className="col-xl-3 col-12">
                    <NewsFilterBox
                        onSearchNews={getSearchNews}
                        searchItem={searchItem}
                        onKeyServices={getKeyServices}
                        initialKeyServices={keyServices}
                        onGetTimeFilter={getTimeFilter}
                        initialTimeFilter={timeFilter}
                        componentUsage="news"
                    />
                    <NewsFilterBoxAccordion
                        onSearchNews={getSearchNews}
                        searchItem={searchItem}
                        onKeyServices={getKeyServices}
                        initialKeyServices={keyServices}
                        onGetTimeFilter={getTimeFilter}
                        initialTimeFilter={timeFilter}
                        componentUsage="news"
                        onReset={resetAll}
                    />
                </div>
            </div>
            {/* <NewsFilterBox
        onSearchNews={getSearchNews}
        searchItem={searchItem}
        onKeyServices={getKeyServices}
        initialKeyServices={keyServices}
        onGetTimeFilter={getTimeFilter}
        initialTimeFilter={timeFilter}
        componentUsage="news"
      />
      <SortBox
        count={count}
        sortItem={sortItem}
        dispatchSortItem={getSortItem}
      /> */}

        </section>
    );
};

export default NewsList;
