import classes from "./SearchableDropDownFilterLifeScience.module.scss";
import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as React from "react";
import { useDropdown } from "../../../hooks/use-dropdown";
import SimpleCheckBox from "../SimpleCheckBox/SimpleCheckBox";
import SelectUnSelectInterests from "../../SelectUnSelectInterests/SelectUnSelectInterests";

const SearchableDropDownFilterLifeScience = (props) => {
  const {
    className,
    filterName,
    filterList,
    initialFilters,
    dispatchFilterList,
    isSelectedItem,
    isDisabled,
    errorMessage,
    customLabel,
    selectUnSelectItems,
    removeSelectedItem
  } = props;
  const [displayedItems, setDisplayedItems] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const { register, handleSubmit, setValue, reset, getValues } = useForm();
  const [dropdownRef, isOpen, setIsOpen] = useDropdown();

  useEffect(() => {
    setDisplayedItems(filterList);
  }, [filterList]);

  const setInitialFilters = useCallback(() => {
    if (initialFilters && initialFilters.length !== 0) {
      reset();
      setSelectedFilter(initialFilters.length);
      initialFilters.forEach((item) => {
        setValue(item, true);
      });
    } else {
      setSelectedFilter(initialFilters.length);
      reset();
    }
  }, [initialFilters, reset, setValue]);

  useEffect(() => {
    setInitialFilters();
  }, [initialFilters, setInitialFilters]);

  const onSubmit = (list) => {
    let arr = [];
    for (const key in list) {
      if (list[key]) {
        arr.push(key);
      }
    }
    dispatchFilterList(arr);
    setIsOpen(!isOpen);
    setDisplayedItems(filterList);
  };

  const resetFilters = () => {
    reset();
    setSelectedFilter(0);
  };

  const keyChangeHandler = (id) => {
    console.log('init lenght: ', initialFilters.length);

    const value = getValues(id);

    let array = initialFilters;
    if (initialFilters.includes(id)) {
      value ? setValue(id, false) : setValue(id, true);
      value
        ? setSelectedFilter(selectedFilter - 1)
        : setSelectedFilter(selectedFilter + 1);
      array = array.filter(item => !(item == id));
    }
    else {
      if (initialFilters.length < 3) {
        value ? setValue(id, false) : setValue(id, true);
        value
          ? setSelectedFilter(selectedFilter - 1)
          : setSelectedFilter(selectedFilter + 1);
        array = array.filter(item => !(item == id));
        array.push('' + id);
      }
    }
    dispatchFilterList(array);
  };

  const searchHandler = (e) => {
    let input = e.target.value.toLowerCase();
    let result = filterList.filter((element) =>
      element.title.toLowerCase().includes(input)
    );
    setDisplayedItems(result);
  };

  return (
    <div ref={dropdownRef} className={`dropdown ${classes.dropdown}`}>
      <button
        style={{ borderColor: (!isSelectedItem || errorMessage) ? "#e10000" : "#343A40" }}
        onClick={() => setIsOpen(!isOpen)}
        className={`${classes["dropdown-button"]}`}
        type="button"
        aria-expanded={isOpen}
        disabled={isDisabled}
      >
        {selectedFilter === 0 && (!isOpen) && (
          <div className={classes["noselect-label"]}>
            Life Science Sector
          </div>
        )}
        <SelectUnSelectInterests
          bgColor="#D3F2FA"
          selectedItems={selectUnSelectItems}
          removeSelectedItem={removeSelectedItem}
          color="#1E4C90"
          searchHandler={searchHandler}
          searchFocus={isOpen}
        />
        {/* <input
          className={classes["inner-search-input"]}
          type="text"
          placeholder=""
          onChange={(e) => searchHandler(e)}
        /> */}
      </button>
      {isOpen && (
        <div
          id="filtersBox"
          className={`${className} ${classes["dropdown-list"]}`}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes["items-wrapper"]}>
              {displayedItems.map((item) => (
                <div className={classes['item-wrapper']}>
                  <SimpleCheckBox
                    key={item.id}
                    onChangeHandler={keyChangeHandler}
                    input={{
                      name: `${item.id}`,
                      label: `${item.title}`,
                      id: item.id,
                    }}
                    register={register}
                  />
                </div>
              ))}
            </div>

          </form>
        </div>
      )}
      {customLabel && (
        <div className={classes["life-science-custom-label"]}>
          {customLabel}
        </div>
      )}
      {!isSelectedItem && (
        <div className={classes["single-input-error"]}>This value is required.</div>
      )}
      {errorMessage && (
        <div className={classes["single-input-error"]}>{errorMessage}</div>
      )}
    </div>
  );
};

export default SearchableDropDownFilterLifeScience;
