import classes from "./CompanyActivity.module.scss";
import Card from "../UI/CardV2/CardV2";
import activity from "../../assets/images/Icons/activity.svg";

const CompanyActivity = ({ companyAssets }) => {
  return (
    
    <Card className={classes["company-activity-card"]}>
      {console.log("companyassets",companyAssets)}
      <div className={classes["header-company-activity"]}>
        {/* <img src={activity} alt="bag" /> */}
        <span className={classes["header-txt"]}>Company Activity</span>
      </div>
      <div className="d-flex justify-content-between px-5">
        <div className={`${classes["activity-item"]} `}>
          {companyAssets && (
            <span className={classes["activity-count"]}>
              {companyAssets.draft_requests}
            </span>
          )}
          <span className={classes["activity-txt"]}>Drafted<br/>Requests</span>
        </div>
        <div className={`${classes["activity-item"]} `}>
          {companyAssets && (
            <span className={classes["activity-count"]}>
              {companyAssets.sent_applications}
            </span>
          )}

          <span className={classes["activity-txt"]}>Sent<br/>Applications</span>
        </div>
        <div className={`${classes["activity-item"]} `}>
          {companyAssets && (
            <span className={classes["activity-count"]}>
              {companyAssets.open_requests}
            </span>
          )}

          <span className={classes["activity-txt"]}>Open<br/>Requests</span>
        </div>
        <div className={`${classes["activity-item"]} `}>
          {companyAssets && (
            <span className={classes["activity-count"]}>
              {companyAssets.closed_requests}
            </span>
          )}

          <span className={classes["activity-txt"]}>Closed<br/>Requests</span>
        </div>
      </div>
      <div className={classes["matchmaking-box"]}>
        <div
          className={`${classes["activity-item"]} ${classes["match-making"]}`}
        >
          {companyAssets && (
            <span className={classes["activity-count"]}>
              {companyAssets.matchmakings}
            </span>
          )}

          <span className={classes["activity-txt"]}>Matchmakings</span>
        </div>
        <div
          className={`${classes["activity-item"]} ${classes["match-making"]}`}
        >
          {companyAssets && (
            <span className={classes["activity-count"]}>
              {companyAssets.successful_applications}
            </span>
          )}

          <span className={classes["activity-txt"]}>
            Successful<br/>Applications
          </span>
        </div>
      </div>
    </Card>
  );
};

export default CompanyActivity;
