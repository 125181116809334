import { useEffect, useState } from "react";
import axios from "axios";
import classes from "./BlogTagFilterBox.module.scss";
import {API_BLOG_URL} from "../../constants/API";

const BlogTagFilterBox = ({ onFilter }) => {
    const [tags, setTags] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [selectedTagId, setSelectedTagId] = useState(null);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axios.get(`${API_BLOG_URL}tags`);
                setTags(response.data);
            } catch (error) {
                console.error("Failed to fetch tags", error);
            }
        };

        fetchTags();
    }, []);

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    const handleTagFilter = (tag) => {
        const newSelectedTagId = selectedTagId === tag.id ? null : tag.id;
        setSelectedTagId(newSelectedTagId);
        onFilter(newSelectedTagId);
    };

    const tagsToShow = showMore ? tags : tags.slice(0, 4);

    return (
        <div className={classes.tagFilterBox}>
            <h3>Recommended Tags</h3>
            {tagsToShow.map((tag, index) => (
                <div
                    key={index}
                    onClick={() => handleTagFilter(tag)}
                    className={`${classes.tagItem} ${selectedTagId === tag.id ? classes.selected : ""}`}
                >
                    {tag.name}
                </div>
            ))}
            {tags.length > 4 && (
                <div onClick={handleShowMore} className={classes.showMore}>
                    {showMore ? "Show Less" : "Show More"}
                </div>
            )}
        </div>
    );
};

export default BlogTagFilterBox;