import React, { Fragment, useEffect, useState } from "react";
import classes from "./SolutionTable.module.scss";
import SolutionButton from "../SolutionButton/SolutionButton";
import { useSelector } from "react-redux";

const SolutionTable = ({ solutionList, onClickHandler }) => {
    const [selectedSolution, setSelectedSolution] = useState(null);
    const userCompany = useSelector((state) => state.user.userCompany);
    useEffect(() => {
    }, [userCompany]);
    useEffect(() => {
        setSelectedSolution(solutionList[0]);
    }, [solutionList]);
    const selectedSolutionHandler = () => {
        onClickHandler(selectedSolution.id)
    };
    const handleColumnClick = (solution) => {
        setSelectedSolution(solution);
    };
    return (
        <section className={`container ${classes['table-section']} justify-content-center`}>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        {solutionList.map((obj) => {
                            return (

                                <th
                                    key={obj.id}
                                    className={selectedSolution === obj ? `${classes.selected}` : ''}
                                    onClick={() => handleColumnClick(obj)}
                                >
                                    {obj.type_display}
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Creating Request</td>
                        {solutionList.map((solution) => {
                            return (
                                <td
                                    key={solution.id}
                                    onClick={() => handleColumnClick(solution)}
                                    className={selectedSolution === solution ? `${classes.selected}` : ''}
                                >Unlimited</td>

                            )
                        })}
                    </tr>
                    <tr>
                        <td>Sending Application</td>
                        {solutionList.map((solution) => {
                            return (
                                <td
                                    key={solution.id}
                                    onClick={() => handleColumnClick(solution)}
                                    className={selectedSolution === solution ? `${classes.selected}` : ''}
                                >{solution.application_count > 1000 ? 'Unlimited' : `${solution.application_count} Applications`}</td>

                            )
                        })}
                    </tr>
                    <tr>
                        <td>Match</td>
                        {solutionList.map((solution) => {
                            return (
                                <td
                                    key={solution.id}
                                    onClick={() => handleColumnClick(solution)}
                                    className={selectedSolution === solution ? `${classes.selected}` : ''}
                                >{solution.matches_count > 1000 ? 'Unlimited' : `${solution.matches_count} Matches`}</td>

                            )
                        })}
                    </tr>
                    <tr>
                        <td><div className={classes['relevant-label']}>Relevant Tendering Notifications</div></td>
                        {solutionList.map((solution) => {
                            return (
                                <td
                                    key={solution.id}
                                    onClick={() => handleColumnClick(solution)}
                                    className={selectedSolution === solution ? `${classes.selected}` : ''}
                                >Unlimited</td>

                            )
                        })}
                    </tr>
                    <tr>
                        <td>Additional Services</td>
                        {solutionList.map((solution) => {
                            return (
                                <td
                                    key={solution.id}
                                    onClick={() => handleColumnClick(solution)}
                                    className={selectedSolution === solution ? `${classes.selected}` : ''}
                                >{solution.type_display === 'Basic' ? `50€ / month` : `Not Applicable`}</td>

                            )
                        })}
                    </tr>
                    <tr>
                        <td>Price</td>
                        {solutionList.map((solution) => {
                            return (
                                <td
                                    key={solution.id}
                                    onClick={() => handleColumnClick(solution)}
                                    className={selectedSolution === solution ? `${classes.selected}` : ''}
                                >{solution.type_display === 'Trial' ? `Free` : `${solution.price}€ / month`}
                                </td>

                            )
                        })}
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        {solutionList.map((solution) => {
                            return (
                                <td
                                    key={solution.id}
                                    onClick={() => handleColumnClick(solution)}
                                    className={selectedSolution === solution ? `${classes['last-item']} ${classes.selected}` : ''}
                                >
                                    {
                                        selectedSolution === solution &&
                                        <SolutionButton
                                            className={classes['solution-table-btn']} text="Start"
                                            onClickHandler={selectedSolutionHandler}
                                            isDisabled={userCompany !== null && solution.type_display === 'Trial' ? userCompany.used_trial : false}


                                        />
                                    }

                                </td>

                            )
                        })}
                    </tr>
                </tfoot>

            </table>
        </section>
    )
};

export default SolutionTable;
