import React from 'react';
import { Fragment } from 'react';
import classes from './CompanyAssetsCount.module.scss';

const CompanyAssetsCount = ({ assetType, companyAssets, dispatchSentReqStatus }) => {
    return (
        <div className={classes['wrapper-assets']}>
            {companyAssets && assetType === "request" && (
                <Fragment>
                    <div
                        style={{ color: '#34A853' }}
                        className={classes['asset-wrapper']}
                        onClick={() => dispatchSentReqStatus(3)}
                    >
                        <div className={classes['asset-count']}>
                            {companyAssets.exact_open_requests}
                        </div>
                        <div className={classes['asset-title']}>
                            Open
                        </div>
                    </div>
                    <div
                        style={{ color: '#24407D' }}
                        className={classes['asset-wrapper']}
                        onClick={() => dispatchSentReqStatus(8)}
                    >
                        <div className={classes['asset-count']}>
                            {companyAssets.matchmakings}
                        </div>
                        <div className={classes['asset-title']}>
                            Successful
                        </div>
                    </div>
                    <div
                        style={{ color: '#E10000' }}
                        className={classes['asset-wrapper']}
                        onClick={() => dispatchSentReqStatus(5)}
                    >
                        <div className={classes['asset-count']}>
                            {companyAssets.closed_requests}
                        </div>
                        <div className={classes['asset-title']}>
                            Closed
                        </div>
                    </div>
                    <div
                        style={{ color: '#22BDE8' }}
                        className={classes['asset-wrapper']}
                        onClick={() => dispatchSentReqStatus(7)}
                    >
                        <div className={classes['asset-count']}>
                            {companyAssets.expired_requests}
                        </div>
                        <div className={classes['asset-title']}>
                            Expired
                        </div>
                    </div>
                </Fragment>
            )}
            {companyAssets && assetType === "application" && (
                <Fragment>
                    <div
                        style={{ color: '#ffb800' }}
                        className={classes['asset-wrapper']}
                        onClick={() => dispatchSentReqStatus('waiting')}
                    >
                        <div className={classes['asset-count']}>
                            {companyAssets.waiting_sent_applications}
                        </div>
                        <div className={classes['asset-title']}>
                        Waiting
                        </div>
                    </div>
                    <div
                        style={{ color: '#24407D' }}
                        className={classes['asset-wrapper']}
                        onClick={() => dispatchSentReqStatus('matched')}
                    >
                        <div className={classes['asset-count']}>
                            {companyAssets.successful_applications}
                        </div>
                        <div className={classes['asset-title']}>
                            Successful
                        </div>
                    </div>
                    <div
                        style={{ color: '#E10000' }}
                        className={classes['asset-wrapper']}
                        onClick={() => dispatchSentReqStatus('rejected')}
                    >
                        <div className={classes['asset-count']}>
                            {companyAssets.rejected_sent_applications}
                        </div>
                        <div className={classes['asset-title']}>
                        Rejected
                        </div>
                    </div>                   
                </Fragment>
            )}
            {companyAssets && assetType === "recieved-application" && (
                <Fragment>
                    <div
                        style={{ color: '#ffb800' }}
                        className={classes['asset-wrapper']}
                        onClick={() => dispatchSentReqStatus('waiting')}
                    >
                        <div className={classes['asset-count']}>
                            {companyAssets.waiting_received_applications}
                        </div>
                        <div className={classes['asset-title']}>
                        Waiting
                        </div>
                    </div>
                    <div
                        style={{ color: '#24407D' }}
                        className={classes['asset-wrapper']}
                        onClick={() => dispatchSentReqStatus('matched')}
                    >
                        <div className={classes['asset-count']}>
                            {companyAssets.matchmakings}
                        </div>
                        <div className={classes['asset-title']}>
                            Successful
                        </div>
                    </div>
                    <div
                        style={{ color: '#E10000' }}
                        className={classes['asset-wrapper']}
                        onClick={() => dispatchSentReqStatus('rejected')}
                    >
                        <div className={classes['asset-count']}>
                            {companyAssets.rejected_received_applications}
                        </div>
                        <div className={classes['asset-title']}>
                        Rejected
                        </div>
                    </div>                   
                </Fragment>
            )}
        </div>
    );
};

export default CompanyAssetsCount;