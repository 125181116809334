export const HOME_TAB_BUTTON = [
  {
    ariaSelected: "true",
    ariaControls: "All-Home",
    dataBsToggle: "#All-Home",
    id: "All",
    title: "All",
    className: "active",
  },
  {
    ariaSelected: "false",
    ariaControls: "FDA-home",
    dataBsToggle: "#FDA-home",
    id: "FDA",
    title: "FDA",
    className: "",
  },
  {
    ariaSelected: "false",
    ariaControls: "FDA-483-Home",
    dataBsToggle: "#FDA-483-Home",
    id: "483",
    title: "483",
    className: "",
  },
  {
    ariaSelected: "false",
    ariaControls: "UK-Home",
    dataBsToggle: "#UK-Home",
    id: "UK",
    title: "UK",
    className: "",
  },
  // {
  //   ariaSelected: "false",
  //   ariaControls: "Eudramed-Home",
  //   dataBsToggle: "#Eudramed-Home",
  //   id: "Eudramed",
  //   title: "Eudramed",
  //   className: "",
  // },
 
];

export const HOME_TAB_CONTENT = [
  {
    id: "FDA-home",
    ariaLabelLedBy: "FDA",
    className: "",
  },
  {
    id: "FDA-483-Home",
    ariaLabelLedBy: "483",
    className: "",
  },
  {
    id: "UK-Home",
    ariaLabelLedBy: "UK",
    className: "",
  },
  // {
  //   id: "Eudramed-Home",
  //   ariaLabelLedBy: "Eudramed",
  //   className: "",
  // },
  {
    id: "All-Home",
    ariaLabelLedBy: "All",
    className: "show active",
  },
];
