import classes from "./Partnership.module.scss";
import { API_TAGS_URL } from "../../constants/API";
import useHttp from "../../hooks/use-http";
import React, { useEffect, useCallback, useState, Fragment } from "react";

const Partnership = () => {
    const { sendRequest, isLoading } = useHttp();
    const [partnerships, setPartnerships] = useState(null);

    const getPartnerships = (partnerships) => {
        setPartnerships(partnerships);
    };

    const fetchPartnerships = useCallback(() => {
        sendRequest(
            {
                url: `${API_TAGS_URL}/partnership/`,
            },
            getPartnerships
        );
    }, [sendRequest]);

    useEffect(() => {
        fetchPartnerships();
        return () => {
            setPartnerships([]); // This worked for me
        };
    }, [fetchPartnerships]);

    return (
        <section className={`${classes["partnership"]}`}>
            <div className="container">
                <p className={`${classes["partnership-title"]}`}>Trusted by Industry Leaders</p>
                <div className={classes["partnership-row"]}>
                    {partnerships && partnerships.map((p) => (
                        <div key={p.id} className={classes["partner"]}>
                            {p.link ? (
                                <a href={p.link} target="_blank">
                                    <img src={p.icon} alt={p.title} />
                                    {/* <h3>{p.title}</h3> */}
                                </a>
                            ) : (
                                <Fragment>
                                    <img src={p.icon} alt={p.title} />
                                    {/* <h3>{p.title}</h3> */}
                                </Fragment>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Partnership;
