import React, { useState, Fragment } from "react";

import { Link } from "react-router-dom";

import ConfirmModal from "../UI/ConfirmModal/ConfirmModal";
import Card from "../UI/CardV2/CardV2";
import useHttp from "../../hooks/use-http";

import { dateFormmater, detectShortestItem } from "../../utils/common";
import { getAccessToken } from "../../utils/authentication";

import { REQUEST_STATUS } from "../../constants/RequestStatus";
import { API_REQUEST_URL } from "../../constants/API";

import classes from "./DraftItems.module.scss";
import defaultLogo from "../../assets/images/placeholder.svg";

const DraftItems = ({ draft, afterDraftDelete }) => {
	let srcLogo = draft.company_logo ? draft.company_logo : defaultLogo;
	const status = REQUEST_STATUS.find((element) => element.id === draft.status);
	const [displayModal, setDisplayModal] = useState(null);
	const { sendRequest } = useHttp();

	const deleteRequest = (userResp) => {
		if (userResp === "confirm") {
			const token = getAccessToken();
			sendRequest(
				{
					url: `${API_REQUEST_URL}${draft.id}`,
					headers: { Authorization: `Bearer ${token}` },
					method: "DELETE",
				},
				afterDraftDelete
			);
			setDisplayModal(false);
		} else if (userResp === "cancel") {
			setDisplayModal(false);
			console.log(userResp, "Canceled");
		}
	};
	return (
		<div className="col-xl-4 col-md-6 col-11">
			{displayModal && (
				<ConfirmModal
					modalButtonText="Delete"
					modalType="warning"
					modalText="Are you sure to delete the draft?"
					modalHeader="Delete"
					onConfirm={deleteRequest}
				/>
			)}
			<Card className={`${classes.card}`}>
				<div className={classes["draft-box"]}>
					{/* <div
						style={{ backgroundImage: `url(${srcLogo})` }}
						className={classes["draft-image"]}
					></div> */}
					<div className={classes["draft-header-box"]}>
						{/* <div
							style={{ color: status.color }}
							className={classes["draft-status"]}
						>
							{status.cardTitle}
						</div> */}
						<div className={classes["draft-date"]}>
							{" "}
							<span className={classes["date-text"]}> Date:</span>{" "}
							{dateFormmater(draft.created_at)}
						</div>
					</div>
					{draft.received_applications_count !== 0 && (
						<span className={classes.badge}>
							{draft.received_applications_count}
						</span>
					)}
				</div>
				<div className={classes.title}>
					<span>{draft.company_title}</span>
				</div>
				<p className={classes.decription}>
					{draft.title ? (draft.title) : ("No Description")}

				</p>
				{console.log("mydraft", draft)}
				<div className={classes["card-detail"]}>
					<div className={classes["due-date"]}>
						<span className={classes.key}>Due Date: </span>
						<span className={classes.value}>{draft.due_date ? (dateFormmater(draft.due_date)) : ("Not Selected")}</span>
					</div>
					<div className={classes["regions"]}>
						<span className={classes.key}>Region(s): </span>
						<span className={classes.value}>{draft.regions_data.length > 0 ? (
							<Fragment>
								{detectShortestItem(draft.regions_data)}
							</Fragment>
						) : ("Not Selected")}</span>
						{draft.regions_data.length > 1 && (
							<span className={classes["more-value"]}>
								&nbsp;{`+${draft.regions_data.length - 1}`}
							</span>
						)}
					</div>
					<div className={classes["country"]}>
						<span className={classes.key}>Country: </span>
						<span className={classes.value}>{draft.countries_data.length > 0 ? (
							<Fragment>
								{detectShortestItem(draft.countries_data)}
								{draft.countries_data.length > 1 && (
									<span className={classes["more-value"]}>
										&nbsp;{`+${draft.countries_data.length - 1}`}
									</span>
								)}
							</Fragment>
						) : ("Not Selected")}</span>
					</div>
				</div>
				<div className={classes["edit-link"]}>
					<Link
						className={`${classes["show-detail"]} btn`}
						to={`/request/edit/${draft.id}`}
					>
						Edit Draft
					</Link>
				</div>
				<div className={classes["delete-btn"]}>
					<button
						onClick={() => setDisplayModal(true)}
						className={`${classes["show-detail"]} btn`}
					>
						Delete
					</button>
				</div>
			</Card>
		</div>
	);
};

export default DraftItems;
