import classes from './SocialInput.module.scss';
import { useState } from "react";


const SocialInput = ({ validation, input, errors, register, icon}) => {
  
    return(
        <div className={classes["single-input-box"]}>
			<input
				style={{ borderColor: errors[input.name] ? "#E10000" : "" }}
				className={classes["single-input"]}
				{...input}
				{...register(input.name, validation)}
				type={input.type}
			/>

			<label
				style={{ color: errors[input.name] ? "#E10000" : "" }}
				htmlFor={input.name}
				className={classes["single-input-label"]}
			>
				{input.label}				
			</label>
            <img className={classes["social-input-icon"]} src={icon} />
			
			{errors[input.name] && errors[input.name].type === "required" && (
				<span className={classes["single-input-error"]}>
					This value is required.
				</span>
			)}
			{errors[input.name] && errors[input.name].message && (
				<span className={classes["single-input-error"]}>
					{" "}
					{errors[input.name].message}
				</span>
			)}
			
		</div>
    );
};

export default SocialInput;