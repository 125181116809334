import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import classes from "./EditCompany.module.scss";
import Card from "../../../components/UI/Card/Card";
import StepTitle from "../../../components/Stepper/StepTitle/StepTitle";
import GeneralInfo from "../../../components/Stepper/GeneralInfo/GeneralInfo";
import CompanyDetails from "../../../components/Stepper/CompanyDetails/CompanyDetails";
import CompanyExperties from "../../../components/Stepper/CompanyExperties/CompanyExperties";
import useHttp from "../../../hooks/use-http";
import { getAccessToken } from "../../../utils/authentication";
import { API_COMPANIES_URL } from "../../../constants/API";
import { addToast } from "../../../redux/Toast/toastAction";
import { updateUserCompany } from "../../../redux/User/userAction";
import {
  createRandomId,
  getAttachmentFileName,
  convertNumberMemberToString,
} from "../../../utils/common";

const EditCompany = () => {
  const { id } = useParams();
  const [activeIndex, setActiveIndex] = useState(parseInt(id));
  const [generalInfo, setGeneralInfo] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [companyExperties, setCompanyExperties] = useState({
    keyServices: [],
    categories: [],
    regions: [],
    countries: [],
    specialities: [],
  });
  const { sendRequest, error, isLoading } = useHttp();
  const { userCompany } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  let history = useHistory();

  //-----------------------------------------------------------------------------------
  const UpdateActiveStep = (index) => {
    setActiveIndex(index);
  };

  // get value from steps-----------------------------------------------------------------------------------

  const getGeneralInfo = (info) => {
    setGeneralInfo(info);
  };

  const getCompanyDetails = (detail) => {
    setCompanyDetails(detail);
  };

  const getCompanyExperties = (keyItem, experties) => {
    setCompanyExperties((previousState) => {
      return {
        ...previousState,
        [keyItem]: experties,
      };
    });
  };
  //-----------------------------------------------------------------------------------
  const setUpdatedCompanyRequest = () => {
    return {
      title: generalInfo.companyName,
      slug: generalInfo.companyName.replace(/ /g, "_"),
      introduction: generalInfo.companyIntroduction,
      // service_description: generalInfo.serviceDescription,
      // specialities: [generalInfo.lifeScienceSector],
      size: generalInfo.companySize,
      owner_name: companyDetails.administratorName,
      owner_sure_name: companyDetails.administratorSurname,
      tel_code: companyDetails.code,
      owner_tel: companyDetails.administratorPhone,
      owner_email: companyDetails.administratorEmail,
      // email: companyDetails.generalCompanyEmail,
      email:'',
      website: companyDetails.website,
      // address: companyDetails.companyStreet,
      address: '',
      // town: companyDetails.companyTown,
      town: '',
      // zip_code: companyDetails.companyZipCode,
      zip_code: '',
      // country: companyDetails.companyCountry,
      country: [],
      twitter: companyDetails.twitter,
      // instagram: companyDetails.instagram,
      // facebook: companyDetails.facebook,
      linkedin: companyDetails.linkedin,
      // xing: companyDetails.xing,
      instagram: '',
      facebook: '', 
      xing: '',
      service_categories: companyExperties.categories,
      key_services: companyExperties.keyServices,
      regions: companyExperties.regions,
      countries: companyExperties.countries,
      specialities: companyExperties.specialities,
      company_type: 1,
    };
  };
  //-----------------------------------------------------------------------------------
  // after uploading file we navigate to my-company
  const getUploadedCompanyFile = (data) => {
    console.log(data);
    dispatch(updateUserCompany(data));
    history.push("/dashboard/my-company");
  };

  // update created company by its ID(upload file and picture company)
  const uploadUpdatedCompanyFiles = (id) => {
    const formData = new FormData();
    if (typeof generalInfo.attachFiles === "object") {
      formData.append("file", generalInfo.attachFiles);
    }
    if (typeof generalInfo.profilePicture === "object") {
      formData.append("logo", generalInfo.profilePicture);
    }
    const token = getAccessToken();
    sendRequest(
      {
        url: `${API_COMPANIES_URL}/company/${id}/`,
        method: "PATCH",
        body: formData,
        headers: { Authorization: `Bearer ${token}` },
      },
      getUploadedCompanyFile
    );
  };
  //-----------------------------------------------------------------------------------

  const sumbitUpdateCompany = (submittedForm) => {
    const companyRequest = setUpdatedCompanyRequest();
    updateCompanyById(userCompany.id, companyRequest, submittedForm);
  };
  //-----------------------------------------------------------------------------------
  // edit company based on some conditions (user changes pictures and files or not)
  const getUpdatedCompanyById = (data) => {
    // console.log(data);
    // if (
    //   typeof generalInfo.attachFiles === "object" ||
    //   typeof generalInfo.profilePicture === "object"
    // ) {
    //   uploadUpdatedCompanyFiles(userCompany.id);
    // } else {
    //   dispatch(updateUserCompany(data));
    //   history.push("/dashboard/my-company");
    // }
    dispatch(updateUserCompany(data));
    history.push("/dashboard/company-info");
  };

  const updateCompanyById = (id, companyRequest, submittedForm) => {
    const formData = new FormData();
    formData.append('title', companyRequest.title);
    formData.append('slug', companyRequest.slug);
    formData.append('description', companyRequest.introduction);
    // formData.append('service_description', companyRequest.service_description);
    // formData.append('specialities', companyRequest.specialities);
    formData.append('size', companyRequest.size);
    formData.append('owner_name', companyRequest.owner_name);
    formData.append('owner_sure_name', companyRequest.owner_sure_name);
    formData.append('tel_code', companyRequest.tel_code);
    formData.append('owner_tel', companyRequest.owner_tel);
    formData.append('owner_email', companyRequest.owner_email);
    formData.append('email', companyRequest.email);
    formData.append('website', companyRequest.website);
    formData.append('address', companyRequest.address);
    formData.append('town', companyRequest.town);
    formData.append('zip_code', companyRequest.zip_code);
    formData.append('country', companyRequest.country);
    formData.append('twitter', companyRequest.twitter);
    formData.append('instagram', companyRequest.instagram);
    formData.append('facebook', companyRequest.facebook);
    formData.append('linkedin', companyRequest.linkedin);
    formData.append('xing', companyRequest.xing);
    // formData.append('specialities', companyRequest.specialities);
    let specialitiesArray = companyRequest.specialities.map((i) => Number(i));
    for (var i = 0; i < specialitiesArray.length; i++) {
      formData.append('specialities', specialitiesArray[i]);
    }
    //formData.append('service_categories',companyRequest.service_categories); 
    let categoryArray = companyRequest.service_categories.map((i) => Number(i));
    for (var i = 0; i < categoryArray.length; i++) {
      formData.append('service_categories', categoryArray[i]);
    }
    //formData.append('key_services', companyRequest.key_services);
    let keyServiceArray = companyRequest.key_services.map((i) => Number(i));
    for (var i = 0; i < keyServiceArray.length; i++) {
      formData.append('key_services', keyServiceArray[i]);
    }
    //formData.append('regions', companyRequest.regions);
    let regionsArray = companyRequest.regions.map((i) => Number(i));
    for (var i = 0; i < regionsArray.length; i++) {
      formData.append('regions', regionsArray[i]);
    }
    //formData.append('countries', companyRequest.countries);
    let countrieArray = companyRequest.countries.map((i) => Number(i));
    for (var i = 0; i < countrieArray.length; i++) {
      formData.append('countries', countrieArray[i]);
    }

    formData.append('company_type', 1);
    if (typeof submittedForm.attachFiles === "object") {
      formData.append("file", submittedForm.attachFiles);
    }
    if (typeof generalInfo.profilePicture === "object") {
      formData.append("logo", generalInfo.profilePicture);
    }
    const token = getAccessToken();
    sendRequest(
      {
        url: `${API_COMPANIES_URL}/company/${id}/`,
        method: "PATCH",
        body: formData,
        headers: { Authorization: `Bearer ${token}` },
      },
      getUpdatedCompanyById
    );
  };
  //-----------------------------------------------------------------------------------
  useEffect(() => {
    console.log(error);
    if (error && error.status === 400) {
      dispatch(
        addToast({
          id: createRandomId(),
          status: "error",
          description: error.data.errors.title
            ? error.data.errors.title
            : error.data.errors.owner_email,
        })
      );
    }
  }, [dispatch, error]);
  //-------------------------------------------------- set defualt values to edit steps
  useEffect(() => {
    console.log('usercompany: ', userCompany);
    if (userCompany) {
      console.log("usercompany:", userCompany);
      setGeneralInfo({
        companyName: userCompany.title,
        companyIntroduction: userCompany.description,
        serviceDescription: userCompany.service_description,
        lifeScienceSector: userCompany.specialities[0],
        companySize: userCompany.size,
        attachFiles: userCompany.attachments[0]
          ? getAttachmentFileName(userCompany.attachments[0])
          : userCompany.attachments[0],
        profilePicture: userCompany.logo,
      });
      setCompanyDetails({
        administratorName: userCompany.owner_name,
        administratorSurname: userCompany.owner_sure_name,
        code: parseInt(userCompany.tel_code),
        administratorPhone: userCompany.owner_tel,
        administratorEmail: userCompany.owner_email,
        generalCompanyEmail: userCompany.email,
        website: userCompany.website,
        companyStreet: userCompany.address,
        companyTown: userCompany.town,
        companyZipCode: userCompany.zip_code,
        companyCountry: userCompany.country,
        twitter: userCompany.twitter,
        instagram: userCompany.instagram,
        facebook: userCompany.facebook,
        linkedin: userCompany.linkedin,
        xing: userCompany.xing,
      });
      setCompanyExperties({
        categories: convertNumberMemberToString(userCompany.service_categories),
        regions: convertNumberMemberToString(userCompany.regions),
        keyServices: convertNumberMemberToString(userCompany.key_services),
        countries: convertNumberMemberToString(userCompany.countries),
        specialities: convertNumberMemberToString(userCompany.specialities),

      });
    }
  }, [userCompany]);

  return (
    <section className={classes.section}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-2 col-lg-12 col-12 d-md-block d-none">
            <Card className={classes["title-card"]}>
              <StepTitle
                index="1"
                activeIndex={activeIndex}
                title="General Info"
              />
              <div
                style={{ background: activeIndex > 1 ? "#204B90" : "#C4C4C4" }}
                className={classes.line}
              ></div>
              <StepTitle
                index="2"
                activeIndex={activeIndex}
                title="Company Details"
              />
              <div
                style={{ background: activeIndex > 2 ? "#204B90" : "#C4C4C4" }}
                className={classes.line}
              ></div>
              <StepTitle
                index="3"
                activeIndex={activeIndex}
                title="Company Expertise"
              />
            </Card>
          </div>
          <div className="col-xl-10 col-lg-12 col-12">
            {/* <Card className={classes["step-card"]}> */}
              <GeneralInfo
                OnUpdateActiveStep={UpdateActiveStep}
                onGetGeneralInfo={getGeneralInfo}
                generalInfo={generalInfo}
                activeIndex={activeIndex}
                generalInfoErrors={error}
                isCreateCompany={false}
              />

              <CompanyDetails
                OnUpdateActiveStep={UpdateActiveStep}
                onGetCompanyDetails={getCompanyDetails}
                companyDetails={companyDetails}
                activeIndex={activeIndex}
                companyDetailErrors={error}
              />

              <CompanyExperties
                onSumbitCreateCompany={sumbitUpdateCompany}
                OnUpdateActiveStep={UpdateActiveStep}
                onGetCompanyExperties={getCompanyExperties}
                companyExperties={companyExperties}
                activeIndex={activeIndex}
                activeLoading={isLoading}
                submitButtonText='Edit Company'
                generalInfo={generalInfo}
              />
            {/* </Card> */}
          </div>
        </div>
      </div>
    </section>
  );
};
export default EditCompany;
