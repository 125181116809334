import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import useHttp from "../../../hooks/use-http";
import { getAccessToken } from "../../../utils/authentication";
import { API_SUBSCRIPTION } from "../../../constants/API";

import Pagination from "../../../components/UI/Pagination/Pagination";
import NotFoundItems from "../../../components/UI/NotFoundItems/NotFoundItems";
import Loading from "../../../components/UI/Loading/Loading";
import InvoicesTable from "./InvoicesTable/InvoicesTable";
import Card from "../../../components/UI/Card/Card"

import calculateOffset from "../../../utils/calculateOffset";
import {
  splitQueryParams,
  updateDuplicatedApiParams,
  updateUniqueApiParams,
} from "../../../utils/QueryParams";

import classes from "./Invoices.module.scss";
import { INVOICE_LIMIT } from "../../../constants/Common";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import { DateRange  } from 'react-date-range';
import { DateRange } from 'react-date-range';
import calendar1 from "../../../assets/images/Icons/calendar1.svg";
import useOutsideClick from "../../../hooks/use-outside-click";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import Moment from 'moment';
import { addDays, addYears, format, isWeekend } from 'date-fns';
import { Link } from 'react-router-dom';
import leftArrow from '../../../assets/images/arrow/left-arrow.svg';

const Invoices = () => {
  const userCompany = useSelector((state) => state.user.userCompany);
  const [invoicesListRes, setInvoicesListRes] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [count, setCount] = useState(null);
  const [filterQuery, setFilterQuery] = useState(null);
  const [apiQueryParams, setApiQueryParams] = useState(
    `?limit=${INVOICE_LIMIT}`
  );
  const [filterAmount, setFilterAmount] = useState({
    min: 0,
    max: 5000,
  });
  const { sendRequest, isLoading } = useHttp();

  const setInvoicesStates = (params) => {
    params.page ? setCurrentPage(parseInt(params.page)) : setCurrentPage(1);
    createOffset(params);
  };

  const createOffset = (params) => {
    let offset;
    // if page is not equal 1
    if (params.page) {
      offset = calculateOffset(parseInt(params.page), INVOICE_LIMIT);
      delete params.page;
    } // page =1 (page load for first time)
    else {
      offset = calculateOffset(1, INVOICE_LIMIT);
    }
    params["offset"] = offset;
    createApiQueryParams(params);
  };

  const createApiQueryParams = (params) => {
    let updatedQueryParams = apiQueryParams;
    for (let key in params) {
      if (key === "keyServices") {
        for (let index = 0; index < params[key].length; index++) {
          updatedQueryParams = updateDuplicatedApiParams(
            key,
            params[key][index],
            updatedQueryParams
          );
        }
      } else {
        updatedQueryParams = updateUniqueApiParams(
          `${key}`,
          params[key],
          updatedQueryParams
        );
      }
    }
    setApiQueryParams(updatedQueryParams);
    fetchInvoicesList(updatedQueryParams);
  };

  const getActivatedPage = (page) => {
    if (currentPage !== page) {
      setCurrentPage(page);
      let offset = calculateOffset(page, INVOICE_LIMIT);
      let updatedQueryParams = updateUniqueApiParams(
        "offset",
        offset,
        apiQueryParams
      );
      setApiQueryParams(updatedQueryParams);
      fetchInvoicesList(updatedQueryParams);
    }
  };

  const getInvoicesList = (data) => {
    setInvoicesListRes(data.results);
    setCount(data.count);
    window.scrollTo(0, 0);
  };

  const fetchInvoicesList = (updatedQueryParams) => {
    setCount(null);
    const token = getAccessToken();
    sendRequest(
      {
        url: `${API_SUBSCRIPTION}/invoices/${updatedQueryParams}&ordering=-invoice_date${filterQuery}`,
        //url: `${API_SUBSCRIPTION}/invoices/${apiQueryParams}&ordering=-invoice_date`,
        headers: { Authorization: `Bearer ${token}` },
      },
      getInvoicesList
    );
  };
  const splitUrlQueryparams = () => {
    const queryParams = window.location.search;
    const splitedQueryParams = splitQueryParams(queryParams);
    setInvoicesStates(splitedQueryParams);
  };

  //------------------------------------date range picker functions
  const [isDatepickerShown, setIsDatePickerShown] = useState(false);
  const handleClick = () => {
    setIsDatePickerShown(current => !current);
  };
  const [datePicker, setDatePicker] = useState([
    {
      startDate: addYears(new Date(), -3),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const handleClickOutside = () => {
    setIsDatePickerShown(false);
  };
  const ref = useOutsideClick(handleClickOutside);
  const handleDateRange = (ranges) => {
    setDatePicker([ranges.selection]);
    if (ranges.selection.endDate !== ranges.selection.startDate) {
      let startDate = Moment(ranges.selection.startDate).format('YYYY-MM-DD');
      let endDate = Moment(ranges.selection.endDate).format('YYYY-MM-DD');
      setIsDatePickerShown(false);
      setFilterQuery(`&invoice_amount__range=${filterAmount.min},${filterAmount.max}&invoice_date__range=${startDate},${endDate}`);
      setCurrentPage(1);
    }
  };

  //-----------------------------------------------------------------

  useEffect(() => {
    splitUrlQueryparams();
    return () => {
      setInvoicesListRes([]);
    };
  }, [filterQuery]);

  return (
    <div className="container px-4">
      <div className={`container ${classes["my-company"]}`}>
        <div className='row'>
          <div className='col-12 mt-5 p-0'>
            <Link to="/dashboard/company-dashboard" className=''>
              <div className={classes['back-to-dashboard']}>
                <img src={leftArrow} alt="arrow" />
                <span>Back to Dashboard</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="row align-items-center mt-5 ">
          {userCompany && (
            <div
              style={{ backgroundImage: `url(${userCompany.logo})` }}
              className={classes["company-logo"]}
            ></div>
          )}
          <div className="col-lg-5 col-md-4 col-12 text-md-start text-center">
            {userCompany && (
              <span className={classes["company-name"]}>
                {userCompany.title}
              </span>
            )}
            {userCompany && (
              <div className={classes["company-address"]}>
                {userCompany.address}
              </div>
            )}
          </div>
        </div>

        <div className={`row ${classes["filter"]}`}>
          <div className="col-lg-3 mb-lg-0 mb-4">
            <span ref={ref}>
              <div
                className={classes["btn-datepicker"]}
                onClick={handleClick}
              >
                <span>Date Filter</span>
                <img
                  className={classes.imgDateFilter}
                  src={calendar1}
                  alt="calendar"
                />

              </div>
              {isDatepickerShown &&
                <DateRange
                  className={classes["date-range-picker"]}
                  editableDateInputs={true}
                  //onChange={item => setDatePicker([item.selection])}
                  onChange={handleDateRange}
                  moveRangeOnFirstSelection={false}
                  ranges={datePicker}
                />
              }
            </span>
          </div>
          <div className="col-lg-9">
            <div className={classes["range-picker"]}>
              <div className={classes["range-text"]}>
                Amount Range:
              </div>
              <div className={classes["input-range"]}>
                <InputRange
                  draggableTrack
                  maxValue={10000}
                  minValue={0}
                  step={100}
                  formatLabel={value => `${value} €`}
                  onChange={value => setFilterAmount(value)}
                  onChangeComplete={value => {
                    let startDate = Moment(datePicker[0].startDate).format('YYYY-MM-DD');
                    let endDate = Moment(datePicker[0].endDate).format('YYYY-MM-DD');
                    setFilterQuery(`&invoice_amount__range=${value.min},${value.max}&invoice_date__range=${startDate},${endDate}`);
                  }
                  }
                  value={filterAmount}
                />
              </div>
            </div>
          </div>
        </div>


        <div className="row">
          <div className={`${classes["invoice-main"]}`}>
            <span className={`${classes["title"]}`}>Invoices</span>
            {invoicesListRes.length !== 0 && !isLoading && (
              <InvoicesTable
                invoicesList={invoicesListRes}
                limit={INVOICE_LIMIT}
                currentPage={currentPage}
              />
            )}
            {isLoading && <Loading className={classes["loading"]} />}
            {invoicesListRes.length === 0 && !isLoading && (
              <NotFoundItems className={classes["not-found"]} item="Invoices" />
            )}
          </div>
        </div>
        <div className="row">
          {count > INVOICE_LIMIT && (
            <Pagination
              onActivatedPage={getActivatedPage}
              count={count}
              limit={INVOICE_LIMIT}
              currentPage={currentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Invoices;
