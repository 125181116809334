import {
  IS_LOGGED_IN,
  USER_PROFILE,
  USER_COMPANY,
  USER_ROLE,
  USER_SUBSCRIPTION,
  RESET_USER,
  IS_PROFILE_CHANGED,
  IS_SUBSCRIPTION_CHANGE,
  USER_ASSETS,
  IS_ASSETS_CHANGED,
} from "./userActionType";

export const isLoggedIn = (isloggedIn) => {
  return {
    type: IS_LOGGED_IN,
    payload: isloggedIn,
  };
};

export const updateUserProfile = (profile) => {
  return {
    type: USER_PROFILE,
    payload: profile,
  };
};

export const updateUserCompany = (company) => {
  return {
    type: USER_COMPANY,
    payload: company,
  };
};

export const updateUserRole = (role) => {
  return {
    type: USER_ROLE,
    payload: role,
  };
};

export const updateUserSubscription = (subscription) => {
  return {
    type: USER_SUBSCRIPTION,
    payload: subscription,
  };
};

export const resetUser = () => {
  return {
    type: RESET_USER,
  };
};

export const updateIsProfileChange = (isChanged) => {
  return {
    type: IS_PROFILE_CHANGED,
    payload: isChanged,
  };
};

export const updateIsSubscriptionChange = (isChanged) => {
  return {
    type: IS_SUBSCRIPTION_CHANGE,
    payload: isChanged,
  };
};

export const updateUserAssets = (assets) => {
  return{
    type: USER_ASSETS,
    payload: assets,
  };
};

export const updateIsAssetsChnage = (isChanged) => {
  return{
    type: IS_ASSETS_CHANGED,
    payload: isChanged
  };
};