import { useState } from "react";
import classes from "./SimpleInput.module.scss";
import ReactTooltip from "react-tooltip";
import { Fragment } from "react";


const SimpleInput = ({ validation, input, errors, register }) => {
	const [type, setType] = useState(input.type || "text");

	const changeType = () => {
		if (type === "password") {
			setType("text");
		} else if (type === "text") {
			setType("password");
		}
	};
	return (
		<div className={classes["single-input-box"]}>
			<input
				style={{ borderColor: errors[input.name] ? "#E10000" : "" }}
				className={classes["single-input"]}
				{...input}
				{...register(input.name, validation)}
				type={type}
				
			/>

			<label
				style={{ color: errors[input.name] ? "#E10000" : "" }}
				htmlFor={input.name}
				className={classes["single-input-label"]}
			>
				{input.label}
				{input.id === "setPassword" && (
					<Fragment>
						<span data-tip data-for='passwordFormat'>
							{" "}
							<i className={`fas fa-info-circle ${classes.info}`}></i>
						</span>
						<ReactTooltip
						
							id="passwordFormat"
							arrowColor="transparent"
							className={classes.toopltip}
							effect="solid"
							place="left"
						>
							1. Your password must contain at least 8 characters.
							<br /> 2. Your password must contain at least 1 upper case letter.{" "}
							<br />
							3. Your password must contain at least 1 number. <br /> 4. Your
							password must include at least one special character, e.g., ! @ # ? ]
							Note: do not use &lt; or &gt; in your password, as both can cause
							problems in your web browser.
						</ReactTooltip>
					</Fragment>
				)}
			</label>
			{input.hasappend === "true" && (
				<div
					className={`${classes["append"]} ${classes[type]}`}
					onClick={changeType}
				>
					{(input.id).toLowerCase().includes("password") && (
						<>
							{type === "password" ? (
								<i className="fas fa-eye"></i>
							) : (
								<i className="fas fa-eye"></i>
							)}
						</>
					)}
				</div>
			)}
			{errors[input.name] && errors[input.name].type === "required" && (
				<span className={classes["single-input-error"]}>
					This is required
				</span>
			)}
			{errors[input.name] && errors[input.name].message && (
				<span className={classes["single-input-error"]}>
					{" "}
					{errors[input.name].message}
				</span>
			)}
			{/* {input.id === "setPassword" && (				
						
			)} */}
		</div>
	);
};

export default SimpleInput;
