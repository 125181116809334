import { Fragment, useState, useRef, useEffect } from "react";
import classes from "./EditProfilePicture.module.scss";
import defaultAvatar from "../../assets/images/Icons/profile-user.svg";
import editProfileImage from "../../assets/images/Icons/edit-profile.svg";
import { getAccessToken } from "../../utils/authentication";
import { API_USERS_URL } from "../../constants/API";
import useHttp from "../../hooks/use-http";
import { useDispatch } from "react-redux";
import { updateUserProfile, updateIsProfileChange } from "../../redux/User/userAction";

const EditProfilePicture = ({ profile }) => {
  const [profileAvatar, setProfileAvatar] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const { sendRequest, error, isLoading } = useHttp();
  const dispatch = useDispatch();
  const ref = useRef();

  const createProfileAvatar = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    updateProfileImage(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
  };

  const getUpdatedProfile = (profile) => {
    dispatch(updateUserProfile(profile));
    dispatch(updateIsProfileChange(true));
  };

  const updateProfileImage = (avatar) => {
    const token = getAccessToken();
    const formData = new FormData();
    formData.append("image", avatar);
    sendRequest(
      {
        url: `${API_USERS_URL}profile/`,
        method: "PUT",
        body: formData,
        headers: { Authorization: `Bearer ${token}` },
      },
      getUpdatedProfile
    );
  };

  useEffect(() => {
    if (!selectedFile) {
      return;
    }
  }, [selectedFile]);

  useEffect(() => {
    if (profile && profile.image) {
      setProfileAvatar(profile.image.file);
    }
  }, [profile]);

  return (
    <Fragment>
      <div
        style={{
          backgroundImage: profileAvatar
            ? `url(${profileAvatar})`
            : `url(${defaultAvatar})`,
        }}
        className={classes["profile-avatar"]}
      >
        <button
          type="button"
          className={`btn ${classes["edit-avatar"]}`}
          onClick={() => ref.current.click()}
        >
          <img src={editProfileImage} alt="edit avatar" />
        </button>
      </div>
      <input
        onChange={($event) => createProfileAvatar($event)}
        id="avatar"
        name="avatar"
        type="file"
        className="file-input"
        ref={ref}
        hidden
      />
    </Fragment>
  );
};

export default EditProfilePicture;
