import React from 'react';
import classes from './PasswordInputCheckList.module.scss';
import ReactTooltip from "react-tooltip";
import { Fragment, useState } from "react";
import PasswordChecklist from "react-password-checklist";
import ValidPassword from '../../../assets/images/Icons/ValidPassword.svg';
import InvalidPassword from '../../../assets/images/Icons/InvalidPassword.svg';
import { invalid } from 'moment';
const PasswordInputCheckList = ({ validation, input, errors, register }) => {
    const [type, setType] = useState(input.type || "text");
    const [password, setPassword] = useState('');
    const validPasswordCharacter = new RegExp(/^(?=.*[~`!@#$%^&*()_\-+={[}\]|\\:;"',.?/]).+$/);
    const changeType = () => {
        if (type === "password") {
            setType("text");
        } else if (type === "text") {
            setType("password");
        }
    };

    return (
        <div className={classes["single-input-box"]}>
            <input
                style={{ borderColor: errors[input.name] ? "#E10000" : "" }}
                className={classes["single-input"]}
                {...input}
                {...register(input.name, validation)}
                type={type}
                onChange={(e)=>{setPassword(e.target.value)}}
            />

            <label
                style={{ color: errors[input.name] ? "#E10000" : "" }}
                htmlFor={input.name}
                className={classes["single-input-label"]}
            >
                {input.label}
                {input.id === "setPassword" && (
                    <Fragment>
                        <span data-tip data-for='passwordFormat'>
                            {" "}
                            <i className={`fas fa-info-circle ${classes.info}`}></i>
                        </span>
                        <ReactTooltip

                            id="passwordFormat"
                            arrowColor="transparent"
                            className={classes.toopltip}
                            effect="solid"
                            place="left"
                        >
                            1. Your password must contain at least 8 characters.
                            <br /> 2. Your password must contain at least 1 upper case letter.{" "}
                            <br />
                            3. Your password must contain at least 1 number. <br /> 4. Your
                            password must include at least one special character, e.g., ! @ # ? ]
                            Note: do not use &lt; or &gt; in your password, as both can cause
                            problems in your web browser.
                        </ReactTooltip>
                    </Fragment>
                )}
            </label>
            {input.hasappend === "true" && (
                <div
                    className={`${classes["append"]} ${classes[type]}`}
                    onClick={changeType}
                >
                    {(input.id).toLowerCase().includes("password") && (
                        <>
                            {type === "password" ? (
                                <i className="fas fa-eye"></i>
                            ) : (
                                <i className="fas fa-eye"></i>
                            )}
                        </>
                    )}
                </div>
            )}
            {errors[input.name] && errors[input.name].type === "required" && (
                <span className={classes["single-input-error"]}>
                    This is required
                </span>
            )}
            {errors[input.name] && errors[input.name].message && (
                <span className={classes["single-input-error"]}>
                    {" "}
                    {errors[input.name].message}
                </span>
            )}
            <PasswordChecklist
                className={classes["password-checklist"]}                
                rules={["minLength",  "number", "capital","specialChar" ]}
                minLength={8}
                value={password}
                messages={{
					minLength: "Should be at least 8 characters.",					
					number: "Should contain at least 1 number.",
					capital: "Should contain at least 1 uppercase and lowercase letter.",
                    specialChar: "Should contain at least 1 special character (@, !, #, etc). Do not use “<” or “>” characters.",
				}}
                iconComponents={{ValidIcon: <img src={ValidPassword} />, InvalidIcon: <img src={InvalidPassword} />}}
                validTextColor="green"
                invalidTextColor="red"
                specialCharsRegex= {validPasswordCharacter}
                onChange={(isValid) => {  }}
            />

        </div>
    );
};

export default PasswordInputCheckList;