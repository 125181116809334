import {Link} from "react-router-dom";
import classes from "./NewsExploreMore.module.scss";
import pharmaNewsLogo from "../../assets/images/pharmatching-news.png";
import NewsItemExplore from "../NewsItemExplore/NewsItemExplore";


const formatDate = (dateString) => {
    const date = new Date(dateString);

    const options = {year: 'numeric', month: 'short', day: 'numeric'};
    return date.toLocaleDateString('en-US', options);
};

const ExploreMoreNews = ({latestNewsList}) => {
    return (
        <div className={'row'}>
            <Link to="/news"><p className={classes.title}>Explore More News</p></Link>
            {latestNewsList.map((newsItem) => (
                <div className={'col-lg-6 col-md-12'}>
                    <NewsItemExplore
                        key={newsItem.id}
                        news={newsItem}
                        className={`col-lg-6 col-md-12 ${classes.latest_news_item}`}
                    />
                </div>
            ))}
        </div>
    );
};

export default ExploreMoreNews;