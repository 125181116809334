import React, { Fragment } from 'react';
import classes from './DashboardSentApplications.module.scss';
import CardV2 from '../UI/CardV2/CardV2';
import { dateFormmaterNormal, dateFormmater, detectShortestItem } from "../../utils/common";
import { REQUEST_STATUS } from "../../constants/RequestStatus";
import actionEdit from '../../assets/images/Icons/actionEdit.svg';
import actionIcon from '../../assets/images/Icons/actionIcon.svg';
import { Link } from "react-router-dom";
import { SENT_APPLICATION_STATUS } from "../../constants/SentApplicationStatus";
import NotFoundItems from "../../components/UI/NotFoundItems/NotFoundItems";

const DashboardSentApplications = ({ companyAssets,sentApplicationList }) => {
    const handleClickAction = () => {
        console.log('action');
    };
    const handleClickEdit = () => {
        console.log('Edit');
    };
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <CardV2 className={classes['card']}>
                        <div className={`row ${classes['header']}`}>
                            <div className={`col-lg-6 col-12   ${classes['wrapper-title']}`}>
                                <div className={classes['title']}>
                                    Sent Applications
                                </div>
                                <Link to="/dashboard/all-sent-applications?page=1&ordering=-created_at" className={classes['view-all']}>
                                    View all
                                </Link>
                            </div>
                            <div className={`col-lg-6 col-12  text-end ${classes['wrapper-assets']}`}>
                                {companyAssets && (
                                    <Fragment>
                                        <div style={{ color: '#ffb800' }} className={classes['asset-wrapper']}>
                                            <div className={classes['asset-count']}>
                                                {companyAssets.waiting_sent_applications}
                                            </div>
                                            <div className={classes['asset-title']}>
                                                Waiting
                                            </div>
                                        </div>
                                        <div style={{ color: '#24407D' }} className={classes['asset-wrapper']}>
                                            <div className={classes['asset-count']}>
                                                {companyAssets.successful_applications}
                                            </div>
                                            <div className={classes['asset-title']}>
                                                Successful
                                            </div>
                                        </div>
                                        <div style={{ color: '#E10000' }} className={classes['asset-wrapper']}>
                                            <div className={classes['asset-count']}>
                                                {companyAssets.rejected_sent_applications}
                                            </div>
                                            <div className={classes['asset-title']}>
                                                Rejected
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                        <div className={classes['body']}>
                            {sentApplicationList && (
                                <table className={classes['table-requests']}>
                                    <thead>
                                        <tr>
                                            <th>
                                                Title
                                            </th>
                                            <th>
                                                Creation Date
                                            </th>

                                            <th>
                                                Requester
                                            </th>
                                            <th>
                                                Status
                                            </th>
                                            <th>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sentApplicationList.map((application) =>
                                            <tr key={application.id}>
                                                <td>
                                                    {application.text}
                                                </td>
                                                <td>
                                                    {dateFormmaterNormal(application.created_at)}
                                                </td>

                                                <td>
                                                    {application.request_data.is_anonymous ? 'Anonymous' : application.request_data.company_title}
}
                                                </td>
                                                <td>
                                                    {SENT_APPLICATION_STATUS.filter(
                                                        (element) => element.title === application.status
                                                    ).map((item) =>
                                                        <div
                                                            style={{ color: item.color, backgroundColor: item.backgroundColor }}
                                                            className={classes["application-status"]}
                                                        >
                                                            {item.cardTitle}
                                                        </div>
                                                    )}
                                                </td>

                                                <td>
                                                    <Link
                                                        to={`/sent-applications/${application.id}`}
                                                        className='btn'>
                                                        <img src={actionIcon} />
                                                    </Link>
                                                    {/* <button
                                                        onClick={handleClickEdit}
                                                        className='btn'>
                                                        <img src={actionEdit} />
                                                    </button> */}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            )}
                            {sentApplicationList.length == 0 && (
                                <NotFoundItems className={classes["not-found"]} item="application" />
                            )}
                        </div>
                    </CardV2>
                </div>
            </div>

        </div>
    );
};

export default DashboardSentApplications;