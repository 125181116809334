import { Link } from "react-router-dom";
import classes from "./CompanyItem.module.scss";
import { detectShortestItem } from "../../utils/common";
import defaultLogo from "../../assets/images/placeholder.svg";
import { Fragment } from "react";

const CompanyItem = (props) => {
  const { companyList } = props;
  let srcLogo = companyList.logo ? companyList.logo : defaultLogo;
  return (
    <div className="col-xl-4 col-lg-6 col-md-8 col-sm-10 col-12">
      <Link to={`/company/${companyList.id}`} className={classes['company-card']}>
        <div className={classes['company-box']}>
          <div style={{ backgroundImage: `url(${srcLogo})` }} className={classes['company-logo']}></div>

        </div>
        <div className={classes.title}>
          <span>{companyList.title}</span>
        </div>
        <div className={classes["specialities-wrapper"]}>
          {companyList.specialities_data.length > 0 &&
            <span className={`${classes.value} ${classes['lss-value']}`}> &nbsp;
              {detectShortestItem(companyList.specialities_data)}
            </span>
          }
          {companyList.specialities_data.length > 1 && (
            <span className={classes["more-value"]}>
              &nbsp;{`+${companyList.specialities_data.length - 1}`}
            </span>
          )}
        </div>
        <div className={classes['card-info']}>
          <div className={classes.key}>Categories:&nbsp;</div>
          {companyList.service_categories_data.length > 0 &&
            <Fragment>
              {companyList.service_categories_data.length > 0 && (
                <div className={classes["category-wrapper"]}>
                  {companyList.service_categories_data.slice(0, 2).map((item) => (
                    <div className={classes["value"]}>
                      {item.title}
                    </div>
                  ))}
                  {companyList.service_categories_data.length > 2 && (
                    <span className={classes['more-value']}>
                      &nbsp;{`+${companyList.service_categories_data.length - 2}`}
                    </span>
                  )}
                </div>
              )}
            </Fragment>
          }

        </div>

        <div className={classes['company-size']}>
          <div className={classes.key}>Employees Count:&nbsp;</div>
          <span className={classes["company-size-val"]}> 
            {companyList.size_data ? companyList.size_data.title : "-"}
          </span>
        </div>
      </Link>
    </div>
  )
}

export default CompanyItem;