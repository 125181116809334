// export const API_BASE_URL = "https://uat.pharmatching.com";
// export const API_BASE_URL = "https://uat-pw.k8s.pharmatching.com";
export const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const API_TAGS_URL = `${API_BASE_URL}/api/v1/tags`;
export const API_NEWS_URL = `${API_BASE_URL}/api/v1/news/`;
export const API_GENERAL_URL = `${API_BASE_URL}/api/v1/general/`;
export const API_REQUEST_URL = `${API_BASE_URL}/api/v1/requests/`;
export const API_COMPANIES_URL = `${API_BASE_URL}/api/v1/companies`;
export const API_SUBSCRIPTION = `${API_BASE_URL}/api/v1/subscription`;
export const API_AUTH_URL = `${API_BASE_URL}/api/v1/auth/`;
export const API_APPLICATION_URL = `${API_BASE_URL}/api/v1/applications/`;
export const API_USERS_URL = `${API_BASE_URL}/api/v1/users/`;
export const API_FEEDBACK_URL = `${API_BASE_URL}/api/v1/feedback/`;
export const API_PAYMENT_URL = `${API_BASE_URL}/api/v1/payments/`;
export const API_BLOG_URL = `${API_BASE_URL}/api/v1/blogs/`;


