import { Fragment } from "react";
import { Route } from "react-router-dom";
import MyCompany from "./MyCompany/MyCompany";
import Invoices from "./Invoices/Invoices";
import Sidebar from "../../components/UI/Sidebar/Sidebar";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import Content from "../../components/Layout/Content/Content";
import Profile from "./Profile/Profile";
import SentApplications from "./SentApplications/SentApplications";
import EditCompany from "./EditCompany/EditCompany";
import SentRequests from "./SentRequests/SentRequests";
import Drafts from "./Drafts/Drafts";
import RecievedApplications from "./RecievedApplications/RecievedApplications";
import AdminCompanyRoute from "../../components/RouteGuard/AdminCompanyRoute";
import CompanyDashboard from "./CompanyDashboard/CompanyDashboard";
import CompanyInfoDashboard from "./CompanyInfoDashboard/CompanyInfoDashboard";
import CompanyPlans from "./CompanyPlans/CompanyPlans";
import AllRequests from "./AllRequests/AllRequests";
import AllDrafts from "./AllDrafts/AllDrafts";
import AllSentApplications from "./AllSentApplications/AllSentApplications";
import AllRecievedApplications from "./AllRecievedApplications/AllRecievedApplications";

const Dashboard = () => {
  return (
    <Fragment>
      {/* <Sidebar /> */}
      <DashboardHeader />
      <Content>
        <Route exact path="/dashboard/my-company">
          <AdminCompanyRoute  path="/dashboard/my-company" children={<MyCompany />} /> 
        </Route>        

        <Route exact path="/dashboard/my-company/edit/:id">
          <EditCompany />
        </Route>
        <Route exact path="/dashboard/invoices">
          <Invoices />
        </Route>
        <Route path="/dashboard/profile">
          <Profile />
        </Route>
        <Route exact path="/dashboard/sent-applications">
          <SentApplications />
        </Route>
        <Route exact path="/dashboard/sent-requests">
          <SentRequests />
        </Route>
        <Route exact path="/dashboard/drafts">
          <Drafts />
        </Route>
        <Route exact path="/dashboard/recieved-applications">
          <RecievedApplications />
        </Route>
        <Route exact path="/dashboard/company-dashboard">
          <CompanyDashboard />
        </Route>
        <Route exact path="/dashboard/company-info">
          <CompanyInfoDashboard />
        </Route>
        <Route exact path="/dashboard/company-plans">
          <CompanyPlans />
        </Route>
        <Route exact path="/dashboard/all-requests">
          <AllRequests />
        </Route>
        <Route exact path="/dashboard/all-drafts">
          <AllDrafts />
        </Route>
        <Route exact path="/dashboard/all-sent-applications">
          <AllSentApplications />
        </Route>
        <Route exact path="/dashboard/all-recieved-applications">
          <AllRecievedApplications />
        </Route>
      </Content>
    </Fragment>
  );
};

export default Dashboard;
