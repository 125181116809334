import { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import classes from "./Companies.module.scss";
import useHttp from "../../hooks/use-http";
import Pagination from "../../components/UI/Pagination/Pagination";
import CompanyItem from "../../components/CompanyItem/CompanyItem";
import CompaniesFilterBox from "../../components/CompaniesFilterBox/CompaniesFilterBox";
import Loading from "../../components/UI/Loading/Loading";
import NotFoundItems from "../../components/UI/NotFoundItems/NotFoundItems";
import SortBox from "../../components/UI/SortBox/SortBox";
import { API_COMPANIES_URL } from "../../constants/API";
import {
  splitQueryParams,
  updateUniqueUrlParams,
  updateUniqueApiParams,
  updateDuplicatedApiParams,
  updateDuplicatedUrlParams,
  resetQueryParams,
} from "../../utils/QueryParams";
import calculateOffset from "../../utils/calculateOffset";
import { LIMIT_ITEM } from '../../constants/Common';
import SimpleFormGroup from '../../components/UI/SimpleFormGroup/SimpleFormGroup';

const Companies = () => {
  const [companiesList, setCompaniesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [sortItem, setSortItem] = useState(null);
  const [companySizeId, setCompanySizeId] = useState([]);
  const [countryId, setCountryId] = useState(null);
  const [regionsId, setRegionsId] = useState(null);
  const [searchItem, setSearchItem] = useState(null);
  const [specialities, setSpecialities] = useState([]);
  const [serviceCategories, setServicesCategories] = useState([]);
  const [keyServices, setKeyServices] = useState([]);
  const [apiQueryParams, setApiQueryParams] = useState(`?limit=${LIMIT_ITEM}`);
  const [count, setCount] = useState(null);
  const history = useHistory();
  const { sendRequest, isLoading } = useHttp();
  const location = useLocation();

  const getCompaniesList = (data) => {
    setCompaniesList(data.results);
    setCount(data.count);
    window.scrollTo(0, 0);
  };

  const splitUrlQueryparams = useCallback(() => {
    const queryParams = window.location.search;
    const splitedQueryParams = splitQueryParams(queryParams);
    console.log(splitedQueryParams)
    setCompaniesStates(splitedQueryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCompaniesStates = (params) => {
    params.page ? setCurrentPage(parseInt(params.page)) : setCurrentPage(1);
    params.ordering ? setSortItem(params.ordering) : setSortItem(null);
    params.search ? setSearchItem(params.search) : setSearchItem(null);
    params.multi_size ? setCompanySizeId(params.multi_size) : setCompanySizeId([]);
    params.regions ? setRegionsId(params.regions) : setRegionsId(null);
    params.countries ? setCountryId(params.countries) : setCountryId(null);
    params.specialities
      ? setSpecialities(params.specialities)
      : setSpecialities([]);
    params.service_categories
      ? setServicesCategories(params.service_categories)
      : setServicesCategories([]);
    params.key_services
      ? setKeyServices(params.key_services)
      : setKeyServices([]);
    createOffset(params);
  };
  const resetFilter = () => {
    setSpecialities([]);
    setCompanySizeId([]);
    setServicesCategories([]);
    setKeyServices([]);
    updateDuplicatedUrlPage('');

  };
  const createOffset = (params) => {
    let offset;
    // if page is not equal 1
    if (params.page) {
      offset = calculateOffset(parseInt(params.page), LIMIT_ITEM);
      delete params.page;
    } // page =1 (page load for first time)
    else {
      offset = calculateOffset(1, LIMIT_ITEM);
    }
    params["offset"] = offset;
    createApiQueryParams(params);
  };

  const createApiQueryParams = (params) => {
    let updatedQueryParams = apiQueryParams;
    for (let key in params) {
      if (
        key === "specialities" ||
        key === "service_categories" ||
        key === "key_services" 
      ) {
        for (let index = 0; index < params[key].length; index++) {
          updatedQueryParams = updateDuplicatedApiParams(
            key,
            params[key][index],
            updatedQueryParams
          );
        }
      } 
      else if (key === "multi_size") {
        let sizeList = "";
        for (let index = 0; index < params[key].length; index++) {
          sizeList += params[key][index] + ",";
        }        
        updatedQueryParams = updateUniqueApiParams(
          `${key}`,
          sizeList.slice(0, -1),
          updatedQueryParams
        );
      }
      else {
        updatedQueryParams = updateUniqueApiParams(
          `${key}`,
          params[key],
          updatedQueryParams
        );
      }
    }
    setApiQueryParams(updatedQueryParams);
    fetchCompaniesList(updatedQueryParams);
  };

  const updateUniqueUrlPage = (result) => {
    history.push({
      pathname: "/company",
      search: result,
    });
  };

  const updateDuplicatedUrlPage = (result) => {
    history.push({
      pathname: "/company",
      search: result,
    });
  };

  const fetchCompaniesList = (updatedQueryParams) => {
    setCount(null)
    sendRequest(
      { url: `${API_COMPANIES_URL}/company/${updatedQueryParams}` },
      getCompaniesList
    );
  };

  const getActivatedPage = (page) => {
    if (currentPage !== page) {
      setCurrentPage(page);
      let offset = calculateOffset(page, LIMIT_ITEM);
      let updatedQueryParams = updateUniqueApiParams(
        "offset",
        offset,
        apiQueryParams
      );
      setApiQueryParams(updatedQueryParams);
      const urlParams = { page };
      let result = updateUniqueUrlParams(urlParams);
      updateUniqueUrlPage(result);
      fetchCompaniesList(updatedQueryParams);
    }
  };

  const resetPage = () => {
    setCurrentPage(1);
    let offset = calculateOffset(1, LIMIT_ITEM);
    let updatedQueryParams = updateUniqueApiParams(
      "offset",
      offset,
      apiQueryParams
    );
    setApiQueryParams(updatedQueryParams);
    return updatedQueryParams;
  };

  const getCompaniesListByUniqueFilters = (newkey, newValue) => {
    let updatedQueryParams;
    updatedQueryParams = resetPage();
    // choose new filter
    if (newValue) {
      updatedQueryParams = updateUniqueApiParams(
        newkey,
        newValue,
        updatedQueryParams
      );
      const urlParams = {
        page: 1,
        [newkey]: newValue,
      };
      let result = updateUniqueUrlParams(urlParams);
      updateUniqueUrlPage(result);
    } // choose All item and remove it from url and API
    else {
      // reset page number to 1---------------------------------------
      const urlParams = { page: 1 };
      let result = updateUniqueUrlParams(urlParams);
      result = resetQueryParams(newkey, result);
      // remover filter from params of page---------------------------
      updateUniqueUrlPage(result);
      // remove filter from params of api----------------------------
      updatedQueryParams = resetQueryParams(newkey, updatedQueryParams);
    }
    setApiQueryParams(updatedQueryParams);
    fetchCompaniesList(updatedQueryParams);
  };

  const getCompaniesListByDuplicatedFilters = (newkey, list) => {
    // update url of API-------------------------------------------
    let updatedQueryParams = resetPage();
    updatedQueryParams = resetQueryParams(newkey, updatedQueryParams);
    list.forEach((element) => {
      updatedQueryParams = updateDuplicatedApiParams(
        newkey,
        element,
        updatedQueryParams
      );
    });
    setApiQueryParams(updatedQueryParams);
    // update url of page----------------------------------------------
    const urlParams = {
      page: [1],
      [newkey]: list,
    };
    let result = updateDuplicatedUrlParams(urlParams);
    updateDuplicatedUrlPage(result);
    // fetch company list -------------------------------------------
    fetchCompaniesList(updatedQueryParams);
  };

  const getSearchCompany = (searchItem) => {
    getCompaniesListByUniqueFilters("search", searchItem);
    setSearchItem(searchItem);
  };

  const getSortItem = (item) => {
    getCompaniesListByUniqueFilters("ordering", item);
    setSortItem(item);
  };

  const getCompanySizeId = (size) => {
    getCompaniesListByDuplicatedFilters("multi_size", size);
    setCompanySizeId(size);
  };

  const getCountryId = (country) => {
    getCompaniesListByUniqueFilters("countries", country);
    setCountryId(country);
  };

  const getRegionsId = (region) => {
    getCompaniesListByUniqueFilters("regions", region);
    setRegionsId(region);
  };

  const getSpecialities = (specialities) => {
    getCompaniesListByDuplicatedFilters("specialities", specialities);
    setSpecialities(specialities);
  };
  const getServiceCategories = (serviceCategories) => {
    getCompaniesListByDuplicatedFilters(
      "service_categories",
      serviceCategories
    );
    setServicesCategories(serviceCategories);
  };
  const getKeyServices = (keyServices) => {
    getCompaniesListByDuplicatedFilters("key_services", keyServices);
    setKeyServices(keyServices);
  };

  useEffect(() => {
    splitUrlQueryparams();
    return () => {
      setCompaniesList([]);
    };
  }, [location, splitUrlQueryparams]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "POP") {
        splitUrlQueryparams();
      }
    });
  }, [splitUrlQueryparams, history]);

  return (
    <section className={`container  flex-grow ${classes.section}`}>
      <div
        className={`row justify-content-lg-start justify-content-center  `}
      >
        <div className={`col-xl-3 col-12 `}>
          <CompaniesFilterBox
            onRegionsId={getRegionsId}
            onCountryId={getCountryId}
            onCompanySizeId={getCompanySizeId}
            onSearchCompany={getSearchCompany}
            onSpecialities={getSpecialities}
            onServiceCategories={getServiceCategories}
            onKeyServices={getKeyServices}
            searchItem={searchItem}
            companySizeId={companySizeId}
            countryId={countryId}
            regionsId={regionsId}
            initialSpecialities={specialities}
            initialServiceCategories={serviceCategories}
            initialKeyServices={keyServices}
            onResetFilter={resetFilter}
          />
        </div>
        <div className={`col-xl-9 col-12 mt-3 mt-xl-0`}>
          <div className={classes["search-wrapper"]}>
            <SimpleFormGroup
              label="Search Companies"
              inputValue={searchItem}
              dispatchInputValue={getSearchCompany}
            />
          </div>
          {companiesList.length !== 0 && !isLoading && (
            <div
              className={`row justify-content-lg-start justify-content-center  align-items-center ${classes['min-h']}`}
            >
              {companiesList.map((companyList) => (
                <CompanyItem key={companyList.id} companyList={companyList} />
              ))}
              {count > LIMIT_ITEM && (
                <Pagination
                  onActivatedPage={getActivatedPage}
                  count={count}
                  limit={LIMIT_ITEM}
                  currentPage={currentPage}
                  countLabel="Company"
                />
              )}
            </div>
          )}
        </div>
        {isLoading || (companiesList.length === 0 && !isLoading) ? (
          <div
            className={`row justify-content-center  align-items-center ${classes["min-h"]}`}
          >
            {isLoading && <Loading className={classes.loading} />}
            {companiesList.length === 0 && !isLoading && <NotFoundItems className={classes["not-found"]} item="Company" />}
          </div>
        ) : ''}

      </div>
    </section>
  );
};

export default Companies;
