import classes from "./SimpleCheckBoxFilterBox.module.scss";
import React, {useState, useRef, useEffect } from "react";


const SimpleCheckBoxFilterBox = ({ onChangeHandler, input, register, isChecked }) => { 
  return (
    <div
      onClick={() => onChangeHandler(`${input.id}`)}      
      className={`${classes["form-group"]} ${classes[isChecked]}`}
    >
     
      <div className={classes['label-wrapper']}>
        
        <div className={`${classes["input-label"]} ${classes[isChecked]}`}>{input.label}</div>
        <input  type="checkbox" id={input.id} {...register(input.name)} />
        <label></label>
        
      </div>
    </div>
  );
};

export default SimpleCheckBoxFilterBox;
