import React from 'react';
import classes from './CompanyInformation.module.scss';
import CardV2 from '../UI/CardV2/CardV2';
import { detectShortestItem, validateUrl } from "../../utils/common";
import { Fragment } from 'react';
import twitter from '../../assets/images/Icons/twitter.svg';
import linkdin from '../../assets/images/Icons/linkdin.svg';

const CompanyInformation = ({ companyInfo }) => {
    // console.log("companyInfo:", companyInfo);
    return (
        <CardV2 className={classes.card}>
            <div className={classes.title}>
                Company information
            </div>
            <div className={classes.body}>
                <div className={classes['body-title']}>
                    Life Science Sector:
                </div>
                <div className={classes['body-value']}>
                    {companyInfo && companyInfo.specialities_data && companyInfo.specialities_data.length > 0 &&
                        companyInfo.specialities_data.map((item) => (
                            <div key={item.id} className={classes['spec-tem']}>
                                {item.title}
                            </div>
                        ))
                    }
                </div>

                {companyInfo && companyInfo.company_type !== 0 && (
                    <Fragment>
                        <div className={classes['body-title']}>
                            Employee Count:
                        </div>
                        <div className={classes['body-value']}>
                            {companyInfo.size_data &&
                                (companyInfo.size_data.title)
                            }
                        </div>

                        {companyInfo.website && (
                            <Fragment>
                                <div className={classes['body-title']}>
                                    Website:
                                </div>
                                <div className={classes['body-value']}>
                                    <a
                                        href={validateUrl(companyInfo.website)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {companyInfo.website}
                                    </a>
                                </div>
                            </Fragment>
                        )}

                        {companyInfo && 
                        ((companyInfo.twitter && companyInfo.twitter !== null && companyInfo.twitter !== "" && companyInfo.twitter !== "null") || 
                         (companyInfo.linkedin && companyInfo.linkedin !== null && companyInfo.linkedin !== "" && companyInfo.linkedin !== "null"))
                         && (
                            <Fragment>
                                <div className={classes['body-title']}>
                                    Social Media:
                                </div>
                                <div className={classes['body-value']}>
                                    <div className={classes['social-wrapper']}>
                                        {companyInfo && companyInfo.twitter && !(companyInfo.twitter == "" || companyInfo.twitter == "null") && (
                                            <div className={classes['twitter']}>
                                                <a
                                                    href={validateUrl(companyInfo.twitter)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src={twitter} />
                                                </a>
                                            </div>
                                        )}
                                        {companyInfo && companyInfo.linkedin && !(companyInfo.linkedin == "" || companyInfo.linkedin == "null") && (
                                            <div className={classes['linkdin']}>
                                                <a
                                                    href={validateUrl(companyInfo.linkedin)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src={linkdin} />
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </Fragment>
                )}





            </div>
        </CardV2>
    );
};

export default CompanyInformation;