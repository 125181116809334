import classes from "./NavRegisterLogin.module.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showCreateRequestModal } from "../../../../redux/Modal/modalAction";
import { Fragment, useEffect } from "react";
import {Button} from "react-bootstrap";

const NavRegisterLogin = () => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const userRole = useSelector((state) => state.user.userRole);
  const requestmodal = useSelector((state) => state.modal.requestModal);
  const dispatch = useDispatch();

  return (
    <div className="mb-2 mt-3 my-lg-0 text-center position-relative">
      {!isLoggedIn && (
        <Fragment>
          <Link
            to="/authentication/login"
            className={`btn ${classes["login-link"]}`}
          >
            Login
          </Link>
          <Button
              className={`${classes["register-link"]}`}
              onClick={() => window.location.href = "/authentication/register"}
          >
                        Register for FREE
          </Button>

        </Fragment>
      )}
      {isLoggedIn && (userRole === "company" || userRole === "freelancer") && (
        // <Link to="/create-request" className={`btn ${classes["create-request-link"]}`}>
        //   Create Request
        // </Link>
        <button className={`${classes["create-request-link"]}`}
          onClick={() => { dispatch(showCreateRequestModal(true)) }}
        >
          Create Request
        </button>
      )}
      {isLoggedIn && userRole === "guest" && (
        <Link
          to="/guest/user-role"
          className={`btn ${classes["selecet-role"]}`}
        >
          Select Role
        </Link>
      )}
    </div>
  );
};

export default NavRegisterLogin;
