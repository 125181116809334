import classes from "./SelectableButtonItem.module.scss";
import tick from "../../../assets/images/Icons/tick-icon.svg";
import { useState, useEffect, useCallback } from "react";
const SelectableButtonItem = ({
    item,
    dispatchSelectedItem,
    isSelectedList

}) => {
    const [selectedCard, setSelectedCard] = useState();
    const checkSelected = () => {        
        let isSelectedArray = isSelectedList.map(Number);
        setSelectedCard(isSelectedArray.includes(item.id));
    }
    useEffect(() => {
        checkSelected();
      }, [isSelectedList]);
    const selectedItemHandler = () => {
        dispatchSelectedItem(item.id);
    };
    return (
        <div
            className={` ${classes.card} ${selectedCard ? classes.selected : ""} `}
            onClick={selectedItemHandler}
        >   
        {selectedCard && (
            <div className={classes["radio-icon"]}>
                <img src={tick} />
            </div>
        )}        
            <div className={classes.title}>
                {item.title}
            </div>
        </div>
    );

};

export default SelectableButtonItem;