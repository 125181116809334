import { useRef, useCallback, useState, useEffect } from "react";

export const useAccordion = (initialState = false, onAfterClose = null) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(initialState);

  const handleClickOutside = useCallback(
    // (event) => {
    //   if (ref.current && ref.current.contains(event.target)) {
    //     return;
    //   }
    //   setIsOpen(false);
    //   onAfterClose && onAfterClose();
    // },
    // [ref, onAfterClose]
  );

  const handleHideDropdown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        setIsOpen(false);
        onAfterClose && onAfterClose();
      }
    },
    [onAfterClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return [ref, isOpen, setIsOpen];
};
