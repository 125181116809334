import classes from "./Footer.module.scss";

import { Link } from "react-router-dom";

import { ReactComponent as PharmatchingLogo } from "../../../assets/images/logo/pharmatching-logo.svg";
import { ReactComponent as Instagram } from "../../../assets/images/instagram.svg";
import { ReactComponent as Linkedin } from "../../../assets/images/linkedin.svg";
import { ReactComponent as Twetter } from "../../../assets/images/twetter.svg";

const Footer = () => {
	return (
		<footer className={classes["footer-section"]}>
			<div
				className={`${classes["footer-container"]} container row justify-content-center`}
			>
				<div className={`${classes["info"]} col-12 col-md-4`}>
					<div className={classes["logo"]}>
						<PharmatchingLogo />
					</div>
					<p className={classes["desc"]}>
						Creating a SMART Life Science Community
					</p>
					<ul className={classes["social"]}>
						<li>
							<a
								href="https://twitter.com/pharmatching"
								rel="noreferrer"
								target="_blank"
							>
								<Twetter />
							</a>
						</li>
						<li>
							<a
								href="https://www.linkedin.com/company/pharmatching"
								rel="noreferrer"
								target="_blank"
							>
								<Linkedin />
							</a>
						</li>
					</ul>
				</div>
				<ul className={`${classes["links"]} col-12 col-md-8`}>
					<li>
						<Link to="/about-us">About us</Link>
					</li>
					<li>
						<Link to="/cookies-policy/">Cookie Policy</Link>
					</li>
					<li>
						<Link to="/legal-dliclaimer/">Legal Disclaimer</Link>
					</li>
					<li>
						<Link to="/imprint">Imprint</Link>
					</li>
					<li>
						<Link to="/faq/">FAQ</Link>
					</li>
					<li>
						<Link to="/privacy-policy">Privacy Policy</Link>
					</li>
					<li>
						<Link to="/terms-conditions">Terms and Conditions</Link>
					</li>
					{/* <li>
						<Link to="/legal-dliclaimer">Imperssum/Legal Notice</Link>
					</li>
					
					<li>
						<Link to="/cookies-policy">Security Policy</Link>
					</li> */}
				</ul>
			</div>
			<div className={classes["version"]}>
				<p>© 2024 Copyright: Pharmatching.com (1.3.4)</p>
			</div>
		</footer>
	);
};

export default Footer;
