import { Fragment, useCallback, useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import classes from "./CategoriesList.module.scss";
import categoriesIcon from "../../../../../assets/images/Icons/categories-icon.svg";
import CategoryItem from "../CategoryItem/CategoryItem";
import KeyServiceItem from "../KeyServiceItem/KeyServiceItem";
import useHttp from "../../../../../hooks/use-http";
import { API_TAGS_URL } from "../../../../../constants/API";

const CategoriesList = () => {
  const dropdownPanel = useRef(null);
  const [categoriesList, setCategoriesList] = useState([]);
  const [displayedKeyService, setDisplayedKeyService] = useState([]);
  const [activatedLink, setActivatedLink] = useState(false);
  const { sendRequest, error, isLoading } = useHttp();
 
  const location = useLocation();
  const getCategoriesList = useCallback((categories) => {
    setCategoriesList(categories);
  }, []);

  const fetchCategoriesList = useCallback(() => {
    sendRequest(
      {
        url: `${API_TAGS_URL}/service-categories/`,
      },
      getCategoriesList
    );
  }, [getCategoriesList, sendRequest]);

  useEffect(() => {
    fetchCategoriesList();
    return () => {
      setCategoriesList([]);
    };
  }, [fetchCategoriesList]);

  useEffect(() => {
    if (location.pathname === "/request") {
      setActivatedLink(true);
    } else {
      setActivatedLink(false);
    }
    dropdownPanel.current.classList.remove("show");    
  }, [location.pathname]);

  const categoryItemHandler = (id) => {
    const category = categoriesList.find((category) => category.id === id);
    setDisplayedKeyService(category.key_services);
  };

  return (
    <Fragment>
      <button
        className={`${classes["nav-link"]} ${activatedLink ? classes["active-link"] : ""
          } nav-link dropdown-toggle`}
        type="button"
        id="dropdownId"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Requests
      </button>
      <div
        className={`${classes["dropdown-menu"]} dropdown-menu p-0 pb-2`}
        aria-labelledby="dropdownId"
        data-testid="categoriesList"
        ref={dropdownPanel}
      >
        <Link
          className={`${classes["dropdown-item"]} dropdown-item ${classes["categories-header"]}`}
          to="/request"
        >
          <div
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse.show"
          >
            <img
              src={categoriesIcon}
              className={classes["categories-icon"]}
              alt="icon"
            />
            Explore All Categories
          </div>
        </Link>
        <CategoryItem
          categoriesList={categoriesList}
          onCategoryItem={categoryItemHandler}
          loading={isLoading}
          error={error}
        />
        <KeyServiceItem
          displayedKeyService={displayedKeyService}
          categoriesListLentgh={categoriesList.length}
        />
      </div>
    </Fragment>
  );
};

export default CategoriesList;
