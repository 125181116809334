import classes from "./WarningLetterEuDramedItem.module.scss";
import Card from "../UI/Card/Card";
import Eudramed from "../../assets/images/warning-letters/Eudramed.svg";
import pdfIcon from "../../assets/images/Icons/pdf-icon.svg";
import { dateFormmater } from "../../utils/common";

const WarningLetterEuDramedItem = ({ warningLetter }) => {
  return (
    <a href={warningLetter.url} target="_blank" rel="noreferrer">
      <Card className={classes["eudramed-card"]}>
        <div className="row">
          <div className="col-6">
            <img
              src={Eudramed}
              alt="eudramed"
              className={classes["eudramed-image"]}
            />
          </div>
          <div className="col-6 text-end">
            <img src={pdfIcon} className={classes["pdf-image"]} alt="pdf" />
          </div>
          <div className="col-6 mt-4">
            <span className={classes["company-name"]}>
              {warningLetter.company_name}
            </span>
          </div>
          <div className="col-6 text-end mt-4">
            <span className={classes["eudramed-date"]}>
              {warningLetter.created_at && dateFormmater(warningLetter.created_at)}
            </span>
          </div>
        </div>
      </Card>
    </a>
  );
};

export default WarningLetterEuDramedItem;
