import classes from "./SelectableButtonList.module.scss";
import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as React from "react";
import { useDropdown } from "../../../hooks/use-dropdown";
import SimpleCheckBox from "../SimpleCheckBox/SimpleCheckBox";
import SelectableButtonItem from "../SelectableButtonItem/SelectableButtonItem";

const SelectableButtonList = (props) => {
  const {
    className,
    filterName,
    filterList,
    initialFilters,
    dispatchFilterList,
    isSelectedItem,
    isDisabled,
    errorMessage,
    customLabel,
    removeSelectedItem
  } = props;
  const [displayedItems, setDisplayedItems] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const { register, handleSubmit, setValue, reset, getValues } = useForm();
  const [dropdownRef, isOpen, setIsOpen] = useDropdown();

  useEffect(() => {
    setDisplayedItems(filterList);
  }, [filterList]);

  const setInitialFilters = useCallback(() => {
    if (initialFilters && initialFilters.length !== 0) {
      console.log('initialFilters:', initialFilters);
      reset();
      setSelectedFilter(initialFilters.length);
      initialFilters.forEach((item) => {
        setValue(item, true);
      });
    } else {
      setSelectedFilter(initialFilters.length);
      reset();
    }
  }, [initialFilters, reset, setValue]);

  useEffect(() => {
    setInitialFilters();
  }, [initialFilters, setInitialFilters]);

  const onSubmit = (list) => {
    let arr = [];
    for (const key in list) {
      if (list[key]) {
        arr.push(key);
      }
    }
    dispatchFilterList(arr);
    setIsOpen(!isOpen);
    setDisplayedItems(filterList);
  };
  const changeSelected = (id) => {
     console.log("changedId:",id); 
    console.log("initial count:", initialFilters.length);
    let initialFiltersArray = initialFilters.map(Number);
    let array = initialFilters;
    if (initialFiltersArray.includes(id)) {
      array = array.filter(item => !(item == id));
      removeSelectedItem(id);
    }
    else {
      if (initialFilters.length < 5) {
        array = array.filter(item => !(item == id));
        array.push('' + id);
      }
    }
    console.log("arrey:", array);
    dispatchFilterList(array);
  }
  const resetFilters = () => {
    reset();
    setSelectedFilter(0);
  };

  const keyChangeHandler = (item) => {
    const value = getValues(item);
    value ? setValue(item, false) : setValue(item, true);
    value
      ? setSelectedFilter(selectedFilter - 1)
      : setSelectedFilter(selectedFilter + 1);
  };

  const searchHandler = (e) => {
    let input = e.target.value.toLowerCase();
    let result = filterList.filter((element) =>
      element.title.toLowerCase().includes(input)
    );
    setDisplayedItems(result);
  };



  return (
    // <div ref={dropdownRef} className={`dropdown ${classes.dropdown}`}>
    //   <button
    //     style={{ borderColor: (!isSelectedItem || errorMessage) ? "#e10000" : "#343A40" }}
    //     onClick={() => setIsOpen(!isOpen)}
    //     className={`${classes["dropdown-button"]}`}
    //     type="button"
    //     aria-expanded={isOpen}
    //     disabled={isDisabled}
    //   >
    //     {selectedFilter === 0 ? (
    //       <span>{filterName}</span>
    //     ) : (
    //       <span>{`${selectedFilter} ${filterName} selected!`}</span>
    //     )}
    //   </button>
    //   {isOpen && (
    //     <div
    //       id="filtersBox"
    //       className={`${className} ${classes["dropdown-list"]}`}
    //     >
    //       <form onSubmit={handleSubmit(onSubmit)}>
    //         <div className={classes["search-box"]}>
    //           <input
    //             className={classes["search-input"]}
    //             type="text"
    //             placeholder="search"
    //             onChange={(e) => searchHandler(e)}
    //           />

    //         </div>
    //         {displayedItems.map((item) => (
    //           <SimpleCheckBox
    //             key={item.id}
    //             onChangeHandler={keyChangeHandler}
    //             input={{
    //               name: `${item.id}`,
    //               label: `${item.title}`,
    //               id: item.id,
    //             }}
    //             register={register}
    //           />
    //         ))}
    //         <div className={classes.footer}>    
    //         <div className={classes.line}></div>          
    //           <button
    //             className={`btn ${classes.reset}`}
    //             type="reset"
    //             onClick={resetFilters}
    //           >
    //             Reset All
    //           </button>
    //           <button className={`btn ${classes.apply}`} type="submit">
    //             Apply
    //           </button>
    //         </div>
    //       </form>
    //     </div>
    //   )}
    //   {customLabel && (
    //     <div className={classes["life-science-custom-label"]}>
    //       {customLabel}
    //     </div>
    //   )}
    //   {!isSelectedItem && (
    //     <div className={classes["single-input-error"]}>This value is required.</div>
    //   )}
    //   {errorMessage && (
    //     <div className={classes["single-input-error"]}>{errorMessage}</div>
    //   )}
    // </div>
    <div className={classes["button-list"]}>
      {displayedItems.map((item) => (
        <SelectableButtonItem
          key={item.id}
          item={item}
          dispatchSelectedItem={changeSelected}
          isSelectedList={initialFilters}
        />
        // <SimpleCheckBox
        //   key={item.id}
        //   onChangeHandler={keyChangeHandler}
        //   input={{
        //     name: `${item.id}`,
        //     label: `${item.title}`,
        //     id: item.id,
        //   }}
        //   register={register}
        // />
      ))}
    </div>
  );
};

export default SelectableButtonList;
