import React, { useRef, useState, useEffect } from 'react';
import classes from './SingleTextRichAreaWordCount.module.scss';
import './SingleTextRichAreaWordCount.scss';
import { Editor } from '@tinymce/tinymce-react';

const SingleTextRichAreaWordCount = ({ validation, input, errors, register, getValues, setValue, isApplication, onKeyUpHandler, hasInitialValue }) => {
    const editorRef = useRef(null);
    const [wordCount, setWordCount] = useState();
    const [hasInitial, setHasInitial] = useState(hasInitialValue);

   
    useEffect(() => {
        if (wordCount > 49)
            onKeyUpHandler(false);
        else
            onKeyUpHandler(true);
    });

    return (
        <div className={classes["single-input-box"]}>
            <label
                style={{ color: errors[input.name] ? "#E10000" : "" }}
                htmlFor={input.name}
                className={classes["single-input-label"]}
            >{input.label}{" "}</label>
            
            <Editor
                apiKey='9cz90yrxa5azvv5pfrcgva75m16fvwuau7rzyl9q0gczgmsg'
                onInit={(evt, editor) => editorRef.current = editor}
                setValue={getValues(input.name)}
                // initialValue={getValues(input.name)}                 
                {...(hasInitial ? {initialValue:getValues(input.name)} : {})}               
                onKeyUp={(e) => {
                    setValue(input.name, editorRef.current.getContent());
                    console.log(editorRef.current.getContent().split(' ').length);
                    setWordCount(editorRef.current.getContent().split(' ').length);
                }}
                onChange={(e) => {
                    setValue(input.name, editorRef.current.getContent());
                    console.log(editorRef.current.getContent().split(' ').length);
                    setWordCount(editorRef.current.getContent().split(' ').length);
                }}
                onPostProcess={()=>{setHasInitial(false)}}
                init={{
                    height: 170,
                    menubar: false,
                    plugins: 'lists advlist',
                    toolbar: 'undo redo bold italic| alignleft aligncenter alignright alignjustify| ' +
                        ' | bullist numlist outdent indent | ',

                }}
            />
            <textarea
                onChange={(e) => { console.log('text:', e) }}
                style={{ borderColor: errors[input.name] ? "#E10000" : "" }}
                className={classes["single-input"]}
                {...input}
                {...register(input.name, validation)}
            />


            {errors[input.name] && errors[input.name].type === "required" && (
                <span className={classes["single-input-error"]}>This is required</span>
            )}
            {errors[input.name] && errors[input.name].message && (
                <span className={classes["single-input-error"]}>
                    {" "}
                    {errors[input.name].message}
                </span>
            )}
            <div className={`${classes["word-count-notif"]}`}>
                {!isApplication && wordCount < 50 && <div className={`${classes["red"]}`}>
                    To ensure the best possible result, we strongly recommend to be more precise with your request description and provide as much information as possible for potential service provider.
                </div>}
                {!isApplication && wordCount > 49 && wordCount < 100 && <div className={`${classes["yellow"]}`}>
                    Your request description seems to be alright. To improve your chances to find the best possible matchmaking partner, it is recommended to provide more details.
                </div>}
                {!isApplication && wordCount > 99 && <div className={`${classes["green"]}`}>
                    Good job! Your description seems to be suitable. Any additional information you provide will help companies to understand your needs more efficiently.
                </div>}

                {isApplication && wordCount < 50 && <div className={`${classes["red"]}`}>
                    To ensure the best possible result, we strongly recommend being more precise with your application description and provide as much information as possible to increase your success potential.
                </div>}
                {isApplication && wordCount > 49 && wordCount < 100 && <div className={`${classes["yellow"]}`}>
                    Your application seems to be alright. To improve your chances to win this tendering, it is still recommended to provide more details. Describe why you and your company might be the best solution for this challenge.
                </div>}
                {isApplication && wordCount > 99 && <div className={`${classes["green"]}`}>
                    Good job! Your description seems to be suitable. Any additional information you provide will help companies to understand your benefits better and increase your success chances.
                </div>}



            </div>
        </div>
    );
};

export default SingleTextRichAreaWordCount;