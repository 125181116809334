import classes from "./ServiceCategoryCarousel.module.scss";
import Slider from "react-slick";
import ServiceCategoryCarouselItem from "../ServiceCategoryCarouselItem/ServiceCategoryCarouselItem";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import useHttp from "../../hooks/use-http";
import {Link, useHistory, useLocation} from "react-router-dom";
import {API_TAGS_URL} from "../../constants/API";
import {showCreateRequestModal} from "../../redux/Modal/modalAction";
import {useDispatch, useSelector} from "react-redux";
import carouselPlaceholder from "../../assets/images/home/carousel-placeholder.webp";
import keyserviceArrow from "../../assets/images/arrow/keyservice-arrow.svg";


const ServiceCategoryCarousel = () => {
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const userRole = useSelector((state) => state.user.userRole);
    const requestmodal = useSelector((state) => state.modal.requestModal);
    const dispatch = useDispatch();
    const [categoriesList, setCategoriesList] = useState([]);
    const [displayedKeyService, setDisplayedKeyService] = useState([]);
    const {sendRequest, error, isLoading} = useHttp();
    const location = useLocation();
    const getCategoriesList = useCallback((categories) => {
        setCategoriesList(categories);
    }, []);
    const history = useHistory();

    const fetchCategoriesList = useCallback(() => {
        sendRequest(
            {
                url: `${API_TAGS_URL}/service-categories/`,
            },
            getCategoriesList
        );
    }, [getCategoriesList, sendRequest]);

    useEffect(() => {
        fetchCategoriesList();
        return () => {
            setCategoriesList([]);
        };
    }, [fetchCategoriesList]);
    const categoryItemHandler = (id) => {
        const category = categoriesList.find((category) => category.id === id);
        setDisplayedKeyService(category.key_services);
    };
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const SampleNextArrow = (props) => {
        const {className, style, onClick} = props;
        return (
            <div
                className={classes['nextBtn']}
                style={{...style}}
                onClick={onClick}
            />
        );
    }

    const SamplePrevArrow = (props) => {
        const {className, style, onClick} = props;
        return (
            <div
                className={classes['prevBtn']}
                style={{...style}}
                onClick={onClick}
            />
        );
    }

    const chunkArray = (arr, chunkSize) => {
        const result = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            result.push(arr.slice(i, i + chunkSize));
        }
        return result;
    }
    const chunkedKeyServices = categoriesList[selectedItemIndex] && categoriesList[selectedItemIndex].key_services
        ? chunkArray(categoriesList[selectedItemIndex].key_services, 7)
        : [];


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6, // Number of items to show at once
        slidesToScroll: 6, // Number of items to scroll at a time
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        appendDots: dots => (
            <div
                style={{
                    backgroundColor: "transparent",
                    borderRadius: "10px",
                    top: "180px",
                    right: "30px",

                }}
            >
                <ul style={{margin: "0px"}}> {dots} </ul>
            </div>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,

                }
            }
        ]
    };

    return (
        <section className={`${classes["carousel-section"]} container my-5`}>
            <h3>Service Categories</h3>
            <p className={`${classes["description"]}`}>Explore our service categories to find the best match or offer
                your service portfolio.</p>
            <Slider {...settings}>
                {categoriesList && categoriesList.map((category, index) => (
                    category.key_services.length > 0 && <ServiceCategoryCarouselItem
                        key={category.id}
                        item={category}
                        isSelected={index === selectedItemIndex}
                        onSelect={() => setSelectedItemIndex(index)}
                    />

                ))}
            </Slider>
            <div
                className={`${classes['active-item-content-container']} container`}>
                <div className="row">
                    <div className={`${classes['item-wrapper']} col-lg-8 col-md-12`}>
                        <div className={classes['keyservice-columns-container']}>
                            <div className="row">
                                {chunkedKeyServices.slice(0, 2).map((column, columnIndex) => (
                                    <div key={columnIndex} className="col-6">
                                        <ul>
                                            {column.slice(0, 11).map((keyService, index) => (
                                                ((index < 6 && columnIndex === 1) || columnIndex === 0 ?
                                                    <li className={classes['carousel-item-keyservice']}
                                                        key={keyService.id}
                                                        onClick={() => categoryItemHandler(keyService.id)}>
                                                        <Link
                                                            to={`/request?page=1&service_categories=${categoriesList[selectedItemIndex].id}`}>
                                                            <span>{keyService.title}</span> <img src={keyserviceArrow} />
                                                        </Link>
                                                    </li>
                                                    :
                                                    <li className={classes['carousel-item-keyservice-explore']}>
                                                        <Link
                                                            to={`/request?page=1&service_categories=${categoriesList[selectedItemIndex].id}`}>
                                                            Explore more key services <span><img src={keyserviceArrow} /></span>
                                                        </Link>
                                                    </li>)

                                            ))}

                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={`${classes['keyservice-image-col']} col-lg-4 col-md-12`}>
                        <div className={classes['keyservice-square-top']}></div>
                        <img
                            className={classes['category-sample-image']}
                            src={(categoriesList[selectedItemIndex] && categoriesList[selectedItemIndex].showcase_photo ? categoriesList[selectedItemIndex].showcase_photo : carouselPlaceholder)}
                        />
                        <div className={classes['keyservice-square-bottom']}>
                            {categoriesList[selectedItemIndex] && categoriesList[selectedItemIndex].title}
                        </div>
                    </div>
                </div>
                <div className={classes["create-request-link"]}>
                    <Fragment>
                        <div
                            className={`${classes["select-btn"]}`}
                            onClick={() => {
                                isLoggedIn && (userRole === "company" || userRole === "freelancer") ? dispatch(showCreateRequestModal(true)) : history.push("/authentication/login");
                            }}
                        >
                            Create your Request for FREE
                        </div>
                    </Fragment>
                </div>
            </div>

        </section>
    );
};

export default ServiceCategoryCarousel;
