import React from 'react';
import classes from './SortableTableSentApplications.module.scss';
import orderArrow from '../../../assets/images/arrow/order-arrow.svg';
import { dateFormmaterNormal } from "../../../utils/common";
import actionEdit from '../../../assets/images/Icons/actionEdit.svg';
import actionIcon from '../../../assets/images/Icons/actionIcon.svg';
import { Link } from "react-router-dom";
import { SENT_APPLICATION_STATUS } from "../../../constants/SentApplicationStatus";

const SortableTableSentApplications = ({ source, onSortItem, isSentApp }) => {
    const items = source;
    console.log('source: ', source);
    const handleClickAction = () => {
        console.log('action');
    };
    const handleClickEdit = () => {
        console.log('Edit');
    };
    return (
        <table className={classes['table-requests']}>
            <thead>
                <tr>
                    <th
                        onClick={() => onSortItem('title')}
                    // className={classes[getClassNamesFor('title')]}
                    >
                        Title
                        <img src={orderArrow} alt='arrow' />
                    </th>
                    <th
                        onClick={() => onSortItem('-created_at')}
                    // className={classes[getClassNamesFor('created_at')]}
                    >
                        Creation Date
                        <img src={orderArrow} alt='arrow' />
                    </th>
                    {isSentApp && (
                        <th>
                            Requester
                        </th>
                    )}
                    <th
                    // onClick={() => requestSort('status')}
                    // className={classes[getClassNamesFor('status')]}
                    >
                        Status
                        {/* <img src={orderArrow} alt='arrow' /> */}
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {items.map((application) => (
                    <tr key={application.id}>
                        <td>{application.text}</td>
                        <td>{dateFormmaterNormal(application.created_at)}</td>
                        {isSentApp && (
                            application.request_data.is_anonymous ? <td>Anonymous</td> : <td>{application.request_data.company_title}</td>
                        )}
                        <td>
                            {SENT_APPLICATION_STATUS.filter(
                                (element) => element.title === application.status
                            ).map((item) =>
                                <div
                                    style={{ color: item.color, backgroundColor: item.backgroundColor }}
                                    className={classes["application-status"]}
                                >
                                    {item.cardTitle}
                                </div>
                            )}
                        </td>
                        <td>
                            {isSentApp && (
                                <Link
                                    to={`/sent-applications/${application.id}`}
                                    className='btn'>
                                    <img src={actionIcon} />
                                </Link>
                            )}
                             {!isSentApp && (
                                <Link
                                    to={`/application-view/${application.id}`}
                                    className='btn'>
                                    <img src={actionIcon} />
                                </Link>
                            )}

                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default SortableTableSentApplications;