import React, {Fragment, useEffect, useState} from 'react';
import classes from './CompanyViewsChart.module.scss';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import useHttp from "../../hooks/use-http";
import {getAccessToken} from "../../utils/authentication";
import {API_COMPANIES_URL} from "../../constants/API";

const CompanyViewsChart = ({userCompany}) => {
    const {sendRequest, error} = useHttp();
    const [companyViews, setCompanyViews] = useState(null);
    const getCompanyViews = (data) => {
        setCompanyViews(data);
    };
    const fetchCompanyViews = () => {
        const token = getAccessToken();
        sendRequest(
            {
                url: `${API_COMPANIES_URL}/company/${userCompany.id}/views-report/`,
                headers: {Authorization: `Bearer ${token}`},
            },
            getCompanyViews
        );
    };

    useEffect(() => {
        // company , freelancer
        if (userCompany) {
            fetchCompanyViews();
        }
    }, [userCompany]);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
                align: "end"
            }
        }
    };

    const labels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday',];
    let companySum = companyViews ? companyViews.company_total_views : 0;
    // let requestSum = companyViews ? companyViews.request_total_views : 0;
    const data = {
        labels,
        datasets: [
            {
                label: 'Company Views',
                data: companyViews ? companyViews.company.map((item) => item.count) : [],
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            // {
            //     label: 'Request Views',
            //     data: companyViews ? companyViews.request.map((item) => item.count) : [],
            //     backgroundColor: 'rgb(255,94,190)',
            // },
        ],
    };
    return (
        <div className={`${classes['chart-container']} container`}>
            <div className='row'>
                <div className={`${classes['chart-title']} col-6`}><p>Last Week Company Views</p></div>
                <div className={`${classes['chart-total-description']} col-6`}><p>Total Company Profile
                    view <span>&nbsp;{companySum}</span></p>
                </div>
            </div>
            <Bar options={options} data={data}/>
        </div>
    );
};

export default CompanyViewsChart;