import { Fragment } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import CreateProfile from "./CreateProfile/CreateProfile";
import { getAccessToken } from "../../utils/authentication";
import UserRole from "./UserRole/UserRole";
import CreateCompany from "./CreateCompany/CreateCompany";
import Freelancer from "./Freelancer/Freelancer";
import JoinCompany from "./JoinCompany/JoinCompany";
import GuestRoute from "../../components/RouteGuard/GuestRoute";
import FreelancerRoute from "../../components/RouteGuard/FreelancerRoute";
import Companies from "../../pages/Companies/Companies";



const GuestUser = () => {
  return (
    <Switch>
      <Fragment>
        <GuestRoute exact path="/guest/create-profile">
          <CreateProfile />
        </GuestRoute>
        <GuestRoute exact path="/guest/user-role">
          <UserRole />
        </GuestRoute>
        <GuestRoute exact path="/guest/create-company" >
          <CreateCompany />          
        </GuestRoute>
        <GuestRoute exact path="/guest/freelancer">
          <Freelancer />
        </GuestRoute>
        <GuestRoute exact path="/guest/join-company">
          <JoinCompany />
        </GuestRoute>

      </Fragment>
    </Switch>
  );
};

export default GuestUser;

// <Switch>
// {getAccessToken() ? (
//   <Fragment>
//     <Route exact path="/guest/create-profile">
//       <CreateProfile />
//     </Route>
//     <Route exact path="/guest/user-role">
//       <UserRole />
//     </Route>
//     <Route exact path="/guest/create-company">
//       <CreateCompany />
//     </Route>
//   </Fragment>
// ) : (
//   <Redirect to="/" />
// )}
// </Switch>
