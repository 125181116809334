import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import classes from "./DisplayPersonalInformation.module.scss";
import defaultAvatar from "../../assets/images/Icons/profile-user.svg";
import editProfile from "../../assets/images/Icons/edit-profile.svg";
import placeholder from "../../assets/images/placeholder.svg";
import Card from "../UI/CardV2/CardV2";
import ConfirmModal from "../UI/ConfirmModal/ConfirmModal";
import useHttp from "../../hooks/use-http";
import { getAccessToken } from "../../utils/authentication";
import { API_COMPANIES_URL } from "../../constants/API";
import { updateIsCompanyChanged } from "../../redux/Company/companyAction";
import { addToast } from "../../redux/Toast/toastAction";
import { createRandomId } from "../../utils/common";
import { updateIsProfileChange } from '../../redux/User/userAction';

const DisplayPersonalInformation = ({
  userProfile,
  userCompany,
  userRole,
  editPersonalInformationHandler,
}) => {
  const [isDisplayModal, setIsDisplayModal] = useState(false);
  const { sendRequest, error } = useHttp();
  const dispatch = useDispatch();

  const getRevokeCompanyRes = (res) => {
    console.log(res);
    dispatch(updateIsCompanyChanged(true));
    dispatch(updateIsProfileChange(true));
  };

  const confirmRevoke = (str) => {
    setIsDisplayModal(false);
    if (str === "confirm") {
      const token = getAccessToken();
      sendRequest(
        {
          url: `${API_COMPANIES_URL}/company/revoke-company/`,
          headers: { Authorization: `Bearer ${token}` },
        },
        getRevokeCompanyRes
      );
    }
  };

  useEffect(() => {
    if (error && error.status === 400) {
      console.log(error.data.errors);
      dispatch(
        addToast({
          id: createRandomId(),
          status: "error",
          description: error.data.errors["0"],
        })
      );
    }
  }, [dispatch, error]);

  return (
    <Card className={classes.card}>
      {isDisplayModal && (
        <ConfirmModal
          modalButtonText="Revoke"
          modalType="warning"
          modalText="Do you confirm to revoke the Company?"
          modalHeader="Revoke"
          onConfirm={confirmRevoke}
        />
      )}
      <div className={classes.header}>
        <div className={classes.title}>
          Personal information
        </div>
        <div className={classes.edit}>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-12 mt-4 d-flex justify-content-center">
          <div
            style={{
              backgroundImage: userProfile.image
                ? `url(${userProfile.image.file})`
                : `url(${defaultAvatar})`,
            }}
            className={classes["profile-avatar"]}
          ></div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-12 d-flex justify-content-center">
          <div className={classes["user-info-wrapper"]}>
            <div className={classes["info-title"]}>
              Name & Surename
            </div>
            <div className={classes["user-name"]}>
              <span>{userProfile.user.first_name}</span>{" "}
              <span>{userProfile.user.last_name}</span>{" "}
            </div>
            <div className={classes["info-title"]}>
              Email
            </div>
            <div className={classes["user-email"]}>
              {" "}
              <span>{userProfile.user.email}</span>{" "}

              <svg className="ml-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="#6AEB9D" />
                <path d="M9.75005 15.1531L6.60005 12.0031L5.55005 13.0531L9.75005 17.2531L18.75 8.25312L17.7 7.20312L9.75005 15.1531Z" fill="#343A40" />
              </svg>

              {/* <i className={`${classes["verify-icon"]} fas fa-check-circle`}></i> */}
            </div>

            <div className={classes["btn-wrapper"]}>
              <button
                onClick={editPersonalInformationHandler}
                className={`btn ${classes["edit-avatar"]}`}
              >
                {/* <img className="w-100" src={editProfile} alt="edit avatar" /> */}
                Edit Profile
              </button>
            </div>

          </div>

          {/* <div className={classes.line}></div> */}


        </div>
      </div>
    </Card>
  );
};

export default DisplayPersonalInformation;
