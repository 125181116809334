import { useEffect } from "react";
import classes from "./ResendEmailVerification.module.scss";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import SimpleInput from "../../../components/UI/SimpleInput/SimpleInput";
import useHttp from "../../../hooks/use-http";
import { API_AUTH_URL } from "../../../constants/API";
import Loading from "../../../components/UI/Loading/Loading";
import { addToast } from "../../../redux/Toast/toastAction";
import { createRandomId } from "../../../utils/common";

const ResendEmailVerification = ({ onResendEmail }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { sendRequest, isLoading, error } = useHttp();
  const history = useHistory();
  const dispatch = useDispatch();

  const getVerificationResponse = (data) => {
    history.push("/authentication/done-registration");
  };

  const onSubmit = (submittedForm) => {
    onResendEmail(submittedForm.Email);
    const verificationRequest = {
      email: submittedForm.email,
    };
    sendRequest(
      {
        url: `${API_AUTH_URL}resend-verification/`,
        method: "POST",
        body: verificationRequest,
      },
      getVerificationResponse
    );
  };
  useEffect(() => {
    if (error) {
      let message = error.data.email ? error.data.email : error.data.detail;
      dispatch(
        addToast({
          id: createRandomId(),
          status: "error",
          description: message,
        })
      );
    }
  }, [dispatch, error]);
  return (
    <section className={`container ${classes.section}`}>
      <div className={`row justify-content-center`}>
        <div className={`col-12 ${classes.card}`}>
          <div className="row">
            <div className="col-xl-4 col-lg-5 col-12">
              <h2 className={classes["verifiaction-title"]}>
                Resend email verification
              </h2>
              <p className={classes["verification-desc"]}>
                It seems like you have already sign up, but your last
                verification was not successful.
                <br />
                Please enter your email address again, to get a new verification
                email.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <SimpleInput
                  validation={{
                    required: true,
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email",
                    },
                  }}
                  input={{
                    type: "text",
                    label: "Email",
                    name: "email",
                    placeholder : "Email",
                    id: "Email",
                    autoComplete: "off",
                  }}
                  errors={errors}
                  register={register}
                />
                <button className={`btn ${classes["submit"]}`} type="submit">
                  Submit
                </button>
              </form>
              <div className={classes["loading-box"]}>
                {isLoading && <Loading className={classes.loading} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResendEmailVerification;
