import { useState, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import classes from "./RecievedApplications.module.scss";
import useHttp from "../../../hooks/use-http";
import {
  updateUniqueApiParams,
  updateUniqueUrlParams,
  splitQueryParams,
  resetQueryParams,
} from "../../../utils/QueryParams";
import calculateOffset from "../../../utils/calculateOffset";
import { API_APPLICATION_URL } from "../../../constants/API";
import { getAccessToken } from "../../../utils/authentication";
import Pagination from "../../../components/UI/Pagination/Pagination";
import NotFoundItems from "../../../components/UI/NotFoundItems/NotFoundItems";
import Loading from "../../../components/UI/Loading/Loading";
import SentApplicationItem from "../../../components/SentApplicationItem/SentApplicationItem";
import DashboardAppFilterSort from "../../../components/DashboardAppFilterSort/DashboardAppFilterSort";
import DashboardSearchBox from "../../../components/DashboardSearchBox/DashboardSearchBox";
import DashboardSearchFilterBox from "../../../components/DashboardSearchFilterBox/DashboardSearchFilterBox";
import { LIMIT_ITEM } from "../../../constants/Common";

const RecievedApplications = () => {
  const [receviedApplicationList, setReceviedApplicationList] = useState([]);
  const [count, setCount] = useState(null);
  const [sortItem, setSortItem] = useState(null);
  const [searchItem, setSearchItem] = useState(null);
  const [sentAppStatus, setSentAppStatus] = useState(null);
  const [apiQueryParams, setApiQueryParams] = useState(`?limit=${LIMIT_ITEM}`);
  const [currentPage, setCurrentPage] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const { sendRequest, isLoading } = useHttp();

  const getSentApplicationList = (data) => {
    setReceviedApplicationList(data.results);
    setCount(data.count);
    window.scrollTo(0, 0);
  };

  const splitUrlQueryparams = useCallback(() => {
    const queryParams = window.location.search;
    const splitedQueryParams = splitQueryParams(queryParams);
    console.log(splitedQueryParams);
    setSentAppStates(splitedQueryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSentAppStates = (params) => {
    params.page ? setCurrentPage(parseInt(params.page)) : setCurrentPage(1);
    params.ordering ? setSortItem(params.ordering) : setSortItem(null);
    params.search ? setSearchItem(params.search) : setSearchItem(null);
    params.status ? setSentAppStatus(params.status) : setSentAppStatus(null);
    createOffset(params);
  };

  const createOffset = (params) => {
    let offset;
    // if page is not equal 1
    if (params.page) {
      offset = calculateOffset(parseInt(params.page), LIMIT_ITEM);
      delete params.page;
    } // page =1 (page load for first time)
    else {
      offset = calculateOffset(1, LIMIT_ITEM);
    }
    params["offset"] = offset;
    createApiQueryParams(params);
  };

  const createApiQueryParams = (params) => {
    let updatedQueryParams = apiQueryParams;
    for (let key in params) {
      updatedQueryParams = updateUniqueApiParams(
        `${key}`,
        params[key],
        updatedQueryParams
      );
    }
    setApiQueryParams(updatedQueryParams);
    fetchReceivedApplicationList(updatedQueryParams);
  };

  const fetchReceivedApplicationList = (updatedQueryParams) => {
    const accessToken = getAccessToken();
    setCount(null);
    sendRequest(
      {
        url: `${API_APPLICATION_URL}user-received-applications/${updatedQueryParams}`,
        headers: { Authorization: `Bearer ${accessToken}` },
      },
      getSentApplicationList
    );
  };

  const resetPage = () => {
    setCurrentPage(1);
    let offset = calculateOffset(1, LIMIT_ITEM);
    let updatedQueryParams = updateUniqueApiParams(
      "offset",
      offset,
      apiQueryParams
    );
    setApiQueryParams(updatedQueryParams);
    return updatedQueryParams;
  };

  const updateUniqueUrlPage = (result) => {
    history.push({
      pathname: "/dashboard/recieved-applications",
      search: result,
    });
  };

  const getSentAppListByUniqueFilters = (newkey, newValue) => {
    let updatedQueryParams;
    updatedQueryParams = resetPage();
    // choose new filter
    if (newValue) {
      updatedQueryParams = updateUniqueApiParams(
        newkey,
        newValue,
        updatedQueryParams
      );
      const urlParams = {
        page: 1,
        [newkey]: newValue,
      };
      let result = updateUniqueUrlParams(urlParams);
      updateUniqueUrlPage(result);
    } // choose All item and remove it from url and API
    else {
      // reset page number to 1---------------------------------------
      const urlParams = { page: 1 };
      let result = updateUniqueUrlParams(urlParams);
      result = resetQueryParams(newkey, result);
      // remover filter from params of page---------------------------
      updateUniqueUrlPage(result);
      // remove filter from params of api----------------------------
      updatedQueryParams = resetQueryParams(newkey, updatedQueryParams);
    }
    setApiQueryParams(updatedQueryParams);
  };

  const getSearchSentApp = (searchItem) => {
    getSentAppListByUniqueFilters("search", searchItem);
    setSearchItem(searchItem);
  };

  const getSentAppStatus = (status) => {
    getSentAppListByUniqueFilters("status", status);
    setSentAppStatus(status);
  };
  const getSortItem = (item) => {
    getSentAppListByUniqueFilters("ordering", item);
    setSortItem(item);
  };

  const getActivatedPage = (page) => {
    if (currentPage !== page) {
      setCurrentPage(page);
      let offset = calculateOffset(page, LIMIT_ITEM);
      let updatedQueryParams = updateUniqueApiParams(
        "offset",
        offset,
        apiQueryParams
      );
      setApiQueryParams(updatedQueryParams);
      const urlParams = { page };
      let result = updateUniqueUrlParams(urlParams);
      updateUniqueUrlPage(result);
    }
  };

  useEffect(() => {
    splitUrlQueryparams();
  }, [location, splitUrlQueryparams]);

  return (
    <div className={`container ${classes["recieved-application"]}`}>
      <div className={classes["profile-page-title"]}>
        Recieved Applications
			</div>	
      <DashboardSearchFilterBox
        searchItem={searchItem}
        onSearchItem={getSearchSentApp}
        sentAppStatus={sentAppStatus}
        dispatchSentAppStatus={getSentAppStatus}
      />
      <DashboardAppFilterSort
        sentAppStatus={sentAppStatus}
        sortItem={sortItem}
        dispatchSentAppStatus={getSentAppStatus}
        dispatchSortItem={getSortItem}
      />
      {receviedApplicationList.length !== 0 && !isLoading && (
        <div
          className={`row justify-content-lg-start justify-content-center  align-items-center`}
        >
          {receviedApplicationList.map((sentApp) => (
            <SentApplicationItem
              key={sentApp.id}
              sentApp={sentApp}
              link="/application-view/"
            />
          ))}
        </div>
      )}
      {isLoading || (receviedApplicationList.length === 0 && !isLoading) ? (
        <div
          className={`row justify-content-center  align-items-center ${classes["min-h"]}`}
        >
          {isLoading && <Loading className={classes.loading} />}
          {receviedApplicationList.length === 0 && !isLoading && (
            <NotFoundItems
              className={classes["not-found"]}
              item="application"
            />
          )}
        </div>
      ) : (
        ""
      )}
      {count > LIMIT_ITEM && (
        <Pagination
          onActivatedPage={getActivatedPage}
          count={count}
          limit={LIMIT_ITEM}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default RecievedApplications;
