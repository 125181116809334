import React, {Fragment} from 'react';
import classes from './CurrentPlanDetails.module.scss';
import basic from '../../assets/images/Icons/basic.svg';
import trial from '../../assets/images/Icons/trial.svg';
import emptyIcon from '../../assets/images/Icons/empty.svg';
import premium from '../../assets/images/Icons/premium.svg';
import matches from '../../assets/images/Icons/matches.svg';
import applications from '../../assets/images/Icons/applications.svg';
import notifications from '../../assets/images/Icons/notifications.svg';
import additional from '../../assets/images/Icons/additional.svg';
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

const CurrentPlanDetails = () => {
    const history = useHistory();
    const userSubscription = useSelector((state) => state.user.userSubscription);
    const userCompany = useSelector((state) => state.user.userCompany);
    const onclickHandler = () => {
        if (userCompany && userSubscription) {
            window.location.href = userCompany.stripe_portal_url
        } else {
            // history.push('/authentication/login')
            history.push('/authentication/login')

        }
    }
    const planIcons = {
        'Trial': trial,
        'Business': premium,
        'Innovator': basic,
        'Early Adaptor': basic,
        'Free':basic,
        'Internal':basic,
    }
    return (
        <div>
            <div className={classes['title-wrapper']}>
                <div className={classes['title']}>
                    Current Plan Details
                    <div className={classes['renew']}>
                        {
                            userSubscription &&
                            new Date(userSubscription.end).toLocaleDateString(
                                'en-US',
                                {
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric'
                                }
                            )
                        }
                    </div>
                </div>
                {
                    userSubscription &&
                    <div className={classes['change-plan']} onClick={onclickHandler}>
                        Change Plan
                    </div>
                }

            </div>
            <div className='row'>
                {
                    userSubscription ? (
                        <Fragment>
                            <div className='col-md-4 col-12 pt-4 d-flex align-middle'>
                                <div className={classes['current-plan']}>
                                    <img src={userSubscription && planIcons[userSubscription.solution.type_display]}
                                         alt='current'/>
                                    <div>{userSubscription && userSubscription.solution.type_display}</div>
                                </div>
                            </div>
                            <div className='col-md-4 col-6 '>
                                <div className={classes['info']}>
                                    <img src={matches} alt='matches'/>
                                    <div>{userSubscription && userSubscription.solution.solution_features[0]}</div>
                                </div>
                                <div className={classes['info-second']}>
                                    {
                                        userSubscription &&
                                        userSubscription.solution.type_display === 'Basic' ? <Fragment>
                                            <img src={additional} alt='additional'/>
                                            <div>
                                                {userSubscription.solution.solution_features[2]}
                                            </div>
                                        </Fragment> : ''

                                    }

                                </div>

                            </div>
                            <div className='col-md-4 col-6'>
                                <div className={classes['info']}>
                                    <img src={applications} alt='applications'/>
                                    <div>{userSubscription && userSubscription.solution.solution_features[1]}</div>
                                </div>
                                <div className={classes['info-second']}>
                                    <img src={notifications} alt='notifications'/>
                                    <div>{
                                        userSubscription ? (userSubscription.solution.type_display === 'Basic' ? userSubscription.solution.solution_features[3] : userSubscription.solution.solution_features[2]) : 'N/A'
                                    }</div>
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className='col-md-12 col-12 pt-4 d-flex align-middle justify-content-center'>
                                <img src={emptyIcon} alt='no plan'/>
                            </div>
                            <div className={`col-md-12 col-12 pt-4 d-flex align-middle justify-content-center ${classes.emptyTitle}`}>
                                You have not selected any plan
                            </div>
                            <div className='col-md-12 col-12 pt-4 d-flex align-middle justify-content-center'>
                                <button className={`btn ${classes.solutionBtn}`} onClick={onclickHandler}>Choose a plan</button>
                            </div>
                        </Fragment>
                    )
                }

            </div>
        </div>
    );
};

export default CurrentPlanDetails;