import React from 'react';
import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useAccordion } from "../../../hooks/use-accordion";
import SimpleCheckBoxFilterBox from "../SimpleCheckBoxFilterBox/SimpleCheckBoxFilterBox";
import { convertStringMemberToNumber } from "../../../utils/common";
import classes from './AccordionFilterSearchable.module.scss';
import removeFilter from '../../../assets/images/Icons/removeFilter.svg';
import searchIcon from '../../../assets/images/Icons/search-keyservice.svg';

const AccordionFilterSearchable = (props) => {
    const {
        className,
        filterName,
        filterList,
        initialFilters,
        dispatchFilterList,
    } = props;
    const [displayedItems, setDisplayedItems] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(0);
    const { register, handleSubmit, setValue, reset, getValues } = useForm();
    const [accordionRef, isOpen, setIsOpen] = useAccordion();

    useEffect(() => {
        setDisplayedItems(filterList);
    }, [filterList]);

    const setInitialFilters = useCallback(() => {
        if (initialFilters && initialFilters.length !== 0) {
            reset();
            setSelectedFilter(initialFilters.length);
            initialFilters.forEach((item) => {
                setValue(item, true);
            });
        } else {
            setSelectedFilter(initialFilters.length);
            reset();
        }
    }, [initialFilters, reset, setValue]);

    useEffect(() => {
        setInitialFilters();
    }, [initialFilters, setInitialFilters]);

    const resetFilters = (e) => {
        e.stopPropagation();
        reset();
        setSelectedFilter(0);
        dispatchFilterList([]);
    };

    const keyChangeHandler = (id) => {
        const value = getValues(id);
        value ? setValue(id, false) : setValue(id, true);
        value
            ? setSelectedFilter(selectedFilter - 1)
            : setSelectedFilter(selectedFilter + 1);
        // add remove item from list   
        let array = initialFilters;
        if (initialFilters.includes(id)) {
            array = array.filter(item => !(item == id));
        }
        else {
            array = array.filter(item => !(item == id));
            array.push('' + id);
        }
        dispatchFilterList(array);
    };

    const searchHandler = (e) => {
        let input = e.target.value.toLowerCase();
        let result = filterList.filter((element) =>
            element.title.toLowerCase().includes(input)
        );
        setDisplayedItems(result);
    };

    return (
        <div>
            <div ref={accordionRef} className={`dropdown ${classes.accordion}`}>
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className={`${classes["accordion-button"]}`}
                    type="button"
                    aria-expanded={isOpen}
                >
                    {selectedFilter === 0 ? (
                        <span>{filterName}</span>
                    ) : (
                        <div className={classes["has-selected"]}>
                            <div className={classes["filter-name"]}>{`${filterName} `}</div>
                            <div className={classes["selected-count"]}>
                                {`${selectedFilter}  selected!`}
                                <img
                                    onClick={(e) => { resetFilters(e) }}
                                    src={removeFilter}
                                />
                            </div>
                        </div>
                    )}
                </button>
                {isOpen && (
                    <div
                        id="filtersBox"
                        className={`${className} ${classes["accordion-list"]}`}
                    >
                        <form >
                            <div className={classes["search-box"]}>
                                <input
                                    className={classes["search-input"]}
                                    type="text"
                                    placeholder="search"
                                    onChange={(e) => searchHandler(e)}
                                />
                                <img src={searchIcon} />

                            </div>
                            <div dir="rtl" className={classes["accordion-items"]}>
                                <div dir="ltr" className=''>
                                    {displayedItems.map((item) => (
                                        <SimpleCheckBoxFilterBox
                                            key={item.id}
                                            onChangeHandler={keyChangeHandler}
                                            input={{
                                                name: `${item.id}`,
                                                label: `${item.title}`,
                                                id: item.id,
                                            }}
                                            register={register}
                                            isChecked={convertStringMemberToNumber(initialFilters).includes(item.id)}
                                        />
                                    ))}
                                </div>
                            </div>
                        </form>
                    </div>
                )}

            </div>
        </div>
    );
};

export default AccordionFilterSearchable;