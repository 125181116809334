import React, { useCallback, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Login from '../../pages/Authentication/Login/Login';

function AdminCompanyRoute(props) {
  const history = useHistory();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const userCompany = useSelector((state) => state.user.userCompany);
  return (
    <>
      {isLoggedIn && userCompany && userCompany.is_admin ? <Route {...props} />
        :
        history.push("/request") 
      }

    </>
  );

}

export default AdminCompanyRoute;