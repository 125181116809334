import { Link } from "react-router-dom";
import classes from "./CategoryItem.module.scss";
import Loading from "../../../../UI/Loading/Loading";

const CategoryItem = (props) => {
  const { categoriesList, onCategoryItem, loading, error } = props;
  return (!loading && !error) ? (
    <div className={`${classes["menu-wrp"]} d-n d-lg-block`}>
      <ul>
        {categoriesList.length > 0 &&
          categoriesList.map((category) => (
            <li
              className={`${classes["menu-item"]} p-0`}
              key={category.id}
              onMouseEnter={(e) => onCategoryItem(category.id, e)}
            >
              <Link
                className="p-0"
                to={`/request?page=1&service_categories=${category.id}`}
              >
                <div              
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                >
                  {category.title}
                </div>
              </Link>
            </li>
          ))}
      </ul>
    </div>
  ) : (!loading && error) ? <p className={classes.wait}>{error}</p> : (
    <Loading className={classes.loading} />
  );
};

export default CategoryItem;
