import { Fragment } from "react";
import ReactDOM from "react-dom";
import classes from "./ConfirmModal.module.scss";
import Card from "../Card/Card";
import Backdrop from "../Backdrop/Backdrop";
const ConfirmOverlay = ({
  modalButtonText,
  modalType,
  modalText,
  modalHeader,
  onConfirm,
}) => {
  return (
    <Card className={classes.modal}>
      <div
        style={{
          background:
            modalType === "warning"
              ? "#FFE6E6"
              : modalType === "success"
              ? "#EAFAF1"
              : "",
        }}
        className={classes["header-modal"]}
      >
        {modalType === "warning" && (
          <span className={classes.warning}>{modalHeader}</span>
        )}
        {modalType === "success" && (
          <span className={classes.success}>{modalHeader}</span>
        )}
      </div>
      <p className={classes["modal-message"]}>{modalText}</p>
      <div className={classes["buttons-box"]}>
      <button
          onClick={() => {
            onConfirm("cancel");
          }}
          className={`btn ${classes.cancel}`}
        >
          No
        </button>

        <button
          onClick={() => {
            onConfirm("confirm");
          }}
          style={{
            backgroundColor:
              modalType === "warning"
                ? "#E10000"
                : modalType === "success"
                ? "#1E4C90"
                : "",
          }}
          className={`btn ${classes.revoke}`}
        >
          {modalButtonText}
        </button>

        
      </div>
    </Card>
  );
};

const ConfirmModal = ({ modalButtonText, modalType, modalText,modalHeader, onConfirm }) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ConfirmOverlay
          modalButtonText={modalButtonText}
          modalType={modalType}
          modalText={modalText}
          modalHeader={modalHeader}
          onConfirm={onConfirm}
        />,
        document.getElementById("overlay-root")
      )}
    </Fragment>
  );
};

export default ConfirmModal;
