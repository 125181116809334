import React from 'react';
import classes from './DisplayCompanyInformation.module.scss';
import Card from '../UI/CardV2/CardV2';
import placeholder from "../../assets/images/placeholder.svg";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import useHttp from "../../hooks/use-http";
import { API_COMPANIES_URL } from "../../constants/API";
import { updateIsCompanyChanged } from "../../redux/Company/companyAction";
import { addToast } from "../../redux/Toast/toastAction";
import { createRandomId } from "../../utils/common";
import { updateIsProfileChange } from '../../redux/User/userAction';
import { getAccessToken } from "../../utils/authentication";
import ConfirmModal from '../UI/ConfirmModal/ConfirmModal';
import {useHistory} from "react-router-dom";
const DisplayCompanyInformation = ({
    userProfile,
    userCompany,
    userRole,
    editPersonalInformationHandler, }) => {
    const [isDisplayModal, setIsDisplayModal] = useState(false);
    const { sendRequest, error } = useHttp();
    const dispatch = useDispatch();
    const history = useHistory();

    const getRevokeCompanyRes = (res) => {
        console.log(res);
        dispatch(updateIsCompanyChanged(true));
        dispatch(updateIsProfileChange(true));
    };

    const confirmRevoke = (str) => {
        setIsDisplayModal(false);
        if (str === "confirm") {
            const token = getAccessToken();
            sendRequest(
                {
                    url: `${API_COMPANIES_URL}/company/revoke-company/`,
                    headers: { Authorization: `Bearer ${token}` },
                },
                getRevokeCompanyRes
            );
        }
    };

    useEffect(() => {
        if (error && error.status === 400) {
            console.log(error.data);
            dispatch(
                addToast({
                    id: createRandomId(),
                    status: "error",
                    description: error.data,
                })
            );
        }
    }, [dispatch, error]);
    return (

        <Card className={classes.card}>
            {isDisplayModal && (
                <ConfirmModal
                    modalButtonText="Revoke"
                    modalType="warning"
                    modalText="Do you confirm to revoke the Company?"
                    modalHeader="Revoke"
                    onConfirm={confirmRevoke}
                />
            )}
            <div className={classes.header}>
                <div className={classes.title}>
                    Company information
                </div>

            </div>
            <div className="d-md-flex flex-column d-grid justify-content-md-between align-items-center mt-4">
                {userCompany && userRole === "company" && (
                    <div className="d-flex flex-column align-items-center py-3">
                        <img
                            className={classes["company-logo"]}
                            src={userCompany.logo ? userCompany.logo : placeholder}
                            alt="company"
                        />
                        <div className={classes["company-name"]}>
                            <div className={classes["info-title"]}>
                                Company Name
                            </div>
                            <div className={classes["company-info"]}>
                                {userCompany.title}{" "}
                            </div>
                            <div className={`${classes["info-title"]} ${classes["department"]}`}>
                                Department
                            </div>
                            <div className={classes["company-info"]}>
                                {userCompany.title}{" "}
                            </div>
                        </div>
                    </div>
                )}

                {userRole === "guest" && userProfile.pending_company && (

                    <div className="d-flex flex-column align-items-center py-3">
                        <img
                            className={classes["company-logo"]}
                            src={
                                userProfile.pending_company.logo
                                    ? userProfile.pending_company.logo
                                    : placeholder
                            }
                            alt="company"
                        />
                        <div className={`${classes["company-name"]}  ${classes["pending"]}`}>
                            <div className={`${classes["info-title"]} `}>
                                Company Name
                            </div>
                            <div className={`${classes["company-info"]}`}>
                                {userProfile.pending_company.title}{" "}
                            </div>  
                            <div className={`${classes["info-title"]} ${classes["department"]}`}>
                                Status
                            </div>                          
                            <div className={`${classes["company-info"]} `}>
                                Pending
                            </div>  
                        </div>
                    </div>
                    // <div className="d-flex align-items-center">

                    //     <img
                    //         className={classes["company-logo"]}
                    //         src={
                    //             userProfile.pending_company.logo
                    //                 ? userProfile.pending_company.logo
                    //                 : placeholder
                    //         }
                    //         alt="company"
                    //     />
                    //     <div className={classes["company-name"]}>
                    //         {userProfile.pending_company.title}{" "}
                    //     </div>
                    //     <span className={classes["pending-txt"]}>Pending</span>
                    // </div>
                )}

                {userRole === "freelancer" && (
                    <span className={classes["freelancer-txt"]}>
                        You are an independent Freelancer and can manage your own
                        assets.You can create a new Company and transfer <br />
                        your assets to the new Company at any given time.
                        <br /> You can join another Company which will remove all your
                        Freelancer assets.
                    </span>
                )}
                {userRole === "guest" && !userProfile.pending_company && (
                    <span className={classes["freelancer-txt"]}>
                        You have no company aasigned. Please join a company, create your
                        own company or join as a freelancer.
                    </span>
                )}
                {(userRole === "guest" || userRole === "freelancer") &&
                    !userProfile.pending_company && (
                        <Link
                            className={classes["btn-edit"]}
                            to="/freelancer/user-role"
                        >
                            Join or Create Company
                        </Link>
                    )}

                {userRole === "guest" && userProfile.pending_company && (                    
                    <Link className={classes["btn-edit"]} to="/guest/user-role">Change</Link>                    
                )}

                {userRole === "company" && (
                    <div className={`${classes["revoke-company"]}`}>
                         <button
                            className={`btn ${classes["btn-edit"]} ${classes["btn-revoke"]}`}
                            onClick={() => {
                                setIsDisplayModal(true);
                            }}
                        >
                            Revoke from Company
                        </button>
                        <button
                            className={`btn ${classes["btn-detail"]}`}
                            onClick={() => { history.push(`/company/${userCompany.id}`)}}      
                            // onClick={() => { console.log('usercompany::',userCompany)}}                     
                        >
                             Company Detail
                        </button>
                    </div>
                )}
            </div>
        </Card>
    );
};

export default DisplayCompanyInformation;