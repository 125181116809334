import React from 'react';
import classes from './LegalDliclaimer.module.scss'

const LegalDliclaimer = () => {
    return (
        <div className={`${classes["main-background"]}`}>
         <div className={`container`}>
            <div className={`row ${classes["static-page"]}`}>
                <div className={`col-12`}>
                    <div className={classes.title}>
                        <h1>Legal DIiclaimer</h1>
                        <h3>www.pharmatching.com</h3>
                    </div>
                    <div className={classes.content}>
                        <div className={classes.section}>
                            <div className={classes.title}>
                                1. NO WARRANTY
                            </div>
                            <p className={classes.text}>
                                1.1 Please note that this is PHARMATCHING disclaimer of legal liability for the quality or reliability of the services.
                                <br />
                                1.2 PHARMATCHING makes no representation or warranty about the services, including any representation that the services will be uninterrupted or error-free and provide the services (including content and information) on “as is” and “as available” basis. To the fullest extent permitted under applicable law, we disclaim any implied or statutory warranty, including any implied or statutory warranty, including any implied warranty of title, accuracy of data, non-infringement, merchantability or fitness for a particular purpose.
                            </p>
                            <div className={classes.title}>
                            2. EXCLUSION OF LIABILITY
                            </div>
                            <p className={classes.text}>
                            2.1 Please note that these are the limits of legal liability PHARMATCHING may have to you.
                                <br />
                                2.2 To the fullest extent permitted by law (and unless we have entered into a separate written agreement that overrides this agreement), PHARMATCHING will not be liable in connection with this agreement for lost profits or lost business opportunities, reputation (e.g., offensive or defamatory statements) or loss of data.
                            </p>
                            <div className={classes.title}>
                            3. BASIS OF THE BARGAIN; EXCLUSIONS
                            </div>
                            <p className={classes.text}>
                                3.1 The Limitations of Liability contained herein are part of the basis of the bargain between you and PHARMATCHING and shall apply to all claims of liability (e.g., tort, contract, negligence and warranty) even if we have been told of the possibility of any such damage and even if these remedies fail their essential purpose.
                                <br />
                                3.2 These limitations of liability do not apply to liability for death or personal injury or for fraud, gross negligence or intentional misconduct or in cases of negligence where a material obligation has been breached, a material obligation being such which forms a prerequisite to the services offered on our platform and on which you may reasonably rely, but only to the extent that the damages were directly caused by the breach and were foreseeable upon conclusion of this Agreement.
                            </p>
                            <div className={classes.title}>
                            4. SEVERABILITY  
                            </div>
                            <p className={classes.text}>
                            In the event any provision or part of this Disclaimer is found to be invalid or unenforceable, only that particular provision or part so found, and not the entire Disclaimer, will be inoperative.
                            </p>
                            <div className={classes.title}>
                            5. ACCEPTANCE  
                            </div>
                            <p className={classes.text}>
                            5.1 Your access to and use of the Site is conditioned on your acceptance of and compliance with this Legal Disclaimer. This Legal Disclaimer applies to all visitors, users and others who access or use the Site. 
                            <br/>
                            5.2 By using our website and agreeing to this policy, you consent to our use of cookies in accordance with the terms given herein.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default LegalDliclaimer;