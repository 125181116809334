import { Fragment } from "react";
import classes from "./DetailPageView.module.scss";
import DetailPageViewCard from "../UI/DetailPageViewCard/DetailPageViewCard";

const DetailPageView = (props) => {
  const { pageViewItem, detailPageInfo } = props;

  return (
    <Fragment>
      <DetailPageViewCard
        backgroundTitle={classes["desc-bg"]}
        title="Description"
        desc={pageViewItem === "request" ? detailPageInfo.content : detailPageInfo.description}
        pageViewItem={pageViewItem}
      />
      <DetailPageViewCard
        backgroundTitle={classes["service-categories-title"]}
        backgroundItem={classes["service-categories-item"]}
        title="Categories"
        serviceCategories={detailPageInfo.ordered_service_categories_data}
        pageViewItem={pageViewItem}
      />
      <DetailPageViewCard
        backgroundTitle={classes["key-services-title"]}
        backgroundItem={classes["key-services-item"]}
        title="Key Services"
        keyServices={detailPageInfo.ordered_key_services_data}
        pageViewItem={pageViewItem}
      />
      {/* <DetailPageViewCard
        backgroundTitle={classes["specialities-title"]}
        backgroundItem={classes["specialities-item"]}
        title="Life Science Sector"
        specialities={detailPageInfo.specialities_data}
        pageViewItem = {pageViewItem}
      /> */}
      {!((pageViewItem === "company") && (detailPageInfo.company_type === 0)) && (
        <DetailPageViewCard
          backgroundTitle={classes["certificates-title"]}
          backgroundItem={classes["certificates-item"]}
          title={pageViewItem === "request" ? "Supporting Documents" : "Certificates / Information"}
          certificates={detailPageInfo.attachments}
        />
      )}

    </Fragment>
  );
};

export default DetailPageView;
