import classes from "./DisplayProfileInterests.module.scss";
import Card from "../UI/CardV2/CardV2";
import DisplaySelectedInterests from "../DisplaySelectedInterests/DisplaySelectedInterests";

const DisplayProfileInterests = ({
  userProfile,
  favouriteWarningLetter,
  onDisplayEditInterest,
}) => {
  return (
    <Card className={`container ${classes["intersting-card"]}`}>
      <div className={classes["intersting-title"]}>
        <span>Interestings</span>
      </div>
    
      <div className="col-12 min-100">
        <div className={classes["title-section"]}>
          Categories
        </div>
        {userProfile.interest && (
          <DisplaySelectedInterests
            bgColor="#7894BC"
            selectedItems={userProfile.interest.service_categories}
            color="#fff"
          />
        )}
      </div>
      <div className="col-12 min-100">
        <div className={classes["title-section"]}>
          KeyServices
        </div>
        {userProfile.interest && (
          <DisplaySelectedInterests
            bgColor="#D3F2FA"
            selectedItems={userProfile.interest.key_services}
            color="#1E4C90"
          />
        )}
      </div>
      <div className="col-12 min-100">
        <div className={classes["title-section"]}>
          Warning Letters
        </div>
        <h4 className={classes["warning-letter-title"]}>
          Stay up to date regarding the newest findings and Warning Letters
          around the globe.
        </h4>
        <div className={classes["selected-warning-letter"]}>
          {favouriteWarningLetter.map((item) => (
            <span key={item}>{item}</span>
          ))}
        </div>
      </div>
      <div className="col-12 text-end">
        <button
          onClick={() => {
            onDisplayEditInterest(true);
          }}
          className={`btn ${classes["add-remove"]}`}
        >
          Add or Remove
        </button>
      </div>
    </Card>
  );
};

export default DisplayProfileInterests;
