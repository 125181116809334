import {Fragment, useEffect} from "react";
import {useSelector} from "react-redux";
import classes from "./RequestViewHeader.module.scss";
import CardV2 from "../UI/CardV2/CardV2";
import defaultLogo from "../../assets/images/placeholder.svg";
import NoData from "../UI/NoData/NoData";
import {REQUEST_STATUS} from "../../constants/RequestStatus";
import {dateFormmater} from "../../utils/common";
import {Link} from "react-router-dom";
import ExtendDueDtae from "../ExtendDueDate/ExtendDueDtae";

const RequestViewHeader = (props) => {
    const {
        requestInfo,
        applicationStatus,
        onConfirmDelete,
        isApplicationView,
        isRequestApplication,
        isSentApplication,
        onSendApplicationHandler,
        userSubscription,
        applicationSender,
        isCreateApplication,
        activeFlag,
        isApplicationOwner
    } = props;
    const {userCompany, isLoggedIn} = useSelector((state) => state.user);
    const status = REQUEST_STATUS.find(
        (element) => element.id === requestInfo.status
    );
    useEffect(() => {
        console.log("request info: ", requestInfo);
    }, [])
    return (
        <div className={`${classes["wrapper"]}`}>
            <div className={"container flex-grow position-relative"}>
                <div className="row">
                    <div className="col-lg-7 col-12">
                        <div className={classes["request-status"]}>
                            <div className={classes["status-badge"]} style={{
                                color: status.color,
                                backgroundColor: status.backgroundColor
                            }}> {status.cardTitle}</div>
                            <div className={classes["request-title"]}>{requestInfo.title}</div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-12">
                        {isCreateApplication && (
                            <Fragment>
                                <div className={classes.buttons}>
                                    <button
                                        onClick={() => onSendApplicationHandler("create application")}
                                        className={`${classes["send-button"]} btn`}
                                        disabled={activeFlag}
                                    >
                                        Send Application
                                    </button>

                                    {userSubscription && userSubscription.is_active && (
                                        <span className={classes["app-remain"]}>
                                            {userSubscription.remaining_applications > 100 && (
                                                <span> You Have Unlimited Applications</span>
                                            )}
                                            {userSubscription.remaining_applications <= 100 && (
                                                <span>
                                                    <strong>{userSubscription.remaining_applications}</strong>{" "}
                                                    Application(s) Remains
                                                </span>
                                            )}
                                        </span>
                                    )}
                                </div>
                            </Fragment>
                        )}
                        {isSentApplication && (
                            <Fragment>
                                <div className={classes.buttons}>
                                    <Link
                                        to={`/request/${requestInfo.id}`}
                                        className={`${classes["show-request-button"]} btn`}
                                    >
                                        Show Request Detail
                                    </Link>
                                </div>
                                {applicationStatus === "waiting" && isApplicationOwner && (
                                    <div className="text-center">
                                        <button
                                            onClick={onConfirmDelete}
                                            className={`${classes["delete-app-button"]} btn`}
                                        >
                                            Delete Application
                                        </button>
                                    </div>
                                )}
                            </Fragment>
                        )}
                        {/* {isRequestApplication && (
                            <Fragment>
                                <div className={classes["recieved-app"]}>
                                    {requestInfo.received_applications_count} Applications recieved
                                </div>
                                <div className={classes.buttons}>
                                    <Link
                                        to={`/request/${requestInfo.id}`}
                                        className={`${classes["show-request-button"]} btn`}
                                    >
                                        View Details
                                    </Link>
                                </div>
                                {(requestInfo.status === 3 || requestInfo.status === 4) && (
                                    <div className="text-center">
                                        <button
                                            onClick={onConfirmDelete}
                                            className={`${classes["delete-app-button"]} btn`}
                                        >
                                            Close Request
                                        </button>
                                    </div>
                                )}
                                {userCompany && !userCompany.is_admin && (
                                    <p className={classes["request-message"]}>
                                        Great! This Request belongs to another User of your Company. Soon
                                        the best Service Provider will be identified by your colleagues
                                    </p>
                                )}
                            </Fragment>
                        )} */}

                        {/* {(isApplicationView ||
                            (userCompany &&
                                userCompany.id === requestInfo.company &&
                                !isRequestApplication)) && (
                                <Fragment>
                                    <div className={classes.buttons}>
                                        <div className={classes["recieved-app"]}>
                                            {requestInfo.received_applications_count} Applications recieved
                                        </div>
                                        <Link
                                            to={`/request-applications/${requestInfo.id}`}
                                            className={`${classes["show-request-button"]} btn`}
                                        >
                                            Received Applications
                                        </Link>
                                    </div>
                                    {(requestInfo.status === 3 || requestInfo.status === 4) && (
                                        <div className="text-center">
                                            <button
                                                onClick={onConfirmDelete}
                                                className={`${classes["delete-app-button"]} btn`}
                                            >
                                                Close Request
                                            </button>
                                        </div>
                                    )}                                 
                                </Fragment>
                            )} */}

                        {(requestInfo.status === 3 || requestInfo.status === 4) && !isLoggedIn && !isCreateApplication && (
                            <div className={classes.buttons}>
                                <button
                                    onClick={() => onSendApplicationHandler("no login")}
                                    className={`${classes["send-button"]} btn`}
                                >
                                    Send Application
                                </button>
                            </div>
                        )}
                        {(requestInfo.status === 3 || requestInfo.status === 4) && !isCreateApplication &&
                            isLoggedIn &&
                            !userCompany && (
                                <div className={classes.buttons}>
                                    <button
                                        onClick={() => onSendApplicationHandler("no company")}
                                        className={`${classes["send-button"]} btn`}
                                    >
                                        Send Application
                                    </button>
                                </div>
                            )}

                        {(requestInfo.status === 3 || requestInfo.status === 4) && !isCreateApplication &&
                            isLoggedIn &&
                            userCompany &&
                            userCompany.id !== requestInfo.company &&
                            userCompany.id !== applicationSender &&
                            requestInfo.can_user_apply &&
                            (
                                <div className={classes.buttons}>
                                    {userSubscription && userSubscription.is_active && (
                                        <span className={classes["app-remain"]}>
                                            {(userSubscription.remaining_applications > 100) && (
                                                <span> You Have Unlimited Applications</span>
                                            )}
                                            {(userSubscription.remaining_applications <= 100) && (
                                                <span>
                                                    <strong>{userSubscription.remaining_applications}</strong>{" "}
                                                    Application(s) Remains
                                                </span>
                                            )}                                            
                                        </span>
                                    )}
                                    <button
                                        onClick={() => onSendApplicationHandler("has company")}
                                        className={`${classes["send-button"]} btn`}
                                    >
                                        Send Application
                                    </button>
                                </div>
                            )}
                        {userCompany && requestInfo && userCompany.id === requestInfo.company && (
                            <div className={classes.buttons}>
                                 <span className={classes["views"]}>
                                           <span>
                                                    <strong>{requestInfo.views}</strong><br/>
                                               Total Views
                                                </span>
                                        </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default RequestViewHeader;
