import { useState, useCallback, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import classes from "./MyRequestApplications.module.scss";
import RequesViewSummary from "../../components/RequestViewSummary/RequstViewSummary";
import useHttp from "../../hooks/use-http";
import { API_REQUEST_URL, API_APPLICATION_URL } from "../../constants/API";
import { getAccessToken } from "../../utils/authentication";
import Loading from "../../components/UI/Loading/Loading";
import ReceivedApplicationItem from "../../components/ReceivedApplicationItem/ReceivedApplicationItem";
import NotFoundItems from "../../components/UI/NotFoundItems/NotFoundItems";
import CloseRequestModal from "../../components/UI/CloseRequestModal/CloseRequestModal";

const MyRequestApplications = () => {
  const [requestInfo, setRequestInfo] = useState(null);
  const [receviedApplicationList, setReceviedApplicationList] = useState([]);
  const [isDisplayModal, setIsDisplayModal] = useState(null);
  const [isNotFound, setIsNotFound] = useState(false);
  const { sendRequest, isLoading } = useHttp();
  const { id } = useParams();
  const history = useHistory();
  //------------------------------------------------------------------------------------------
  const getRequestInfo = (data) => {
    console.log(data);
    setRequestInfo(data);
    window.scrollTo(0, 0);
  };

  const fetchRequestInfo = useCallback(() => {
    const token = getAccessToken();
    sendRequest({ 
      url: `${API_REQUEST_URL}${id}`,
      headers: { Authorization: `Bearer ${token}` },
    }, getRequestInfo);
  }, [id, sendRequest]);
  //------------------------------------------------------------------------------------------

  const getReceivedApplicationList = (data) => {
    console.log(data);
    data.length === 0 && setIsNotFound(true);
    setReceviedApplicationList(data);
  };

  const fetchReceivedApplicationList = useCallback(() => {
    const token = getAccessToken();
    sendRequest(
      {
        url: `${API_APPLICATION_URL}?request=${id}`,
        headers: { Authorization: `Bearer ${token}` },
      },
      getReceivedApplicationList
    );
  }, [id, sendRequest]);
  //------------------------------------------------------------------------------------------

  const confirmDeleteRequest = () => {
    setIsDisplayModal(true);
  };
  //------------------------------------------------------------------------------------------

  const getClosedRequestRes = (data) => {
    console.log(data);
    history.push("/dashboard/sent-requests");
  };

  const closeRequest = (arg) => {
    setIsDisplayModal(false);
    const closeReq = {
      close_reason: parseInt(arg.data),
      status: 5,
    };
    if (arg.type === "confirm") {
      const token = getAccessToken();
      sendRequest(
        {
          url: `${API_REQUEST_URL}${id}`,
          body: closeReq,
          headers: { Authorization: `Bearer ${token}` },
          method: "PATCH",
        },
        getClosedRequestRes
      );
    }
  };
  //------------------------------------------------------------------------------------------

  useEffect(() => {
    fetchRequestInfo();
    fetchReceivedApplicationList();
    return () => {
      setRequestInfo(null);
    };
  }, [fetchReceivedApplicationList, fetchRequestInfo]);
  //------------------------------------------------------------------------------------------
  return (
    <section
      className={`container  flex-grow position-relative ${classes.section}  ${classes["min-h"]}`}
    >
      {isDisplayModal && <CloseRequestModal onConfirm={closeRequest} />}
      {isLoading && <Loading className={classes.loading} />}
      {requestInfo && !isLoading && (
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-5 col-md-10 col-12">
            <RequesViewSummary
              requestInfo={requestInfo}
              onConfirmDelete={confirmDeleteRequest}
              isRequestApplication={true}
            />
          </div>
          <div className="col-xl-8 col-lg-7 col-md-10 col-12">
            <div className={classes["page-title"]}>
              Recieved Applications
            </div>
            {receviedApplicationList.map((receivedItem) => (
              <ReceivedApplicationItem
                key={receivedItem.id}
                receivedItem={receivedItem}
              />
            ))}
            {isNotFound && (
              <NotFoundItems
                className={classes["not-found"]}
                item=" Application"
              />
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default MyRequestApplications;
