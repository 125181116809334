import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import DetailPageView from "../../components/DetailPageView/DetailPageView";
import CompanyViewSummary from "../../components/CompanyViewSummary/CompanyViewSummary";
import classes from "./CompanyView.module.scss";
import useHttp from "../../hooks/use-http";
import { API_COMPANIES_URL} from "../../constants/API";
import Loading from "../../components/UI/Loading/Loading";
import { Fragment } from "react";
import CompanyInformation from "../../components/CompanyInformation/CompanyInformation";
import CompanyRequestList from "../../components/CompanyRequestList/CompanyRequestList";


const CompanyView = () => {
  const [comapnyInfo, setCompanyInfo] = useState(null);
 
  
  const { sendRequest, error, isLoading } = useHttp();
  const { id } = useParams();

  const getCompanyInfo = (data) => {
    console.log("xompany Info:",data);
    setCompanyInfo(data);
    window.scrollTo(0, 0);
  };
  

  const fetchCompaniesInfo = useCallback(() => {
    sendRequest(
      { url: `${API_COMPANIES_URL}/company/${id}` },
      getCompanyInfo
    );
  }, [id, sendRequest]);

  
  useEffect(() => {
    fetchCompaniesInfo();
  }, [fetchCompaniesInfo ]);

  return (
    <Fragment>
      <div className={classes.header}>
      </div>
      <section
        className={`container  flex-grow position-relative ${classes.section}  ${classes["min-h"]}`}
      >
        {isLoading && <Loading className={classes.loading} />}
        {comapnyInfo && !isLoading && (
          <Fragment>
            <div className="row justify-content-center">
              <div className="col-12">
                <CompanyViewSummary comapnyInfo={comapnyInfo} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 col-12">
                <DetailPageView pageViewItem="company" detailPageInfo={comapnyInfo} />
              </div>
              <div className="col-md-4 col-12">
                <CompanyInformation companyInfo={comapnyInfo} />
              </div>
            </div>
            <div className={classes.line}></div>
            <div className="row">
              <div className="col-12">
                <CompanyRequestList
                  companyName={comapnyInfo.title}
                />
              </div>
            </div>
          </Fragment>
        )}
      </section>
    </Fragment>
  );
};

export default CompanyView;
