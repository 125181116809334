import React, { useCallback, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";


function GuestRoute(props) {
  const isLogin = useSelector((state) => state.user.isLoggedIn);
  const history = useHistory();
  const profile = useSelector((state) => state.user.userProfile);
  const userRole = useSelector((state) => state.user.userRole);
  const userCompany = useSelector((state)  => state.user.userCompany);
  useEffect(() => {

    // if (userRole === "guest" && isLogin && profile && profile.interest.service_categories.length === 0) {
    if (userRole === "guest" && isLogin && profile === null) {
      history.push("/guest/create-profile");
    } else if (userRole === "guest" && isLogin) {
      // user filled profile page but has not selected his role yet
      history.push("/guest/user-role");
    } // user doesnt have company and login for first time(doesnt have any interest)
    else if ((userRole === "company") && isLogin && !userCompany.is_admin) {      
      history.push("/dashboard/company-dashboard");
    }
    else if ((userRole === "company") && isLogin) {      
      history.push("/dashboard/company-dashboard");
    }
    else if ((userRole === "freelancer") && isLogin) {
      history.push("/request?ordering=-created_at");
    }
    // else if (!isLogin){
    //   history.push("/authentication/login");
    // }
    }, [userRole, history, isLogin, profile]);
 
  // return isLogin
  //   ? <Redirect to="/" />
  //   : <Route {...props} />;
  return (
    
    <Route {...props} />
  );
}

export default GuestRoute;