import {Link} from "react-router-dom";
import classes from "./BlogItem.module.scss";
import Card from "../UI/CardV2/CardV2";
import pharmaNewsLogo from "../../assets/images/pharmatching-news.png";
import {dateFormmater} from "../../utils/common";

const BlogItem = (props) => {
    const {blog, className, isSlider} = props;
    const time = blog.publish_date || blog.created_at
    return (
        <div className={className}>
            <Link to={`/blogs/${blog.id}`}>
                <Card
                    className={`row justify-content-lg-start ${isSlider ? classes.card_slider : classes.card}`}>
                    <div
                        className={`col-lg-9 col-md-9 col-9 ${isSlider ? classes["blog-details-slider"] : classes["blog-details"]}`}>
                        <div className={classes.title}>
                            <span>{blog.title}</span>
                        </div>
                        <p className={classes.decription}>
                            {blog.description}
                        </p>
                        <div className={classes["blog-header-box"]}>
                            <div
                                className={classes["blog-date"]}>{dateFormmater(time)}</div>
                        </div>
                    </div>
                    <div
                        className={`col-lg-3 col-md-3 col-3 ${isSlider ? classes["blog-box-slider"] : classes["blog-box"]}`}>
                        <div style={{backgroundImage: `url(${blog.image ? blog.image : pharmaNewsLogo})`}}
                             className={isSlider ? classes["blog-image-slider"] : classes["blog-image"]}></div>
                    </div>
                </Card>
            </Link>

        </div>
    );
};

export default BlogItem;
