import React, {Fragment, useCallback, useEffect, useState} from 'react';
import classes from './PaymentAccount.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {getAccessToken} from "../../utils/authentication";
import {API_PAYMENT_URL} from "../../constants/API";
import useHttp from "../../hooks/use-http";
import {addToast} from "../../redux/Toast/toastAction";
import {createRandomId} from "../../utils/common";
import {useHistory} from "react-router-dom";

const PaymentAccount = () => {
    const history = useHistory();
    const [paymentAccounts, setPaymentAccounts] = useState([]);
    const [selectedPaymentAccount, setSelectedPaymentAccount] = useState('');
    const {sendRequest, error} = useHttp();
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const dispatch = useDispatch();
    const userCompany = useSelector((state) => state.user.userCompany);
    const getSelectedPaymentAccount = (data) => {
        if (data) {
            setSelectedPaymentAccount(data.id);
        }
    };
    const onclickHandler = () => {
        if (userCompany) {
            window.location.href = userCompany.stripe_portal_url
        } else {
            // history.push('/subscription')
        }
    }
    const handleRadioChange = useCallback((event) => {
        if (isLoggedIn) {
            const selectedValue = event.target.value;
            const token = getAccessToken();
            sendRequest({
                url: `${API_PAYMENT_URL}payment-account/${selectedValue}/activate/`,
                headers: {Authorization: `Bearer ${token}`},
                method: "POST",
            }, getSelectedPaymentAccount);
        }

    }, [sendRequest, isLoggedIn]);

    const getPaymentAccount = (data) => {
        if (data) {
            setPaymentAccounts(data);
            data.map((pm) => pm.is_active ? setSelectedPaymentAccount(pm.id) : null)
        }
    };
    const fetchPaymentAccount = useCallback(() => {
        if (isLoggedIn) {
            const token = getAccessToken();
            sendRequest({
                url: `${API_PAYMENT_URL}payment-account/`,
                headers: {Authorization: `Bearer ${token}`},
            }, getPaymentAccount);
        }

    }, [sendRequest, isLoggedIn]);


    useEffect(() => {
        fetchPaymentAccount();
    }, [fetchPaymentAccount]);


    useEffect(() => {
    }, [userCompany, selectedPaymentAccount, paymentAccounts]);

    useEffect(() => {
        if (error) {
            if (error.status === 404 || error.status === 400) {
                dispatch(
                    addToast({
                        id: createRandomId(),
                        status: "error",
                        description: error.data.detail,
                    })
                );
            } else if (error.status === 500) {
                dispatch(
                    addToast({
                        id: createRandomId(),
                        status: "error",
                        description: "Unexpected Error occurred. Please try again.",
                    })
                );

            }

        }
    }, [dispatch, error]);

    return (
        <div>
            <div className={classes['title']}>
                Payment Methods Information
            </div>
            <div className={classes['edit-bill']} onClick={onclickHandler}>
                Edit Payment Method
            </div>
            {
                paymentAccounts.map((pm) => (
                    <Fragment key={pm.id}>
                        <div className={classes['bill-info']}>
                            <div className={classes['title-wrapper']}>
                                <label className={classes['title']}>
                                    <input
                                        type="radio"
                                        value={pm.id}
                                        className={classes["radio-button"]}
                                        checked={pm.id === selectedPaymentAccount}
                                        onChange={handleRadioChange}
                                    />
                                    {pm.account_type}
                                </label>
                            </div>

                            <div className={classes['info-wrapper']}>
                                {
                                    pm.account_type === 'card' ? (
                                        <Fragment>
                                            <div className={classes.key}>Title</div>
                                            <div
                                                className={classes.val}>{Object.keys(pm.account_type_data).length !== 0 ? `${pm.account_type_data.brand} **** **** **** ${pm.account_type_data.last4}` : 'N/A'}
                                            </div>
                                        </Fragment>

                                    ) : (
                                        <Fragment>
                                            <div className={classes.key}>Invoices are sent to the following email at the
                                                end of each month.
                                            </div>
                                        </Fragment>

                                    )

                                }

                            </div>
                            <div className={classes['info-wrapper']}>
                                {
                                    pm.account_type === 'card' ? (
                                        <Fragment>
                                            <div className={classes.key}>Expiration date</div>
                                            <div
                                                className={classes.val}>Expires {Object.keys(pm.account_type_data).length !== 0 ? `${pm.account_type_data.exp_month}/${pm.account_type_data.exp_year}` : 'N/A'}</div>
                                        </Fragment>

                                    ) : (
                                        <Fragment>
                                            <div className={classes.key}>Email</div>
                                            <div
                                                className={classes.val}>{userCompany ? userCompany.owner_email : 'N/A'}</div>
                                        </Fragment>

                                    )

                                }

                            </div>
                        </div>
                    </Fragment>

                ))
            }

        </div>

    );
};

export default PaymentAccount;