import React, { Fragment, useRef, useState, useEffect } from "react";
import classes from "./AttachFileInput.module.scss";
import attachFiles from "../../../assets/images/Icons/attach-files.svg";
import deleteIcon from "../../../assets/images/Icons/delete.svg";
import removeIcon from "../../../assets/images/Icons/remove-item.svg";
import { FileUploader } from "react-drag-drop-files";

const AttachFileInput = ({
  validation,
  input,
  register,
  errors,
  setValue,
  getValues,
  previewdValue,
  placeHolderText,
}) => {
  const ref = useRef();
  const [fileName, setFileName] = useState('');
  const [isUploaded, setIsUploaded] = useState(false);
  const [isExceed, setIsExceed] = useState(false);
  const fileTypes = ["pdf"];
  const uploadAttachFiles = (e) => {
    let uploadedFileSize = e.target.files[0].size;
    console.log(e.target.files[0].size);
    if (uploadedFileSize < 20971520) {
      setValue(input.name, e.target.files[0]);
      setFileName(e.target.files[0].name);
      setIsUploaded(true);
      setIsExceed(false);
    } else {
      setIsExceed(true);
      setIsUploaded(false)
    }
  };

  const deleteSelectedFile = () => {
    ref.current.value = "";
    setValue(input.name, '');
    setFileName('');
    setIsUploaded(false);
    setIsExceed(false);
  };



  const [myFile, setMyFile] = useState('');
  const handleChangeFile = (myFile) => {
    setMyFile(myFile);
  };
  useEffect(() => {
    console.log("myFile:", myFile)
    if (typeof previewdValue !== "object") {
      setFileName(previewdValue)
    }
  }, [previewdValue, myFile])

  return (
    <Fragment>
      {/* <FileUploader
          multiple={true}
          handleChange={handleChangeFile}
          name="my-file"
          types={fileTypes}
        /> */}
      <input
        accept="application/pdf"
        {...input}
        {...register(input.name, validation)}
        ref={ref}
        onChange={($event) => uploadAttachFiles($event)}
        hidden
      />
      {(!isUploaded && !fileName) && (
        <button
          className={`${classes["upload-btn"]}`}
          type="button"
          onClick={() => ref.current.click()}
        >
          <img
            className={classes.picture}
            src={attachFiles}
            alt="attach files"
          />
          <span>Attach Files</span>
          <div className={classes["attach-description"]}>            
            {placeHolderText}
          </div>
        </button>
      )}
      {errors[input.name] && !getValues(input.name) && !isExceed && (
        <span className={classes["single-input-error"]}>This is required</span>
      )}
      {isExceed && (
        <span className={classes["single-input-error"]}>
          The file should not exceed 20MB
        </span>
      )}

      {(isUploaded || fileName) && (
        <div className={classes["uploaded-file"]}>
          <span>Certificates:</span>
          <div className={classes["uploaded-file-item"]}>
            <span className={classes["uploaded-file-name"]}>{fileName}</span>
            <div onClick={deleteSelectedFile} className={classes["delete-box"]}>
              <img src={removeIcon} alt="delete" />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AttachFileInput;
