import axios from "axios";
import { API_BASE_URL } from "../constants/API";
import { getRefreshToken, setAuthorization,removeAuthorization } from "../utils/authentication";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let isRefreshing = false;
let failedQueue = [];

// create a queue of faild APIs
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
//--------------------------------------------------------------------------------------------------

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    // Access Token was expired
    if (err.response.status === 403 && !originalConfig._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalConfig.headers["Authorization"] = "Bearer " + token;
            return axiosInstance(originalConfig);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalConfig._retry = true;
      isRefreshing = true;
      return new Promise(function (resolve, reject) {
        axiosInstance
          .post("/api/v1/auth/token/refresh/", {
            refresh: getRefreshToken(),
          })
          .then(({ data }) => {
            setAuthorization(data);
            axiosInstance.defaults.headers.common["Authorization"] =
              "Bearer " + data.access;
            originalConfig.headers["Authorization"] = "Bearer " + data.access;
            processQueue(null, data.access);
            resolve(axiosInstance(originalConfig));
          })
          .catch((err) => {
            // if refresh token is expired , clean localstorage and redirect to home page
            processQueue(err, null);
            reject(err);
            removeAuthorization();
            window.location.href = window.location.origin;
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);

export default axiosInstance;
