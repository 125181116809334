import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import SimpleInput from "../../../components/UI/SimpleInput/SimpleInput";
import PasswordInputCheckList from "../../../components/UI/PasswordInputCheckList/PasswordInputCheckList";
import useHttp from "../../../hooks/use-http";
import { API_AUTH_URL } from "../../../constants/API";

import { createRandomId } from "../../../utils/common";
import { addToast } from "../../../redux/Toast/toastAction";
import ReCAPTCHA from "react-google-recaptcha";

import classes from "./Register.module.scss";

const Register = ({ onResendEmail }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const { sendRequest, isLoading, error } = useHttp();
	const history = useHistory();
	const dispatch = useDispatch();
	const [hasReCaptcha, setHasRecaptcha] = useState(false);
	const [showReCaptchaAlert, setShowReCaptchaAlert] = useState(false);
	const [reCaptchaToken, setReCaptchaToken] = useState('');
	const getAuthorizationTokens = (data) => {
		onResendEmail(data.email);
		history.push("/authentication/done-registration");
	};
	function onCaptchaChange(value) {
		if (value != null) {
			setReCaptchaToken(value);
			setHasRecaptcha(true);
		}
		else {
			setReCaptchaToken('');
			setHasRecaptcha(false);
		}
	}

	const onSubmit = (submittedForm) => {
		if (!hasReCaptcha) {
			dispatch(
				addToast({
					id: createRandomId(),
					status: "error",
					description: "Please solve the reCaptcha",
				})
			);
			return false;
		}
		const registerRequest = {
			name: submittedForm.name,
			sure_name: submittedForm.surename,
			email: submittedForm.email,
			password: submittedForm.password,
			confirm_password: submittedForm.password,
			captcha_token: reCaptchaToken,
		};
		sendRequest(
			{
				url: `${API_AUTH_URL}register/`,
				method: "POST",
				body: registerRequest,
			},
			getAuthorizationTokens
		);
	};
	useEffect(() => {
		if (error) {
			let message = error.data.email
				? error.data.email
				: error.data.password
					? error.data.password
					: "Something went wrong! please try again";
			dispatch(
				addToast({
					id: createRandomId(),
					status: "error",
					description: message,
				})
			);
		}
	}, [dispatch, error]);
	return (
		<section className={classes["section-container"]}>
			<div className={`${classes["form-container"]} container mx-3 mx-lg-0 my-3 my-md-5`}>
				<h1 className={classes['title']}>Register</h1>
				<form onSubmit={handleSubmit(onSubmit)}>
					<SimpleInput
						validation={{
							required: true,
						}}
						input={{
							type: "text",
							label: "First name",
							name: "name",
							placeholder: "First name",
							id: "name",
							autoComplete: "off",
						}}
						errors={errors}
						register={register}
					/>
					<SimpleInput
						validation={{
							required: true,
						}}
						input={{
							type: "text",
							label: "Surname",
							name: "surename",
							placeholder: "Surname",
							id: "surename",
							autoComplete: "off",
						}}
						errors={errors}
						register={register}
					/>
					<SimpleInput
						validation={{
							required: true,
							pattern: {
								value:
									/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: "Please enter a valid email",
							},
						}}
						input={{
							type: "text",
							label: "Email",
							name: "email",
							placeholder: "Email",
							id: "Email",
							autoComplete: "off",
						}}
						errors={errors}
						register={register}
					/>
					<PasswordInputCheckList
						validation={{
							required: true,
							pattern: {
								value:
									/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={[}\]|\\:;"',.?/])[A-Za-z\d@}{;(\[\])|+"$!%*#?&_,/\[\]'.:^=]{8,}$/,
								message: "Please enter a valid password",
							},
						}}
						input={{
							type: "password",
							label: "Password",
							name: "password",
							placeholder: "Password",
							id: "setPassword",
							autoComplete: "new-password",
							hasappend: "true"
						}}
						errors={errors}
						register={register}
					/>
					{/* <reCAPTCHA
						sitekey={process.env.REACT_APP_SITE_KEY}
					/> */}
					<div className={classes.recaptcha}>
						<ReCAPTCHA
							sitekey='6LfwNlcnAAAAALx96eQLrj4urm3WENXtZ7qB6LDx'
							onChange={onCaptchaChange}
						/>
					</div>
					<button
						className={`btn ${classes["submit"]}`}
						type="submit"
					>
						Register
					</button>
					<div className={classes['desc']}>
						<span>Already have an account?</span>
						<Link to='/authentication/login'>Login</Link>
					</div>
				</form>
			</div>
		</section>
	);
};
export default Register;
