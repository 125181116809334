export const REQUEST_STATUS = [
  // { id: 1, 
  //   title: "Draft", 
  //   cardTitle: "DRAFT", 
  //   color: "#007bff" ,
  //   backgroundColor: '#EBFAF1',
  // },
  {
    id: 2,
    title: "Approval Pending",
    cardTitle: "APPROVAL PENDING",
    color: "#FFB800",
    backgroundColor: '#EBFAF1',
  },
  { id: 3, 
    title: "Open", 
    cardTitle: "OPEN", 
    color: "#04622A", 
    backgroundColor: '#EBFAF1',
  },
  {
    id: 4,
    title: "Open (Extended)",
    cardTitle: "OPEN (EXTENDED)",
    color: "#2FCF6F",
    backgroundColor: '#EBFAF1',
  },
  { id: 5, 
    title: "Closed", 
    cardTitle: "CLOSED", 
    color: "#E10000", 
    backgroundColor: '#FFE6E6',
  },
    
  // {
  //   id: 6,
  //   title: "Deleted by Support",
  //   cardTitle: "DELETED BY SUPPORT",
  //   color: "#E10000",
  //   backgroundColor: '#FFE6E6',
  // },
  { id: 7, 
    title: "Expired", 
    cardTitle: "EXPIRED", 
    color: "#E10000", 
    backgroundColor: '#FFE6E6',
  },
  {
    id: 8,
    title: "Successful",
    cardTitle: "Successful",
    color: "#1E4C90",
    backgroundColor: '#21bce733'
  },
];
