import React from 'react';
import classes from "./AboutUs.module.scss";

const AboutUs = () => {
    return (
        <div className={`${classes["main-background"]}`}>
            <div className={`container`}>
                <div className={`row ${classes["static-page"]}`}>
                    <div className={`col-12`}>
                        <div className={classes.title}>
                            <h1>About Us</h1>
                            <h3>www.pharmatching.com</h3>
                        </div>
                        <div className={classes.content}>
                            <div className={classes.section}>
                                <div className={classes.title} style={{ 'font-size': '20px', 'margin-bottom': '32px' }}>
                                    Pharmatching is the Outsourcing Specialist for the Pharmaceutical and Life-Science Industry worldwide.
                            </div>
                                <p className={classes.text}>
                                    You might not find what you need on Google or on any other sources - but you will find it on <span>Pharmatching.com!</span>
                                </p>
                                <div className={classes.title}>
                                    What is <span>Pharmatching.com?</span>
                                </div>
                                <p className={classes.text}>
                                    Behind Pharmatching is a young agile tech and consulting Company that has the will and power to change things for the better. This platform will be your favorite search engine for the complex world of Life-Science, Pharma and Biotech.
                            </p>
                                <div className={classes.title}>
                                    The benefits of using Pharmatching?
                            </div>
                                <p className={classes.text}>
                                    We offer a collaboration tool for procurement and outsourcing experts in Life-Science around the world with the following benefits:<br />
                                    <ul>
                                        <li> This Database contains information, which could never be found via regular web search</li>

                                        <li>      Identifying the contacts for the best matching needs</li>

                                        <li>     No wasting of time by conventional internet searches</li>

                                        <li>     The database of thousands of profiles of companies offering Life Science, Pharma and Biotech services</li>

                                        <li>     Millions of specific keywords allowing full text search for any keyword</li>

                                        <li>     Extensive search & filter functions making the search comfortable</li>
                                    </ul>
                                </p>
                                <p className={classes.text}>
                                    Result: selection of companies including niche providers delivering the service of interest B2B collaboration in Pharma needs to use innovative and up-to- date collaboration tools!
                            </p>
                                <p className={classes.text}>
                                    <span>Pharmatching.com</span> provides a way for procurement and outsourcing experts in Pharma around the world to identify their contacts, best matching their service needs without worrying wasting time in conventional search engines, to collaborate with each other, and importantly, to start the business processes significantly quicker than with conventional ways.
                            </p>
                                <p className={classes.text}>
                                    All industries nowadays utilize external service providers to whom services are outsourced. Either locally or globally. Particularly in Pharma, the business value in outsourcing is increasing significantly. “Increasing R&D costs and continued pressure on Pharma companies’ operating margins have encouraged the industry to outsource their non-core operations and rationalise their fixed cost base”.
                            </p>
                                <div className={classes.title}>
                                    How the process was before:
                            </div>
                                <p className={classes.text}>
                                    For both sides, the service buyer’s and the service provider’s side, the procurement process is demanding. The buyer needs to identify the right provider and to perform a due diligence process to understand whether their capabilities are matching their demands. All existing tools and the internet search do not provide adequate sources for this as it is extremely time consuming to identify the right service provider, as the selection identified is incomplete and as it is very difficult to figure out the real experience and capabilities of a service provider not yet known.
                            </p>
                                <p className={classes.text}>
                                    On the service provider’s side the efforts to be installed regularly in order to be present in a way to be found are tremendous and the typically used pathway being internet search does not allow to identify small and mid-size companies and niche providers. They are simply not found in the internet. Whereas the potential of the fast and collaborative web 2.0 tools commonly used in all areas of our life the processes used in B2B outsourcing seem to stem from last century. They are so old fashioned that they cannot support effective outsourcing. They even block it or keep it constantly slow. It is time to disrupt these outdated processes to the benefit of patients as novel medicines have to be developed more efficiently and thus made available quicker.
                            </p>
                                <div className={classes.title}>
                                    Quote of our CEO Alireza Zarei:
                            </div>
                                <p className={classes.text}>
                                    „We need to disrupt these out-dated processes to the benefit of patients.“
                            </p>
                                <div className={classes.title}>
                                    How it works now:
                               </div>
                                <p className={classes.text}>
                                    Pharmatching’s solution is to offer a collaboration network for B2B processes where outsourcing and offering of services play a central role that would help them collaborate by making it easier to become aware of capabilities of service providers by providing a platform through which they can get direct contact to those they need. Compared to the current system, where valuable time is spent in search using insufficient tools and search engines not providing valuable output of satisfactory results Pharmatching makes a real difference.
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AboutUs;