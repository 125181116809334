import { useCallback, useState } from "react";
import axiosInstance from "./use-interceptor";

const useHttp = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const sendRequest = useCallback(async (requestConfig, getData) => {
    setIsLoading(true);
    setError(null);
    axiosInstance
      .request({
        url: requestConfig.url,
        method: requestConfig.method ? requestConfig.method : "GET",
        headers: requestConfig.headers ? requestConfig.headers : {},
        data: requestConfig.body ? requestConfig.body : null,
      })
      .then((response) => {
        getData(response.data);
        setIsLoading(false);
      })
      .catch((catchedError) => {
        setError(catchedError.response)
        setIsLoading(false);
      });
   
  }, []);
  return {
    sendRequest,
    error,
    isLoading,
  };
};

export default useHttp;

