import classes from "./SingleTextArea.module.scss";

const SingleTextArea = ({ validation, input, errors, register }) => {  
  return (
    <div className={classes["single-input-box"]}>
      <textarea
        style={{ borderColor: errors[input.name] ? "#E10000" : "" }}
        className={classes["single-input"]}
        {...input}
        {...register(input.name, validation)}
      />

      <label
        style={{ color: errors[input.name] ? "#E10000" : "" }}
        htmlFor={input.name}
        className={classes["single-input-label"]}
      >{input.label}{" "}</label>
      {errors[input.name] && errors[input.name].type === "required" && (
        <span className={classes["single-input-error"]}>This is required</span>
      )}
      {errors[input.name] && errors[input.name].message && (
        <span className={classes["single-input-error"]}>
          {" "}
          {errors[input.name].message}
        </span>
      )}
    </div>
  );
};

export default SingleTextArea;
