import classes from "./NotFoundItems.module.scss";
import notFoundImg from "../../../assets/images/notFound.svg";

const NotFoundItems = (props) => {
  const { className,item } = props;
  return (
    <div className={`${className} col-12 text-center`}>
      <img src={notFoundImg} alt="not found" />
      <span className={classes.item}>There is no</span>
      <span className={`fw-bold  ${classes.item}`}>{item}</span>
    </div>
  );
};

export default NotFoundItems;
