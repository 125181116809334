import { useCallback, useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import classes from "./EditProfileInterests.module.scss";
import Card from "../UI/CardV2/CardV2";
import SearchableDropdownFilter from "../UI/SearchableDropdownFilter/SearchableDropdownFilter";
import useHttp from "../../hooks/use-http";
import { API_TAGS_URL, API_USERS_URL, API_NEWS_URL } from "../../constants/API";
import SelectUnSelectInterests from "../SelectUnSelectInterests/SelectUnSelectInterests";
import { getAccessToken } from "../../utils/authentication";
import SolidCheckBox from "../UI/SolidCheckBox/SolidCheckBox";
import { useForm } from "react-hook-form";
import { updateIsProfileChange } from "../../redux/User/userAction";
import Loading from "../UI/Loading/Loading";
import { WARNING_LETTER_LIST } from "../../constants/WarningLetterList";
import {WARNING_LETTER_OBJECT} from "../../constants/WarningLetterList"
import { useProfileInterests } from "../../hooks/use-profile-Interests";

const EditProfileInterests = ({
  componentUsage,
  initialServiceCategories,
  initialKeyServices,
  favouriteWarningLetter,
  backButtonHandler,
}) => {
  const { sendRequest, isLoading } = useHttp();
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, setValue, getValues } = useForm();
  const [serviceCategories, setServiceCategories] = useState([]);
  const {
    handleServiceCategories,
    handleKeyServices,
    removeSelectedCategories,
    removeSelectedKeyServices,
    filterOnServiceCategories,
    setInitialSelectedItems,
    isDiabled,
    selectedCategoriesId,
    selectedKeyServicesId,
    selectedKeyServices,
    selectedCategories,
    keyServices,
  } = useProfileInterests(serviceCategories);

  // Get service-categories and keyServices------------------------------------------------------------
  const getServiceCategories = (serviceCategories) => {
    setServiceCategories(serviceCategories);
    if (componentUsage === "createProfile") {
      filterOnServiceCategories(serviceCategories, serviceCategories);
    }
    if (componentUsage === "editProfile") {
      filterOnServiceCategories(serviceCategories, initialServiceCategories);
    }

  };

  const fetchCategories = useCallback(() => {
    sendRequest(
      {
        url: `${API_TAGS_URL}/service-categories/`,
      },
      getServiceCategories
    );
  }, [sendRequest]);
  // set intial favorite warning letter-----------------------------------------------------------------
  const setFavWarningLetter = useCallback(
    (favWarningLetter) => {
      favWarningLetter.forEach((item) => {
        setValue(item, true);
      });
    },
    [setValue]
  );

  // fetch categories list-----------------------------------------------------------------------------
  useEffect(() => {
    fetchCategories();
    return () => {
      setServiceCategories([]);
    };
  }, [fetchCategories]);

  // set initial items in edit profile----------------------------------------------------------------
  useEffect(() => {
    if (componentUsage === "editProfile") {
      setInitialSelectedItems(initialServiceCategories, initialKeyServices);
      setFavWarningLetter(favouriteWarningLetter);
    }
  }, [
    componentUsage,
    favouriteWarningLetter,
    initialKeyServices,
    initialServiceCategories,
    setFavWarningLetter,
    setInitialSelectedItems,
  ]);

  // set initial all items in create profile page---------------------------------------------------
  useEffect(() => {
    if (componentUsage === "createProfile") {
      // find all key servies from categories items
      let ks = [];
      serviceCategories.forEach((item) => {
        ks = [...ks, ...item.key_services];
      });
      // remove duplicated key service from ks array
      const ids = ks.map(o => o.id)
      const filtered = ks.filter(({ id }, index) => !ids.includes(id, index + 1))

      setInitialSelectedItems(serviceCategories, filtered);
      setFavWarningLetter(favouriteWarningLetter);
    }
  }, [
    serviceCategories,
    componentUsage,
    setInitialSelectedItems,
    favouriteWarningLetter,
    setFavWarningLetter,
  ]);
  //--------------------------------------------------------------------------------------------------
  const getSelectedFavaoriteWarningLetter = () => {
    const warningLetter = getValues();
    let selectedItems = [];
    for (const property in warningLetter) {
      if (warningLetter[property]) {
        selectedItems.push(`${property}`);
      }
    }
    return selectedItems;
  };
  //--------------------------------------------------------------------------------------------------
  const getUpdatedInterest = (data) => {
    dispatch(updateIsProfileChange(true));
    if (componentUsage === "editProfile") {
      backButtonHandler();
    }
    if (componentUsage === "createProfile") {
      history.push("/guest/user-role");
    }
  };

  const getFavouriteWarningLetter = (fav) => {
    console.log(fav, "fav");
  };

  //--------------------------------------------------------------------------------------------------
  const saveChangesHandler = () => {
    const token = getAccessToken();
    const interestRequest = {
      key_services: selectedKeyServicesId,
      service_categories: selectedCategoriesId,
    };
    const favoritewarningLetterRequest = {
      sources: getSelectedFavaoriteWarningLetter(),
    };
    //console.log("my favorit wl: ",favoritewarningLetterRequest);
    sendRequest(
      {
        url: `${API_USERS_URL}interests/`,
        method: "PUT",
        body: interestRequest,
        headers: { Authorization: `Bearer ${token}` },
      },
      getUpdatedInterest
    );
    sendRequest(
      {
        url: `${API_NEWS_URL}fav_wl/`,
        method: "POST",
        body: favoritewarningLetterRequest,
        headers: { Authorization: `Bearer ${token}` },
      },
      getFavouriteWarningLetter
    );
  };
  //--------------------------------------------------------------------------------------------------
  return (
    <Fragment>
      <Card className={`container ${classes["intersting-card"]}`}>
        <div className={classes["intersting-title"]}>
          <span>Interestings</span>
        </div>
        <div style={{ marginBottom: "10px" }} className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <SearchableDropdownFilter
              className={classes["left-0px"]}
              filterName="Categories"
              initialFilters={selectedCategoriesId}
              filterList={serviceCategories}
              dispatchFilterList={handleServiceCategories}
              isSelectedItem={true}
              isDisabled={false}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <SearchableDropdownFilter
              className={classes["right-0px"]}
              filterName="Key Services"
              initialFilters={selectedKeyServicesId}
              filterList={keyServices}
              dispatchFilterList={handleKeyServices}
              isSelectedItem={true}
              isDisabled={isDiabled}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 min-100">
            <div className={classes["title-border"]}>
              <span className={classes["categories-title"]}>Categories</span>
            </div>
            <SelectUnSelectInterests
              bgColor="#7894BC"
              selectedItems={selectedCategories}
              removeSelectedItem={removeSelectedCategories}
              color="#fff"
            />
          </div>
          <div className="col-12 min-100">
            <div className={classes["title-border"]}>
              <span className={classes["categories-title"]}>KeyServices</span>
            </div>
            <SelectUnSelectInterests
              bgColor="#E4F8FB"
              selectedItems={selectedKeyServices}
              removeSelectedItem={removeSelectedKeyServices}
              color="#1E4C90"
            />
          </div>

        </div>
      </Card>
      <Card className={`container ${classes["warning-card"]}`}>
        <div className={classes["warning-title"]}>
          <span>Warning Letters</span>
        </div>
        <div className="row">
          <div className="col-12">            
            <h4 className={classes["warning-letter-title"]}>
              Stay up to date regarding the newest findings and Warning Letters
              around the globe.
            </h4>
            <form>              
                {WARNING_LETTER_OBJECT.map((item) => (
                <SolidCheckBox
                  key={item.val}
                  input={{
                    name: item.val,
                    label: item.name,
                  }}
                  register={register}
                />
              ))}
              
            </form>
          </div>

        </div>

      </Card>
      {componentUsage === "editProfile" && (
        <div className="d-flex justify-content-sm-end justify-content-between">
          <button
            onClick={backButtonHandler}
            className={`btn ${classes.back} ${classes.button}`}
            type="button"
          >
            Discard Changes
          </button>
          <div>
            <button
              onClick={saveChangesHandler}
              className={`btn ${classes["save-changes"]} ${classes.button}`}
              type="submit"
            >
              Save
            </button>
            <div className={classes["loading-box"]}>
              {isLoading && <Loading className={classes.loading} />}
            </div>
          </div>
        </div>
      )}
      {componentUsage === "createProfile" && (
        <div className="col-12 text-sm-end text-center">
          <Link to="/guest/user-role" className={`btn ${classes["skip-btn"]}`}>
            Skip now
          </Link>
          <button
            onClick={saveChangesHandler}
            className={`btn ${classes["save-btn"]}`}
          >
            Save and continue
          </button>
          
        </div>
      )}
    </Fragment>
  );
};

export default EditProfileInterests;
