import classes from "./HomeLetterCard.module.scss";
import Card from "../UI/Card/Card";
import FDA from "../../assets/images/warning-letters/FDA.svg";
import UK from "../../assets/images/warning-letters/UK.svg";
import fourEightThree from "../../assets/images/warning-letters/483.svg";
import { dateFormmater } from "../../utils/common";

const HomeLetterCard = ({ warningLetter, type }) => {
  let letterImage = type === "FDA" ? FDA : type === "UK" ? UK : fourEightThree;
  return (
    <a href={warningLetter.url} target="_blank" rel="noreferrer">
      <Card className={classes.card}>
        <div className="row">
          <div className={classes["header"]}>
            <div
              style={{ backgroundImage: `url(${letterImage})` }}
              className={`${classes["letter-image"]}`}
            ></div>
            <div className={`${classes["letter-title-container"]}  d-grid`}>
              <h4 className={classes["letter-title"]}>{warningLetter.subject}</h4>

            </div>
          </div>

          <div
            className={`d-flex justify-content-between align-items-end ${classes["letter-info"]}`}
          >
            <span className={classes["letter-source"]}>
              {warningLetter.company_name}
            </span>
            <span className={classes["letter-date"]}>
              {warningLetter.issued_at && dateFormmater(warningLetter.issued_at)}
            </span>
          </div>
        </div>
      </Card>
    </a>
  );
};

export default HomeLetterCard;
