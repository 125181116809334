import { useState, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import classes from "./RequestList.module.scss";
import RequestFilterBox from "../../components/RequestFilterBox/RequestFilterBox";
import RequestItem from "../../components/RequestItem/RequestItem";
import RequestItemV2 from "../../components/RequestItem/RequestItemV2";
import Pagination from "../../components/UI/Pagination/Pagination";
import calculateOffset from "../../utils/calculateOffset";
import {
  updateUniqueApiParams,
  updateUniqueUrlParams,
  splitQueryParams,
  updateDuplicatedApiParams,
  resetQueryParams,
  updateDuplicatedUrlParams,
} from "../../utils/QueryParams";
import { API_REQUEST_URL } from "../../constants/API";
import useHttp from "../../hooks/use-http";
import Loading from "../../components/UI/Loading/Loading";
import NotFoundItems from "../../components/UI/NotFoundItems/NotFoundItems";
import SortBox from "../../components/UI/SortBox/SortBox";
import { LIMIT_ITEM } from '../../constants/Common';
import NotLoginAlert from '../../components/UI/NotLoginAlert/NotLoginAlert';
import SimpleFormGroup from '../../components/UI/SimpleFormGroup/SimpleFormGroup';

const RequestList = () => {
  const [requestsList, setRequestsList] = useState([]);
  const [count, setCount] = useState(null);
  const [sortItem, setSortItem] = useState(null);
  const [searchItem, setSearchItem] = useState(null);
  const [requestStatusId, setRequestStatusId] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [requestSizeId, setRequestSizeId] = useState(null);
  const [specialities, setSpecialities] = useState([]);
  const [serviceCategories, setServicesCategories] = useState([]);
  const [keyServices, setKeyServices] = useState([]);
  const [apiQueryParams, setApiQueryParams] = useState(`?limit=${LIMIT_ITEM}`);
  const [currentPage, setCurrentPage] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const { sendRequest, isLoading } = useHttp();

  const getRequestList = (data) => {
    // console.log(data, 'data')
    setRequestsList(data.results);
    setCount(data.count);
    window.scrollTo(0, 0);
  };

  const splitUrlQueryparams = useCallback(() => {
    const queryParams = window.location.search;
    const splitedQueryParams = splitQueryParams(queryParams);
    setRequestStates(splitedQueryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setRequestStates = (params) => {
    params.page ? setCurrentPage(parseInt(params.page)) : setCurrentPage(1);
    params.ordering ? setSortItem(params.ordering) : setSortItem(null);
    params.search ? setSearchItem(params.search) : setSearchItem(null);
    params.company_id ? setCompanyId(params.company_id) : setCompanyId(null);
    params.request_size
      ? setRequestSizeId(params.request_size)
      : setRequestSizeId(null);
    params.multi_status
      ? setRequestStatusId(params.multi_status)
      : setRequestStatusId([]);
    params.specialities
      ? setSpecialities(params.specialities)
      : setSpecialities([]);
    params.service_categories
      ? setServicesCategories(params.service_categories)
      : setServicesCategories([]);
    params.key_services
      ? setKeyServices(params.key_services)
      : setKeyServices([]);
    createOffset(params);
  };
  const resetFilter = () => {
    setRequestStatusId([]);
    setServicesCategories([]);
    setKeyServices([]);
    updateUniqueUrlPage('');

  };

  const createOffset = (params) => {
    let offset;
    // if page is not equal 1
    if (params.page) {
      offset = calculateOffset(parseInt(params.page), LIMIT_ITEM);
      delete params.page;
    } // page =1 (page load for first time)
    else {
      offset = calculateOffset(1, LIMIT_ITEM);
    }
    params["offset"] = offset;
    createApiQueryParams(params);
  };

  const createApiQueryParams = (params) => {
    let updatedQueryParams = apiQueryParams;
    for (let key in params) {
      if (
        key === "specialities" ||
        key === "service_categories" ||
        key === "key_services"
      ) {
        for (let index = 0; index < params[key].length; index++) {
          updatedQueryParams = updateDuplicatedApiParams(
            key,
            params[key][index],
            updatedQueryParams
          );
        }
      } else if (key === "multi_status") {
        let statusList = "";
        for (let index = 0; index < params[key].length; index++) {
          statusList += params[key][index] + ",";
        }
        // console.log('statusList: ', statusList);
        updatedQueryParams = updateUniqueApiParams(
          `${key}`,
          statusList.slice(0, -1),
          updatedQueryParams
        );

      } else {
        updatedQueryParams = updateUniqueApiParams(
          `${key}`,
          params[key],
          updatedQueryParams
        );
      }
    }
    setApiQueryParams(updatedQueryParams);
    fetchRequestList(updatedQueryParams);
  };

  const updateUniqueUrlPage = (result) => {
    history.push({
      pathname: "/request",
      search: result,
    });
  };

  const updateDuplicatedUrlPage = (result) => {
    history.push({
      pathname: "/request",
      search: result,
    });
  };

  const fetchRequestList = (updatedQueryParams) => {
    setCount(null);
    sendRequest(
      { url: `${API_REQUEST_URL}${updatedQueryParams}` },
      getRequestList
    );
  };

  const getActivatedPage = (page) => {
    if (currentPage !== page) {
      setCurrentPage(page);
      let offset = calculateOffset(page, LIMIT_ITEM);
      let updatedQueryParams = updateUniqueApiParams(
        "offset",
        offset,
        apiQueryParams
      );
      setApiQueryParams(updatedQueryParams);
      const urlParams = { page };
      let result = updateUniqueUrlParams(urlParams);
      updateUniqueUrlPage(result);
    }
  };

  const resetPage = () => {
    setCurrentPage(1);
    let offset = calculateOffset(1, LIMIT_ITEM);
    let updatedQueryParams = updateUniqueApiParams(
      "offset",
      offset,
      apiQueryParams
    );
    setApiQueryParams(updatedQueryParams);
    return updatedQueryParams;
  };

  const getRequsetListByUniqueFilters = (newkey, newValue) => {
    let updatedQueryParams;
    updatedQueryParams = resetPage();
    // choose new filter
    if (newValue) {
      updatedQueryParams = updateUniqueApiParams(
        newkey,
        newValue,
        updatedQueryParams
      );
      const urlParams = {
        page: 1,
        [newkey]: newValue,
      };
      let result = updateUniqueUrlParams(urlParams);
      updateUniqueUrlPage(result);
    } // choose All item and remove it from url and API
    else {
      // reset page number to 1---------------------------------------
      const urlParams = { page: 1 };
      let result = updateUniqueUrlParams(urlParams);
      result = resetQueryParams(newkey, result);
      // remover filter from params of page---------------------------
      updateUniqueUrlPage(result);
      // remove filter from params of api----------------------------
      updatedQueryParams = resetQueryParams(newkey, updatedQueryParams);
    }
    setApiQueryParams(updatedQueryParams);
  };

  const getRequestListByDuplicatedFilters = (newkey, list) => {
    // update url of API-------------------------------------------
    let updatedQueryParams = resetPage();
    updatedQueryParams = resetQueryParams(newkey, updatedQueryParams);
    list.forEach((element) => {
      updatedQueryParams = updateDuplicatedApiParams(
        newkey,
        element,
        updatedQueryParams
      );
    });
    setApiQueryParams(updatedQueryParams);
    // update url of page----------------------------------------------
    const urlParams = {
      page: [1],
      [newkey]: list,
    };
    let result = updateDuplicatedUrlParams(urlParams);
    updateDuplicatedUrlPage(result);
  };
  const getSearchRequest = (searchItem) => {
    getRequsetListByUniqueFilters("search", searchItem);
    setSearchItem(searchItem);
  };
  const getRequestStatusId = (status) => {
    //  console.log('status: ', status);
    getRequestListByDuplicatedFilters("multi_status", status);
    setRequestStatusId(status);
  };

  const getServiceCategories = (serviceCategories) => {
    getRequestListByDuplicatedFilters("service_categories", serviceCategories);
    setServicesCategories(serviceCategories);
  };
  const getKeyServices = (keyServices) => {
    getRequestListByDuplicatedFilters("key_services", keyServices);
    setKeyServices(keyServices);
  };

  useEffect(() => {
    splitUrlQueryparams();
  }, [location, splitUrlQueryparams]);

  return (
    <section className={`container  flex-grow ${classes.section}`}>
      {/* <NotLoginAlert /> */}
      {/* <SortBox
        count={count}
        sortItem={sortItem}
        dispatchSortItem={getSortItem}
      /> */}
      <div
        className={`row justify-content-lg-start justify-content-center  `}
      >
        <div className={`col-xl-3 col-12 `}>
          <RequestFilterBox
            requestStatusId={requestStatusId}
            initialServiceCategories={serviceCategories}
            initialKeyServices={keyServices}
            onRequestStatusId={getRequestStatusId}
            onServiceCategories={getServiceCategories}
            onKeyServices={getKeyServices}
            onResetFilter={resetFilter}
          />
        </div>
        <div className={`col-xl-9 col-12 mt-3 mt-xl-0`}>
          <div className={classes["search-wrapper"]}>
            <SimpleFormGroup
              label="Search Requests"
              inputValue={searchItem}
              dispatchInputValue={getSearchRequest}
            />
          </div>
          {requestsList.length !== 0 && !isLoading && (
            <div className={`row justify-content-lg-start justify-content-center px-2 align-items-center ${classes['min-h']}`}>
              {requestsList.map((requestItem) => (
                <div key={requestItem.id} className="col-lg-4 col-md-6 col-11 ps-1">
                  <RequestItemV2  requestItem={requestItem} />
                </div>
              ))}
              {isLoading || (requestsList.length === 0 && !isLoading) ? (
                <div
                  className={`row justify-content-center  align-items-center `}
                >
                  {isLoading && <Loading className={classes.loading} />}
                  {requestsList.length === 0 && !isLoading && (
                    <NotFoundItems className={classes["not-found"]} item="request" />
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          {count > LIMIT_ITEM && (
            <Pagination
              onActivatedPage={getActivatedPage}
              count={count}
              limit={LIMIT_ITEM}
              currentPage={currentPage}
              countLabel="Requests"
            />
          )}
        </div>

      </div>



    </section>
  );
};

export default RequestList;
