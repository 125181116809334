import classes from "./SimpleCheckBox.module.scss";

const SimpleCheckBox = ({ onChangeHandler, input, register }) => {
  return (
    <div
      onClick={() => onChangeHandler(`${input.id}`)}
      className={classes["form-group"]}
    >
      <div className={classes['label-wrapper']}>
      <input type="checkbox" id={input.id} {...register(input.name)} />      
        <label></label>
        <div>{input.label}</div>
      </div>
    </div>
  );
};

export default SimpleCheckBox;
