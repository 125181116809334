import { useEffect, useState, useCallback } from "react";
import classes from "./JoinCompany.module.scss";
import { useForm } from "react-hook-form";
import SimpleInput from "../../../components/UI/SimpleInput/SimpleInput";
import backArrow from "../../../assets/images/arrow/back-arrow.svg";
import { Link, useHistory } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import { API_COMPANIES_URL } from "../../../constants/API";
import DebouncedSearchInput from "../../../components/DebouncedSearchInput/DebouncedSearchInput";
import { getAccessToken } from "../../../utils/authentication";
import { useDispatch } from "react-redux";
import { addToast } from "../../../redux/Toast/toastAction";
import { createRandomId } from "../../../utils/common";
import Loading from "../../../components/UI/Loading/Loading";
import { updateIsProfileChange } from '../../../redux/User/userAction';
import joinCompany from '../../../assets/images/Icons/join-company-icon.svg';

const JoinCompany = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [searchItem, setSearchItem] = useState("");
  const [isDisplay, setIsDisplay] = useState(false);
  const [isSubmited, setIsSubmitted] = useState(false);
  const [searchedCompanies, setSearchedCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const { sendRequest, error, isLoading } = useHttp();
  const dispatch = useDispatch();
  const history = useHistory();


  const onSubmit = (submittedForm) => {
    console.log(submittedForm);
    console.log("selectedCompanyId", selectedCompanyId);
    const token = getAccessToken();
    setIsSubmitted(true);
    const joinCompanyRequest = {
      department: submittedForm.department,
    };
    sendRequest(
      {
        url: `${API_COMPANIES_URL}/company/${selectedCompanyId}/access/`,
        method: "POST",
        body: joinCompanyRequest,
        headers: { Authorization: `Bearer ${token}` },
      },

      fetchJoinCompanyRes
    );
  };

  const searchInCompaniesList = (txt) => {
    setSearchItem(txt);
  };

  const fetchJoinCompanyRes = (res) => {
    console.log(res);
    dispatch(updateIsProfileChange(true));
    dispatch(
      addToast({
        id: createRandomId(),
        status: "success",
        description: "Your request has been sent.",
      })
    );
    history.push("/");
  };

  const getCompaniesList = (list) => {
    setSearchedCompanies(list);
    setIsDisplay(true);
    console.log(list);
  };

  const fetchSearchedCompanyList = useCallback(() => {
    if (searchItem.length > 2) {
      sendRequest(
        { url: `${API_COMPANIES_URL}/company/?search=${searchItem}` },
        getCompaniesList
      );
    } else {
      setIsDisplay(false);
    }
  }, [setIsDisplay, searchItem, sendRequest]);

  const selectCompanyHandler = (comapny) => {
    console.log(comapny);
    setValue("companyName", comapny.title);
    setSelectedCompanyId(comapny.id);
    setIsDisplay(false);
  };

  // debounce in search company name
  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("searchItem", searchItem);
      fetchSearchedCompanyList();
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [fetchSearchedCompanyList, searchItem]);

  // user is searching in companies and remove previous selectedCompany
  useEffect(() => {
    if (isDisplay) {
      setSelectedCompanyId(null);
    }
  }, [isDisplay]);

  useEffect(() => {
    if (error) {
      setIsSubmitted(false);
      // console.log(error)
      dispatch(
        addToast({
          id: createRandomId(),
          status: "error",
          description: error.data["0"],
        })
      );
    }

  }, [dispatch, error]);

  return (
    <section className={`${classes.section}`}>
      <div className="container">
        <div className={`row justify-content-center`}>
          <div className={`col-xl-9  col-12 ${classes.card}`}>
            <div className="row justify-content-center">
              <div className="col-lg-5 col-10 text-center">
                <img src={joinCompany} alt='join icon' />
                <div className={classes.title}>
                  Join as a company member
                </div>
                <form
                  className={`${classes["join-form"]}`}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div>
                    <DebouncedSearchInput
                      input={{
                        type: "text",
                        label: "Company Name",
                        name: "companyName",
                        placeholder: "company Name",
                        id: "companyName",
                        autoComplete: "off",
                      }}
                      onDebouncedSearch={searchInCompaniesList}
                      isDisplay={isDisplay}
                      isLoading={isLoading}
                      isSubmited={isSubmited}
                      register={register}
                      resultList={searchedCompanies}
                      selectItemHandler={selectCompanyHandler}
                    />
                  </div>
                  <div >
                    <SimpleInput
                      input={{
                        type: "text",
                        label: "Department",
                        name: "department",
                        placeholder: "Department",
                        id: "Department",
                        autoComplete: "off",
                      }}
                      errors={errors}
                      register={register}
                    />
                  </div>
                  <div >
                    <button
                      disabled={!selectedCompanyId}
                      className={`btn ${classes["apply"]}`}
                      type="submit"
                    >
                      Join to company
                    </button>
                    {(isLoading && isSubmited) && <Loading className={classes.loading} />}
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinCompany;
