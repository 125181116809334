import { useEffect } from "react";
import classes from "./ForgetPassword.module.scss";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import useHttp from "../../../hooks/use-http";
import SimpleInput from "../../../components/UI/SimpleInput/SimpleInput";
import { API_AUTH_URL } from "../../../constants/API";
import Loading from "../../../components/UI/Loading/Loading";
import { addToast } from "../../../redux/Toast/toastAction";
import { createRandomId } from "../../../utils/common";

import { ReactComponent as Icon } from "../../../assets/images/reset-pass.svg";

const ForgetPassword = ({ onResendEmail }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const { sendRequest, isLoading, error } = useHttp();
	const history = useHistory();
	const dispatch = useDispatch();

	const getForgetPasswordResponse = (data) => {
		history.push("/authentication/done-forget-password");
	};

	const onSubmit = (submittedForm) => {
		onResendEmail(submittedForm.Email);
		const verificationRequest = {
			email: submittedForm.email,
		};
		sendRequest(
			{
				url: `${API_AUTH_URL}forget-password/`,
				method: "POST",
				body: verificationRequest,
			},
			getForgetPasswordResponse
		);
	};
	useEffect(() => {
		if (error) {
			let message = error.data.email ? error.data.email : error.data.detail;
			dispatch(
				addToast({
					id: createRandomId(),
					status: "error",
					description: message,
				})
			);
		}
	}, [dispatch, error]);
	return (
		<section className={classes["section-container"]}>
			<div className={`container ${classes["form-container"]} mx-3 mx-lg-0 my-2 my-lg-5`}>
				<Icon />
				<h1 className={classes["title"]}>Forgot Password?</h1>
				<p className={classes["desc"]}>
					Please enter your registered email to <br/> recieve a reset password link.
				</p>
				<form onSubmit={handleSubmit(onSubmit)}>
					<SimpleInput
						validation={{
							required: true,
							pattern: {
								value:
									/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: "Please enter a valid email",
							},
						}}
						input={{
							type: "email",
							label: "Email",
							name: "email",
							placeholder: "Email",
							id: "Email",
							autoComplete: "off",
						}}
						errors={errors}
						register={register}
					/>
					<button
						className={`btn ${classes["submit"]}`}
						type="submit"
					>
						Reset Password
					</button>
				</form>
				<div className={classes["link"]}>
					<span>Back to</span>
					<Link to="/authentication/login">Login</Link>
				</div>
			</div>
		</section>
	);
};

export default ForgetPassword;
