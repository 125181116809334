import { Link } from "react-router-dom";

import { ReactComponent as HeaderBackground } from "../../../assets/images/home/banner-bg.svg";
// import { ReactComponent as HeaderBackgroundExtralarge } from "../../../assets/images/home/banner-bg-xl.svg";
import HeaderBackgroundExtralarge from "../../../assets/images/home/banner-bg-xl.svg";

import classes from "./DefaultBanner.module.scss";

const Banner = () => {
	return (
		<section
			className={`${classes["banner-section"]} d-flex flex-column align-items-center`}
		>
			<div
				className={`${classes["banner-container"]} d-flex justify-content-center`}
			>
				<div className={classes["banner-svg-normal"]}>
					<HeaderBackground />
				</div>
				<div className={classes["banner-svg-xxl"]}>
					{/* <HeaderBackgroundExtralarge /> */}
					<img src={HeaderBackgroundExtralarge} alt="banner-background" />
				</div>
				<div
					className={`${classes["title"]} d-flex flex-column gap-1 gap-md-4 justify-content-center`}
				>
					<h1>
					Connecting Pharma Service Providers and Requestors Directly
					</h1>
					<p className={classes["sub-title"]}>
					Reduce Costs and Save Time by Eliminating the Middleman with Pharmatching – Your Efficient Pharma Service Marketplace
					</p>
				</div>
			</div>
			<Link to="/authentication/register" className={classes["link"]}>
				Register for FREE
			</Link>
		</section>
	);
};

export default Banner;
