import { Link } from "react-router-dom";
import classes from "./RequestItemV2.module.scss";
import CardV2 from "../UI/CardV2/CardV2";
import defaultLogo from "../../assets/images/placeholder.svg";
import anonLogo from "../../assets/images/logo/anon-request.svg";
import { REQUEST_STATUS } from "../../constants/RequestStatus";
import { dateFormmater, detectShortestItem } from "../../utils/common";
import { useSelector } from "react-redux";


const RequestItem = (props) => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const { requestItem } = props;
  let srcLogo = requestItem.is_anonymous ? anonLogo : requestItem.company_logo
    ? requestItem.company_logo
    :  defaultLogo;
  const status = REQUEST_STATUS.find(
    (element) => element.id === requestItem.status
  );

  return (
    <div className={` ${classes["wrapper"]}`}>
      <Link to={`/request/${requestItem.id}`}>
        <CardV2 className={`${classes.card}`}>
          <div className={classes["request-box"]}>
            <div
              style={{ backgroundImage: `url(${srcLogo})` }}
              className={classes["request-image"]}
            ></div>
          </div>
          <div className={classes.title}>
            {requestItem.is_anonymous ? "" : <span>{requestItem.company_title}</span>}
          </div>
          <div className={classes["title-label"]}>Title:</div>
          <p className={classes.decription}>  {requestItem.title}</p>


          <div className={classes["card-info"]}>
            <div className={classes["key-value-box"]}>
              <div className={classes.key}>Due Date:&nbsp;</div>
              <div className={classes.value}> {dateFormmater(requestItem.due_date)}</div>
            </div>

            <div className={classes["request-status-wrapper"]}>
              <div
                style={{ color: status.color, backgroundColor: status.backgroundColor }}
                className={classes["request-status"]}
              >
                {status.cardTitle}
              </div>

            </div>
          </div>



        </CardV2>
      </Link>
    </div>
  );
};

export default RequestItem;
