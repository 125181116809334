import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import classes from './DashboardCompanyInfo.module.scss';
import CardV2 from '../../components/UI/CardV2/CardV2';
import { Link } from 'react-router-dom';
import { showEditCompanyInfo } from '../../redux/Modal/modalAction';
import { validateUrl } from "../../utils/common";
import twitter from '../../assets/images/Icons/twitter.svg';
import linkdin from '../../assets/images/Icons/linkdin.svg';

const DashboardCompanyInfo = ({ userCompany, userRole }) => {
    const dispatch = useDispatch();
    const time = new Date();
     console.log('usercompanyxx: ', userCompany);
    return (
        <CardV2 className={classes.card}>
            <div className={classes['card-title']}>
                Company Info
            </div>
            <div className={classes['edit-btn-container']}>
                {userCompany && userRole && (userCompany.is_admin || userRole === "freelancer") && (
                    <button className={classes['edit-btn']} onClick={() => { dispatch(showEditCompanyInfo(true)) }}>
                        Edit Company Information
                    </button>
                )}
            </div>
            <div className={`row ${classes['info-wrapper']}`}>
                <div className='col-lg-3 col-12 p-0 text-center'>
                    {userCompany && (
                        <Fragment>
                            <img src={userCompany.logo + '?' + time.getTime()} className={classes['company-logo']} />
                            {userCompany.website &&
                                <a
                                    className={`${classes["view-web"]} `}
                                    href={validateUrl(userCompany.website)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Visit Website
                                </a>
                            }
                            <div className={classes['social-media']}>
                                {userCompany.linkedin && userCompany.linkedin !== null && userCompany.linkedin !== "" && userCompany.linkedin !== "null" &&
                                    <div className={classes['linkdin']}>
                                        <a
                                            href={validateUrl(userCompany.linkedin)}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img src={linkdin} />
                                        </a>
                                    </div>
                                }
                                {userCompany.twitter && userCompany.twitter !== null && userCompany.twitter !== "" && userCompany.twitter !== "null" &&
                                    <div className={classes['twitter']}>
                                        <a
                                            href={validateUrl(userCompany.twitter)}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img src={twitter} />
                                        </a>
                                    </div>
                                }
                            </div>
                        </Fragment>
                    )}

                </div>
                <div className='col-lg-9 col-12 p-0'>
                    {userCompany && (
                        <Fragment>
                            <div className={classes['title']}>
                                Company Name
                            </div>
                            <div className={classes['company-name']}>
                                {userCompany.title}
                            </div>

                            {userRole && userRole !== "freelancer" && (
                                <div className={`${classes['title']} ${classes['mt']}`}>
                                    Company Introduction
                                </div>
                            )}
                            {userRole && userRole === "freelancer" && (
                                <div className={`${classes['title']} ${classes['mt']}`}>
                                    Service Introduction
                                </div>
                            )}


                            <div
                                dangerouslySetInnerHTML={{ __html: userCompany?.description }}
                                className={classes['company-introduction']}>
                            </div>
                            {userRole && userRole !== "freelancer" && (
                                <Fragment>
                                    <div className={`${classes['title']} ${classes['mt']}`}>
                                        Employees Count
                                    </div>
                                    <div className={classes['company-size']}>
                                        {userCompany.size_data && userCompany.size_data.title}
                                    </div>
                                </Fragment>
                            )}

                        </Fragment>
                    )}

                </div>
            </div>
        </CardV2>
    );
};

export default DashboardCompanyInfo;