import classes from "./RequestSize.module.scss"
import Card from "../UI/CardV2/CardV2"

const RequestSize = ({
    requestSize,
    dispatchSelectedRequest,
    selectedId

}) => {
    let selectedCard =
        requestSize.id == selectedId
            ? true
            : false;
    const selectedRequestHandler = () => {
        dispatchSelectedRequest(requestSize);
    };
    return (
        <Card className={`${selectedCard ? classes.selected : ""}  ${classes.card}`}>
            <div
                className={classes.cardContext}
                onClick={selectedRequestHandler}
            >
                <div className={classes.sizeIcon}>
                    {requestSize.summary}
                </div>
                <div className={classes.title}>
                    {requestSize.title}
                </div>
            </div>
        </Card>

    );

};

export default RequestSize;