import classes from "./CheckBox.module.scss";

  const CheckBox = ({ onChangeHandler, input, defaultValue=false }) => {
  return (
    <div
      onClick={() => {
        onChangeHandler(!defaultValue)
      }}
      className={classes["form-group"]}
    >
      <div className={classes['label-wrapper']}>
        <input type="checkbox" id={input.id} checked={defaultValue} onChange={() => {}} />
        <label></label>
        <div>{input.label}</div>
      </div>
    </div>
  );
};

export default CheckBox;
