import { Fragment } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { getAccessToken } from "../../utils/authentication";
import UserRole from "../GuestUser/UserRole/UserRole";
import CreateCompany from "../GuestUser/CreateCompany/CreateCompany";
import Freelancer from "../GuestUser/Freelancer/Freelancer";
import JoinCompany from "../GuestUser/JoinCompany/JoinCompany";
import FreelancerRoute from "../../components/RouteGuard/FreelancerRoute";



const FreelancerUser = () => {
  return (
    <Switch>
      <Fragment>       
        <FreelancerRoute exact path="/freelancer/user-role">
          <UserRole />
        </FreelancerRoute>
        <FreelancerRoute exact path="/freelancer/create-company" >
          <CreateCompany />          
        </FreelancerRoute>
        <FreelancerRoute exact path="/freelancer/freelancer">
          <Freelancer />
        </FreelancerRoute>
        <FreelancerRoute exact path="/freelancer/join-company">
          <JoinCompany />
        </FreelancerRoute>

      </Fragment>
    </Switch>
  );
};

export default FreelancerUser;

// <Switch>
// {getAccessToken() ? (
//   <Fragment>
//     <Route exact path="/guest/create-profile">
//       <CreateProfile />
//     </Route>
//     <Route exact path="/guest/user-role">
//       <UserRole />
//     </Route>
//     <Route exact path="/guest/create-company">
//       <CreateCompany />
//     </Route>
//   </Fragment>
// ) : (
//   <Redirect to="/" />
// )}
// </Switch>
