import React from 'react';
import "./FAQ.scss";
import { Accordion, Card } from "react-bootstrap";
import classes from "./FAQ.module.scss";



const FAQ = () => {
    return (
        <div className={`${classes["main-background"]}`}>
            <div className={`container`}>
                <div className={`row ${classes["static-page"]}`}>
                    <div className={`col-12`}>
                        <div className={classes.title}>
                            <h1>Frequently Asked Questions (FAQ)</h1>
                            <h3>www.pharmatching.com</h3>
                        </div>
                        <div className={classes.content}>
                            <div className={classes.section}>
                                <div className={classes.title}>
                                    General
                            </div>
                                <div className={classes.tabs}>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>What is Pharmatching?</Accordion.Header>
                                            <Accordion.Body>
                                                Pharmatching is platform for smart procurement and outsourcing activities in the Life Science industry. It supports companies and Freelancers to collaborate on a global scale and based on efficient procedures, while maintaining a high degree of compliance.
                                        </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>What are the benefits of being part of Pharmatching?</Accordion.Header>
                                            <Accordion.Body>
                                                •           Cost and time saving: One platform for all! Do not waste time and money in inefficient internet searches or expensive recruitment agencies
                                            <br />•           Big database = best matching options: access the world’s biggest Life Science database
                                            <br />•           Easy and comfortable search: find the right solution to detailed Key Services for your specific need
                                            <br />•           Smart algorithm: We tell you what you need before an Auditor finds out
                                        </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>How does Pharmatching work?</Accordion.Header>
                                            <Accordion.Body>
                                                You can open a global request or apply to a request from all around the world and access new functionalities like our Pharma Wizard to improve your Company efficiency.
                                        </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>Is Pharmatching trustworthy?</Accordion.Header>
                                            <Accordion.Body>
                                                Pharmatching is an IT platform created and managed by the German Company Zamann Pharma Support GmbH (ZPS). ZPS is supporting and consulting Life Science companies all around the world to implement state-of-the-art digitalization and efficient GMP solutions.
                                        </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>Where can I file a complaint about a product or order?</Accordion.Header>
                                            <Accordion.Body>
                                                If you are confronted with any kind of problems or if you have questions, please never hesitate to contact us. For business inquiries feel free to write us an email to info@Pharmatching.com.
                                        </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>Got any technical question?</Accordion.Header>
                                            <Accordion.Body>
                                                Please contact us via the email address support@Pharmatching.com. We get back to you and try to solve your problem as soon as possible.
                                        </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>What payment option are available?</Accordion.Header>
                                            <Accordion.Body>
                                                As an international platform we offer you the payment solutions provided by stripe, which includes VISA, Mastercard as well as American Express.
                                        </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header>What currency will appear on the invoice?</Accordion.Header>
                                            <Accordion.Body>
                                                All invoices are based on Euro.
                                        </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className={classes.section}>
                                <div className={classes.title}>
                                    From Project Owner Side
                            </div>
                                <div className={classes.tabs}>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>How to find the expert for your project on Pharmatching?</Accordion.Header>
                                            <Accordion.Body>
                                                After your registration and your Company assignment the world of Life Science service providers will be open to serve your needs. But first you have to create a request by clicking on “Create request” and describe your project, requirements, project size etc. The more information you provide, the better for potential service providers. After their application you can check their qualifications and choose the most suitable partner for your project.
                                        </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Who on the team receives the invoice?</Accordion.Header>
                                            <Accordion.Body>
                                                The administrators of your Company will receive all invoices via email.
                                         </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>How will I know if I have received an application from a service provider?</Accordion.Header>
                                            <Accordion.Body>
                                                You can always check your request dashboard if you want to check out the current state of received applications or check your email notifications.
                                        </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>


                            <div className={`mb-5 pb-3 ${classes.section}`}>
                                <div className={classes.title}>
                                    From Application Side
                            </div>
                                <div className={classes.tabs}>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>How to search for a Request on Pharmatching?</Accordion.Header>
                                            <Accordion.Body>
                                                You can select “Explore all” in the Request section on the homepage to check out all current and past requests.
                                        </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>How can I apply for a project?</Accordion.Header>
                                            <Accordion.Body>
                                                If you have a subscribed account with available applications in your inventory, you can access all open requests with few clicks.
                                         </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;