import { useState, useCallback, useEffect } from "react";
import {
  createSelectedItemArray,
  removeFromSelectedItems,
  createSelectedIdArray,
} from "../utils/ProfileHandler";

export const useProfileInterests = (serviceCategories) => {
  const [selectedCategoriesId, setSelectedCategoriesId] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [keyServices, setKeyServices] = useState([]);
  const [selectedKeyServicesId, setSelectedKeyServicesId] = useState([]);
  const [selectedKeyServices, setSelectedKeyServices] = useState([]);
  const [isDiabled, setIsDisabled] = useState(true);
  //--------------------------------------------------------------------------------------------------
  const handleServiceCategories = (selectedServiceCategories) => {
    console.log(selectedServiceCategories, "selectedServiceCategories");
    let result = createSelectedItemArray(
      selectedServiceCategories,
      serviceCategories
    );
    filterOnServiceCategories(serviceCategories, result);
    setSelectedCategories(result);
    setSelectedCategoriesId(selectedServiceCategories);
    if (selectedServiceCategories.length === 0) {
      setSelectedKeyServices([]);
      setSelectedKeyServicesId([]);
    }
  };

  const handleKeyServices = (selectedKeyServices) => {
    let result = createSelectedItemArray(selectedKeyServices, keyServices);
    setSelectedKeyServices(result);
    setSelectedKeyServicesId(selectedKeyServices);
  };
  //--------------------------------------------------------------------------------------------------
  const removeSelectedCategories = (selectedId) => {
    removeSelectedKeyServicesByCategory(selectedId);
    let updatedSelectedItems = removeFromSelectedItems(
      selectedCategories,
      selectedId
    );
    setSelectedCategories(updatedSelectedItems);
    filterOnServiceCategories(serviceCategories, updatedSelectedItems);
    let selectedItemsId = createSelectedIdArray(updatedSelectedItems);
    setSelectedCategoriesId(selectedItemsId);
  };

  const removeSelectedKeyServices = (selectedId) => {
    let updatedSelectedItems = removeFromSelectedItems(
      selectedKeyServices,
      selectedId
    );
    setSelectedKeyServices(updatedSelectedItems);
    let selectedItemsId = createSelectedIdArray(updatedSelectedItems);
    setSelectedKeyServicesId(selectedItemsId);
  };
  //--------------------------------------------------------------------------------------------------
  const filterOnServiceCategories = useCallback(
    (serviceCategoriesList, selectedItems) => {
      // find all key servies from selected categories
      const result = serviceCategoriesList.filter((category) => {
        return selectedItems.find(
          (intialCategory) => category.id === intialCategory.id
        );
      });
      let keyServices = [];
      result.forEach((item) => {
        keyServices = [...keyServices, ...item.key_services];
      });
      console.log(keyServices);
      // remove duplicated key service from ks array
      const ids = keyServices.map((o) => o.id);
      const filtered = keyServices.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
      );
      filtered.length !== 0 ? setIsDisabled(false) : setIsDisabled(true);
      setKeyServices(filtered);
    },
    []
  );
  //--------------------------------------------------------------------------------------------------
  const removeSelectedKeyServicesByCategory = (selectedId) => {
    const found = serviceCategories.find(
      (element) => element.id === selectedId
    );
    const result = selectedKeyServices.filter((keyService) => {
      return !found.key_services.some((ks) => {
        return ks.id === keyService.id;
      });
    });
    setSelectedKeyServices(result);
    let selectedItemsId = createSelectedIdArray(result);
    setSelectedKeyServicesId(selectedItemsId);
  };
  //--------------------------------------------------------------------------------------------------
  const setInitialSelectedItems = useCallback((categories, keyService) => {
    // set initial service categories
    let initialServiceCategoriesId = createSelectedIdArray(categories);
    setSelectedCategoriesId(initialServiceCategoriesId);
    setSelectedCategories(categories);
    // set intital key services
    let initialKeyServicesId = createSelectedIdArray(keyService);
    setSelectedKeyServicesId(initialKeyServicesId);
    setSelectedKeyServices(keyService);
  }, []);
  //--------------------------------------------------------------------------------------------------
  useEffect(
    () => () => {
      setKeyServices([]);
      setSelectedCategoriesId([]);
      setSelectedCategories([]);
      setSelectedKeyServicesId([]);
      setSelectedKeyServices([]);
    },
    []
  );

  //--------------------------------------------------------------------------------------------------
  return {
    handleServiceCategories,
    handleKeyServices,
    removeSelectedCategories,
    removeSelectedKeyServices,
    filterOnServiceCategories,
    setInitialSelectedItems,
    isDiabled,
    selectedCategoriesId,
    selectedKeyServicesId,
    selectedKeyServices,
    selectedCategories,
    keyServices,
  };
};
