import { Fragment, useState, useRef, useEffect } from "react";
import classes from "./SelectUnSelectInterests.module.scss";
import closeButton from "../../assets/images/Icons/close-button.svg";
import arrow from "../../assets/images/arrow/arrow-down.svg";
import removeItem from "../../assets/images/Icons/remove-item.svg";


const SelectUnSelectInterests = ({
  bgColor,
  selectedItems,
  removeSelectedItem,
  color,
  searchHandler,
  searchFocus,
}) => {
  const [isShowMore, setIsShowMore] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const ref = useRef();
  const searchInput = useRef(null);
  const showMoreHandler = () => {
    let showMore;
    if (isShowMore) {
      showMore = false;
    } else {
      showMore = true;
    }
    setIsShowMore(showMore);
  };
  
  useEffect(() => {
  //  console.log(ref.current.scrollHeight)
    if (ref.current.scrollHeight > 126) {
      setHasMore(true);
    } else if(ref.current.scrollHeight <= 126) {
      setHasMore(false)
    }
  }, [selectedItems]);

  useEffect(() =>{
    if(searchFocus)
      searchInput.current.focus();
  },[searchFocus])

  const onRemoveSelected = (Id) => {
    removeSelectedItem(Id);
    
  };
  return (
    <Fragment>
      <div
        ref={ref}
        style={{ maxHeight: !isShowMore ? 110 : "none" }}
        className={`${classes["selected-items-box"]} ${
          isShowMore ? classes["opened-box"] : ""
        }`}
      >
        {selectedItems.map((selected) => (
          <div
            key={selected.id}
            // style={{ background: bgColor, color:color }}
            style={{ background: 'white', color:'#1E4C90' }}
            className={classes["selected-item"]}
          >
            <div className={classes["selected-item-title"]}> {selected.title}</div>
            <img
              onClick={(e) => {onRemoveSelected(selected.id); e.stopPropagation();}}
              className={classes["remove-selected-item"]}
              src={removeItem}
              alt="remove"
            />
          </div>
        ))}
        <input
          className={classes["inner-search-input"]}
          type="text"
          placeholder=""
          ref={searchInput}
          onChange={(e) => searchHandler(e)}
        />
      </div>
      {/* {hasMore && (
        <button
          onClick={showMoreHandler}
          className={`${classes["show-more"]}`}
        >
          {!isShowMore && <span>Show more</span>}
          {isShowMore && <span>Show less</span>}
          <img
            style={{
              transform: !isShowMore ? "rotate(0deg)" : "rotate(180deg)",
            }}
            src={arrow}
            alt="show more"
          />
        </button>
      )} */}
    </Fragment>
  );
};

export default SelectUnSelectInterests;
