import { useForm } from "react-hook-form";
import classes from "./NewsComment.module.scss";

const NewsComment = ({ onSubmitAnswer }) => {
	const { register, handleSubmit } = useForm();
	const onSubmit = (form) => {
		onSubmitAnswer("No", form.userComment);
	};

	return (
		<form className={classes["form-comment"]} onSubmit={handleSubmit(onSubmit)}>
			<textarea
				name="userComment"
				className={`form-control ${classes.input}`}
				{...register("userComment")}
				required
				placeholder="What can we improve for you?"
			/>
			<button type="submit" className={`btn ${classes.submit}`}>
				Send Feedback
			</button>
		</form>
	);
};

export default NewsComment;
