import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addToast } from "../../redux/Toast/toastAction";
import { createRandomId } from "../../utils/common";
import classes from "./ApplicationView.module.scss";
import { getAccessToken } from "../../utils/authentication";
import { useHistory, useParams } from "react-router-dom";
import useHttp from "../../hooks/use-http";
import { API_REQUEST_URL, API_APPLICATION_URL } from "../../constants/API";
import Loading from "../../components/UI/Loading/Loading";
import RequesViewSummary from "../../components/RequestViewSummary/RequstViewSummary";
import ApplicationDetail from "../../components/ApplicationDetail/ApplicationDetail";
import CloseRequestModal from "../../components/UI/CloseRequestModal/CloseRequestModal";
import ConfirmModal from "../../components/UI/ConfirmModal/ConfirmModal";


const ApplicationView = () => {
  const dispatch = useDispatch();
  const [isDisplayModal, setIsDisplayModal] = useState(null);
  const [applicationInfo, setApplicationInfo] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const history = useHistory();
  const { id } = useParams();
  const { sendRequest, error, isLoading } = useHttp();
  //-----------------Modal consts-------------------
  const [modalMessage, setModalMessage] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [modalButtonText, setModalButtonText] = useState(null);
  const [modalHeader, setmodalHeader] = useState(null);

  const acceptApplicationRequest = {
    redirect_url: 'https://webapp.pharmatching.com/payment-result',
  };
  const getUserActionResponseDecline = (data) => {
    dispatch(
      addToast({
        id: createRandomId(),
        status: "success",
        description: "Application declined!",
      })
    );
    history.push("/dashboard/recieved-applications");
  };
  const getUserActionResponseAccept = (data) => {
    // window.location.href = data.payment_url;
    history.push('/payment-result?success=true')
  };
  const confirmUserAction = (str) => {
    const token = getAccessToken();
    if (str === "confirm") {
      let url;
      if (modalButtonText === "Decline") {
        url = `${API_APPLICATION_URL}${id}/decline-application/`;
        sendRequest(
          {
            url: url,
            headers: { Authorization: `Bearer ${token}` },
          },
          getUserActionResponseDecline
        );
      }
      else if (modalButtonText === "YES") {
        const token = getAccessToken();
        sendRequest(
          {
            url: `${API_APPLICATION_URL}${id}/accept-application/`,
            method: "POST",
            body: acceptApplicationRequest,
            headers: { Authorization: `Bearer ${token}` },
          },
          getUserActionResponseAccept
        );
        // url = `${API_APPLICATION_URL}${id}/accept-application/`;
        // sendRequest(
        //   {
        //     url: url,
        //     headers: { Authorization: `Bearer ${token}` },
        //   },
        //   getUserActionResponseAccept
        // );
        // history.push(`/payment/${id}`);
      }


    }
    setModalType(null);
    setModalButtonText(null);
    setModalMessage(null);
  };

  //--------------------decide application-----------
  const declineApplication = () => {

    setModalType("warning");
    setModalButtonText("Decline");
    setModalMessage("Do you confirm to Decline Application?");
    setmodalHeader("Decline Application");
  };
  const acceptApplication = () => {
    setModalType("success");
    setModalButtonText("YES");
    setModalMessage(`Do you want to choose "${applicationInfo.company_title}" as winner for your Request?`);
    setmodalHeader(" Matchmaking!");
  };

  //------------------------------------------------------------------------------------------
  const getRequestInfo = (data) => {

    setApplicationInfo(data);
    setRequestId(data.request);
    window.scrollTo(0, 0);
  };

  const fetchApplicationInfo = useCallback(() => {
    const token = getAccessToken();
    sendRequest(
      {
        url: `${API_APPLICATION_URL}${id}`,
        headers: { Authorization: `Bearer ${token}` },
      },
      getRequestInfo
    );
  }, [id, sendRequest]);

  //------------------------------------------------------------------------------------------
  useEffect(() => {
    fetchApplicationInfo();
  }, [fetchApplicationInfo]);

  //------------------------------------------------------------------------------------------

  const confirmCloseRequest = () => {
    setIsDisplayModal(true);
  };
  //------------------------------------------------------------------------------------------
  const getClosedRequestRes = (data) => {
    history.push("/dashboard/sent-requests");
  };
  //------------------------------------------------------------------------------------------
  const closeRequest = (arg) => {
    const closeReq = {
      close_reason: parseInt(arg.data),
      status: 5,
    };
    setIsDisplayModal(false);
    if (arg.type === "confirm") {
      const token = getAccessToken();
      sendRequest(
        {
          url: `${API_REQUEST_URL}${requestId}`,
          body: closeReq,
          headers: { Authorization: `Bearer ${token}` },
          method: "PATCH",
        },
        getClosedRequestRes
      );
    }
  };

  useEffect(() => {
    if (error && error.status === 400) {
      dispatch(
        addToast({
          id: createRandomId(),
          status: "error",
          description: error.data.detail
            ? error.data.detail
            : "",
        })
      );
    }
    else if (error && error.status === 404) {
      dispatch(
        addToast({
          id: createRandomId(),
          status: "error",
          description: "Unfortunately an error occurred",
        })
      );
    }
  }, [dispatch, error]);


  return (
    <section
      className={`container  flex-grow position-relative ${classes.section}  ${classes["min-h"]}`}
    >
      {modalMessage && (
        <ConfirmModal
          modalButtonText={modalButtonText}
          modalType={modalType}
          modalText={modalMessage}
          modalHeader={modalHeader}
          onConfirm={confirmUserAction}
        />
      )}
      {isDisplayModal && <CloseRequestModal onConfirm={closeRequest} />}
      {isLoading && <Loading className={classes.loading} />}
      {applicationInfo && !isLoading && (
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-5 col-md-10 col-12">
            <RequesViewSummary
              requestInfo={applicationInfo.request_data}
              onConfirmDelete={confirmCloseRequest}
              isApplicationView={true}
            />
          </div>
          <div className="col-xl-8 col-lg-7 col-md-10 col-12">
            <ApplicationDetail
              applicationInfo={applicationInfo}
              requestInfo={applicationInfo.request_data}
              isApplicationView={true}
              onDeclineApplication={declineApplication}
              onAcceptApplication={acceptApplication}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default ApplicationView;
