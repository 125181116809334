import { useState, useCallback, useEffect } from "react";
import {
  createSelectedItemArray,
  removeFromSelectedItems,
  createSelectedIdArray,
} from "../utils/ProfileHandler";
import { convertStringMemberToNumber } from "../utils/common";
import { SELECT_3_CHOICES, SELECT_5_CHOICES } from "../constants/ErrorMessage";

export const useInterests = (
  intialCategories,
  intialKeyServices,
  intialRegions,
  intialCountries,
  initialSpecialities,
  serviceCategories,
  regions,
  countries,
  specialities,
  componentType,
  setSelectedItems = () => { }
) => {
  const [interestItems, setInterestItems] = useState({
    isDiabled: true,
    selectedCategoriesId: intialCategories,
    selectedKeyServicesId: intialKeyServices,
    selectedRegionId: intialRegions,
    selectedCountriesId: intialCountries,
    selectedSpecialitiesId: initialSpecialities,
    selectedCategories: [],
    selectedKeyServices: [],
    selectedRegion: [],
    selectedCountries: [],
    selectedSpecialities: [],
    keyServices: [],
    isSelectedCategories: true,
    isSelectedKeyServices: true,
    isSelectedRegion: true,
    isSelectedCountries: true,
    isSelectedSpecialities: true,
    categoriesErrorMessage: null,
    keyServiceErrorMessage: null,
    specialitiesErrorMessage: null,
  });

  // set and display selected items----------------------------------------------------------------
  const handleServiceCategories = (selectedCategories) => {
    // lift up selected ids
    // console.log('selectedCategories in interests',selectedCategories);
    setSelectedItems("categories", selectedCategories);
    // create an array ofselectedCategories with title and its id
    let result = createSelectedItemArray(selectedCategories, serviceCategories);
    filterOnServiceCategories(serviceCategories, selectedCategories);
    setInterestItems((previousState) => {
      return {
        ...previousState,
        isSelectedCategories: selectedCategories.length !== 0 ? true : false,
        selectedCategoriesId: selectedCategories,
        selectedCategories: result,
      };
    });
  };

  const handleKeyServices = (selectedKeyServices) => {
    // lift up selected ids
    setSelectedItems("keyServices", selectedKeyServices);
    // create an array ofselectedCategories with title and its id
    let result = createSelectedItemArray(
      selectedKeyServices,
      interestItems.keyServices
    );
    setInterestItems((previousState) => {
      return {
        ...previousState,
        isSelectedKeyServices: selectedKeyServices.length !== 0 ? true : false,
        selectedKeyServicesId: selectedKeyServices,
        selectedKeyServices: result,
      };
    });
  };

  const handleRegions = (selectedRegion) => {
    setSelectedItems("regions", selectedRegion);
    let result = createSelectedItemArray(selectedRegion, regions);
    setInterestItems((previousState) => {
      return {
        ...previousState,
        isSelectedRegion: selectedRegion.length !== 0 ? true : false,
        selectedRegionId: selectedRegion,
        selectedRegion: result,
      };
    });
  };

  const handleCountries = (selectedCountries) => {
    setSelectedItems("countries", selectedCountries);
    let result = createSelectedItemArray(selectedCountries, countries);
    setInterestItems((previousState) => {
      return {
        ...previousState,
        isSelectedCountries: selectedCountries.length !== 0 ? true : false,
        selectedCountriesId: selectedCountries,
        selectedCountries: result,
      };
    });
  };

  const handleSpecialities = (selectedSpecialities) => {
    setSelectedItems("specialities", selectedSpecialities);
    let result = createSelectedItemArray(selectedSpecialities, specialities);
    setInterestItems((previousState) => {
      return {
        ...previousState,
        isSelectedSpecialities:
          selectedSpecialities.length !== 0 ? true : false,
        selectedSpecialitiesId: selectedSpecialities,
        selectedSpecialities: result,
      };
    });
  };

  // remove items-----------------------------------------------------------------------------------
  const removeSelectedCategories = (selectedId) => {
    removeSelectedKeyServicesByCategory(selectedId);
    // create new array of selectedCategories execpt remove item
    let updatedSelectedItems = removeFromSelectedItems(
      interestItems.selectedCategories,
      selectedId
    );
    let selectedItemsId = createSelectedIdArray(updatedSelectedItems);
    filterOnServiceCategories(serviceCategories, selectedItemsId);
    setSelectedItems("categories", selectedItemsId);
    setInterestItems((previousState) => {
      return {
        ...previousState,
        isSelectedCategories: updatedSelectedItems.length !== 0 ? true : false,
        selectedCategories: updatedSelectedItems,
        selectedCategoriesId: selectedItemsId,
      };
    });
  };

  const removeSelectedKeyServices = (selectedId) => {
    let updatedSelectedItems = removeFromSelectedItems(
      interestItems.selectedKeyServices,
      selectedId
    );
    let selectedItemsId = createSelectedIdArray(updatedSelectedItems);
    setSelectedItems("keyServices", selectedItemsId);
    setInterestItems((previousState) => {
      return {
        ...previousState,
        isSelectedKeyServices: updatedSelectedItems.length !== 0 ? true : false,
        selectedKeyServices: updatedSelectedItems,
        selectedKeyServicesId: selectedItemsId,
      };
    });
  };

  const removeSelectedRegion = (selectedId) => {
    let updatedSelectedItems = removeFromSelectedItems(
      interestItems.selectedRegion,
      selectedId
    );
    let selectedItemsId = createSelectedIdArray(updatedSelectedItems);
    setSelectedItems("regions", selectedItemsId);

    setInterestItems((previousState) => {
      return {
        ...previousState,
        isSelectedRegion: updatedSelectedItems.length !== 0 ? true : false,
        selectedRegionId: selectedItemsId,
        selectedRegion: updatedSelectedItems,
      };
    });
  };
  const removeSelectedCountries = (selectedId) => {
    let updatedSelectedItems = removeFromSelectedItems(
      interestItems.selectedCountries,
      selectedId
    );
    let selectedItemsId = createSelectedIdArray(updatedSelectedItems);
    setSelectedItems("countries", selectedItemsId);

    setInterestItems((previousState) => {
      return {
        ...previousState,
        isSelectedCountries: updatedSelectedItems.length !== 0 ? true : false,
        selectedCountriesId: selectedItemsId,
        selectedCountries: updatedSelectedItems,
      };
    });
  };

  const removeSelectedSpecialities = (selectedId) => {

    let updatedSelectedItems = removeFromSelectedItems(
      interestItems.selectedSpecialities,
      selectedId
    );
    let selectedItemsId = createSelectedIdArray(updatedSelectedItems);
    setSelectedItems("specialities", selectedItemsId);
    setInterestItems((previousState) => {
      return {
        ...previousState,
        isSelectedSpecialities:
          updatedSelectedItems.length !== 0 ? true : false,
        selectedSpecialitiesId: selectedItemsId,
        selectedSpecialities: updatedSelectedItems,
      };
    });
  };

  //filterd keyService based on selected categories------------------------------------------------------
  const filterOnServiceCategories = useCallback(
    (serviceCategoriesList, items) => {
      // console.log("called filteronService");
      // console.log("item",serviceCategoriesList);
      const selectedItems = convertStringMemberToNumber(items);
      const result = serviceCategoriesList.filter((category) => {
        return selectedItems.find((item) => category.id === item);
      });
      // console.log("result", result);
      let keyServices = [];

      result.forEach((item) => {
        keyServices = [...keyServices, ...item.key_services];

      });
      // console.log("keyservices:",keyServices);
      const ids = keyServices.map((o) => o.id);
      const filtered = keyServices.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
      );
      // console.log("firstfilter", filtered);
      setInterestItems((previousState) => {
        return {
          ...previousState,
          isDiabled: filtered.length !== 0 ? false : true,
          keyServices: filtered,
        };
      });
    },
    []
  );
  // removed selected key service if user removed its category--------------------------------------
  const removeSelectedKeyServicesByCategory = (selectedId) => {
    const found = serviceCategories.find(
      (element) => element.id === selectedId
    );
    const result = interestItems.selectedKeyServices.filter((keyService) => {
      return !found.key_services.some((ks) => {
        return ks.id === keyService.id;
      });
    });
    let selectedItemsId = createSelectedIdArray(result);
    setSelectedItems("keyServices", selectedItemsId);
    setInterestItems((previousState) => {
      return {
        ...previousState,
        selectedKeyServices: result,
        selectedKeyServicesId: selectedItemsId,
      };
    });
  };
  // initial selected item (edit pages)----------------------------------------------------------

  const initialSelectedKeyServices = useCallback(() => {
    if (intialKeyServices.length !== 0 && interestItems.keyServices) {
      let result = createSelectedItemArray(
        intialKeyServices,
        interestItems.keyServices
      );
      setInterestItems((previousState) => {
        return {
          ...previousState,
          selectedKeyServices: result,
          selectedKeyServicesId: intialKeyServices,
        };
      });
    }
  }, [interestItems.keyServices, intialKeyServices]);

  const initialSelectedCategories = useCallback(() => {
    if (intialCategories.length !== 0 && serviceCategories) {
      let result = createSelectedItemArray(intialCategories, serviceCategories);
      setInterestItems((previousState) => {
        return {
          ...previousState,
          selectedCategories: result,
          selectedCategoriesId: intialCategories,
        };
      });
    }
  }, [intialCategories, serviceCategories]);

  const initialSelectedRegions = useCallback(() => {
    if (intialRegions.length !== 0 && regions) {
      let result = createSelectedItemArray(intialRegions, regions);
      setInterestItems((previousState) => {
        return {
          ...previousState,
          selectedRegionId: intialRegions,
          selectedRegion: result,
        };
      });
    }
  }, [intialRegions, regions]);

  const initialSelectedCountries = useCallback(() => {
    if (intialCountries.length !== 0 && countries) {
      let result = createSelectedItemArray(intialCountries, countries);
      setInterestItems((previousState) => {
        return {
          ...previousState,

          selectedCountriesId: intialCountries,
          selectedCountries: result,
        };
      });
    }
  }, [intialCountries, countries]);

  const initialSelectedSpecialities = useCallback(() => {
    if (initialSpecialities.length !== 0 && specialities) {
      let result = createSelectedItemArray(initialSpecialities, specialities);
      setInterestItems((previousState) => {
        return {
          ...previousState,

          selectedSpecialitiesId: initialSpecialities,
          selectedSpecialities: result,
        };
      });
    }
  }, [initialSpecialities, specialities]);

  //if user selected four interests then form can be submitted-------------------------------------------
  const detectIsSubmitForm = () => {
    if (componentType === "freelancer") {
      setInterestItems((previousState) => {
        return {
          ...previousState,
          isSelectedCategories:
            interestItems.selectedCategoriesId.length !== 0 ? true : false,
          isSelectedKeyServices:
            interestItems.selectedKeyServicesId.length !== 0 ? true : false,
          categoriesErrorMessage:
            interestItems.selectedCategoriesId.length > 6
              ? SELECT_3_CHOICES
              : null,
          // keyServiceErrorMessage:
          //   interestItems.selectedKeyServicesId.length > 3
          //     ? SELECT_3_CHOICES
          //     : null,
          isSelectedSpecialities:
            interestItems.selectedSpecialitiesId.length !== 0 ? true : false,
        };
      });
      return (
        interestItems.selectedCategoriesId.length !== 0 &&
        interestItems.selectedCategoriesId.length <= 5 &&
        interestItems.selectedKeyServicesId.length !== 0 &&
        // interestItems.selectedKeyServicesId.length <= 3 &&
        interestItems.selectedSpecialitiesId.length !== 0 &&
        interestItems.selectedSpecialitiesId.length <= 5
      );
    }
    if (componentType === "company") {
      setInterestItems((previousState) => {
        return {
          ...previousState,
          isSelectedCategories:
            interestItems.selectedCategoriesId.length !== 0 ? true : false,
          isSelectedKeyServices:
            interestItems.selectedKeyServicesId.length !== 0 ? true : false,
          // isSelectedRegion:
          //   interestItems.selectedRegionId.length !== 0 ? true : false,
          // isSelectedCountries:
          //   interestItems.selectedCountriesId.length !== 0 ? true : false,
          isSelectedSpecialities:
            interestItems.selectedSpecialitiesId.length !== 0 ? true : false,
          categoriesErrorMessage:
            interestItems.selectedCategoriesId.length > 6
              ? SELECT_3_CHOICES
              : null,
        };
      });
      return (
        interestItems.selectedCategoriesId.length !== 0 &&
        interestItems.selectedKeyServicesId.length !== 0 &&
        // interestItems.selectedRegionId.length !== 0 &&
        // interestItems.selectedCountriesId.length !== 0 &&
        interestItems.selectedCategoriesId.length <= 5 &&
        interestItems.selectedSpecialitiesId.length !== 0 &&
        interestItems.selectedSpecialitiesId.length <= 5
      );
    }
    if (componentType === "request") {
      setInterestItems((previousState) => {
        return {
          ...previousState,
          isSelectedCategories:
            interestItems.selectedCategoriesId.length !== 0 ? true : false,
          isSelectedKeyServices:
            interestItems.selectedKeyServicesId.length !== 0 ? true : false,
          isSelectedRegion:
            interestItems.selectedRegionId.length !== 0 ? true : false,
          isSelectedCountries:
            interestItems.selectedCountriesId.length !== 0 ? true : false,
          isSelectedSpecialities:
            interestItems.selectedSpecialitiesId.length !== 0 ? true : false,
          categoriesErrorMessage:
            interestItems.selectedCategoriesId.length > 3
              ? SELECT_3_CHOICES
              : null,
          keyServiceErrorMessage:
            interestItems.selectedKeyServicesId.length > 5
              ? SELECT_5_CHOICES
              : null,
          specialitiesErrorMessage:
            interestItems.selectedSpecialitiesId.length > 3
              ? SELECT_3_CHOICES
              : null,
        };
      });
      return (
        interestItems.selectedCategoriesId.length !== 0 &&
        interestItems.selectedKeyServicesId.length !== 0 &&
        interestItems.selectedRegionId.length !== 0 &&
        interestItems.selectedCountriesId.length !== 0 &&
        interestItems.selectedSpecialitiesId.length !== 0 &&
        interestItems.selectedCategoriesId.length <= 3 &&
        interestItems.selectedKeyServicesId.length <= 5 &&
        interestItems.selectedSpecialitiesId.length <= 3
      );
    }
  };
  //--------------------------------------------------------------------------------------------------
  useEffect(() => {
    // console.log('intialCountries: ',intialCountries);
    // console.log('initialSpecialities: ',initialSpecialities);
    initialSelectedKeyServices();
    initialSelectedCategories();
    initialSelectedRegions();
    initialSelectedCountries();
    initialSelectedSpecialities();
  }, [
    initialSelectedCategories,
    initialSelectedCountries,
    initialSelectedKeyServices,
    initialSelectedRegions,
    initialSelectedSpecialities,
  ]);
  //--------------------------------------------------------------------------------------------------
  useEffect(() => {
    filterOnServiceCategories(serviceCategories, intialCategories);
  }, [filterOnServiceCategories, intialCategories, serviceCategories]);
  //--------------------------------------------------------------------------------------------------
  return {
    handleServiceCategories,
    handleKeyServices,
    handleRegions,
    handleCountries,
    handleSpecialities,
    removeSelectedCategories,
    removeSelectedKeyServices,
    removeSelectedRegion,
    removeSelectedCountries,
    removeSelectedSpecialities,
    filterOnServiceCategories,
    detectIsSubmitForm,
    interestItems,
  };
};
