import { useState, useEffect } from "react";
import classes from "./NewsSearchBox.module.scss";
import Card from "../UI/Card/Card";
import SimpleFormGroupLtr from "../UI/SimpleFormGroupLtr/SimpleFormGroupLtr";
import useHttp from "../../hooks/use-http";
import { API_TAGS_URL } from "../../constants/API";

const NewsSearchBox = (props) => {
  const {
    onSearchNews,
    searchItem,
    componentUsage,
  } = props;
  const [keyServices, setKeyServices] = useState([]);
  const { sendRequest, error, isLoading } = useHttp();

  const handleOnSearchNews = (searchItem) => {
    onSearchNews(searchItem);
  };
  const getKeyServices = (keyServices) => {
    setKeyServices(keyServices);
  };

  useEffect(() => {
    if (componentUsage === "news") {
      sendRequest(
        {
          url: `${API_TAGS_URL}/service-categories/`,
        },
        getKeyServices
      );
    }
  }, [componentUsage, sendRequest]);

  return (
    <Card className={classes.card}>
      <div className="container">
        <div>
          <div>
            <SimpleFormGroupLtr
              className="mb-20"
              label={componentUsage === "news" ? "Search News" : "Search Warning Letters"}
              inputValue={searchItem}
              dispatchInputValue={handleOnSearchNews}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default NewsSearchBox;
