import React, { useCallback, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Login from '../../pages/Authentication/Login/Login';
import Requests from '../../pages/RequestList/RequestList';

function FreelancerRoute(props) {
  const history = useHistory();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const userCompany = useSelector((state) => state.user.userCompany);
  const userRole = useSelector((state) => state.user.userRole);

  
  return (
    
    <>
      {isLoggedIn && (userRole === "freelancer" || userRole==="guest") ? <Route {...props} />
        :
        <Requests />
      } 
      {/* <Route {...props} /> */}
    </>
  );
}
  export default FreelancerRoute;