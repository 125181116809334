import {Link} from "react-router-dom";
import classes from "./NewsItemExplore.module.scss";
import Card from "../UI/CardV2/CardV2";
import pharmaNewsLogo from "../../assets/images/pharmatching-news.png";
import {dateFormmater} from "../../utils/common";

const NewsItemExplore = (props) => {
    const {news, className, isSlider} = props;
    const cleanHtmlString = news.text
        .trim()
        .replace(/^```html/, "")
        .replace(/```$/, "")
        .replace(/<\/?[^>]+>/gi, "");
    return (
        <div className={className}>
            <Link to={`/news/${news.id}`}>
                <Card
                    className={`row ${classes.card}`}>
                    <div
                        className={`col-lg-9 col-md-9 col-9 ${isSlider ? classes["news-details-slider"] : classes["news-details"]}`}>
                        <div className={classes.title}>
                            <span>{news.title}</span>
                        </div>
                        <p className={classes.decription} dangerouslySetInnerHTML={{ __html: cleanHtmlString }}>
                           
                            
                        </p>
                        <div className={classes["news-header-box"]}>
                            <div
                                className={classes["news-date"]}>{news.crawled_at && dateFormmater(news.crawled_at)}</div>
                                <div
                                className={classes["news-date"]}>{news.source}</div>
                        </div>
                        
                        
                    </div>
                    <div
                        className={`col-lg-3 col-md-3 col-3 ${isSlider ? classes["news-box-slider"] : classes["news-box"]}`}>
                        <div style={{backgroundImage: `url(${news.image_url ? news.image_url : pharmaNewsLogo})`}}
                             className={isSlider ? classes["news-image-slider"] : classes["news-image"]}></div>
                    </div>
                </Card>
            </Link>
        </div>
    );
};

export default NewsItemExplore;
