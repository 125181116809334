import ReactDOM from "react-dom";
import classes from "./CloseRequestModal.module.scss";
import { Fragment } from "react";
import Backdrop from "../Backdrop/Backdrop";
import Card from "../Card/Card";
import { useForm } from "react-hook-form";

const ConfirmOverlay = ({ onConfirm }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {radioButton : "1"}
    
  });

  const onSubmit = (submittedForm) => {
    console.log(submittedForm);
    onConfirm({
      type: "confirm",
      data: submittedForm.radioButton,
    });
  };
  return (
    <Card className={classes.modal}>
      <div className={classes["header-modal"]}>
        <span className={classes.warning}>Hang in there!</span>
      </div>
      <p className={classes["modal-message"]}>
        Are you sure to close the Request before its Due Date was reached or a
        Matchmaking partner was found? If this is so, please tell us the reason
        so we can keep improving:{" "}
      </p>
      <form className={classes["close-form"]} onSubmit={handleSubmit(onSubmit)}>
        <div className="form-check mb-3 px-0">
          <input
            className={classes["radio-button"]}
            type="radio"
            id="flexRadioDefault1"
            {...register("radioButton")}
            value="1"
           
          />
          <label className="form-check-label d-inline" htmlFor="flexRadioDefault1">
            did not found any relevant partner
          </label>
        </div>
        <div className="form-check mb-3 px-0">
          <input
            className={classes["radio-button"]}
            type="radio"
            id="flexRadioDefault2"
            {...register("radioButton")}
            value="2"
          />
          <label className="form-check-label d-inline" htmlFor="flexRadioDefault2">
            Nobody applied
          </label>
        </div>
        <div className="form-check mb-3 px-0">
          <input
            className={classes["radio-button"]}
            type="radio"
            id="flexRadioDefault3"
            {...register("radioButton")}
            value="3"
          />
          <label className="form-check-label d-inline" htmlFor="flexRadioDefault3">
            I found my partner in a different way
          </label>
        </div>
        <div className="form-check mb-3 px-0">
          <input
            className={classes["radio-button"]}
            type="radio"
            id="flexRadioDefault4"
            {...register("radioButton")}
            value="4"
          />
          <label className="form-check-label d-inline" htmlFor="flexRadioDefault4">
            Something unexpected has occurred
          </label>
        </div>
        <div className="form-check px-0">
          <input
            className={classes["radio-button"]}
            type="radio"
            id="flexRadioDefault5"
            {...register("radioButton")}
            value="5"
          />
          <label className="form-check-label d-inline" htmlFor="flexRadioDefault5">
            There were internal adjustments and the project had to be stopped
          </label>
        </div>
        <div className={classes["buttons-box"]}>
          <button type="submit" className={`btn ${classes["close-req"]}`}>
            Close Request
          </button>

          <button
            type="button"
            onClick={() => {
              onConfirm({
                type: "cancel",
                data: null,
              });
            }}
            className={`btn ${classes.cancel}`}
          >
            Cancel
          </button>
        </div>
      </form>
    </Card>
  );
};

const CloseRequestModal = ({ onConfirm }) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ConfirmOverlay onConfirm={onConfirm} />,
        document.getElementById("overlay-root")
      )}
    </Fragment>
  );
};

export default CloseRequestModal;
