import classes from "./WorldAccess.module.scss";

const WorldAccess = () => {
    return (
        <section className={classes["world-section"]}>
            <div className={`${classes["world-container"]} container`}>
                <div className='row m-0'>
                    <div className={`${classes["world-container-title"]} col-md-4`}>
                        <h2>What makes Pharmatching so helpful?</h2>
                    </div>
                    <div className={`col-md-8`}>
                        <div className={`${classes["world-container-content"]} row m-0`}>
                            <div className={`col-6 p-0 pe-md-2`}>
                                <div className={`${classes["world-container-content-item"]} ${classes["world-container-content-item-left"]}`}>
                                    <h3>
                                        Fast & Efficient
                                    </h3>
                                    <p>
                                        Foster industry-wide collaboration.
                                    </p>
                                </div>
                            </div>
                            <div className={`col-6 p-0 ps-md-2`}>
                                <div className={`${classes["world-container-content-item"]}`}>
                                    <h3>
                                        Brand Awareness
                                    </h3>
                                    <p>
                                        Choose or be chosen from a global network of qualified supplier.
                                    </p>
                                </div>
                            </div>
                            <div className={`col-6 p-0 pe-md-2`}>
                                <div className={`${classes["world-container-content-item"]} ${classes["world-container-content-item-left"]}`}>
                                    <h3>
                                        All in one solution
                                    </h3>
                                    <p>
                                        All life cycle steps of your product covered by any global or local provider.
                                    </p>
                                </div>
                            </div>
                            <div className={`col-6 p-0 ps-md-2`}>
                                <div className={`${classes["world-container-content-item"]}`}>
                                    <h3>
                                        Business Development
                                    </h3>
                                    <p>
                                        Find new business opportunities and merge synergies - from a start up all the way to corporate.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default WorldAccess;
