import React from "react";
import MainBanner from "./BannersType/MainBanner";
import SolutionBanner from "./BannersType/SolutionBanner";

export const BANNER_TYPE_CLASSES = {
    main: 'banner-main',
    solution: 'banner-solution',
}

const getBanner = (bannerType = BANNER_TYPE_CLASSES.main) => (
    {
        [BANNER_TYPE_CLASSES.main]: MainBanner,
        [BANNER_TYPE_CLASSES.solution]: SolutionBanner,

    }[bannerType]
)
const Banner = ({children, bannerType}) => {
    const CustomBanner = getBanner(bannerType);
    return (
        <CustomBanner>
            {children}
        </CustomBanner>
    );
};

export default Banner;
