import React from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useCallback, useMemo } from "react";
import classes from "./ModalEditRequest.module.scss";
import Card from "../UI/CardV2/CardV2";
import SimpleInput from "../UI/SimpleInput/SimpleInput";
import SingleTextAreaWithWordCounter from "../UI/SingleTextAreaWithWordCounter/SingleTextAreaWithWordCounter";
import DropDownInput from "../UI/DropDownInput/DropDownInput";
import AttachFileInput from "../UI/AttachFileInput/AttachFileInput";
import DatePickerDueDateSimple from "../UI/DatePickerDueDateSimple/DatePickerDueDateSimple";
import { useForm } from "react-hook-form";
import { API_TAGS_URL } from "../../constants/API";
import { API_REQUEST_URL } from "../../constants/API";
import SearchableDropdownFilter from "../UI/SearchableDropdownFilter/SearchableDropdownFilter";
import SelectUnSelectInterests from "../SelectUnSelectInterests/SelectUnSelectInterests";
import RequestSize from "../RequestSize/RequestSize";
import useHttp from "../../hooks/use-http";
import { useInterests } from "../../hooks/use-interests";
import { getAccessToken } from "../../utils/authentication";
import { addToast } from "../../redux/Toast/toastAction";
import Loading from "../UI/Loading/Loading";
import { API_SUBSCRIPTION } from "../../constants/API";
import { createRandomId } from "../../utils/common";
import { updateIsAssetsChnage } from "../../redux/User/userAction";
import { Fragment } from "react";
import SelectableButtonList from "../UI/SelectableButtonList/SelectableButtonList";
import SearchableDropdownFilterKeyServicesCreateRequest from "../UI/SearchableDropdownFilterKeyServicesCreateRequest/SearchableDropdownFilterKeyServicesCreateRequest";
import { showEditRequestModal } from "../../redux/Modal/modalAction";
import SearchInput from "../UI/SearchInput/SearchInput";
import leftArrow from "../../assets/images/arrow/left-arrow.svg";
import closeModal from "../../assets/images/Icons/close-modal.svg";
import {
    getAttachmentFileName,
    convertNumberMemberToString,
} from "../../utils/common";
import SingleTextRichArea from '../UI/SingleTextRichArea/SingleTextRichArea';
import SingleTextRichAreaWordCount from "../UI/SingleTextRichAreaWordCount/SingleTextRichAreaWordCount";
const ModalEditRequest = () => {
    const id = useSelector((state) => state.modal.requestCurrentId);
    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);
    const { sendRequest, error, isLoading } = useHttp();
    const [reuiredItem, setReuiredItem] = useState(true);
    const dispatch = useDispatch();
    let history = useHistory();
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const [mySolution, setMySolution] = useState([]);
    const [requestStatus, setRequestStatus] = useState(3);
    //---------keyService search 
    const [searchKey, setSearchKey] = useState('');
    //---------region and country and specialities consts
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [specialities, setSpecialities] = useState([]);
    //------key service category consts
    const [serviceCategories, setServiceCategories] = useState([]);

    const [defaultRequest, setDefaultRequest] = useState([]);
    const [defaultCountries, setDefaultCountries] = useState([]);
    const [defaultCategories, setDefaultCategories] = useState([]);
    const [defaultKeyServices, setDefaultKeyServices] = useState([]);
    const [defaultRegions, setDefaultRegions] = useState([]);
    const [defaultSpecialities, setDefaultSpecialities] = useState([]);
    const [defaultFile, setDefaultFile] = useState([]);
    const [defaultDueDate, setDefaultDueDate] = useState();
    const [defaultId, setDefaultId] = useState();

    //------get categories
    const getServiceCategories = (serviceCategories) => {
        setServiceCategories(serviceCategories);
    };
    const fetchCategories = useCallback(() => {
        sendRequest(
            {
                url: `${API_TAGS_URL}/service-categories/`,
            },
            getServiceCategories
        );
    }, [sendRequest]);


    //---------get default request----------

    const getDefaultRequest = (data) => {
        console.log('default: ', data);
        setDefaultId(data.id);
        let myCountries = [];
        let myRegions = [];
        let myCategories = [];
        let myKeyServices = [];
        let mySpecialities = [];
        data.key_services_data.map((keyService) => (
            myKeyServices.push(keyService.id)
        ));
        data.regions_data.map((region) => (
            myRegions.push(region.id)
        ));
        data.service_categories_data.map((category) => (
            myCategories.push(category.id)
        ));
        data.countries_data.map((country) => (
            myCountries.push(country.id)
        ));
        data.specialities_data.map((speciality) => (
            mySpecialities.push(speciality.id)
        ));
        setDefaultCountries(convertNumberMemberToString(myCountries));
        setDefaultCategories(convertNumberMemberToString(myCategories));
        setDefaultKeyServices(convertNumberMemberToString(myKeyServices));
        setDefaultRegions(convertNumberMemberToString(myRegions));
        setDefaultSpecialities(convertNumberMemberToString(mySpecialities));
        setDefaultFile(data.attachments[0]
            ? getAttachmentFileName(data.attachments[0])
            : data.attachments[0]);
        //setDefaultFile(data.attachments[0]);
        reset({
            title: data.title,
            description: data.content,
        });
        setDefaultDueDate(data.due_date);
        //   setSelectedRequestSize(data.request_size_data);
        setDefaultRequest(data);
    };
    const fetchDefaultRequest = useCallback(() => {
        const accessToken = getAccessToken();
        sendRequest({
            url: `${API_REQUEST_URL}${id}`,
            headers: { Authorization: `Bearer ${accessToken}` },
        }, getDefaultRequest);
    }, [id, sendRequest]);

    //------get general info
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        setError,
        reset,
    } = useForm({
        defaultValues: {



        }
    });
    //-------
    const searchInputHandler = (searchKey) => {
        setSearchKey(searchKey);
    }

    const changeStep = (from, to) => {
        // console.log(from,to);
        switch (from) {
            case 'step1':
                setStep1(false);
                break;
            case 'step2':
                setStep2(false);
                break;
            case 'step3':
                setStep3(false);
                break;
        }
        switch (to) {
            case 'step1':
                setStep1(true);
                break;
            case 'step2':
                setStep2(true);
                break;
            case 'step3':
                setStep3(true);
                break;
        }
    };



    //get company solution------------------------------------------------------------------------------------------

    const getMySolution = (data) => {
        console.log(data);
        if (!data.is_active) {
            // history.push("/subscription");
            // dispatch(showEditRequestModal(false));
        }
        setMySolution(data);
    };

    const fetchMySolution = useCallback(() => {
        if (isLoggedIn || getAccessToken()) {
            const token = getAccessToken();
            sendRequest({
                url: `${API_SUBSCRIPTION}/current-subscription/`,
                headers: { Authorization: `Bearer ${token}` },
            }, getMySolution);
        }
        else {
            // history.push("/subscription");
            // dispatch(showEditRequestModal(false))
        }
    }, [sendRequest, isLoggedIn]);

    // create request object----------------------------------------------------------------------

    const setCreateRequestRequest = (submittedForm) => {
        console.log(submittedForm);
        return {
            title: submittedForm.title,
            content: submittedForm.description,
            service_categories: interestItems.selectedCategoriesId,
            key_services: interestItems.selectedKeyServicesId,
            due_date: submittedForm.duedate,

        };
    };

    const handleServiceCategoriesFunction = (selectedCategories) => {
        setDefaultCategories(selectedCategories);
        handleServiceCategories(selectedCategories);
    }
    const removeSelectedCategoriesFunction = (id) => {
        setDefaultCategories(defaultCategories.filter((category) => category !== id));
        removeSelectedCategories(id);
    }
    const {
        handleServiceCategories,
        handleKeyServices,
        handleRegions,
        handleCountries,
        handleSpecialities,
        removeSelectedCategories,
        removeSelectedKeyServices,
        removeSelectedRegion,
        removeSelectedCountries,
        removeSelectedSpecialities,
        interestItems,
        detectIsSubmitForm,
    } = useInterests(
        defaultCategories,
        defaultKeyServices,
        defaultRegions,
        defaultCountries,
        defaultSpecialities,
        serviceCategories,
        regions,
        countries,
        specialities,
        "request"
    );
    //------------------------------------------------------------------------------------------
    const displayCreateToast = useCallback((message) => {
        dispatch(
            addToast({
                id: createRandomId(),
                status: "success",
                description: message,
            })
        );
    }, [dispatch]);

    const getCreatedRequestRes = (res) => {
        dispatch(updateIsAssetsChnage(true));
        if (requestStatus == 3) {
            displayCreateToast("Your request has created successfully.");
            dispatch(showEditRequestModal(false));
            history.push("/request?page=1&ordering=-created_at");
        }
        else {
            displayCreateToast("Your request was saved as draft.");
            dispatch(showEditRequestModal(false));
            history.push("/dashboard/all-drafts?page=1&ordering=-created_at");
        }
        // uploadCreateRequestFiles(res.id);
    };

    const sumbitCreateRequest = (submittedForm) => {
        const token = getAccessToken();
        const createRequestRequest = setCreateRequestRequest(submittedForm);
        console.log('request request: ', createRequestRequest);
        const formData = new FormData();
        // if (requestStatus == 1)
        //     formData.append("id", defaultId);
        if (submittedForm.attachFiles)
            formData.append("file", submittedForm.attachFiles);
        if (submittedForm.duedate) {
            formData.append("due_date", submittedForm.duedate);
        }
        else {
            formData.append("due_date", '');
        }
        formData.append("title", submittedForm.title);
        formData.append("request_size", 1);
        formData.append("content", submittedForm.description);
        formData.append("status", requestStatus);
        let categoryArray = interestItems.selectedCategoriesId.map((i) => Number(i));
        let keyServiceArray = interestItems.selectedKeyServicesId.map((i) => Number(i));
        for (var i = 0; i < categoryArray.length; i++) {
            formData.append('service_categories', categoryArray[i]);
        }
        for (var i = 0; i < keyServiceArray.length; i++) {
            formData.append('key_services', keyServiceArray[i]);
        }

        sendRequest(
            {
                url: requestStatus == 1 ? `${API_REQUEST_URL}${defaultId}/` : `${API_REQUEST_URL}`,
                method: requestStatus == 1 ? "PUT" : "POST",
                body: formData,
                headers: { Authorization: `Bearer ${token}` },
            },
            getCreatedRequestRes
        );
    };
    // upload file and company picture after creating company(second call)-------------------------

    //------------------------------------------------------------------------------------------
    useEffect(() => {
        console.log(error);
        if (error && error.status === 400) {
            error.data.errors.title &&
                setError("name", { type: "custom", message: error.data.errors.title });
            error.data.errors.owner_email &&
                setError("email", {
                    type: "custom",
                    message: error.data.errors.owner_email,
                });
            dispatch(
                addToast({
                    id: createRandomId(),
                    status: "error",
                    description: error.data?.detail,
                })
            );
        }
    }, [dispatch, error, setError]);

    useEffect(() => {
        fetchDefaultRequest();
        // fetchMySolution();
        fetchCategories();
    }, [fetchDefaultRequest, fetchCategories]);
    useEffect(() => {
        console.log('mysteps: ', step1, step2, step3);
    }, [step1, step2, step3]);
    return (
        <section className={classes.section}>
            {isLoading && (
                <div
                    className={`row justify-content-center  align-items-center ${classes["min-h"]}`}
                >
                    <Loading className={classes.loading} />
                </div>
            )}
            {(!isLoading) && (
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-12 p-0">
                            <div className={classes["modal-header"]}>
                                <div className={classes["step-title"]}>Create Request</div>
                                <button
                                    className={`btn`}
                                    onClick={() => { dispatch(showEditRequestModal(false)) }}
                                >
                                    <img src={closeModal} />
                                </button>
                            </div>
                            <div className={classes["description-wrapper"]}>

                                {step1 && (
                                    <Fragment>
                                        <div className={classes["description"]}>
                                            Which categories does your request fit in? (select up to 5)
                                        </div>
                                        <div className={`${classes["select-category"]}`}>
                                            <SelectableButtonList
                                                className="w-100"
                                                filterName="Categories"
                                                initialFilters={interestItems.selectedCategoriesId}
                                                filterList={serviceCategories}
                                                dispatchFilterList={handleServiceCategoriesFunction}
                                                isSelectedItem={interestItems.isSelectedCategories}
                                                isDisabled={false}
                                                errorMessage={interestItems.categoriesErrorMessage}
                                                removeSelectedItem={removeSelectedCategories}
                                            />
                                        </div>
                                        <div className={classes["footer-wrapper"]}>
                                            <div></div>

                                            <button
                                                className={classes["btn-next"]}
                                                onClick={() => { changeStep('step1', 'step2') }}
                                                disabled={!interestItems.selectedCategoriesId.length}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                                {step2 && (
                                    <Fragment>
                                        <div className={classes["description"]}>
                                            Select Key Services
                                            <SearchInput
                                                onChangeHandler={searchInputHandler}
                                            />
                                        </div>
                                        <div className={classes["detail-description"]}>
                                            Help people find your project easily by selecting the key services based on the categories you've chosen.
                                        </div>
                                        <div className={classes["keyservices-wrapper"]}>
                                            <SearchableDropdownFilterKeyServicesCreateRequest
                                                className="w-100"
                                                filterName="Key Services"
                                                initialFilters={interestItems.selectedKeyServicesId}
                                                filterList={interestItems.keyServices}
                                                dispatchFilterList={handleKeyServices}
                                                isSelectedItem={interestItems.isSelectedKeyServices}
                                                isDisabled={interestItems.isDiabled}
                                                selectedCategories={interestItems.selectedCategoriesId}
                                                categories={serviceCategories}
                                                selectUnSelectItems={interestItems.selectedKeyServices}
                                                removeSelectedItem={removeSelectedKeyServices}
                                                searchKey={searchKey}
                                            // customLabel='Available Services are affected by Categories you’ve selected'
                                            />

                                        </div>
                                        <div className={classes["footer-wrapper"]}>
                                            <button
                                                onClick={() => { changeStep('step2', 'step1') }}
                                                className={`btn ${classes.back}`}
                                                type="button"
                                            >
                                                <img src={leftArrow} alt="arrow" />
                                                Back
                                            </button>
                                            <button
                                                className={classes["btn-next"]}
                                                onClick={() => { changeStep('step2', 'step3') }}
                                                disabled={!interestItems.selectedKeyServicesId.length}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                                {step3 && (
                                    <Fragment>
                                        <div className={classes["description"]}>
                                            Let’s add the final details
                                        </div>
                                        <div className={classes["detail-description"]}>
                                            Now it's time to provide additional information about your project. Be as concise and accurate as possible to ensure the best match for you.
                                        </div>
                                        <form onSubmit={handleSubmit(sumbitCreateRequest)}>
                                            <div className={classes["request-detail-scroll"]}>
                                                <div className={classes["request-details-wrapper"]}>

                                                    <div className="row">
                                                        <div className="col-lg-8 col-12">
                                                            <SimpleInput
                                                                validation={{
                                                                    required: reuiredItem,
                                                                    maxLength: {
                                                                        value: 200,
                                                                        message: 'This value is too long. It should have 200 characters or fewer.' // JS only: <p>error message</p> TS only support string
                                                                    }
                                                                }}
                                                                input={{
                                                                    type: "text",
                                                                    label: "Title*",
                                                                    name: "title",
                                                                    placeholder: "Title",
                                                                    id: "Title",
                                                                    autoComplete: "off",
                                                                }}
                                                                errors={errors}
                                                                register={register}
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 col-12 mb-4">
                                                            <DatePickerDueDateSimple
                                                                title="Due Date:"
                                                                validation={{
                                                                    required: reuiredItem,
                                                                }}
                                                                input={{
                                                                    type: "text",
                                                                    name: "duedate",
                                                                }}
                                                                register={register}
                                                                errors={errors}
                                                                setValue={setValue}
                                                                getValues={getValues}
                                                                previewdValue={defaultDueDate}
                                                                placeHolder="Select Due Date"

                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <SingleTextAreaWithWordCounter
                                                    validation={{
                                                        required: reuiredItem,
                                                        validate: {
                                                            positive: v => v.split(' ').length > 49 || '' || !reuiredItem,
                                                        }
                                                    }}
                                                    input={{
                                                        type: "text",
                                                        label: "Description*",
                                                        name: "description",
                                                        placeholder: "Description",
                                                        autoComplete: "off",
                                                        //onKeyUp: () => {alert("ads")},

                                                    }}
                                                    errors={errors}
                                                    register={register}
                                                    onKeyUpHandler={() => { }}
                                                /> */}
                                                    <SingleTextRichAreaWordCount
                                                        validation={{
                                                            required: reuiredItem,
                                                            validate: {
                                                                positive: v => v.split(' ').length > 49 || '' || !reuiredItem,
                                                            }
                                                        }}
                                                        input={{
                                                            type: "text",
                                                            label: "Description*",
                                                            name: "description",
                                                            placeholder: "Description",
                                                            autoComplete: "off",
                                                            // onChange: () =>{console.log('keyup')}
                                                            //onKeyUp: () => {alert("ads")},
                                                        }}
                                                        getValues={getValues}
                                                        setValue={setValue}
                                                        errors={errors}
                                                        register={register}
                                                        isApplication={false}
                                                        onKeyUpHandler={() => { }}
                                                        hasInitialValue={true}
                                                    />
                                                    {/* <SingleTextRichArea
                                                    validation={{
                                                        required: reuiredItem,
                                                        validate: {
                                                            positive: v => v.split(' ').length > 49 || '' || !reuiredItem,
                                                        }
                                                    }}
                                                    input={{
                                                        type: "text",
                                                        label: "Description*",
                                                        name: "description",
                                                        placeholder: "Description",
                                                        autoComplete: "off",
                                                        //onKeyUp: () => {alert("ads")},
                                                    }}
                                                    getValues={getValues}
                                                    setValue={setValue}
                                                    errors={errors}
                                                    register={register}                                                    
                                                    onKeyUpHandler={() => { }}
                                                /> */}
                                                    <div className={classes.fileInputButton}>
                                                        <AttachFileInput
                                                            validation={{
                                                                required: false,
                                                            }}
                                                            input={{
                                                                type: "file",
                                                                name: "attachFiles",
                                                            }}
                                                            register={register}
                                                            errors={errors}
                                                            setValue={setValue}
                                                            getValues={getValues}
                                                            placeHolderText="Feel free to upload one PDF file to provide more information and to increase the quality of your request."
                                                            previewdValue={defaultFile}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <button
                                                        onClick={() => { changeStep('step3', 'step2') }}
                                                        className={`btn ${classes.back} ${classes["last-back"]}`}
                                                        type="button"
                                                    >
                                                        <img src={leftArrow} alt="arrow" />
                                                        Back
                                                    </button>
                                                </div>
                                                <div className="col-md-5 text-end">
                                                    <button
                                                        onClick={
                                                            () => {
                                                                setReuiredItem(false);
                                                                setRequestStatus(1);
                                                            }}
                                                        className={`${classes.draftButton}`}
                                                        type="submit"
                                                    >
                                                        Save Draft
                                                    </button>
                                                </div>
                                                <div className="col-md-3 text-end">
                                                    <button
                                                        onClick={
                                                            () => {
                                                                detectIsSubmitForm();
                                                                setReuiredItem(true);
                                                                setRequestStatus(3);
                                                            }}
                                                        className={`btn ${classes.submit}`}
                                                        type="submit">
                                                        Create Request
                                                        <div className={classes["loading-box"]}>
                                                            {isLoading && <Loading className={classes.loading} />}
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>

                                    </Fragment>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default ModalEditRequest;