import { useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import useHttp from "../../../hooks/use-http";
import { API_AUTH_URL } from "../../../constants/API";

import SimpleInput from "../../../components/UI/SimpleInput/SimpleInput";
import Loading from "../../../components/UI/Loading/Loading";


import { addToast } from "../../../redux/Toast/toastAction";
import { isLoggedIn } from "../../../redux/User/userAction";
import { setAuthorization } from "../../../utils/authentication";
import { createRandomId } from "../../../utils/common";

import classes from "./Login.module.scss";

const Login = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const { sendRequest, error, isLoading } = useHttp();
	const history = useHistory();
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.user.userProfile);
	// const userRole = useSelector((state) => state.user.userRole);
	// const isLogin = useSelector((state) => state.user.isLoggedIn);

	const getAuthorizationTokens = (data) => {
		setAuthorization(data);
		dispatch(isLoggedIn(true));
	};

	const onSubmit = (submittedForm) => {
		const loginRequest = {
			username: submittedForm.email,
			password: submittedForm.password,
		};
		sendRequest(
			{ url: `${API_AUTH_URL}token/`, method: "POST", body: loginRequest },
			getAuthorizationTokens
		);
	};

	useEffect(() => {
		// user is not verified
		if (error && error.status === 400) {
			history.push("/authentication/resend-verification");
		}
		if (error && error.status === 401) {
			dispatch(
				addToast({
					id: createRandomId(),
					status: "error",
					description: error.data.detail,
				})
			);
		}
	}, [dispatch, error, history, profile]);

	// useEffect(() => {

	//   if (userRole === "guest" && isLogin && profile === null) {
	//     history.push("/guest/create-profile");
	//   }  else if (userRole === "guest" && isLogin) {
	//     // user filled profile page but has not selected his role yet
	//     history.push("/guest/user-role");
	//   } // user doesnt have company and login for first time(doesnt have any interest)
	//   else if (( userRole === "company") && isLogin) {
	//     history.push("/dashboard/my-company");
	//   }
	//   else if ((userRole === "freelancer" ) && isLogin) {
	//     history.push("/dashboard/profile");
	//   }
	// }, [userRole, history, isLogin, profile]);

	return (
		<section className={`${classes["section-container"]}`}>
			<div className={`${classes["form-container"]} container mx-3 mx-lg-0 my-2 my-md-5`}>
				<h1 className={classes['title']}>
					Welcome Back to <span className={classes['pam']}>Pharmatching</span>
				</h1>
				<form onSubmit={handleSubmit(onSubmit)}>
					<SimpleInput
						validation={{
							required: true,
							pattern: {
								value:
									/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: "Please enter a valid email",
							},
						}}
						input={{
							type: "text",
							label: "Email",
							name: "email",
							placeholder: "Email",
							id: "Email",
							autoComplete: "off",
						}}
						errors={errors}
						register={register}
					/>
					<SimpleInput
						validation={{
							required: true,
						}}
						input={{
							type: "password",
							label: "Password",
							name: "password",
							placeholder: "Password",
							id: "Password",
							autoComplete: "off",
							hasappend: "true"
						}}
						errors={errors}
						register={register}
					/>
					<button
						className={`btn ${classes["submit"]}`}
						type="submit"
					>
						Login
					</button>
				</form>
				<div className={classes["loading-box"]}>
					{isLoading && <Loading className={classes.loading} />}				
				</div>
				<div className="text-center mt-1">
					<Link
						className={classes["register-link"]}
						to="/authentication/forget-password"
					>
						Forgot password?
					</Link>
				</div>

				<div className="text-center mt-4">
					<span className={classes["dont-account"]}>
						Don’t have an account?
					</span>
					<Link
						to="/authentication/register"
						className={classes["register-link"]}
					>
						Register
					</Link>
				</div>
			</div>
		</section>
	);
};

export default Login;
