import { useSelector } from "react-redux";
import classes from "./Sidebar.module.scss";
import { NavLink } from "react-router-dom";
import userIcon from "../../../assets/images/Icons/user.svg";
import myCompanyIcon from "../../../assets/images/Icons/my-company.svg";
import sendAppIcon from "../../../assets/images/Icons/send-Application.svg";
import sendReqIcon from "../../../assets/images/Icons/send-request.svg";
import recievedAppIcon from "../../../assets/images/Icons/recieved-application.svg";
import draftsIcon from "../../../assets/images/Icons/drafts.svg";
import wizardIcon from "../../../assets/images/Icons/wizard.svg";
import defaultAvatar from "../../../assets/images/Icons/profile-user.svg";
import { Fragment } from "react";

const Sidebar = () => {
  const userProfile = useSelector((state) => state.user.userProfile);
  const userRole = useSelector((state) => state.user.userRole);
  const userCompany = useSelector((state) => state.user.userCompany);
  return (
    <div className={`d-md-flex flex-column ${classes.sidebar}`}>
      <div className={classes["user-information"]}>
        {userProfile && (
          <div
            style={{
              backgroundImage: userProfile.image
                ? `url(${userProfile.image.file})`
                : `url(${defaultAvatar})`,
            }}
            className={classes["profile-avatar"]}
          ></div>
        )}

        {userProfile && (
          <span className={classes["user-name"]}>
            {userProfile.user.first_name} {userProfile.user.last_name}
          </span>
        )}
      </div>

      {/* <div className={classes["seprate-line"]}></div> */}
      <ul className={`nav flex-column mb-auto mt-4 ${classes["link-list"]}`}>
        <li className="nav-item">
          <NavLink
            to="/dashboard/profile"
            className={`nav-link ${classes.link}`}
            activeClassName={classes["active-link"]}
          >
            <div className={`${classes["icon-box"]} bi `}>
              <img className={classes.icon} src={userIcon} alt="user" />
            </div>
            <span className={classes.text}>My Profile</span>
          </NavLink>
        </li>
        {userRole && userRole !== "guest" && (
          <Fragment>
            {(userRole === "freelancer" || userCompany.is_admin) &&
              <li className="nav-item">
                <NavLink
                  to="/dashboard/company-dashboard"
                  className={`nav-link ${classes.link}`}
                  activeClassName={classes["active-link"]}
                >
                  <div className={`${classes["icon-box"]} bi `}>
                    <img
                      className={classes.icon}
                      src={myCompanyIcon}
                      alt="user"
                    />
                  </div>
                  <span className={classes.text}> Company dashboard</span>
                </NavLink>
              </li>
            }

            {/* <div className={classes["seprate-line"]}></div> */}
            <li className="nav-item mt-4">
              <NavLink
                exact
                to="/dashboard/all-sent-applications?page=1&ordering=-created_at"
                className={`nav-link ${classes.link}`}
                activeClassName={classes["active-link"]}
              >
                <div className={`${classes["icon-box"]} bi `}>
                  <img className={classes.icon} src={sendAppIcon} alt="user" />
                </div>

                <span className={classes.text}> Sent Applications</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/dashboard/all-requests?page=1&ordering=-created_at"
                className={`nav-link ${classes.link}`}
                activeClassName={classes["active-link"]}
              >
                <div className={`${classes["icon-box"]} bi `}>
                  <img className={classes.icon} src={sendReqIcon} alt="user" />
                </div>

                <span className={classes.text}> Posted Requests</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/dashboard/all-recieved-applications?page=1&ordering=-created_at"
                className={`nav-link ${classes.link}`}
                activeClassName={classes["active-link"]}
              >
                <div className={`${classes["icon-box"]} bi`}>
                  <img
                    className={classes.icon}
                    src={recievedAppIcon}
                    alt="user"
                  />
                </div>

                <span className={classes.text}> Recieved Applications</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/dashboard/all-drafts?page=1&ordering=-created_at"
                className={`nav-link ${classes.link}`}
                activeClassName={classes["active-link"]}
              >
                <div className={`${classes["icon-box"]} bi `}>
                  <img className={classes.icon} src={draftsIcon} alt="user" />
                </div>

                <span className={classes.text}> Drafts</span>
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                exact
                to="/"
                className={`nav-link ${classes.link}`}
                activeClassName={classes["active-link"]}
              >
                <div className={`${classes["icon-box"]} bi `}>
                  <img className={classes.icon} src={wizardIcon} alt="user" />
                </div>

                <span className={classes.text}> Wizard</span>
              </NavLink>
            </li> */}
          </Fragment>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
