import classes from "./SearchableDropdownFilterKeyServicesCreateRequest.module.scss";
import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as React from "react";
import { useDropdown } from "../../../hooks/use-dropdown";
import SimpleCheckBox from "../SimpleCheckBox/SimpleCheckBox";
import SelectUnSelectInterests from "../../SelectUnSelectInterests/SelectUnSelectInterests";

const SearchableDropdownFilterKeyServicesCreateRequest = (props) => {
  const {
    className,
    filterName,
    filterList,
    initialFilters,
    dispatchFilterList,
    isSelectedItem,
    isDisabled,
    errorMessage,
    customLabel,
    selectedCategories,
    categories,
    selectUnSelectItems,
    removeSelectedItem,
    searchKey,
  } = props;
  const [displayedItems, setDisplayedItems] = useState([]);
  const [displayedCategory, setDisplayedCategory] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const { register, handleSubmit, setValue, reset, getValues } = useForm();
  const [dropdownRef, isOpen, setIsOpen] = useDropdown();

  useEffect(() => {
    setDisplayedItems(filterList);
    setAllCategories(categories);
    console.log('categories:',categories);
    console.log('filterList:',filterList);
  }, [filterList], [categories]);

  const setInitialFilters = useCallback(() => {
    if (initialFilters && initialFilters.length !== 0) {
      reset();
      setSelectedFilter(initialFilters.length);
      initialFilters.forEach((item) => {
        setValue(item, true);
      });
    } else {
      setSelectedFilter(initialFilters.length);
      reset();
    }
  }, [initialFilters, reset, setValue]);

  useEffect(() => {
    setInitialFilters();
    console.log('initialFilters:',initialFilters);
  }, [initialFilters, setInitialFilters]);
  useEffect(() => {
    let input = searchKey.toLowerCase();
    let result = filterList.filter((element) =>
      element.title.toLowerCase().includes(input)
    );
    setDisplayedItems(result);
    console.log('displayed items:',result);
  }, [searchKey])

  const onSubmit = (list) => {
    let arr = [];
    for (const key in list) {
      if (list[key]) {
        arr.push(key);
      }
    }
    dispatchFilterList(arr);
    setIsOpen(!isOpen);
    setDisplayedItems(filterList);
  }


  const resetFilters = () => {
    reset();
    setSelectedFilter(0);
  };

  const keyChangeHandler = (id) => {
    const value = getValues(id);
    value ? setValue(id, false) : setValue(id, true);
    value
      ? setSelectedFilter(selectedFilter - 1)
      : setSelectedFilter(selectedFilter + 1);
    // add remove item from list   
    let array = initialFilters;
    if (initialFilters.includes(id)) {
      array = array.filter(item => !(item == id));
    }
    else {
      array = array.filter(item => !(item == id));
      array.push('' + id);
    }
    dispatchFilterList(array);
  };

  const searchHandler = (e) => {
    let input = e.target.value.toLowerCase();
    let result = filterList.filter((element) =>
      element.title.toLowerCase().includes(input)
    );
    setDisplayedItems(result);
  };

  return (
    <div ref={dropdownRef} className={`dropdown ${classes.dropdown}`}>
      <div
        id="filtersBox"
        className={`${className} ${classes["dropdown-list"]}`}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes["key-service-items"]}>
            {allCategories.map((cat) => (
              <React.Fragment
                key={cat.id}>
                {cat.key_services.slice(0, 1).map((keycat) => (
                  <React.Fragment
                    key={keycat.id}
                  >
                    {displayedItems.filter(x => (x.id == keycat.id)).map((item) => (
                      <div className={classes["category-title-container"]}
                        key={item.id}
                      >
                        <hr />
                        <div className={classes["category-title"]}>
                          {cat.title}
                        </div>

                      </div>
                    ))}
                  </React.Fragment>
                ))}
                <div className={classes["key-service-category-wrap"]}>
                  {cat.key_services.map((keycat) => (
                    <React.Fragment
                    key={keycat.id}
                    >
                      {displayedItems.map((item) => (
                        <React.Fragment
                          key={item.id}
                        >
                          {(item.id == keycat.id) && (
                            <div
                              className={classes["key-item"]}
                            >
                              <SimpleCheckBox
                                key={item.id}
                                onChangeHandler={keyChangeHandler}
                                input={{
                                  name: `${item.id}`,
                                  label: `${item.title}`,
                                  id: item.id,
                                }}
                                register={register}
                              />
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchableDropdownFilterKeyServicesCreateRequest;
