import classes from "./NewsLinkGroup.module.scss";
import { NavLink } from 'react-router-dom';

const NewsLinkGroup = () => {
  return (
    <div className={`${classes["buttons-group"]}`}>
      <NavLink
        exact
        className={classes["nav-item"]}
        to="/warning-letter"
        activeClassName={classes["active-link"]}
      >
        Warning Letters
      </NavLink>

      <NavLink
        className={classes["nav-item"]}
        to="/news"
        activeClassName={classes["active-link"]}
      >
        News
      </NavLink>
    </div>
  );
};

export default NewsLinkGroup;
