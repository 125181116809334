import React, { useState, useEffect, Fragment } from 'react';
import { Link } from "react-router-dom";
import classes from './RequestViewExpertise.module.scss';

const RequestViewExpertise = (props) => {
    const { requestInfo } = props;
    const [maxKeyService, setMaxKeyService] = useState(0);

    useEffect(() => {
        if (requestInfo.key_services_data.length > 6)
            setMaxKeyService(6);

    }, [requestInfo]);

    return (
        <div className={classes["wrapper"]}>
            <div className={classes["title"]}>
                Request Categories:
            </div>
            {requestInfo.service_categories_data.map((category) => (
                <Link
                    to={`/request?page=1&service_categories=${category.id}`}
                    key={category.id}
                    className={`${classes.item}`}
                >
                    {category.title}
                </Link>
            ))}

            <div className={`${classes["title"]} ${classes["second"]}`}>
                Request Key Services:
            </div>
            {maxKeyService !== 0 && (
                <Fragment>
                    {requestInfo.key_services_data.slice(0, maxKeyService).map((keyService) => (
                        <Link
                            to={`/request?page=1&key_services=${keyService.id}`}
                            key={keyService.id}
                            className={`${classes.item} ${classes.keyservice}`}
                        >
                            {keyService.title}
                        </Link>
                    ))}
                    <div
                        className={classes["show-more"]}
                        onClick={()=>{setMaxKeyService(0)}}
                    >
                        +{requestInfo.key_services_data.length - 6}  more
                    </div>
                </Fragment>
            )}
            {maxKeyService == 0 && (
                requestInfo.key_services_data.map((keyService) => (
                    <Link
                        to={`/request?page=1&key_services=${keyService.id}`}
                        key={keyService.id}
                        className={`${classes.item} ${classes.keyservice}`}
                    >
                        {keyService.title}
                    </Link>
                ))
            )}




        </div>
    );
};

export default RequestViewExpertise;