import { Fragment, useRef, useState } from "react";
import ReactDOM from "react-dom";
import classes from "./CroperModal.module.scss";
import Backdrop from "../../UI/Backdrop/Backdrop";
import Card from "../../UI/Card/Card";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import closeBtn from "../../../assets/images/Icons/close-button-toast.svg";

const ModalOverlay = ({ closeModal, image, cropImage }) => {
  const cropperRef = useRef();
  const [cropper, setCropper] = useState();

  const onCrop = () => {
    if (typeof cropper !== "undefined") {
      // conver base 64 to file------------------------------------------------
      let arr = cropper.getCroppedCanvas().toDataURL("image/png").split(",");
      let  mime = arr[0].match(/:(.*?);/)[1];
      let  bstr = atob(arr[1]);
      let  n = bstr.length;
       let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const croppedFile = new File([u8arr], "image.png", { type: mime });
      const previewCroppedImage = cropper.getCroppedCanvas().toDataURL("image/png")
      cropImage(previewCroppedImage ,croppedFile );
      closeModal();
    }
  };

  const resetCropper = () => {
    cropper.reset();
  };

  return (
    <Card className={classes.modal}>
      <div className={classes["title-box"]}>
        <h4 className={classes.title}>Please crop your logo</h4>
        <img
          className={classes.close}
          src={closeBtn}
          alt="close"
          onClick={closeModal}
        />
      </div>

      <Cropper
        src={image}
        style={{ height: "auto", width: "100%" }}
        aspectRatio={2.88}
        guides={false}
        ref={cropperRef}
        movable={false}
        dragMode="move"
        background={false}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
      />
      <div>
        <button className={`btn ${classes["btn-crop"]}`} onClick={onCrop}>
          Crop
        </button>
        <button
          className={`btn ${classes["btn-restore"]}`}
          onClick={resetCropper}
        >
          Restore
        </button>
      </div>
    </Card>
  );
};

const CropperModal = ({
  onCloseCropperModal,
  companyImage,
  onCropCompanyImage,
}) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          closeModal={onCloseCropperModal}
          image={companyImage}
          cropImage={onCropCompanyImage}
        />,
        document.getElementById("overlay-root")
      )}
    </Fragment>
  );
};

export default CropperModal;
