import { useState, useCallback, useEffect, useMemo } from "react";
import classes from "./CompanyExperties.module.scss";
import useHttp from "../../../hooks/use-http";
import { API_TAGS_URL } from "../../../constants/API";
import { useForm } from "react-hook-form";
import SearchableDropdownFilter from "../../UI/SearchableDropdownFilter/SearchableDropdownFilter";
import SelectUnSelectInterests from "../../SelectUnSelectInterests/SelectUnSelectInterests";
import Loading from "../../UI/Loading/Loading";
import { useInterests } from "../../../hooks/use-interests";
import Card from "../../../components/UI/Card/Card";
import leftArrow from "../../../assets/images/arrow/left-arrow.svg";
import AttachFileInput from "../../UI/AttachFileInput/AttachFileInput";
import SelectableButtonList from "../../UI/SelectableButtonList/SelectableButtonList";
import SearchableDropdownFilterKeyServices from "../../UI/SearchableDropdownFilterKeyServices/SearchableDropdownFilterKeyServices";
import SearchableDropDownFilterLifeScience from "../../UI/SearchableDropDownFilterLifeScience/SearchableDropDownFilterLifeScience";
const CompanyExperties = ({
  onSumbitCreateCompany,
  OnUpdateActiveStep,
  onGetCompanyExperties,
  companyExperties,
  activeIndex,
  activeLoading,
  submitButtonText,
  generalInfo,
}) => {
  const { sendRequest } = useHttp();
  const [serviceCategories, setServiceCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const {
    handleServiceCategories,
    handleKeyServices,
    handleRegions,
    handleCountries,
    handleSpecialities,
    removeSelectedCategories,
    removeSelectedKeyServices,
    removeSelectedRegion,
    removeSelectedCountries,
    removeSelectedSpecialities,
    detectIsSubmitForm,
    interestItems,
  } = useInterests(
    companyExperties.categories,
    companyExperties.keyServices,
    companyExperties.regions,
    companyExperties.countries,
    companyExperties.specialities,
    //useMemo(() => [], []),
    serviceCategories,
    regions,
    countries,
    specialities,
    "company",
    onGetCompanyExperties
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: generalInfo
      ? {
        attachFiles: generalInfo["attachFiles"],
      }
      : {},
  });
  useEffect(() => {
    if (generalInfo) {
      setValue("attachFiles", generalInfo.attachFiles);
    }
  }, [generalInfo, setValue]);
  // set categories /regions and countries lists after fetching-----------------------

  const getServiceCategories = (serviceCategories) => {
    setServiceCategories(serviceCategories);
  };

  const getRegionsList = (regions) => {
    setRegions(regions);
  };

  const getCountriesList = (countries) => {
    setCountries(countries);
  };

  const getSpecialities = (specialities) => {
    setSpecialities(specialities);
  };

  // fetch categories / key services / regions / countires----------------------------------------

  const fetchCategories = useCallback(() => {
    sendRequest(
      {
        url: `${API_TAGS_URL}/service-categories/`,
      },
      getServiceCategories
    );
  }, [sendRequest]);

  const fetchRegions = useCallback(() => {
    sendRequest(
      {
        url: `${API_TAGS_URL}/regions/`,
      },
      getRegionsList
    );
  }, [sendRequest]);

  const fetchCountries = useCallback(() => {
    sendRequest(
      {
        url: `${API_TAGS_URL}/countries/`,
      },
      getCountriesList
    );
  }, [sendRequest]);

  const fetchSpecialitiesList = useCallback(() => {
    sendRequest(
      {
        url: `${API_TAGS_URL}/specialities/`,
      },
      getSpecialities
    );
  }, [sendRequest]);

  useEffect(() => {
    fetchCategories();
    fetchRegions();
    fetchCountries();
    fetchSpecialitiesList();
    return () => {
      setServiceCategories([]);
      setCountries([]);
      setRegions([]);
      setSpecialities([]);
    };
  }, [fetchCategories, fetchRegions, fetchCountries, fetchSpecialitiesList]);
  //----------------------------------------------------------------------------------------------

  const termsConditionHandler = (e) => {
    setIsChecked(e.target.checked);
  };

  // submit form--------------------------------------------------------------------------------
  const onSubmit = (submittedForm) => {
    const isSubmit = detectIsSubmitForm();
    console.log(isSubmit);
    if (isSubmit) {
      onSumbitCreateCompany(submittedForm);
    }
  };

  const backButton = () => {
    OnUpdateActiveStep(2);
  };

  return (
    <div style={{ display: activeIndex === 3 ? "block" : "none" }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <Card className={classes["step-card"]}>
          <div className={classes["section-title"]}>
            Company Expertise
          </div>
          <div className={classes["section-subtitle"]}>
            This information will be publicly displayed on your company profile and you can change it later.
          </div>
          <div
            className="row"
          >
            <div className={classes["input-container"]}>
              <div className={classes["custom-input-label"]}>
                Select your Company Life Science Sectors* (Up to 3)
              </div>
              <div className={`col-12`}>
                <SearchableDropDownFilterLifeScience
                  className="w-100"
                  filterName="Life Science Sector "
                  initialFilters={interestItems.selectedSpecialitiesId}
                  filterList={specialities}
                  dispatchFilterList={handleSpecialities}
                  isSelectedItem={interestItems.isSelectedSpecialities}
                  isDisabled={false}
                  errorMessage={interestItems.specialitiesErrorMessage}
                  // customLabel="Select your Life Science Sector"
                  selectUnSelectItems={interestItems.selectedSpecialities}
                  removeSelectedItem={removeSelectedSpecialities}
                />
              </div>
              {/* <div className="col-12">
              <SelectUnSelectInterests
                title="Specialities"
                bgColor="#7894BC"
                selectedItems={interestItems.selectedSpecialities}
                removeSelectedItem={removeSelectedSpecialities}
                color="#fff"
              />
            </div> */}
            </div>
            <div className={classes["line"]}></div>
            <div className={classes["input-container"]}>
              <div className={`${classes["custom-input-label-category"]} mb-4`}>
                Select your Company Categories* (Up to 5)
              </div>
              <div className={`col-12`}>
                <SelectableButtonList
                  className="w-100"
                  filterName="Categories"
                  initialFilters={interestItems.selectedCategoriesId}
                  filterList={serviceCategories}
                  dispatchFilterList={handleServiceCategories}
                  isSelectedItem={interestItems.isSelectedCategories}
                  isDisabled={false}
                  errorMessage={interestItems.categoriesErrorMessage}
                  removeSelectedItem={removeSelectedCategories}
                />
              </div>
              {/* <div className="col-12">
              <SelectUnSelectInterests
                title="Categories"
                bgColor="#7894BC"
                selectedItems={interestItems.selectedCategories}
                removeSelectedItem={removeSelectedCategories}
                color="#fff"
              />
            </div> */}

              <div className={`col-12`}>
                <div className={classes["custom-input-label"]}>
                  Select your Company Key Services* (Available Services are affected by Categories you’ve selected)
                </div>
                <SearchableDropdownFilterKeyServices
                  className="w-100"
                  filterName="Key Services"
                  initialFilters={interestItems.selectedKeyServicesId}
                  filterList={interestItems.keyServices}
                  dispatchFilterList={handleKeyServices}
                  isSelectedItem={interestItems.isSelectedKeyServices}
                  isDisabled={interestItems.isDiabled}
                  selectedCategories={interestItems.selectedCategoriesId}
                  categories={serviceCategories}
                  selectUnSelectItems={interestItems.selectedKeyServices}
                  removeSelectedItem={removeSelectedKeyServices}
                // customLabel='Available Services are affected by Categories you’ve selected'
                />
              </div>
              <div className="col-12">
                {/* <SelectUnSelectInterests
                bgColor="#D3F2FA"
                selectedItems={interestItems.selectedKeyServices}
                removeSelectedItem={removeSelectedKeyServices}
                color="#1E4C90"
              /> */}
              </div>
              {/* <div className={`h-70 col-12`}>
              <SearchableDropdownFilter
                className="w-100"
                filterName="Regions"
                initialFilters={interestItems.selectedRegionId}
                filterList={regions}
                dispatchFilterList={handleRegions}
                isSelectedItem={interestItems.isSelectedRegion}
                isDisabled={false}
              />
            </div>
            <div className="col-12">
              <SelectUnSelectInterests
                bgColor="#E4EAF5"
                selectedItems={interestItems.selectedRegion}
                removeSelectedItem={removeSelectedRegion}
                color="#013480"
              />
            </div>
            <div className={`h-70 col-12`}>
              <SearchableDropdownFilter
                className="w-100"
                filterName="Countries"
                initialFilters={interestItems.selectedCountriesId}
                filterList={countries}
                dispatchFilterList={handleCountries}
                isSelectedItem={interestItems.isSelectedCountries}
                isDisabled={false}
              />
            </div>
            <div className="col-12">
              <SelectUnSelectInterests
                bgColor="#FFF8E6"
                selectedItems={interestItems.selectedCountries}
                removeSelectedItem={removeSelectedCountries}
                color="#664900"
              />
            </div> */}
            </div>
            <div className={classes["line"]}></div>
            <div className={`${classes["section-title"]} mb-3`}>
              Company Certificate
            </div>
            <div className={classes["input-container"]}>
              <AttachFileInput
                validation={{
                  required: false,
                }}
                input={{
                  type: "file",
                  name: "attachFiles",
                }}
                register={register}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                previewdValue={generalInfo?.attachFiles}
                placeHolderText="You can upload your Certificates and / or additional company information combined into one PDF (max. 20 MB) here."
              />
            </div>

            <div className={classes["invoice-form"]}>
              <div className="form-check">
                <input
                  onChange={(e) => termsConditionHandler(e)}
                  className={`form-check-input ${classes["form-check-input"]}`}
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label
                  className={`form-check-label ${classes["form-check-label"]}`}
                  htmlFor="flexCheckDefault"
                >
                  <span style={{ marginRight: 5 }}>I have read and agree to the</span>
                  <a
                    href="/privacy-policy/"
                    rel="noreferrer"
                    target="_blank"
                    className={classes["privacy-terms"]}
                  >
                    Privacy Policy
                  </a>
                </label>
              </div>
            </div>

          </div>
          <div className="col-12 d-flex justify-content-between">
            <button
              onClick={backButton}
              className={`btn ${classes.back}`}
              type="button"
            >
              <img src={leftArrow} alt="arrow" />
              Back
            </button>
            <button
              disabled={!isChecked}
              // onClick={onSubmit}
              className={`${classes.submit}`}
              type="submit"
            >
              {submitButtonText}
            </button>
          </div>
          <div className={classes["loading-box"]}>
            {activeLoading && <Loading className={classes.loading} />}
          </div>
        </Card>

      </form>

    </div>
  );
};

export default CompanyExperties;
