import React, {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {getAccessToken} from '../../../utils/authentication';
import useHttp from "../../../hooks/use-http";
import {API_COMPANIES_URL, API_REQUEST_URL, API_APPLICATION_URL} from "../../../constants/API";
import DashboardLatestRequests from '../../../components/DashboardLatestRequests/DashboardLatestRequests';
import DashboardSentApplications from '../../../components/DashboardSentApplications/DashboardSentApplications';
import CompanyViewsChart from "../../../components/CompanyViewsChart/CompanyViewsChart";
import CategoryChart from "../../../components/CategoryChart/CategoryChart";


const CompanyDashboard = () => {
    const userCompany = useSelector((state) => state.user.userCompany);
    const {sendRequest, error} = useHttp();
    const [companyAssets, setCompanyAssets] = useState(null);
    const [sentRequestList, setSentRequestList] = useState([]);
    const [sentApplicationList, setSentApplicationList] = useState([]);

    const getCompanyAssets = (data) => {
        console.log("getCompanyAssets", data);
        setCompanyAssets(data);
    };
    const getSentRequestList = (data) => {
        console.log('request list: ', data);
        setSentRequestList(data.results);
    };
    const getSentApplicationList = (data) => {
        console.log('application list: ', data);
        setSentApplicationList(data.results);
    };

    const fetchComapayAssets = () => {
        const token = getAccessToken();
        sendRequest(
            {
                url: `${API_COMPANIES_URL}/company/${userCompany.id}/assets/`,
                headers: {Authorization: `Bearer ${token}`},
            },
            getCompanyAssets
        );
    };
    const fetchSentRequestList = useCallback(() => {
        const accessToken = getAccessToken();
        sendRequest(
            {
                url: `${API_REQUEST_URL}user-requests/?limit=5&offset=0&ordering=-created_at`,
                headers: {Authorization: `Bearer ${accessToken}`},
            },
            getSentRequestList
        );
    }, []);
    const fetchSentApplicationList = useCallback(() => {
        const accessToken = getAccessToken();
        sendRequest(
            {
                url: `${API_APPLICATION_URL}user-sent-applications/?limit=5&offset=0&ordering=-created_at`,
                headers: {Authorization: `Bearer ${accessToken}`},
            },
            getSentApplicationList
        );
    }, []);
    useEffect(() => {
        // company , freelancer
        if (userCompany) {
            fetchComapayAssets();
        }
    }, [userCompany]);
    useEffect(() => {
        fetchSentRequestList();
        fetchSentApplicationList();
    }, [fetchSentRequestList, fetchSentApplicationList])
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <CompanyViewsChart userCompany={userCompany}/>
                    </div>
                    <div className="col-6">
                        <CategoryChart userCompany={userCompany} />
                    </div>
                </div>
            </div>
            <DashboardLatestRequests
                companyAssets={companyAssets}
                sentRequestList={sentRequestList}
            />
            <DashboardSentApplications
                companyAssets={companyAssets}
                sentApplicationList={sentApplicationList}
            />
        </div>
    );
};

export default CompanyDashboard;