import classes from "./SimpleFormGroupLtr.module.scss";
import searchIcon from "../../../assets/images/Icons/search-keyservice.svg";
import tikIcon from "../../../assets/images/Icons/tick.svg";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

const SimpleFormGroupLtr = (props) => {
    const { className, label, inputValue, dispatchInputValue } = props;
    const { register, handleSubmit, setValue } = useForm();

    const onSubmit = (data) => {
        dispatchInputValue(data.inputValue);
    };

    useEffect(() => {
        setValue("inputValue", inputValue);
    }, [inputValue, setValue]);

    return (
        <div className={`${classes["search-box"]} ${className}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <button type="submit" style={label === "Gift Code" ? { paddingTop: 5 } : { paddingTop: 3 }} className={`btn ${classes["btn-submit"]}`}>
                    {label === "Gift Code" ? (
                        <img src={tikIcon} alt="tick" />
                    ) : (
                        <img src={searchIcon} alt="search" />
                    )}
                </button>
                <input
                    id="inputValue"
                    className={classes["search-input"]}
                    type="text"
                    {...register("inputValue")}
                    autoComplete="off"
                    data-testid="search-input"
                    placeholder="Search"
                />

            </form>
        </div>
    );
};

export default SimpleFormGroupLtr;
