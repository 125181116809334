import classes from "./DashboardSearchFilterBoxRequest.module.scss";
import { useState, useEffect } from "react";
import Card from "../UI/CardV2/CardV2";
import SimpleFormGroup from "../UI/SimpleFormGroup/SimpleFormGroup";
import SortItems from "../UI/SortBox/SortItems/SortItems";
import ButtonSortItems from "../UI/ButtonSortItems/ButtonSortItems";
import { REQUEST_STATUS } from "../../constants/RequestStatus";

const DashboardSearchFilterBoxRequest = ({
  searchItem, onSearchItem,
  sentReqStatus, dispatchSentReqStatus,
}) => {
  const handleOnSearch = (search) => {
    onSearchItem(search);
    console.log(search);
  };

  const [requestStatusList, setRequestStatusList] = useState([]);
  // Approval Pending with id=2 isnt be part of filter
  const getRequestStatusList = () => {
    const result = REQUEST_STATUS.filter((status) => status.id !== 2);
    setRequestStatusList(result);
  };

  let filterTitle = "Status";
  if (requestStatusList.length > 0) {
    let result = requestStatusList.find(
      (item) => item.id === parseInt(sentReqStatus)
    );
    filterTitle = result ? result.title : "Status";
  }
  const getFilterItem = (item) => {
    dispatchSentReqStatus(item);
  };
  useEffect(() => {
    getRequestStatusList();
  }, []);

  return (
    <Card className={classes.card}>
      <div className="row">
        <div className="col-lg-4 col-12">
          <SimpleFormGroup
            className="mb-20"
            label="Search Companies"
            inputValue={searchItem}
            dispatchInputValue={handleOnSearch}
          />
        </div>
        <div className="col-lg-3 col-12">

        </div>
        <div className="col-lg-5 col-12">
        {requestStatusList.map((item) => (
                    <button key={item.id}                    
                        className={`dropdown-item ${classes.items}`}
                        onClick={() => getFilterItem(item.id)}
                      >
                        <span>{item.title}</span>
                      
                    </button>
                  ))}
        </div>
      </div>
    </Card>
  );
};

export default DashboardSearchFilterBoxRequest;
