import React, { useCallback, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Login from '../../pages/Authentication/Login/Login';

function PrivateRoute(props) {
  const history = useHistory();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  
  return (
    <>
      {isLoggedIn ? <Route {...props} /> : <Login />}
    </>
  );

}

export default PrivateRoute;