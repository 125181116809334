import classes from "./NeedHelp.module.scss";

const NeedHelp = () => {
	return (
		<section className={`${classes["section-container"]} container`}>
			<span className={classes["section-overlay"]} />
			<div className={classes["items"]}>
				<h2>How is your experience with us?</h2>
				<p>
				We want you to enjoy using our platform and get the most out of it. If you have any questions or concerns, we are here to help.
				 Just send us an email at: 
				</p>
				<a href="mailto:Support@pharmatching.com">Support@pharmatching.com</a>
			</div>
		</section>
	);
};

export default NeedHelp;
