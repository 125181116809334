import classes from "./SentRequestItem.module.scss";
import Card from "../UI/CardV2/CardV2";
import defaultLogo from "../../assets/images/placeholder.svg";
import { dateFormmater, detectShortestItem } from "../../utils/common";
import { REQUEST_STATUS } from "../../constants/RequestStatus";
import { Link } from "react-router-dom";

const SentRequestItem = ({ sentReq }) => {
  let srcLogo = sentReq.company_logo ? sentReq.company_logo : defaultLogo;
  const status = REQUEST_STATUS.find(
    (element) => element.id === sentReq.status
  );

  return (
    <div className=" col-xl-4 col-md-6 col-11">
      <Link to={`/request/${sentReq.id}`}>
        <Card className={`${classes.card}`}>
          <div className={classes["sent-req-box"]}>
            <div
              style={{ backgroundImage: `url(${srcLogo})` }}
              className={classes["request-image"]}
            ></div>
            <div className={classes["request-header-box"]}>
              <div
                style={{ color: status.color, backgroundColor: status.backgroundColor }}
                className={classes["request-status"]}
              >
                {status.cardTitle}
              </div>
              <div className={classes["request-date"]}>
                {dateFormmater(sentReq.created_at)}
              </div>
            </div>
          </div>
          <div className={classes.title}>
            <span>{sentReq.company_title}</span>
          </div>
          <p className={classes.decription}> {sentReq.title}</p>

          <div className={classes["card-info"]}>
            <div className={classes["key-value-box"]}>
              <span className={classes.key}>Due Date:&nbsp;</span>
              <span className={classes.value}> {dateFormmater(sentReq.due_date)}</span>
            </div>
            <div className={classes["key-value-box"]}>
              <span className={classes.key}>Region(s):&nbsp;</span>
              <span className={classes.value}>
                {sentReq.regions_data.length > 0 &&
                  <span className={classes.regions}>
                    {detectShortestItem(sentReq.regions_data)}
                  </span>
                }
                {sentReq.regions_data.length > 1 && (
                  <span className={classes["more-value"]}>
                    &nbsp;{`+${sentReq.regions_data.length - 1}`}
                  </span>
                )}
              </span>
            </div>
            <div className={classes["key-value-box"]}>
              <span className={classes.key}>Country(s):&nbsp;</span>
              <span className={classes.value}>
                {sentReq.countries_data.length > 0 &&
                  detectShortestItem(sentReq.countries_data)}
                {sentReq.countries_data.length > 1 && (
                  <span className={classes["more-value"]}>
                    &nbsp;{`+${sentReq.countries_data.length - 1}`}
                  </span>
                )}
              </span>
            </div>
          </div>

          <Link
            to={`/request-applications/${sentReq.id}`}
          >
            <div className={classes["app-recived"]}>
              {sentReq.received_applications_count} Applications Recieved
            </div>
          </Link>


        </Card>
      </Link>
    </div>
  );
};

export default SentRequestItem;
