import React, {Fragment, useEffect, useState} from 'react';
import classes from './CategoryChart.module.scss';

import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import {Radar} from 'react-chartjs-2';
import useHttp from "../../hooks/use-http";
import {getAccessToken} from "../../utils/authentication";
import {API_COMPANIES_URL} from "../../constants/API";

const CategoryChart = ({userCompany}) => {
    const {sendRequest, error} = useHttp();
    const [categoryReports, setCategoryReports] = useState(null);
    const getCategoryReport = (data) => {
        setCategoryReports(data);
    };
    const fetchCategoryReport = () => {
        const token = getAccessToken();
        sendRequest(
            {
                url: `${API_COMPANIES_URL}/company/${userCompany.id}/category-report/`,
                headers: {Authorization: `Bearer ${token}`},
            },
            getCategoryReport
        );
    };

    useEffect(() => {
        // company , freelancer
        if (userCompany) {
            fetchCategoryReport();
        }
    }, [userCompany]);

    ChartJS.register(
        RadialLinearScale,
        PointElement,
        LineElement,
        Filler,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top",
                align: "end"
            }
        }
    };

    const data = {
        labels: categoryReports ? Object.keys(categoryReports) : [],
        datasets: [
            {
                label: 'requests',
                data: categoryReports ? Object.values(categoryReports).map(category => category['requests']) : [],
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
            {
                label: 'applications',
                data: categoryReports ? Object.values(categoryReports).map(category => category['applications']) : [],
                backgroundColor: 'rgba(99,120,255,0.2)',
                borderColor: 'rgb(99,200,255)',
                borderWidth: 1,
            },
        ],
    };
    return (
        <div className={`${classes['chart-container']} container`}>
            <div className={`${classes['chart-title']}`}>
                <p>The Extent Of Request Categories</p>

            </div>
            <Radar options={options} data={data}/>
        </div>
    );
};

export default CategoryChart;