import { useState, useEffect } from "react";
import classes from "./NewsFilterBox.module.scss";
import Card from "../UI/Card/Card";
import SimpleFormGroup from "../UI/SimpleFormGroup/SimpleFormGroup";
import SimpleFormGroupLtr from "../UI/SimpleFormGroupLtr/SimpleFormGroupLtr";
import useHttp from "../../hooks/use-http";
import { API_TAGS_URL } from "../../constants/API";
import SearchableDropdownFilter from "../UI/SearchableDropdownFilter/SearchableDropdownFilter";
import NewsLinkGroup from "../NewsLinkGroup/NewsLinkGroup";
import NewsTimeFilter from "../NewsTimeFilter/NewsTimeFilter";
import WarningLetterDropdownFilter from "../UI/WarningLetterDropdownFilter/WarningLetterDropdownFilter";
import { WARNING_LETTER_SORT_ITEMS } from "../../constants/WarningLetterList";

const NewsFilterBox = (props) => {
  const {
    onSearchNews,
    searchItem,
    onKeyServices,
    initialKeyServices,
    onGetTimeFilter,
    initialTimeFilter,
    onGetSource,
    sourceItem,
    componentUsage,
  } = props;
  const [keyServices, setKeyServices] = useState([]);
  const { sendRequest, error, isLoading } = useHttp();

  const handleOnSearchNews = (searchItem) => {
    onSearchNews(searchItem);
  };
  const getKeyServices = (keyServices) => {
    setKeyServices(keyServices);
  };
  const handleKeyServices = (keyServices) => {
    onKeyServices(keyServices);
  };

  const handleTimeFilter = (time) => {
    onGetTimeFilter(time);
  };

  const handleSource = (item) => {
    onGetSource(item);
  };

  useEffect(() => {
    if (componentUsage === "news") {
      sendRequest(
        {
          url: `${API_TAGS_URL}/service-categories/`,
        },
        getKeyServices
      );
    }
  }, [componentUsage, sendRequest]);

  return (
    <Card className={classes.card}>
      <div className="container">
        <div>
          <div>
            <NewsLinkGroup />
          </div>
          {/* {componentUsage === "news" && (
            <div className="col-lg-5  col-12">
              <SearchableDropdownFilter
                className={classes["right-0px"]}
                filterName="Categories"
                initialFilters={initialKeyServices}
                filterList={keyServices}
                dispatchFilterList={handleKeyServices}
                isSelectedItem={true}
              />
            </div>
          )}

          {componentUsage === "warning-letter" && (
            <div className={`${classes["warning-letter-filter-wrapper"]} col-lg-5  col-12`}>
              <WarningLetterDropdownFilter
                filterItem={sourceItem}
                dispatchFilterItem={handleSource}
                dropdownItems={WARNING_LETTER_SORT_ITEMS}
                dropdownTitle="Source"
              />
            </div>
          )}

          <div className="col-lg-7 col-12">
            <NewsTimeFilter
              dispatchTimeFilter={handleTimeFilter}
              initialTimeFilter={initialTimeFilter}
            />
          </div> */}
        </div>
      </div>
    </Card>
  );
};

export default NewsFilterBox;
