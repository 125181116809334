import { Fragment, useRef, useState, useEffect } from "react";
import React from 'react';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import classes from "./DatePickerDueDateSimple.module.scss";
import "./DatePickerDueDateSimple.scss";
import calendar2 from "../../../assets/images/Icons/calendar2.svg";
// import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";



const DatePickerDueDateSimple = ({
  title,
  validation,
  input,
  register,
  errors,
  setValue,
  getValues,
  previewdValue,
  placeHolder
}) => {


  const ref = useRef();
  const [dueDate, setDueDate] = useState();
  // const [openDatePicker, setOpenDatePicker] = useState(0);
  const [minDate, setMinDate] = useState();
  // let close = 1;
   

  
  useEffect(() => {

    var myCurrentDate = new Date();
    var myFutureDate = new Date(myCurrentDate);
    myFutureDate.setDate(myFutureDate.getDate() + 56);
    setMinDate(myFutureDate);
    // setDueDate(myFutureDate);
    // setValue(input.name, Moment(myFutureDate).format('YYYY-MM-DD'));  
    if (previewdValue) {  
      setDueDate(new Date(previewdValue));
      setValue(input.name, Moment(new Date(previewdValue)).format('YYYY-MM-DD'));      
    }
  }, [previewdValue])


  return (
    <Fragment> 
        <DatePicker {...register(input.name, validation)}
          // readOnly={true}
          placeholderText= "Due Date"
          // open={openDatePicker}          
          //onClick={() => openDatePicker === 1 ? setOpenDatePicker(1) : setOpenDatePicker(0)}
          // onClickOutside={() => {setOpenDatePicker(0)}}
          autoComplete="off"
          onMonthChange={console.log}
          selected={dueDate}
          minDate={minDate}
          onChange={(date) => {
            setDueDate(date);
            setValue(input.name, Moment(date).format('YYYY-MM-DD'));
            //selected = date;
            // setOpenDatePicker(0);            
            // close = 0;           
          }}
        />   
        <img
          className={classes.picture}
          src={calendar2}
          alt="attach files"
        /> 
      {errors[input.name] && !getValues(input.name) && (
        <span className={classes["single-input-error"]}>This is required</span>
      )}
      {errors[input.name] && errors.duedate && (
        <span className={classes["single-input-error"]}>{errors.duedate.message}</span>
      )}


    </Fragment>
  );
};

export default DatePickerDueDateSimple;
