import { useState, useEffect, useCallback } from "react";
import classes from "./GeneralInfo.module.scss";
import SimpleInput from "../../UI/SimpleInput/SimpleInput";
import SingleTextArea from "../../UI/SingleTextArea/SingleTextArea";
import DropDownInput from "../../UI/DropDownInput/DropDownInput";
import UploadImageInput from "../../UI/UploadImageInput/UploadImageInput";
import AttachFileInput from "../../UI/AttachFileInput/AttachFileInput";
import { useForm } from "react-hook-form";
import useHttp from "../../../hooks/use-http";
import { API_TAGS_URL } from "../../../constants/API";
import Card from "../../../components/UI/Card/Card";
import SelectableButton from "../../UI/SelectableButton/SelectableButton";
import CompanySizeInput from "../../CompanySizeInput/CompanySizeInput";
import { Fragment } from "react";
import SingleTextRichArea from "../../UI/SingleTextRichArea/SingleTextRichArea";

const GeneralInfo = ({
  OnUpdateActiveStep,
  onGetGeneralInfo,
  generalInfo,
  activeIndex,
  generalInfoErrors,
  isCreateCompany,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: generalInfo
      ? {
        companyName: generalInfo["companyName"],
        companyIntroduction: generalInfo["companyIntroduction"],
        serviceDescription: generalInfo["serviceDescription"],
        companySize: generalInfo["companySize"],
        lifeScienceSector: generalInfo["lifeScienceSector"],
        vat_id: generalInfo["vat_id"],
        vat_region: generalInfo["vat_region"],
        profilePicture: generalInfo["profilePicture"],
        attachFiles: generalInfo["attachFiles"],
      }
      : {},
  });
  const { sendRequest } = useHttp();
  const [specialities, setSpecialities] = useState([]);
  const [companySize, setCompanySize] = useState([]);
  const [vatRegion, setVatRegion] = useState([]);
  const [selectedCompanySizeId, setSelectedCompanySizeId] = useState(2);

  const getSelectedCompanySize = (companySizeId) => {
    // console.log("companySize1: ", selectedCompanySizeId);  
    setSelectedCompanySizeId(companySizeId);
     setValue("companySize", companySizeId);
    //  console.log("companySize2: ", companySizeId);
  };
  const getSpecialities = (specialities) => {
    setSpecialities(specialities);
  };

  const getCompanySizeList = (companySize) => {
    setCompanySize(companySize);
  };

  const getVatRegion = (vatRegions) => {
    setVatRegion(vatRegions);
  }

  const fetchVatRegion = useCallback(() => {
    sendRequest(
      {
        url: `${API_TAGS_URL}/vat-regions/`,
      },
      getVatRegion
    );
  }, [sendRequest]);
  const fetchSpecialitiesList = useCallback(() => {
    sendRequest(
      {
        url: `${API_TAGS_URL}/specialities/`,
      },
      getSpecialities
    );
  }, [sendRequest]);

  const fetchCompanysize = useCallback(() => {
    sendRequest(
      {
        url: `${API_TAGS_URL}/sizes/`,
      },
      getCompanySizeList
    );
  }, [sendRequest]);

  const onSubmit = (submittedForm) => {
    OnUpdateActiveStep(2);
    onGetGeneralInfo(submittedForm);
    window.scrollTo(0, 0);
  };


  useEffect(() => {
    fetchSpecialitiesList();
    fetchCompanysize();
    fetchVatRegion();
  }, [fetchCompanysize, fetchSpecialitiesList, fetchVatRegion]);

  useEffect(() => {
    if (generalInfoErrors && generalInfoErrors.data.errors && generalInfoErrors.data.errors.title ) {
      // generalInfoErrors.data.errors.title &&
        setError("companyName", {
          type: "custom",
          message: generalInfoErrors.data.errors.title,
        });
    }
  }, [generalInfoErrors, setError]);

  useEffect(() => {
    console.log("general:", generalInfo);
    if (generalInfo) {
      setValue("companyName", generalInfo.companyName);
      setValue("companyIntroduction", generalInfo.companyIntroduction);
      setValue("companySize", generalInfo.companySize);
      setValue("lifeScienceSector", generalInfo.lifeScienceSector);
      // setValue("serviceDescription", generalInfo.serviceDescription);
      // setValue("vat_id", generalInfo.vat_id);
      // setValue("vat_region", generalInfo.vat_region);
      setValue("attachFiles", generalInfo.attachFiles);
      setValue("profilePicture", generalInfo.profilePicture);
      setSelectedCompanySizeId(generalInfo.companySize);
    }
    else{
      setValue("companySize", 2);
    }
  }, [generalInfo, setValue]);

  return (
    <div style={{ display: activeIndex === 1 ? "block" : "none" }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <Card className={classes["step-card"]}>
          <div className={classes["section-title"]}>
            About Company
          </div>
          <div className={classes["section-subtitle"]}>
            This information will be publicly displayed on your profile
          </div>
          <UploadImageInput
            validation={{
              required: true,
            }}
            input={{
              type: "file",
              name: "profilePicture",
            }}
            errors={errors}
            register={register}
            setValue={setValue}
            getValues={getValues}
            previewdValue={generalInfo?.profilePicture}
          />

          <div className={classes["input-container"]}>
            <SimpleInput
              validation={{
                required: true,
              }}
              input={{
                type: "text",
                label: "Company Name*",
                name: "companyName",
                placeholder: "Company Name",
                id: "Company Name",
                autoComplete: "off",
              }}
              errors={errors}
              register={register}
            />
            <SingleTextRichArea
              validation={{
                required: true,
              }}
              input={{
                type: "text",
                label: "Company Introduction*",
                name: "companyIntroduction",
                placeholder: "Company Introduction",
                autoComplete: "off",
              }}
              getValues={getValues}
              setValue={setValue}
              errors={errors}
              register={register}
            />
          </div>
          <div className={classes["line"]}></div>
          <div className={classes["section-title"]}>
            Employees Count
          </div>
          <div className={classes["section-subtitle"]}>
            This information will be publicly displayed on your profile
          </div>
          {/* <SingleTextArea
            validation={{
              required: true,
            }}
            input={{
              type: "text",
              label: "Service Description*",
              name: "serviceDescription",
              placeholder: "Service Description",
              autoComplete: "off",
            }}
            errors={errors}
            register={register}
          /> */}
          {/* {specialities.length > 0 && (
            <DropDownInput
              validation={{
                required: true,
              }}
              input={{
                type: "text",
                label: "Life Science Sector",
                name: "lifeScienceSector",
              }}
              errors={errors}
              register={register}
              setValue={setValue}
              getValues={getValues}
              dropDownList={specialities}
            />
          )} */}
          <div className="mt-4"></div>


          {companySize.length > 0 && (
            <div className={`${classes["company-size-container"]} mt-4 row justify-content-center`}>
              {companySize.map((item) => (
                <div key={item.id} className={`${classes["col-lg-equal"]} col-md-2 col-sm-6 col-6 mt-4 text-center`}>
                  <SelectableButton
                    item={item}
                    dispatchSelectedItem={getSelectedCompanySize}
                    selectedId={selectedCompanySizeId}
                  />
                </div>

              ))}
            </div>
          )}



          <div className="row mt-4">
            {/* {isCreateCompany && (
              <Fragment>
                <div className="col-md-6 col-12">
                  {vatRegion.length > 0 && (
                    <DropDownInput
                      validation={{
                        required: true,
                      }}
                      input={{
                        type: "text",
                        label: "Vat Regions*",
                        name: "vat_region",
                      }}
                      errors={errors}
                      register={register}
                      setValue={setValue}
                      getValues={getValues}
                      dropDownList={vatRegion}
                    />
                  )}

                </div>
                <div className="col-md-6 col-12 mt-3 mt-md-0 d-grid">
                  <SimpleInput
                    validation={{
                      required: true,
                    }}
                    input={{
                      type: "text",
                      label: "VAT ID*",
                      name: "vat_id",
                      placeholder: "VAT ID",
                      id: "Vat ID",
                      autoComplete: "off",
                    }}
                    errors={errors}
                    register={register}
                  />

                </div>
              </Fragment>
            )} */}
            <div className="col-12">
              {/* <div className={classes.Certificates}>
            upload your <span>Certificates</span> to create a company.
          </div> */}
              {/* <AttachFileInput
                validation={{
                  required: false,
                }}
                input={{
                  type: "file",
                  name: "attachFiles",
                }}
                register={register}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                previewdValue={generalInfo?.attachFiles}
                placeHolderText="You can upload your Certificates and / or additional company information combined into one PDF (max. 20 MB) here."
              /> */}
            </div>
          </div>
          <div className="text-end">
            <button className={`btn ${classes.submit}`} type="submit">
              Continue
            </button>
          </div>ّ
        </Card>

      </form>

    </div>
  );
};

export default GeneralInfo;
