import classes from "./PaymentResult.module.scss";
import successResult from "../../assets/images/paymentResultSuccess.svg";
import failedResult from "../../assets/images/paymentResultFailed.svg";
import { Link, useLocation } from "react-router-dom";
import React from "react";



const PaymentResult = () => {
    const { search } = useLocation();
    let isSuccess = React.useMemo(() => new URLSearchParams(search), [search]).get("success");
    if (isSuccess == "true")
        isSuccess = true;
    else
        isSuccess = false;
    return (
        <section className={`container  flex-grow ${classes.section}`}>
            {/* <div className={classes.paymentBanner}></div> */}

            <div className="row justify-content-center ">
                <div className={`col-xl-8 ${classes.paymentMessage}`}>
                    <div className="text-center"><img src={isSuccess ? successResult : failedResult} alt="" />

                    </div>
                    {isSuccess && (
                        <div className={`text-center ${classes.congraculations}`}>Congratulations!</div>
                    )
                    }
                    {!isSuccess && (
                        <div className={`text-center ${classes.failed}`}>Payment Failed!</div>
                    )
                    }
                    {isSuccess && (
                        <div className={classes.congText}> You successfully finished your payment and digitalized your outsourcing procedures. You can access the global Life Science market now.
                            Please check your email.</div>
                    )
                    }


                    <div className="text-center">
                        <Link className={`btn ${classes.congBtn}`} to="/">
                            Home Page
                        </Link>
                    </div>
                </div>

            </div>


        </section >
    );
};

export default PaymentResult;
