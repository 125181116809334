import { useState } from "react";
import classes from "./SearchInput.module.scss";
import searchImg from "../../../assets/images/Icons/search-keyservice.svg";
import remove from "../../../assets/images/Icons/close-modal.svg";
const SearchInput = ({ onChangeHandler }) => {

    const [filter, setFilter] = useState("");

    return (
        <div className={classes["search-input-wrapper"]}>
            <img src={searchImg} alt="search" className={classes["img-search"]} />
            <input
                className={classes["search-input"]}
                value={filter}
                onChange={(e) => { onChangeHandler(e.target.value); setFilter(e.target.value) }}
                placeholder="Search"
            />
            {filter !=="" && (
                <button
                    onClick={() => { setFilter(""); onChangeHandler("") }}
                    className={classes["remove-button"]}
                >
                    <img src={remove} />
                </button>
            )}

        </div>
    );
};

export default SearchInput;
