import classes from "./NewsTimeFilter.module.scss";
import { useAccordion } from "../../hooks/use-accordion";
const NewsTimeFilter = ({ dispatchTimeFilter, initialTimeFilter }) => {
  const [accordionRef, isOpen, setIsOpen] = useAccordion();
  return (
    <div className={classes["filter-box"]}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`${classes["accordion-button"]}`}
        type="button"
        aria-expanded={isOpen}
      >Date</button>
      {isOpen && (
        <div className={classes['filter-item']}>
          <button
            style={{
              background: initialTimeFilter === null ? "#dee4ee" : "",
              color: initialTimeFilter === null ? "#1E4C90" : "#85898C",
            }}
            onClick={() => {
              dispatchTimeFilter(null);
            }}
            className={`btn ${classes["filter-btn"]}`}
          >
            All
          </button>
          <button
            style={{
              background: initialTimeFilter === "this week" ? "#dee4ee" : "",
              color: initialTimeFilter === "this week" ? "#1E4C90" : "#85898C",
            }}
            onClick={() => {
              dispatchTimeFilter("this week");
            }}
            className={`btn ${classes["filter-btn"]}`}
          >
            This week
          </button>
          <button
            style={{
              background: initialTimeFilter === "this month" ? "#dee4ee" : "",
              color: initialTimeFilter === "this month" ? "#1E4C90" : "#85898C",
            }}
            onClick={() => {
              dispatchTimeFilter("this month");
            }}
            className={`btn ${classes["filter-btn"]}`}
          >
            This month
          </button>
          <button
            style={{
              background: initialTimeFilter === "this year" ? "#dee4ee" : "",
              color: initialTimeFilter === "this year" ? "#1E4C90" : "#85898C",
            }}
            onClick={() => {
              dispatchTimeFilter("this year");
            }}
            className={`btn ${classes["filter-btn"]}`}
          >
            This year
          </button>
          <button
            style={{
              background: initialTimeFilter === "previous year" ? "#dee4ee" : "",
              color: initialTimeFilter === "previous year" ? "#1E4C90" : "#85898C",
            }}
            onClick={() => {
              dispatchTimeFilter("previous year");
            }}
            className={`btn ${classes["filter-btn"]}`}
          >
            Previous year
          </button>
        </div>
      )}

    </div>
  );
};

export default NewsTimeFilter;
