import classes from "./HomeTabButton.module.scss";

const HomeTabButton = ({
  className,
  ariaSelected,
  ariaControls,
  dataBsToggle,
  id,
  title,
  onChangeTab,
}) => {
  return (
    <button
      onClick={(e) => onChangeTab(e)}
      className={`nav-link btn  ${classes["nav-link"]}`}
      id={id}
      data-bs-toggle="pill"
      data-bs-target={dataBsToggle}
      type="button"
      role="tab"
      aria-controls={ariaControls}
      aria-selected={ariaSelected}
    >
      {title}
    </button>
  );
};

export default HomeTabButton;
