import classes from "./DropDownInputSearchable.module.scss";
import { useEffect, useState, useCallback } from "react";

const DropDownInputSearchable = ({
  validation,
  input,
  errors,
  register,
  setValue,
  getValues,
  dropDownList,
}) => {
  const [inputTitle, setInputTitle] = useState(input.label);
  const [displayedItems, setDisplayedItems] = useState([]);

  const changeInputTitle = useCallback(
    (item) => {
      const result = dropDownList.find(({ id }) => id === item);
      result ? setInputTitle(result.title) : setInputTitle(input.label);
    },
    [dropDownList, input.label]
  );

  const getFilterItem = (item) => {
    setValue(input.name, item);
    changeInputTitle(item);
  };

  useEffect(() => {
    const item = getValues(input.name);
    if (item) {
      changeInputTitle(item);
    }
  }, [changeInputTitle, getValues, input]);
  useEffect(() => {
    setDisplayedItems(dropDownList);
  }, [dropDownList]);

  const searchHandler = (e) => {
    let input = e.target.value.toLowerCase();
    let result = dropDownList.filter((element) =>
      element.title.toLowerCase().includes(input)
    );
    setDisplayedItems(result);
  };

  return (
    <div className={`dropdown ${classes.dropdown}`}>
      <input {...input} {...register(input.name, validation)} hidden />

      <button
        style={{
          borderColor:
            errors[input.name] && !getValues(input.name) ? "#E10000" : "",
        }}
        className={` dropdown-toggle ${classes["dropdown-button"]}`}
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span>{inputTitle}</span>
      </button>
      
      <ul
        className={`dropdown-menu ${classes.menus}`}
        aria-labelledby="dropdownMenuButton1"
      >
        <div className={classes["search-box"]}>
          <input
            className={classes["search-input"]}
            type="text"
            placeholder="search"
            onChange={(e) => searchHandler(e)}
          />

        </div>
        {displayedItems.map((item) => (
          <li key={item.id}>
            <button
              type="button"
              className={`dropdown-item ${classes.items}`}
              onClick={() => getFilterItem(item.id)}
              // style={{ borderColor: errors[input.name] ? "#E10000" : "" }}
            >
              <span>{item.title}</span>
            </button>
          </li>
        ))}
      </ul>
      {errors[input.name] && !getValues(input.name) && (
        <div className={classes["single-input-error"]}>
          Please select {input.label}
        </div>
      )}
    </div>
  );
};

export default DropDownInputSearchable;
