import React from 'react';
import classes from './CompanyInfoDashboard.module.scss';
import { useSelector, useDispatch } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import DashboardCompanyInfo from '../../../components/DashboardCompanyInfo/DashboardCompanyInfo';
import DashboardCompanyExperties from '../../../components/DashboardCompanyExperties/DashboardCompanyExperties';
import DashboardUserManagment from '../../../components/DashboardUserManagment/DashboardUserManagment';
import { getAccessToken } from "../../../utils/authentication";
import useHttp from "../../../hooks/use-http";
import { API_COMPANIES_URL } from "../../../constants/API";
import { addToast } from "../../../redux/Toast/toastAction";
import { createRandomId } from "../../../utils/common";
import { updateIsSubscriptionChange } from "../../../redux/User/userAction";
import { updateIsCompanyChanged } from "../../../redux/Company/companyAction";
import ConfirmModal from "../../../components/UI/ConfirmModal/ConfirmModal";

const CompanyInfoDashboard = () => {
    const userCompany = useSelector((state) => state.user.userCompany);
    const userRole = useSelector((state) => state.user.userRole);
    const { sendRequest, error } = useHttp();
    const [companyParticipants, setCompanyParticipants] = useState([]);
    const [invitedUsersList, setInvitedUsersList] = useState([]);
    const [joinRequestCompany, setJoinRequestCompany] = useState([]);
    const [modalMessage, setModalMessage] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [modalButtonText, setModalButtonText] = useState(null);
    const [modalHeader, setmodalHeader] = useState(null);
    const [userId, setUserId] = useState(null);
    const dispatch = useDispatch();
    let history = useHistory();

    const getUserActionResponse = (data) => {
        console.log(data);
        setUserId(null);
        switch (modalButtonText) {
            case "Grant Access":
                fetchJoinRequestsCompany();
                fetchCompanyParticipants();
                break;
            case "Deny User":
                fetchJoinRequestsCompany();
                break;
            case "Deactive":
                dispatch(updateIsCompanyChanged(true));
                history.push("/");
                break;
            case "Cancel Invitation":
                fetchInviteUserList();
                dispatch(updateIsSubscriptionChange(true));
                break;
            default:
                fetchCompanyParticipants();
                break;
        }
    };

    const confirmUserAction = (str) => {
        console.log(str);
        const token = getAccessToken();
        if (str === "confirm") {
            let url;
            if (modalButtonText === "Make Admin") {
                url = `${API_COMPANIES_URL}/company/${userCompany.id}/promote-admin/`;
            } else if (modalButtonText === "Remove Admin") {
                url = `${API_COMPANIES_URL}/company/${userCompany.id}/demote-admin/`;
            } else if (modalButtonText === "Delete User") {
                url = `${API_COMPANIES_URL}/delete-user/${userId}`;
            } else if (modalButtonText === "Grant Access") {
                url = `${API_COMPANIES_URL}/company/${userCompany.id}/grant/`;
            } else if (modalButtonText === "Deny User") {
                url = `${API_COMPANIES_URL}/company/${userCompany.id}/deny/`;
            } else if (modalButtonText === "Cancel Invitation") {
                url = `${API_COMPANIES_URL}/invitation/${userId}`;
            } else if (modalButtonText === "Deactive") {
                url = `${API_COMPANIES_URL}/company/deactivate/`;
            }
            sendRequest(
                {
                    url: url,
                    method:
                        modalButtonText === "Delete User" ||
                            modalButtonText === "Cancel Invitation"
                            ? "DELETE"
                            : "POST",
                    body:
                        modalButtonText === "Grant Access" ||
                            modalButtonText === "Deny User"
                            ? { access_request: userId }
                            : modalButtonText === "Deactive"
                                ? { slug: userCompany.slug, title: userCompany.title }
                                : { user: userId },
                    headers: { Authorization: `Bearer ${token}` },
                },
                getUserActionResponse
            );
        }
        setModalType(null);
        setModalButtonText(null);
        setModalMessage(null);
        setmodalHeader(null);
    };
    const deleteInvitation = (inviteId) => {
        console.log(inviteId);
        setUserId(inviteId);
        setModalType("warning");
        setModalButtonText("Cancel Invitation");
        setModalMessage("Do you confirm to Cancel Invitation?");
        setmodalHeader("Cancel Invitation");
    };
    const inviteUserToCompany = (email) => {
        console.log(email);
        const token = getAccessToken();
        sendRequest(
            {
                url: `${API_COMPANIES_URL}/company/${userCompany.id}/invite-user/`,
                method: "POST",
                body: { email: email },
                headers: { Authorization: `Bearer ${token}` },
            },
            getInviteUserToCompanyRes
        );
    };
    const getInviteUserToCompanyRes = (data) => {
        console.log('invite:', data);
        dispatch(
            addToast({
                id: createRandomId(),
                status: "success",
                description: "Your invitation was successfully send out.",
            })
        );
        dispatch(updateIsSubscriptionChange(true));
        fetchInviteUserList();
    };
    const promoteUserToAdmin = (userId) => {
        console.log(userId);
        setUserId(userId);
        setModalType("success");
        setModalButtonText("Make Admin");
        setModalMessage("Do you confirm to make admin?");
        setmodalHeader("Make Admin");
    };
    const removeUserFromAdmin = (userId) => {
        console.log(userId);
        setUserId(userId);
        setModalType("warning");
        setModalButtonText("Remove Admin");
        setModalMessage("Do you confirm to remove admin?");
        setmodalHeader("Remove Admin");
    };
    const deleteUser = (userId) => {
        console.log(userId);
        setUserId(userId);
        setModalType("warning");
        setModalButtonText("Delete User");
        setModalMessage("Do you confirm to delete user?");
        setmodalHeader("Delete User");
    };
    const deactivateCompany = () => {
        setModalType("warning");
        setModalButtonText("Deactive");
        setModalMessage(
            "Are you sure to deactivate your company profile and remove all its assets?"
        );
        setmodalHeader("Deactive");
    };
    const denyUser = (userId) => {
        console.log(userId);
        setUserId(userId);
        setModalType("warning");
        setModalButtonText("Deny User");
        setModalMessage("Do you confirm to Deny user?");
        setmodalHeader("Deny User");

    };
    const grantAccessUser = (userId) => {
        console.log(userId);
        setUserId(userId);
        setModalType("success");
        setModalButtonText("Grant Access");
        setModalMessage("Do you confirm to Grant Access?");
        setmodalHeader("Grant Access");
    };
    const getJoinRequestCompany = (data) => {
        console.log('JoinRequestCompany: ', data);
        setJoinRequestCompany(data);
    };
    const getInviteUserList = (data) => {
        console.log("invited list: ", data);
        setInvitedUsersList(data);
    };

    const getCompanyParticipants = (data) => {
        console.log('CompanyParticipants:', data);
        setCompanyParticipants(data);
    };
    const fetchJoinRequestsCompany = () => {
        const token = getAccessToken();
        sendRequest(
            {
                url: `${API_COMPANIES_URL}/company/${userCompany.id}/access/`,
                headers: { Authorization: `Bearer ${token}` },
            },
            getJoinRequestCompany
        );
    };
    const fetchCompanyParticipants = () => {
        const token = getAccessToken();
        sendRequest(
            {
                url: `${API_COMPANIES_URL}/company/${userCompany.id}/participants/`,
                headers: { Authorization: `Bearer ${token}` },
            },
            getCompanyParticipants
        );
    };
    const fetchInviteUserList = () => {
        const token = getAccessToken();
        sendRequest(
            {
                url: `${API_COMPANIES_URL}/company/${userCompany.id}/invite-user/`,
                headers: { Authorization: `Bearer ${token}` },
            },
            getInviteUserList
        );
    };
    useEffect(() => {
        // memeber of company : admin or not admin
        if (userRole === "company") {
            fetchCompanyParticipants();
        }
    }, [userRole]);
    useEffect(() => {
        // company , freelancer
        // if (userCompany) {
        //     fetchComapayAssets();
        // }
        // only company who is admin
        if (userCompany && userCompany.is_admin) {
            fetchJoinRequestsCompany();
            fetchInviteUserList();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCompany]);
    useEffect(() => {
        console.log(error);
        if (error && error.status === 400) {
            dispatch(
                addToast({
                    id: createRandomId(),
                    status: "error",
                    description: error.data
                        ? error.data[0]
                        : error.data.errors["non_field_errors"],
                })
            );
        }
    }, [dispatch, error]);
    return (
        <div className='container'>
            {modalMessage && (
                <ConfirmModal
                    modalButtonText={modalButtonText}
                    modalType={modalType}
                    modalText={modalMessage}
                    modalHeader={modalHeader}
                    onConfirm={confirmUserAction}
                />
            )}
            <div className={`row ${classes['public-profile']}`}>
                <div className='col-12 text-center text-md-end align-middle'>
                    {userCompany && (
                        <Link
                            className={`${classes["view-profile"]} `}
                            to={`/company/${userCompany.id}`}
                        >
                            View Company Details Page
                        </Link>
                    )}
                </div>
            </div>
            <div className='row'>
                <div className={'col-12 mb-5 pb-5'}>
                    {userCompany && (
                        <Fragment>
                            <DashboardCompanyInfo userCompany={userCompany} userRole={userRole} />
                            <DashboardCompanyExperties userCompany={userCompany} userRole={userRole} />
                            {userRole && userRole !== "freelancer" && (
                                <DashboardUserManagment
                                    isAdmin={userCompany.is_admin}
                                    companyParticipants={companyParticipants}
                                    invitedUsersList={invitedUsersList}
                                    joinRequestCompany={joinRequestCompany}
                                    onInviteUserToCompany={inviteUserToCompany}
                                    onDeleteInvitation={deleteInvitation}
                                    onRemoveUserFromAdmin={removeUserFromAdmin}
                                    onPromoteUserToAdmin={promoteUserToAdmin}
                                    onDeleteUser={deleteUser}
                                    onDeactiveCompany={deactivateCompany}
                                    onDenyUser={denyUser}
                                    onGrantAccessUser={grantAccessUser}
                                />
                            )}

                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CompanyInfoDashboard;