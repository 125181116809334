import React, {useCallback, useEffect, useState} from 'react';
import classes from './Payment.module.scss';
import {useDispatch, useSelector} from "react-redux";
import Loading from "../../components/UI/Loading/Loading";
import useHttp from "../../hooks/use-http";
import {useHistory, useParams} from "react-router-dom";
import {getAccessToken} from "../../utils/authentication";
import {API_TAGS_URL, API_APPLICATION_URL} from '../../constants/API';
import giftBox from '../../assets/images/Icons/gift-box-pharmatching.svg';
import arrow from '../../assets/images/arrow/Arrow-pharmatching-payment.svg';


const Payment = () => {
    const {sendRequest, error, isLoading} = useHttp();
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const history = useHistory();
    const {id} = useParams();
    const [projectSize, setProjectSize] = useState([]);
    const [currentProjectSize, setCurrentProjectSize] = useState();
    const getUserActionResponseAccept = (res) => {
        history.push('/payment-result?success=true')
    };
    const acceptApplicationRequest = {
        redirect_url: 'https://webapp.pharmatching.com/payment-result',
    };
    const declinePayment = () => {
        history.push('/')
    };
    const acceptApplication = () => {
        const token = getAccessToken();
        sendRequest(
            {
                url: `${API_APPLICATION_URL}${id}/accept-application/`,
                method: "POST",
                body: acceptApplicationRequest,
                headers: {Authorization: `Bearer ${token}`},
            },
            getUserActionResponseAccept
        );
    };

    //------------------get current project size
    const getCurrentProject = (res) => {
        setCurrentProjectSize(res.request_data.request_size_data.title);
    };
    const fetchCurrentProject = useCallback(() => {
        const token = getAccessToken();
        sendRequest(
            {
                url: `${API_APPLICATION_URL}${id}`,
                headers: {Authorization: `Bearer ${token}`},
            },
            getCurrentProject
        );
    }, [sendRequest]);

    // -----------------get list of project size
    const getProjectSize = (res) => {
        setProjectSize(res);
    };
    const fetchProjectSize = useCallback(() => {
        const token = getAccessToken();
        sendRequest(
            {
                url: `${API_TAGS_URL}/sizes/`,
                headers: {Authorization: `Bearer ${token}`},
            },
            getProjectSize
        );
    }, [sendRequest]);

    useEffect(() => {
        fetchProjectSize();
        fetchCurrentProject();
    }, [fetchProjectSize, fetchCurrentProject]);


    return (
        <div>
            {/* <div className={`${classes["payment-banner"]}`}></div> */}
            <div className="container">
                <div className="row justify-content-center pb-5 mb-5">
                    <div className={`${classes["payment-message"]} col-xl-8 `}>
                        <h4>Congratulation!</h4>
                        <p className={`${classes["text"]}`}>Your request has been matched, please take a look at the
                            invoice below and confirm
                            it.<br/>

                        </p>
                        <p className={`${classes["text-link"]}`}>
                            After we receive your payment, we will forward the documents from the applicant.
                        </p>
                    </div>
                    <div className={`${classes["payment-free"]} col-xl-8`}>
                        <img src={giftBox}/>
                        <span className="m-3">Free of service costs for 2022</span>
                        <img src={giftBox}/>
                    </div>
                    <div className={`${classes["payment-table-wrapper"]} ${classes["table-responsive"]} col-xl-8`}>
                        <div className={`${classes["table-title"]}`}>
                            <span className={`${classes["table-title-left"]}`}>Per Case Revenue</span>
                            <span className={`${classes["table-title-right"]}`}>Pricing Tendering Platform</span>
                        </div>
                        <div className={classes["main-table"]}>
                            <table className="table table-borderless">
                                <thead>
                                <tr>
                                    <th scope="col">
                                        <div>Size</div>
                                    </th>
                                    <th scope="col">
                                        <div className={`${classes["mid"]}`}>Project Size</div>
                                    </th>
                                    <th scope="col">
                                        <div className={`${classes["end"]} text-center`}>Per Deal Fee</div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th>
                                        <div>S</div>
                                    </th>
                                    <td>
                                        <div className={`${classes["mid"]}`}>Up to <b>10000 €</b> contract value</div>
                                    </td>
                                    <td>
                                        <div className={`${classes["end"]}`}>
                                            <del className={`${classes["del-price"]}`}>&nbsp;490.00 €&nbsp;</del>
                                            <img src={arrow}/>
                                            <b> 1 € </b>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <div>M</div>
                                    </th>
                                    <td>
                                        <div className={`${classes["mid"]}`}>Up to <b>50000 €</b> contract value</div>
                                    </td>
                                    <td>
                                        <div className={`${classes["end"]}`}>
                                            <del className={`${classes["del-price"]}`}>&nbsp;1990.00 €&nbsp;</del>
                                            <img src={arrow}/>
                                            <b> 1 € </b>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <div>L</div>
                                    </th>
                                    <td>
                                        <div className={`${classes["mid"]}`}>Up to <b>250000 €</b> contract value</div>
                                    </td>
                                    <td>
                                        <div className={`${classes["end"]}`}>
                                            <del className={`${classes["del-price"]}`}>&nbsp;4900.00 €&nbsp;</del>
                                            <img src={arrow}/>
                                            <b> 1 € </b>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <div>XL</div>
                                    </th>
                                    <td>
                                        <div className={`${classes["mid"]}`}>Up to <b>1000000 €</b> contract value</div>
                                    </td>
                                    <td>
                                        <div className={`${classes["end"]}`}>
                                            <del className={`${classes["del-price"]}`}>&nbsp;9900.00 €&nbsp;</del>
                                            <img src={arrow}/>
                                            <b> 1 € </b>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <div>XXL</div>
                                    </th>
                                    <td>
                                        <div className={`${classes["mid"]}`}>Over <b>1000000 €</b> contract value</div>
                                    </td>
                                    <td>
                                        <div className={`${classes["end"]}`}>
                                            <del className={`${classes["del-price"]}`}>&nbsp;19900.00 €&nbsp;</del>
                                            <img src={arrow}/>
                                            <b> 1 € </b>
                                        </div>
                                    </td>
                                </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={`${classes["payment-invoice"]} col-xl-8`}>
                        <div className={classes["section-title"]}>
                            Your Invoice
                        </div>
                        <div className={`${classes["detail"]}`}>Your project size is <b> {currentProjectSize}</b>,
                            your invoice amount is <b> 1 €</b></div>
                        <div className={`${classes["btn-wrapper"]} text-center mt-4`}>
                            <button id="decline" type="button"
                                    onClick={declinePayment}
                                    className={`${classes["btn-decline"]} btn`}>Decline
                            </button>
                            <button id="submitBtn" type="button"
                                    onClick={acceptApplication}
                                    className={`${classes["btn-navyblue"]} btn m-0 mr-md-1 mb-1 mb-md-0 `}> Pay
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
};

export default Payment;