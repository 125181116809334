import { createStore, combineReducers } from "redux";
import userReducer from "./User/userReducer";
import toastReducer from "./Toast/toastReducer";
import companyReducer from "./Company/companyReducer";
import modalReducer from "./Modal/modalReducer";

const rootReducer = combineReducers({
    user: userReducer,
    toast: toastReducer,
    company: companyReducer,
    modal: modalReducer
});
const store = createStore(rootReducer);
export default store;
