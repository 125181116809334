import { Link } from "react-router-dom";
import classes from "./SentApplicationItem.module.scss";
import Card from "../UI/Card/Card";
import defaultLogo from "../../assets/images/placeholder.svg";
import { SENT_APPLICATION_STATUS } from "../../constants/SentApplicationStatus";
import { dateFormmater, detectShortestItem } from "../../utils/common";
import { Fragment } from "react";
const SentApplicationItem = ({ sentApp, link }) => {
  let srcLogo = sentApp.company_logo ? sentApp.company_logo : defaultLogo;
  const status = SENT_APPLICATION_STATUS.find(
    (element) => element.title === sentApp.status
  );

  return (
    <div className="col-xl-4 col-md-6 col-11">
      <Link to={`${link}${sentApp.id}`}>
        <Card className={`${classes.card}`}>
          <div className={classes["sent-app-box"]}>
            <div
              style={{ backgroundImage: `url(${srcLogo})` }}
              className={classes["sent-app-image"]}
            ></div>
            <div className={classes["sent-app-header-box"]}>
              <div
                style={{ color: status.color, backgroundColor: status.backgroundColor }}
                className={classes["sent-app-status"]}
              >
                {status.cardTitle}
              </div>
              <div className={classes["sent-app-date"]}>
                {" "}
                <span className={classes["date-text"]}> Date:</span>  {dateFormmater(sentApp.created_at)}
              </div>
            </div>
          </div>
          <div className={classes.title}>
            <span>{sentApp.company_title}</span>
          </div>
          <p className={classes.decription}> {sentApp.text}</p>
          <div className={classes["card-info"]}>
          {sentApp.request_data && (
            <Fragment>
              <div className={classes["key-value-box"]}>
                <span className={classes.key}>Due Date:&nbsp;</span>
                <span className={classes.value}> {dateFormmater(sentApp.request_data.due_date)}</span>
              </div>
              <div className={classes["key-value-box"]}>
                <span className={classes.key}>Region(s):&nbsp;</span>
                <span className={classes.value}>
                  {sentApp.request_data.regions_data.length > 0 &&
                    <span className={classes.regions}>
                      {detectShortestItem(sentApp.request_data.regions_data)}
                    </span>
                  }
                  {sentApp.request_data.regions_data.length > 1 && (
                    <span className={classes["more-value"]}>
                      &nbsp;{`+${sentApp.request_data.regions_data.length - 1}`}
                    </span>
                  )}
                </span>
              </div>
              <div className={classes["key-value-box"]}>
                <span className={classes.key}>Country(s):&nbsp;</span>
                <span className={classes.value}>
                  {sentApp.request_data.countries_data.length > 0 &&
                    detectShortestItem(sentApp.request_data.countries_data)}
                  {sentApp.request_data.countries_data.length > 1 && (
                    <span className={classes["more-value"]}>
                      &nbsp;{`+${sentApp.request_data.countries_data.length - 1}`}
                    </span>
                  )}
                </span>
              </div>
            </Fragment>
          )}
          </div>
        </Card>
      </Link>
    </div>
  );
};

export default SentApplicationItem;
