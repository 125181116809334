import "./NewsSlider.scss";
import Slider from "react-slick";
import NewsItem from "../NewsItem/NewsItem";

const NewsSlider = ({ latestNewsList }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Slider className="row" {...settings}>
      {latestNewsList && latestNewsList.map((news) => (
        <NewsItem
          key={news.elastic_id}
          news={news}
          className="col-11 mx-auto mb-2"
          isSlider={true}
        />
      ))}
    </Slider>
  );
};

export default NewsSlider;
