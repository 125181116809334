import React, { Fragment } from 'react';
import { useState, useEffect, useCallback } from "react";
import classes from './CompanyInfoModal.module.scss';
import Loading from '../../UI/Loading/Loading';
import closeModal from '../../../assets/images/Icons/close-modal.svg';
import { showEditCompanyInfo } from '../../../redux/Modal/modalAction';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import useHttp from "../../../hooks/use-http";
import { getAccessToken } from "../../../utils/authentication";
import { API_COMPANIES_URL, API_TAGS_URL } from "../../../constants/API";
import { addToast } from "../../../redux/Toast/toastAction";
import { updateUserCompany } from "../../../redux/User/userAction";
import UploadImageInput from "../../UI/UploadImageInput/UploadImageInput";
import SimpleInput from "../../UI/SimpleInput/SimpleInput";
import SingleTextRichArea from "../../UI/SingleTextRichArea/SingleTextRichArea";
import SelectableButton from "../../UI/SelectableButton/SelectableButton";
import SocialInput from "../../UI/SocialInput/SocialInput";
import imgTwitter from "../../../assets/images/Icons/twitter.svg";
import imgLinkedin from "../../../assets/images/Icons/linkdin.svg";
import {
    createRandomId,
    getAttachmentFileName,
} from "../../../utils/common";
const CompanyInfoModal = () => {
    const userRole = useSelector((state) => state.user.userRole);
    const { sendRequest, error, isLoading } = useHttp();
    const dispatch = useDispatch();
    const [selectedCompanySizeId, setSelectedCompanySizeId] = useState(2);

    const { userCompany } = useSelector((state) => state.user);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        setError,
        reset,
    } = useForm({

    });
    const [companySize, setCompanySize] = useState([]);
    const getCompanySizeList = (companySize) => {
        setCompanySize(companySize);
    };
    const fetchCompanysize = useCallback(() => {
        sendRequest(
            {
                url: `${API_TAGS_URL}/sizes/`,
            },
            getCompanySizeList
        );
    }, [sendRequest]);
    const getSelectedCompanySize = (companySizeId) => {
        setSelectedCompanySizeId(companySizeId);
        setValue("companySize", companySizeId);
    };
    const onSubmit = (submittedForm) => {
        sumbitUpdateCompany(submittedForm);
    };


    //-----------------------------------------------------------------------------------

    const sumbitUpdateCompany = (submittedForm) => {
        // const companyRequest = setUpdatedCompanyRequest();
        updateCompanyById(userCompany.id, submittedForm);
    };
    //-----------------------------------------------------------------------------------
    // edit company based on some conditions (user changes pictures and files or not)
    const getUpdatedCompanyById = (data) => {
        dispatch(showEditCompanyInfo(false));
        dispatch(updateUserCompany(data));
        dispatch(
            addToast({
                id: createRandomId(),
                status: "success",
                description: "Your company has been edited successfully.",
            })
        );

    };

    const updateCompanyById = (id, submittedForm) => {
        console.log('submited form2:', submittedForm);
        const formData = new FormData();
        formData.append('title', submittedForm.companyName);
        formData.append('slug', submittedForm.companyName.replace(/ /g, "_"));
        formData.append('description', submittedForm.companyIntroduction);
        if (userRole && userRole !== "freelancer") {
            formData.append('size', submittedForm.companySize);
            formData.append('website', submittedForm.website);
            formData.append('twitter', submittedForm.twitter);
            formData.append('linkedin', submittedForm.linkedin);
            formData.append('company_type', 1);
            if (typeof submittedForm.attachFiles === "object") {
                formData.append("file", submittedForm.attachFiles);
            }
        }
        if (typeof submittedForm.profilePicture === "object") {
            formData.append("logo", submittedForm.profilePicture);
        }
        const token = getAccessToken();
        sendRequest(
            {
                url: `${API_COMPANIES_URL}/company/${id}/`,
                method: "PATCH",
                body: formData,
                headers: { Authorization: `Bearer ${token}` },
            },
            getUpdatedCompanyById
        );
    };
    //-----------------------------------------------------------------------------------
    useEffect(() => {
        console.log(error);
        if (error && error.status === 400) {
            dispatch(
                addToast({
                    id: createRandomId(),
                    status: "error",
                    description: error.data.errors.title
                        ? error.data.errors.title
                        : error.data.errors.owner_email,
                })
            );
        }
    }, [dispatch, error]);
    //-------------------------------------------------- set defualt values to edit steps
    useEffect(() => {
        console.log('usercompany:', userCompany);
        if (userCompany) {
            console.log("usercompany:", userCompany);
            reset({
                companyName: userCompany.title,
                companyIntroduction: userCompany.description,
                serviceDescription: userCompany.service_description,
                lifeScienceSector: userCompany.specialities[0],
                companySize: userCompany.size,
                attachFiles: userCompany.attachments[0]
                    ? getAttachmentFileName(userCompany.attachments[0])
                    : userCompany.attachments[0],
                profilePicture: userCompany.logo,
                website: userCompany.website,
                linkedin: userCompany.linkedin,
                twitter: userCompany.twitter,
            });
            setSelectedCompanySizeId(userCompany.size);
        }
    }, [userCompany]);
    //-------get data
    useEffect(() => {
        // fetchSpecialitiesList();
        fetchCompanysize();
        // fetchVatRegion();
    }, [fetchCompanysize]);

    return (
        <section className={classes.section}>
            {isLoading && (
                <div
                    className={`row justify-content-center  align-items-center ${classes["min-h"]}`}
                >
                    <Loading className={classes.loading} />
                </div>
            )}
            {(!isLoading) && (
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-12 p-0">
                            <div className={classes["modal-header"]}>
                                <div className={classes["modal-title"]}>Edit Company Info</div>
                                <button
                                    className={`btn`}
                                    onClick={() => { dispatch(showEditCompanyInfo(false)) }}
                                >
                                    <img src={closeModal} />
                                </button>
                            </div>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <div className={classes["description-wrapper"]}>
                                    <div className={classes["step-card"]}>
                                        <div className={classes["section-title"]}>
                                            About Company
                                        </div>
                                        <div className={classes["section-subtitle"]}>
                                            This information will be publicly displayed on your profile
                                        </div>
                                        <UploadImageInput
                                            validation={{
                                                required: true,
                                            }}
                                            input={{
                                                type: "file",
                                                name: "profilePicture",
                                            }}
                                            errors={errors}
                                            register={register}
                                            setValue={setValue}
                                            getValues={getValues}
                                            //previewdValue={generalInfo?.profilePicture}
                                            previewdValue={getValues('profilePicture')}
                                        />

                                        <div className={classes["input-container"]}>
                                            <SimpleInput
                                                validation={{
                                                    required: true,
                                                }}
                                                input={{
                                                    type: "text",
                                                    label: "Company Name*",
                                                    name: "companyName",
                                                    placeholder: "Company Name",
                                                    id: "Company Name",
                                                    autoComplete: "off",
                                                }}
                                                errors={errors}
                                                register={register}
                                            />
                                            <SingleTextRichArea
                                                validation={{
                                                    required: true,
                                                }}
                                                input={{
                                                    type: "text",
                                                    label: "Company Introduction*",
                                                    name: "companyIntroduction",
                                                    placeholder: "Company Introduction",
                                                    autoComplete: "off",
                                                }}
                                                getValues={getValues}
                                                setValue={setValue}
                                                errors={errors}
                                                register={register}
                                            />
                                        </div>
                                        {userRole && userRole !== "freelancer" && (
                                            <Fragment>

                                                <div className={classes["line"]}></div>
                                                <div className={classes["section-title"]}>
                                                    Employees Count
                                                </div>
                                                <div className={classes["section-subtitle"]}>
                                                    This information will be publicly displayed on your profile
                                                </div>

                                                <div className="mt-4"></div>


                                                {companySize.length > 0 && (
                                                    <div className={`${classes["company-size-container"]} mt-4 row justify-content-center`}>
                                                        {companySize.map((item) => (
                                                            <div key={item.id} className={`${classes["col-lg-equal"]} col-md-2 col-sm-6 col-6 mt-4 text-center`}>
                                                                <SelectableButton
                                                                    item={item}
                                                                    dispatchSelectedItem={getSelectedCompanySize}
                                                                    selectedId={selectedCompanySizeId}
                                                                />
                                                            </div>

                                                        ))}
                                                    </div>
                                                )}
                                                <div className={classes["line"]}></div>
                                                <div className={classes["section-title"]}>
                                                    Social Media
                                                </div>
                                                <div className={classes["section-subtitle"]}>
                                                    This information will  be publicly displayed on your profile
                                                </div>
                                                <div className={`${classes["input-container"]} ${classes["input-container-social"]} row`}>
                                                    <div className="col-md-12 col-12">
                                                        <SimpleInput
                                                            validation={{
                                                                required: false,
                                                                pattern: {
                                                                    value:
                                                                        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                                                                    message: "Please enter a valid url",
                                                                },
                                                            }}
                                                            input={{
                                                                type: "text",
                                                                label: "Website",
                                                                name: "website",
                                                                placeholder: "Website",
                                                                id: "Website",
                                                                autoComplete: "off",
                                                            }}
                                                            errors={errors}
                                                            register={register}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <SocialInput
                                                            validation={{
                                                                pattern: {
                                                                    value:
                                                                        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                                                                    message: "Please enter a valid url",
                                                                },
                                                            }}
                                                            input={{
                                                                type: "text",
                                                                label: "Linkedin link",
                                                                name: "linkedin",
                                                                placeholder: "linkedin",
                                                                id: "linkedinLink",
                                                                autoComplete: "off",
                                                            }}
                                                            errors={errors}
                                                            register={register}
                                                            icon={imgLinkedin}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <SocialInput
                                                            validation={{
                                                                pattern: {
                                                                    value:
                                                                        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                                                                    message: "Please enter a valid url",
                                                                },
                                                            }}
                                                            input={{
                                                                type: "text",
                                                                label: "Twitter link",
                                                                name: "twitter",
                                                                placeholder: "twitter",
                                                                id: "twitterLink",
                                                                autoComplete: "off",
                                                            }}
                                                            errors={errors}
                                                            register={register}
                                                            icon={imgTwitter}
                                                        />
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )}
                                    </div>

                                </div>
                                <div className={classes['action-wrapper']}>
                                    <button className={classes.cancel}
                                        onClick={() => { dispatch(showEditCompanyInfo(false)) }}
                                    >
                                        Cancel
                                    </button>
                                    <button className={`btn ${classes.submit}`} type="submit">
                                        Save Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default CompanyInfoModal;