const hasSingleValue = [
  "page",
  "ordering",
  "sort",
  "search",
  "text",
  "status",
  "regions",
  // "size",
  "request_size",
  "countries",
  "when",
  "source",
  "company_id",
];

const hasMultipleValue = ["specialities", "service_categories", "key_services", "multi_status", "multi_size"];

export const splitQueryParams = (queryParams) => {
  let params = new URLSearchParams(queryParams);
  console.log(params);
  let obj = {};

  hasSingleValue.forEach((item) => {
    if (params.get(item)) {
      obj[item] = params.get(item);
    }
  });
  hasMultipleValue.forEach((item) => {
    if (params.getAll(item).length !== 0) {
      obj[item] = params.getAll(item);
    }
  });

  console.log("obj", obj);
  return obj;
};

export const updateUniqueUrlParams = (urlParam) => {
  let search = window.location.search;
  for (const [key, value] of Object.entries(urlParam)) {
    let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    let separator = search.indexOf("?") !== -1 ? "&" : "?";
    if (search.match(re)) {
      search = search.replace(re, "$1" + key + "=" + value + "$2");
    } else {
      search = search + separator + key + "=" + value;
    }
  }
  return search;
};

export const updateDuplicatedUrlParams = (urlParam) => {
  let search = window.location.search;
  for (const [key] of Object.entries(urlParam)) {
    if (key !== "page") {
      search = resetQueryParams(key, search);
    }
  }
  let re;
  for (const [key, value] of Object.entries(urlParam)) {
    for (let index = 0; index < value.length; index++) {
      if (key === "page") {
        re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      } else {
        re = new RegExp("([?&])" + key + "=" + value[index], "i");
      }
      let separator = search.indexOf("?") !== -1 ? "&" : "?";
      if (search.match(re)) {
        search = search.replace(re, "$1" + key + "=" + value[index] + "$2");
      } else {
        search = search + separator + key + "=" + value[index];
      }
    }
  }
  return search;
};

export const updateUniqueApiParams = (key, value, previousQueryParams) => {
  let ApiQueryParams = "";
  let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  if (value !== null) {
    let separator = previousQueryParams.indexOf("?") !== -1 ? "&" : "?";
    if (previousQueryParams.match(re)) {
      ApiQueryParams = previousQueryParams.replace(
        re,
        "$1" + key + "=" + value + "$2"
      );
    } else {
      ApiQueryParams = previousQueryParams + separator + key + "=" + value;
    }
  } else {
    ApiQueryParams = previousQueryParams;
  }
  return ApiQueryParams;
};

export const resetQueryParams = (key, previousQueryParams) => {
  let splitedParams = previousQueryParams.split("&");
  const result = splitedParams.filter((word) => !word.includes(key));
  return result.join("&");
};

export const updateDuplicatedApiParams = (key, value, previousQueryParams) => {
  let ApiQueryParams = "";
  let re = new RegExp("([?&])" + key + "=" + value, "i");
  let separator = previousQueryParams.indexOf("?") !== -1 ? "&" : "?";
  if (previousQueryParams.match(re)) {
    ApiQueryParams = previousQueryParams.replace(re, key + "=" + value);
  } else {
    ApiQueryParams = previousQueryParams + separator + key + "=" + value;
  }
  return ApiQueryParams;
};

// export const splitQueryParams = (queryParams) => {
//   let params = new URLSearchParams(queryParams);
//   let page = params.get("page");
//   let ordering = params.get("ordering");
//   let sort = params.get("sort")
//   let search = params.get("search");
//   let text = params.get("text")
//   let status = params.get("status")
//   let regions = params.get("regions");
//   let size = params.get("size");
//   let request_size = params.get("request_size");
//   let countries = params.get("countries");
//   let specialities = params.getAll("specialities");
//   let serviceCategories = params.getAll("service_categories");
//   let keyServices = params.getAll("key_services");
//   return Object.assign({},
//     page === null ? null : {page},
//     ordering === null ? null : {ordering},
//     sort === null ? null : {sort},
//     search === null ? null : {search},
//     text === null ? null : {text},
//     regions === null ? null : {regions},
//     status === null ? null : {status},
//     size === null ? null : {size},
//     request_size === null ? null : {request_size},
//     countries === null ? null : {countries},
//     specialities.length === 0 ? null : {specialities},
//     serviceCategories.length === 0 ? null : {serviceCategories},
//     keyServices.length === 0 ? null : {keyServices}
//   )
// };
