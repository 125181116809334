import React from 'react';
import classes from './AllRequests.module.scss';
import { Link } from 'react-router-dom';
import leftArrow from '../../../assets/images/arrow/left-arrow.svg';
import CardV2 from '../../../components/UI/CardV2/CardV2';
import { useState, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import Pagination from "../../../components/UI/Pagination/Pagination";
import { getAccessToken } from "../../../utils/authentication";
import { API_REQUEST_URL, API_COMPANIES_URL } from "../../../constants/API";
import NotFoundItems from "../../../components/UI/NotFoundItems/NotFoundItems";
import Loading from "../../../components/UI/Loading/Loading";
import { useSelector, useDispatch } from "react-redux";
import { addToast } from "../../../redux/Toast/toastAction";
import { createRandomId } from "../../../utils/common";
import { updateIsAssetsChnage } from "../../../redux/User/userAction";
import CompanyAssetsCount from '../../../components/CompanyAssetsCount/CompanyAssetsCount';
import SortableTableRequests from '../../../components/UI/SortableTableRequests/SortableTableRequests';
import SimpleFormGroupLtr from '../../../components/UI/SimpleFormGroupLtr/SimpleFormGroupLtr';
import { LIMIT_ITEM_TABLE } from '../../../constants/Common';
import {
    updateUniqueApiParams,
    updateUniqueUrlParams,
    splitQueryParams,
    resetQueryParams,
} from "../../../utils/QueryParams";
import calculateOffset from "../../../utils/calculateOffset";
import DashboardSearchBox from "../../../components/DashboardSearchBox/DashboardSearchBox";


const AllRequests = () => {
    const [companyAssets, setCompanyAssets] = useState(null);
    const userCompany = useSelector((state) => state.user.userCompany);
    const getCompanyAssets = (data) => {
        // console.log(data, "getCompanyAssets");
        setCompanyAssets(data);
    };
    const fetchComapayAssets = () => {
        const token = getAccessToken();
        sendRequest(
            {
                url: `${API_COMPANIES_URL}/company/${userCompany.id}/assets/`,
                headers: { Authorization: `Bearer ${token}` },
            },
            getCompanyAssets
        );
    };
    useEffect(() => {
        if (userCompany) {
            fetchComapayAssets();
        }
    }, [userCompany]);

    const [sentRequestList, setSentRequestList] = useState([]);
    const [count, setCount] = useState(null);
    const [searchItem, setSearchItem] = useState(null);
    const [sentReqStatus, setSentReqStatus] = useState(null);
    const [sortItem, setSortItem] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const { sendRequest, isLoading } = useHttp();
    const [apiQueryParams, setApiQueryParams] = useState(`?limit=${LIMIT_ITEM_TABLE}`);
    const history = useHistory();
    const location = useLocation();

    const getSentRequestList = (data) => {
        console.log(data);
        setSentRequestList(data.results);
        setCount(data.count);
        window.scrollTo(0, 0);
    };

    const splitUrlQueryparams = useCallback(() => {
        const queryParams = window.location.search;
        const splitedQueryParams = splitQueryParams(queryParams);
        console.log(splitedQueryParams);
        setSentReqStates(splitedQueryParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setSentReqStates = (params) => {
        params.page ? setCurrentPage(parseInt(params.page)) : setCurrentPage(1);
        params.ordering ? setSortItem(params.ordering) : setSortItem(null);
        params.search ? setSearchItem(params.search) : setSearchItem(null);
        params.status ? setSentReqStatus(params.status) : setSentReqStatus(null);
        createOffset(params);
    };

    const createOffset = (params) => {
        let offset;
        // if page is not equal 1
        if (params.page) {
            offset = calculateOffset(parseInt(params.page), LIMIT_ITEM_TABLE);
            delete params.page;
        } // page =1 (page load for first time)
        else {
            offset = calculateOffset(1, LIMIT_ITEM_TABLE);
        }
        params["offset"] = offset;
        createApiQueryParams(params);
    };

    const createApiQueryParams = (params) => {
        let updatedQueryParams = apiQueryParams;
        for (let key in params) {
            updatedQueryParams = updateUniqueApiParams(
                `${key}`,
                params[key],
                updatedQueryParams
            );
        }
        setApiQueryParams(updatedQueryParams);
        fetchSentRequestList(updatedQueryParams);
    };

    const fetchSentRequestList = (updatedQueryParams) => {
        const accessToken = getAccessToken();
        setCount(null);
        sendRequest(
            {
                url: `${API_REQUEST_URL}user-requests/${updatedQueryParams}`,
                headers: { Authorization: `Bearer ${accessToken}` },
            },
            getSentRequestList
        );
    };

    const resetPage = () => {
        setCurrentPage(1);
        let offset = calculateOffset(1, LIMIT_ITEM_TABLE);
        let updatedQueryParams = updateUniqueApiParams(
            "offset",
            offset,
            apiQueryParams
        );
        setApiQueryParams(updatedQueryParams);
        return updatedQueryParams;
    };

    const updateUniqueUrlPage = (result) => {
        history.push({
            pathname: "/dashboard/all-requests",
            search: result,
        });
    };

    const getSentReqListByUniqueFilters = (newkey, newValue) => {
        let updatedQueryParams;
        updatedQueryParams = resetPage();
        // choose new filter
        if (newValue) {
            updatedQueryParams = updateUniqueApiParams(
                newkey,
                newValue,
                updatedQueryParams
            );
            const urlParams = {
                page: 1,
                [newkey]: newValue,
            };
            let result = updateUniqueUrlParams(urlParams);
            updateUniqueUrlPage(result);
        } // choose All item and remove it from url and API
        else {
            // reset page number to 1---------------------------------------
            const urlParams = { page: 1 };
            let result = updateUniqueUrlParams(urlParams);
            result = resetQueryParams(newkey, result);
            // remover filter from params of page---------------------------
            updateUniqueUrlPage(result);
            // remove filter from params of api----------------------------
            updatedQueryParams = resetQueryParams(newkey, updatedQueryParams);
        }
        setApiQueryParams(updatedQueryParams);
    };

    const getSearchSentReq = (searchItem) => {
        getSentReqListByUniqueFilters("search", searchItem);
        setSearchItem(searchItem);
    };
    const getSentReqStatus = (status) => {
        getSentReqListByUniqueFilters("status", status);
        setSentReqStatus(status);
    };
    const getSortItem = (item) => {
        getSentReqListByUniqueFilters("ordering", item);
        setSortItem(item);
    };

    const getActivatedPage = (page) => {
        if (currentPage !== page) {
            setCurrentPage(page);
            let offset = calculateOffset(page, LIMIT_ITEM_TABLE);
            let updatedQueryParams = updateUniqueApiParams(
                "offset",
                offset,
                apiQueryParams
            );
            setApiQueryParams(updatedQueryParams);
            const urlParams = { page };
            let result = updateUniqueUrlParams(urlParams);
            updateUniqueUrlPage(result);
        }
    };

    useEffect(() => {
        splitUrlQueryparams();
    }, [location, splitUrlQueryparams]);



    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-10 col-12 mt-5'>
                    <Link to="/dashboard/company-dashboard" className=''>
                        <div className={classes['back-to-dashboard']}>
                            <img src={leftArrow} alt="arrow" />
                            <span>Back to Dashboard</span>
                        </div>
                    </Link>
                </div>
                <div className='col-md-2 col-12 mt-5'>
                    <SimpleFormGroupLtr
                        className="mb-20"
                        label="Search Requests"
                        inputValue={searchItem}
                        dispatchInputValue={getSearchSentReq}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <CardV2 className={classes.card}>
                        <div className='row'>
                            <div className='col-6'>
                                <div className={classes['title']}>
                                    Requests Lists

                                </div>
                            </div>
                            <div className='col-6'>
                                <div className={classes['wrapper-company-asset']}>
                                    <CompanyAssetsCount
                                        assetType="request"
                                        companyAssets={companyAssets}
                                        dispatchSentReqStatus={getSentReqStatus}
                                    />
                                </div>

                            </div>
                        </div>

                        {sentRequestList && sentRequestList.length !== 0 && !isLoading && (
                            <SortableTableRequests
                                source={sentRequestList}
                                onSortItem={getSortItem}
                            />
                        )}
                        {sentRequestList && sentRequestList.length == 0 && (
                            <NotFoundItems className={classes["not-found"]} item="request" />
                        )}

                        {isLoading && (
                            <div className={classes['loading-wrapper']}>
                                <Loading className={classes.loading} />
                            </div>
                        )}
                    </CardV2>
                    {count > LIMIT_ITEM_TABLE && (
                        <Pagination
                            onActivatedPage={getActivatedPage}
                            count={count}
                            limit={LIMIT_ITEM_TABLE}
                            currentPage={currentPage}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AllRequests;