import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import classes from "./Solutions.module.scss";
import SolutionCard from "../../components/SolutionCardV2/SolutionCardV2";
import useHttp from "../../hooks/use-http";
import { API_SUBSCRIPTION } from "../../constants/API";
import { createRandomId } from "../../utils/common";
import { addToast } from "../../redux/Toast/toastAction";
import { getAccessToken } from "../../utils/authentication";
import Banner, { BANNER_TYPE_CLASSES } from "../../components/Banner/Banner";
import SolutionTable from "../../components/SolutionTable/SolutionTable";
import { useHistory } from "react-router-dom";
import Appointment from "../../components/Appointment/Appointment";
import AppointmentSubscription from "../../components/AppointmentSubscription/AppointmentSubscription";
import ConfirmModal from '../../components/UI/ConfirmModal/ConfirmModal';
import Loading from "../../components/UI/Loading/Loading";
const Solutions = () => {
    const history = useHistory();
    const { sendRequest, error, isLoading } = useHttp();
    const [solutionList, setSolutionList] = useState([]);
    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();
    const trialUser = (userCompany) => userCompany.used_trial
    const [isDisplayModal, setIsDisplayModal] = useState(false);
    const [mySolution, setMySolution] = useState([]);
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const userCompany = useSelector((state) => state.user.userCompany);

    const getMySolution = (data) => {
        setMySolution(data);
    };

    const fetchMySolution = useCallback(() => {
        if (isLoggedIn) {
            const token = getAccessToken();
            sendRequest({
                url: `${API_SUBSCRIPTION}/current-subscription/`,
                headers: { Authorization: `Bearer ${token}` },
            }, getMySolution);
        }

    }, [sendRequest, isLoggedIn]);


    const getSolutionList = (data) => {
        window.scrollTo(0, 0);
        setTotal(0);
        setSolutionList(data);
    };

    const fetchSolutionList = useCallback(() => {

        sendRequest({ url: `${API_SUBSCRIPTION}/solutions/` }, getSolutionList);
    }, [sendRequest]);


    const goToStripeUrl = (data) => {
        window.location.href = data.payment_url;
    };
    const connectStrip = (solutionId) => {
        if (solutionId === "confirm")
        {
            solutionId = 4;
            setIsDisplayModal(false);
        }
        else if (solutionId === "cancel") {
            setIsDisplayModal(false);
            return;
        }

        const token = getAccessToken();
        sendRequest(
            {
                url: "/api/v1/subscription/",
                method: "POST",
                body: {
                    solutions: solutionId,
                    redirect_url: window.location.origin + "/payment-result?success=true"
                },
                headers: { Authorization: `Bearer ${token}` },
            },
            goToStripeUrl
        );

    };
    const onclickHandler = (solutionId) => {
        if (isLoggedIn) {
            if (userCompany === null || userCompany === undefined) {
                dispatch(
                    addToast({
                        id: createRandomId(),
                        status: "error",
                        description: "You need to select your role first.",
                    })
                );
                return history.push('/guest/user-role')
            }
            if (solutionId === 4) {
                let disable = userCompany !== null ? userCompany.used_trial : false;
                if (!disable) {
                    setIsDisplayModal(true);
                    return;
                }
                else {
                    return connectStrip(solutionId);
                }
            }
            else
                return connectStrip(solutionId);
        }
        dispatch(
            addToast({
                id: createRandomId(),
                status: "error",
                description: "You need to login first.",
            })
        )
        history.push('/authentication/login')
    }


    useEffect(() => {
        fetchMySolution();
    }, [fetchMySolution]);

    useEffect(() => {
        fetchSolutionList();
    }, [fetchSolutionList]);

    useEffect(() => {
    }, [userCompany]);

    useEffect(() => {
        if (error) {
            if (error.status === 404 || error.status === 400) {
                dispatch(
                    addToast({
                        id: createRandomId(),
                        status: "error",
                        description: error.data.detail,
                    })
                );
            } else if (error.status === 500) {
                dispatch(
                    addToast({
                        id: createRandomId(),
                        status: "error",
                        description: "Unexpected Error occurred. Please try again.",
                    })
                );

            }

        }
    }, [dispatch, error]);


    return (
        <Fragment>
            {isDisplayModal && !isLoading && (
                <ConfirmModal
                    modalButtonText="Yes"
                    modalType="success"
                    modalText="Do you confirm to start 30 Day Trial?"
                    modalHeader="30 Day Trial"
                    onConfirm={connectStrip}
                />
            )}
            <div className={classes.content}>
                <div className={`${classes['table-title']} d-flex flex-column gap-1 gap-md-4 justify-content-center`}>
                    <h2>
                        Unlock advanced features
                    </h2>
                </div>
                <section className={`container  flex-grow ${classes.section}`}>
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-12">
                            <div className="row justify-content-center">
                                {solutionList.map((solutionItem) => {

                                    return (
                                        <div key={solutionItem.id} className="col-xl-4 col-lg-4 col-12">
                                            <SolutionCard
                                                solutionItem={solutionItem}
                                                onClickHandler={onclickHandler}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                </section>
                {isLoading && (
                    <div className={classes["loading-wrapper"]}>
                        <Loading className={classes.loading} />
                    </div>
                )}
                <section className={`container  flex-grow ${classes.section}`}>
                    <div className="row justify-content-center">
                        <div className="col-lg-11 col-12">
                            <AppointmentSubscription
                                title={"Book your FREE 15 min consultation call with us."}
                                link={"https://outlook.office365.com/owa/calendar/Pharmatching@zamann-pharma.com/bookings/s/c1VC86MlykGgZJoQNl824Q2"}
                                text={"Unsure which subscription plan suits you best? Take the first step towards optimized supplier outsourcing & project tendering and book a 15 min consultation call."}
                                buttonText={"Book a free consultation"}
                            />
                        </div>
                    </div>
                </section>

            </div>
        </Fragment>
    );
};

export default Solutions;
