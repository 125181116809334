import ShowMoreText from "react-show-more-text";
import classes from "./ReceivedApplicationItem.module.scss";
import Card from "../UI/CardV2/CardV2";
import { Link } from "react-router-dom";

const ReceivedApplicationItem = ({ receivedItem }) => {
  return (
    <Card className={classes.card}>
      <h4 className={classes["received-title"]}>
        {receivedItem.company_title}
      </h4>
      <div className={classes["title-border"]}>
        <span className={classes["categories-title"]}>Description</span>
      </div>
      <div dangerouslySetInnerHTML={{ __html: receivedItem?.text }}
        className="application-desc">
      </div>
      {/* <ShowMoreText
        lines={2}
        more="Show more"
        less="Show less"
        className={classes["application-desc"]}
        anchorClass={classes["more-less"]}
        expanded={false}
        truncatedEndingComponent={"... "}
      >
        {receivedItem.text}
      </ShowMoreText> */}
      {/* <div className={classes["seprate-line"]}></div> */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        {receivedItem.attachments.length !== 0 && (
          <span className={classes["attached-file"]}>
            {" "}
            {receivedItem.attachments.length} Files Attached
          </span>
        )}
        {receivedItem.attachments.length === 0 && (
          <span className={classes["attached-file"]}>
            No Attachments
          </span>
        )}

        <Link to={`/application-view/${receivedItem.id}`} className={`${classes["show-detail"]} btn`}>
          Show Details
        </Link>
      </div>
    </Card>
  );
};

export default ReceivedApplicationItem;
