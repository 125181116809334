import { useState, useEffect, useCallback, Fragment } from "react";
import { useSelector } from "react-redux";
import classes from "./Profile.module.scss";
import DisplayPersonalInformation from '../../../components/DisplayPersonalInformation/DisplayPersonalInformation';
import DisplayProfileInterests from '../../../components/DisplayProfileInterests/DisplayProfileInterests';
import EditProfileInterests from '../../../components/EditProfileInterests/EditProfileInterests';
import EditPersonalInformation from '../../../components/EditPersonalInformation/EditPersonalInformation';
import { API_NEWS_URL } from '../../../constants/API';
import useHttp from '../../../hooks/use-http';
import { getAccessToken } from '../../../utils/authentication';
import DisplayCompanyInformation from "../../../components/DisplayCompanyInformation/DisplayCompanyInformation";


const Profile = () => {
  const { sendRequest } = useHttp();
  const [favouriteWarningLetter, setFavouriteWarningLetter] = useState([]);
  const [isEditPersonalInfo, setIsEditPersonalInfo] = useState(false);
  const [isEditInterests, setIsEditInterests] = useState(false);
  const userProfile = useSelector((state) => state.user.userProfile);
  const userCompany = useSelector((state) => state.user.userCompany);
  const userRole = useSelector((state) => state.user.userRole);
  const isProfileChanged = useSelector((state) => state.user.isProfileChanged);

  const getFavouriteWarningLetter = (fav) => {
    setFavouriteWarningLetter(fav.interests);
  };

  const DisplayEditInterest = (item) => {
    setIsEditInterests(item);
  };

  const fetchFavWarningLetter = useCallback(() => {
    const token = getAccessToken();
    sendRequest(
      {
        url: `${API_NEWS_URL}fav_wl/`,
        headers: { Authorization: `Bearer ${token}` },
      },
      getFavouriteWarningLetter
    );
  }, [sendRequest]);

  useEffect(() => {
    fetchFavWarningLetter();
  }, [fetchFavWarningLetter]);

  useEffect(() => {
    if (isProfileChanged) {
      fetchFavWarningLetter();
    }
  }, [fetchFavWarningLetter, isProfileChanged]);

  return (
    <section className={classes.section}>
      <div className="container">
        <div className="row justify-content-center">
          {!isEditPersonalInfo && (
            <Fragment>
              <div className="col-lg-6 col-12">
                {userProfile && (
                  <DisplayPersonalInformation
                    userProfile={userProfile}
                    userCompany={userCompany}
                    userRole={userRole}
                    editPersonalInformationHandler={() => {
                      setIsEditPersonalInfo(true);
                    }}
                  />
                )}
              </div>
              <div className="col-lg-6 col-12">
                {userProfile && (
                  <DisplayCompanyInformation
                    userProfile={userProfile}
                    userCompany={userCompany}
                    userRole={userRole}
                    editPersonalInformationHandler={() => {
                      setIsEditPersonalInfo(true);
                    }}
                  />
                )}
              </div>

              {/* {!isEditInterests && userProfile && (
                <DisplayProfileInterests
                  userProfile={userProfile}
                  favouriteWarningLetter={favouriteWarningLetter}
                  onDisplayEditInterest={DisplayEditInterest}
                />
              )}
              {isEditInterests && (
                <EditProfileInterests
                  componentUsage="editProfile"
                  initialServiceCategories={
                    userProfile.interest
                      ? userProfile.interest.service_categories
                      : []
                  }
                  initialKeyServices={
                    userProfile.interest
                      ? userProfile.interest.key_services
                      : []
                  }
                  favouriteWarningLetter={favouriteWarningLetter}
                  backButtonHandler={() => {
                    setIsEditInterests(false);
                  }}
                />
              )} */}
            </Fragment>
          )}
          {isEditPersonalInfo && (
            <div className="col-12">
              {" "}
              <EditPersonalInformation
                profile={userProfile}
                backButtonHandler={() => {
                  setIsEditPersonalInfo(false);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </section >
  );
};

export default Profile;
