import classes from "./DropdownFilter.module.scss";

const DropdownFilter = (props) => {
  const { filterName, filterId, filterList, dispatchFilterItem } = props;

  const getFilterItem = (item) => {
    dispatchFilterItem(item);
  };

  let filterTitle = filterName;
  if (filterList.length > 0) {
    let result = filterList.find((item) => item.id === parseInt(filterId) );
    filterTitle = result ? result.title : filterName;
  }

  return (
    <div className={`dropdown ${classes.dropdown}`}>
      <button
        className={`btn dropdown-toggle ${classes["dropdown-button"]}`}
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span>{filterTitle}</span>
      </button>
      <ul
        className={`dropdown-menu ${classes.menus}`}
        aria-labelledby="dropdownMenuButton1"
      >
        <li>
          <button
            className={`dropdown-item ${classes.items}`}
            onClick={() => getFilterItem('')}
          >
            <span>All</span>
          </button>
        </li>
        {filterList.map((item) => (
          <li key={item.id}>
            <button
              className={`dropdown-item ${classes.items}`}
              onClick={() => getFilterItem(item.id)}
            >
              <span>{item.title}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownFilter;
