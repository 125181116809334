import moment from "moment";

export const detectShortestItem = (arr) => {
  let result;
  if (arr) {
    result = arr.reduce((a, b) => (a.title.length <= b.title.length ? a : b));
    return result.title;
  }
};

export const dateFormmater = (date) => {
  const indexOfT = date.indexOf("T");
  let result = indexOfT !== -1 ? date.slice(0, indexOfT) : date;
  return moment(result).format("MMM. DD YYYY");
};
export const dateFormmaterNormal = (date) => {
  const indexOfT = date.indexOf("T");
  let result = indexOfT !== -1 ? date.slice(0, indexOfT) : date;
  return moment(result).format("YYYY/MM/DD");
};

export const SeprateNumberWithComma = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const createRandomId = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

export const convertStringMemberToNumber = (arr) => {
  return arr.map((item) => parseInt(item));
};

export const convertNumberMemberToString = (arr) => {
  return arr.map((item) => `${(item)}`);
};

export const getAttachmentFileName = (str) => {

  const lastIndex = str.lastIndexOf('/');
  return str.substring(lastIndex + 1);
}

export const validateUrl = (url) => {
  if (url.indexOf("http://") !== 0 && url.indexOf("https://") !== 0) {
    url = "http://" + url;
  }
  return url;
}
