import React from "react";
import { useSelector } from "react-redux";

import BannerUserCart from "./BannerCards/BannerUserCart";
import BannerSlider from "./BannerCards/BannerSlider";
import BannerTeamProjects from "./BannerCards/BannerTeamProjects";

import classes from "./LoggedInBanner.module.scss";

import avatar from "../../../assets/images/Icons/avatar.svg";

const LoggedInBanner = () => {
  const { userProfile, userRole, userCompany } = useSelector(
    (state) => state.user
  );
  return (
    <div className={`container`}>     
      <div className="row pt-3 justify-content-between align-items-center">
        <BannerUserCart
          className="col-lg-4 mb-3"
          userRole={userRole}
          userCompany={userCompany}
          userProfile={userProfile}
        />
        <BannerSlider className="col-lg-8 mb-3" />
      </div>
      <BannerTeamProjects />
    </div>
  );
};

export default LoggedInBanner;
