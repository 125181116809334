import {
  SHOW_CREATE_REQUEST_MODAL,
  SHOW_EDIT_REQUEST_MODAL,
  SET_REQUEST_CURRENT_ID,
  SHOW_EDIT_COMPANY_INFO,
  SHOW_EDIT_COMPANY_EXPERTISE
} from "./modalActionType";

const initialState = {
  requestModal: false,
  requestEditModal: false,
  requestCurrentId: 0,
  editCompanyInfo: false,
  editCompanyExpertise: false,
};


const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CREATE_REQUEST_MODAL:
      return {
        ...state,
        requestModal: action.payload,
      };
    case SHOW_EDIT_REQUEST_MODAL:
      return {
        ...state,
        requestEditModal: action.payload,
      };
    case SET_REQUEST_CURRENT_ID:
      return {
        ...state,
        requestCurrentId: action.payload,
      };
    case SHOW_EDIT_COMPANY_INFO:
      return {
        ...state,
        editCompanyInfo: action.payload,
      };
    case SHOW_EDIT_COMPANY_EXPERTISE:
      return {
        ...state,
        editCompanyExpertise: action.payload,
      }

    default:
      return state;
  }
};

export default modalReducer;