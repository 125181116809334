import React, {Fragment, useState, useEffect} from 'react';
import classes from './RequestViewDetails.module.scss';
import ShowMoreText from "react-show-more-text";
import fileIcon from "../../assets/images/Icons/file.svg";
import {dateFormmater, detectShortestItem} from "../../utils/common";
import RequestViewsChart from "../RequestViewsChart/RequestViewsChart";

const RequestViewDetails = ({requestInfo, userCompany = null}) => {
    const [activeShowMore, setActiveShowMore] = useState(true);
    const [maxHeight, setMaxHeight] = useState(true);
    useEffect(() => {
        const height = document.getElementById('div-desc')?.clientHeight;
        if (height && height < 100)
            setActiveShowMore(false);
    }, []);
    return (
         userCompany ? (
             <Fragment>
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-12">
                        <div className={`${classes["wrapper"]} ${classes["requestDescription"]}`}>
                            <div className={classes["title"]}>Request Description</div>
                            <div className={classes["description"]}>
                                <div
                                    id='div-desc'
                                    dangerouslySetInnerHTML={{__html: requestInfo.content}}
                                    style={{maxHeight: maxHeight ? '100px' : 'none'}}
                                    className={classes["application-desc"]}
                                >
                                </div>
                                {activeShowMore && (
                                    <div
                                        className={classes['show-more']}
                                        onClick={() => setMaxHeight(!maxHeight)}
                                    >
                                        {maxHeight && ('Show more')}
                                        {!maxHeight && ('Show less')}
                                    </div>
                                )}
                                {/* <ShowMoreText

                        lines={8}
                        more="Read more"
                        less="Show less"
                        className={classes["application-desc"]}
                        anchorClass={classes["more-less"]}
                        expanded={false}
                        truncatedEndingComponent={"... "}
                    >
                        {requestInfo.content}
                    </ShowMoreText> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 col-12">
                        <RequestViewsChart requestInfo={requestInfo}/>
                    </div>
                </div>

                <div className={`${classes["wrapper"]} ${classes["second"]}`}>
                    <div className={classes["title-file"]}>
                        <div className={classes["title"]}>Request Details</div>
                        <div className={classes["file"]}>
                            {requestInfo.attachments.length > 0 && (
                                <a
                                    href={requestInfo.attachments}
                                    target="_blank" rel="no noreferrer"
                                >
                                    <img src={fileIcon}/>
                                </a>
                            )}

                        </div>
                    </div>
                    <div className={classes["details-wrapper"]}>
                        {requestInfo.is_anonymous ? (
                            <div className={classes["anon-wrapper"]}>
                                <h4>
                                    Anonymous Request
                                </h4>
                                <p>
                                    This is an anonymous request. If the requestor accepts your application, their information (company name, contact information, etc.) will be shown to you.
                                </p>

                            </div>
                        ) : (
                            <div className={classes["requester-wrapper"]}>
                            <div className={classes["requester-label"]}>
                                Requester:
                            </div>
                            <div className={classes["requester-logo"]}>
                                <a href={'/company/' + requestInfo.company}>
                                    <div
                                        style={{backgroundImage: `url(${requestInfo.company_logo})`}}
                                        className={classes["company-logo"]}
                                    ></div>
                                </a>
                            </div>
                            <div className={classes["requester-name"]}>
                                <a href={'/company/' + requestInfo.company}>
                                    {requestInfo.company_title}
                                </a>
                            </div>
                        </div>
                        )}
                        <div className={classes["duedate-wrapper"]}>
                            <div className={classes["duedate-label"]}>
                                Due Date:
                            </div>
                            <div className={classes["duedate-date"]}>
                                {dateFormmater(requestInfo.due_date)}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
         ) : (
             <Fragment>
            <div className={classes["wrapper"]}>
                <div className={classes["title"]}>Request Description</div>
                <div className={classes["description"]}>
                    <div
                        id='div-desc'
                        dangerouslySetInnerHTML={{ __html: requestInfo.content }}
                        style={{ maxHeight: maxHeight ? '100px' : 'none' }}
                        className={classes["application-desc"]}
                    >
                    </div>
                    {activeShowMore && (
                        <div
                            className={classes['show-more']}
                            onClick={() => setMaxHeight(!maxHeight)}
                        >
                            {maxHeight && ('Show more')}
                            {!maxHeight && ('Show less')}
                        </div>
                    )}
                    {/* <ShowMoreText

                        lines={8}
                        more="Read more"
                        less="Show less"
                        className={classes["application-desc"]}
                        anchorClass={classes["more-less"]}
                        expanded={false}
                        truncatedEndingComponent={"... "}
                    >
                        {requestInfo.content}
                    </ShowMoreText> */}
                </div>
            </div>
            <div className={`${classes["wrapper"]} ${classes["second"]}`}>
                <div className={classes["title-file"]}>
                    <div className={classes["title"]}>Request Details</div>
                    <div className={classes["file"]}>
                        {requestInfo.attachments.length > 0 && (
                            <a
                                href={requestInfo.attachments}
                                target="_blank" rel="no noreferrer"
                            >
                                <img src={fileIcon} />
                            </a>
                        )}

                    </div>
                </div>
                <div className={classes["details-wrapper"]}>
                        {requestInfo.is_anonymous ? (
                            <div className={classes["anon-wrapper"]}>
                                <h4>
                                    Anonymous Request
                                </h4>
                                <p>
                                    This is an anonymous request. If the requestor accepts your application, their information (company name, contact information, etc.) will be shown to you.
                                </p>

                            </div>
                        ) : (
                            <div className={classes["requester-wrapper"]}>
                            <div className={classes["requester-label"]}>
                                Requester:
                            </div>
                            <div className={classes["requester-logo"]}>
                                <a href={'/company/' + requestInfo.company}>
                                    <div
                                        style={{backgroundImage: `url(${requestInfo.company_logo})`}}
                                        className={classes["company-logo"]}
                                    ></div>
                                </a>
                            </div>
                            <div className={classes["requester-name"]}>
                                <a href={'/company/' + requestInfo.company}>
                                    {requestInfo.company_title}
                                </a>
                            </div>
                        </div>
                        )}
                        <div className={classes["duedate-wrapper"]}>
                            <div className={classes["duedate-label"]}>
                                Due Date:
                            </div>
                            <div className={classes["duedate-date"]}>
                                {dateFormmater(requestInfo.due_date)}
                            </div>
                        </div>
                    </div>

            </div>
        </Fragment >

         )

    );
};

export default RequestViewDetails;