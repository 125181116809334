import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import classes from "./UserRole.module.scss";

import { ReactComponent as Freelancer } from "../../../assets/images/register/freelancer.svg";
import { ReactComponent as Join } from "../../../assets/images/register/join.svg";
import { ReactComponent as Create } from "../../../assets/images/register/create.svg";
import { ReactComponent as Congrats } from "../../../assets/images/register/congrat.svg";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";

const ROLES = [
	{
		title: "Freelancer",
		icon: <Freelancer />,
	},
	{
		title: "Join a Company",
		icon: <Join />,
	},
	{
		title: "Create Company",
		icon: <Create />,
	},
];

const UserRole = () => {
	const userProfile = useSelector((state) => state.user.userProfile);
	const userRole = useSelector((state) => state.user.userRole);
	const [link, setLink] = useState("/guest");
	const [selectedRole, setSelectedRole] = useState(null);

	const history = useHistory();

	useEffect(() => {
		if (userRole === "freelancer") setLink("/freelancer");
	}, [userRole]);

	const selectRole = (role) => {
		setSelectedRole(role);
		switch (role) {
			case "Freelancer":
				history.push(link + "/freelancer");
				break;
			case "Create Company":
				history.push(link + "/create-company");
				break;
			case "Join a Company":
				history.push(link + "/join-company");
				break;
			case "guest":
				history.push("/");
				break;
			default:
				break;
		}
	};

	return (
		<section className={classes["section-container"]}>
			{
				<div className={`${classes["box-container"]} container mx-3 mx-lg-0`}>
					<h1 className={classes["title"]}>
						{!userProfile?.pending_company
							? "Customize your experience"
							: "Request not approved!"}
					</h1>
					<p className={classes["desc"]}>Choose your preferred account type to access the platform:</p>
					<ul className={classes["roles"]}>
						{ROLES.map((role) => (
							<li
								className={classes["role"]}
								key={role.title}
								onClick={() => selectRole(role.title)}
							>
								{role.icon}
								<p>{role.title}</p>
							</li>
						))}
					</ul>
					<div
						role="button"
						tabIndex={-1}
						onClick={() => selectRole("guest")}
						className={classes["guest"]}
					>
						Continue as Guest
					</div>
				</div>
			}
		</section>
	);
};
export default UserRole;
