import React, { Fragment, useCallback, useEffect, useState } from 'react';
import classes from './PaymentInfo.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { getAccessToken } from "../../utils/authentication";
import { API_PAYMENT_URL } from "../../constants/API";
import useHttp from "../../hooks/use-http";
import { addToast } from "../../redux/Toast/toastAction";
import { createRandomId } from "../../utils/common";
import { useHistory } from "react-router-dom";

const PaymentInfo = () => {
    const [paymentAccounts, setPaymentAccounts] = useState([]);
    const { sendRequest, error } = useHttp();
    const history = useHistory();
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const dispatch = useDispatch();
    const userCompany = useSelector((state) => state.user.userCompany);
    const onclickHandler = () => {
        if (userCompany) {
            window.open(userCompany.stripe_portal_url, "_blank", "noreferrer");
        } else {
            // history.push('/subscription')
        }
    }

    const getPaymentAccount = (data) => {
        if (data) {
            setPaymentAccounts(data);
        }
    };
    const fetchPaymentAccount = useCallback(() => {
        if (isLoggedIn) {
            const token = getAccessToken();
            sendRequest({
                url: `${API_PAYMENT_URL}payment-account/`,
                headers: { Authorization: `Bearer ${token}` },
            }, getPaymentAccount);
        }

    }, [sendRequest, isLoggedIn]);
    useEffect(() => {
        fetchPaymentAccount();
    }, [fetchPaymentAccount]);

    useEffect(() => {
        console.log('usercompany:', userCompany);
    }, [userCompany]);

    useEffect(() => {
        if (error) {
            if (error.status === 404 || error.status === 400) {
                dispatch(
                    addToast({
                        id: createRandomId(),
                        status: "error",
                        description: error.data.detail,
                    })
                );
            } else if (error.status === 500) {
                dispatch(
                    addToast({
                        id: createRandomId(),
                        status: "error",
                        description: "Unexpected Error occurred. Please try again.",
                    })
                );

            }

        }
    }, [dispatch, error]);

    return (
        <div>
            <div className={classes['title']}>
                Payment Account Information
            </div>
            <div className={classes['bill-info']}>
                <div className={classes['title-wrapper']}>
                    <div className={classes['title']}>
                        Billing Information
                    </div>
                    <div className={classes['edit-bill']} onClick={onclickHandler}>
                        Edit Billing Information
                    </div>
                </div>
                <div className={classes['info-wrapper']}>
                    <div className={classes.key}>Email</div>
                    <div className={classes.val}>{userCompany ? userCompany.owner_email : ''}</div>
                </div>
                <div className={classes['info-wrapper']}>
                    <div className={classes.key}>Billing Address</div>
                    <div className={classes.val}>                       
                        {(userCompany && userCompany.address) ? `${userCompany.address}, ${userCompany.zip_code}, ${userCompany.town}` : '-'} {(userCompany && userCompany.country_title) ? (' ' + userCompany.country_title) : ''}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default PaymentInfo;