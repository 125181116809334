import classes from "./CompanyUsers.module.scss";
import Card from "../UI/CardV2/CardV2";
import defaultAvatar from "../../assets/images/Icons/profile-user.svg";
import star from "../../assets/images/Icons/star.svg";
import threeDots from "../../assets/images/Icons/3dot.svg";
import closeBtn from "../../assets/images/Icons/close-button-toast.svg";

const CompanyUsers = ({
  joinRequestCompany,
  companyParticipants,
  onPromoteUserToAdmin,
  onRemoveUserFromAdmin,
  onDeleteUser,
  onGrantAccessUser,
  onDenyUser,
  onDeactiveCompany,
  isAdmin,
}) => {
  return (
    <Card className={`container ${classes["company-users-card"]}`}>
      <div className={classes["company-users-title"]}>
        <span>Company Users</span>
      </div>
      <div className="col-12">
        <div className={classes["title-border"]}>
          <span className={classes["categories-title"]}>Approved Users</span>
        </div>
        <div className={`${classes["approved-users-box"]} table-responsive`}>
          <table
            className={`table table-borderless ${classes["approved-users-table"]}`}
          >
            <tbody>
              {companyParticipants.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}.</td>
                  <td>
                    <div className={`${classes["image-email"]} d-flex align-items-center`}>
                      <div
                        style={{
                          backgroundImage: `url(${
                            item.image ? item.image : defaultAvatar
                          })`,
                        }}
                        className={classes["user-picture"]}
                      ></div>
                      <span className={classes["user-name"]}>
                        {item.first_name} {item.last_name}
                      </span>
                    </div>
                  </td>
                  <td>{item.email} </td>
                  <td>{item.department} Department</td>
                  <td>
                    {item.is_admin ? (
                      <img src={star} alt="star" className={classes.star} />
                    ) : (
                      ""
                    )}
                  </td>
                  {companyParticipants.length > 1 && isAdmin && (
                    <td className="text-end">
                      <div className="btn-group dropstart mx-2">
                        <button
                          type="button"
                          className={`${classes["show-more-btn"]} btn p-0`}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={threeDots} alt="menu" />
                        </button>
                        <ul
                          className={`${classes["admin-menu"]} dropdown-menu`}
                        >
                          <img
                            src={closeBtn}
                            alt="close"
                            className={classes["close-btn"]}
                          />

                          {item.is_admin ? (
                            <li>
                              <button
                                className={`btn ${classes["remove-admin"]}`}
                                onClick={() => onRemoveUserFromAdmin(item.user)}
                              >
                                Remove Admin
                              </button>
                            </li>
                          ) : (
                            <li>
                              <button
                                className={`btn ${classes["remove-admin"]}`}
                                onClick={() => onPromoteUserToAdmin(item.user)}
                              >
                                Make Admin
                              </button>
                            </li>
                          )}

                          <li>
                            <button
                              className={`btn ${classes["delete-user"]}`}
                              onClick={() => onDeleteUser(item.id)}
                            >
                              Delete User
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  )}
                  {companyParticipants.length === 1 && isAdmin && (
                    <td>
                      <button
                        onClick={onDeactiveCompany}
                        className={`${classes["deactive-company"]} btn`}
                      >
                        Deactivate Company
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {isAdmin && (
        <div className="col-12">
          <div className={classes["title-border"]}>
            <span className={classes["categories-title"]}>Join Requests</span>
          </div>
          <div className={`${classes["request-users-box"]} table-responsive`}>
            <table
              className={`table table-borderless ${classes["request-users-table"]}`}
            >
              <tbody>
                {joinRequestCompany.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <span className={classes["row-number"]}>
                        {" "}
                        {index + 1}.
                      </span>
                    </td>
                    <td>
                      <div className="d-flex align-items-baseline">
                        <span className={classes["user-name"]}>
                          {item.first_name} {item.last_name}
                        </span>
                      </div>
                    </td>
                    <td>{item.email} </td>
                    <td>{item.department} Department</td>
                    <td className="text-md-end">
                      <button
                        className={`btn ${classes["deny-btn"]}`}
                        onClick={() => onDenyUser(item.id)}
                      >
                        Deny
                      </button>
                      <button
                        className={`btn ${classes["grant-btn"]}`}
                        onClick={() => onGrantAccessUser(item.id)}
                      >
                        Grant Access
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Card>
  );
};

export default CompanyUsers;
