import { useEffect, Fragment } from "react";
import "./Pagination.scss";
import arrowLeft from "../../../assets/images/pagination/page-arrow-left.svg";
import arrowRight from "../../../assets/images/pagination/page-arrow-right.svg";
import usePagination from "../../../hooks/use-pagination";

const Pagination = (props) => {
  const { onActivatedPage, count, limit, currentPage, countLabel, noCount } = props;
  const {totalButtons,displayedButtons , activatedPage , updateActivatedPage} = usePagination({count, limit, currentPage})

  const changePageNumberHandler = (number) => {
    updateActivatedPage(number);
  };

  const nextButtonHandler = () => {
    updateActivatedPage(activatedPage + 1);
  };

  const previousButtonHandler = () => {
    updateActivatedPage(activatedPage - 1);
  };

  useEffect(() => {
    onActivatedPage(activatedPage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activatedPage]);


  return (
    <div className="pagination-wrapper text-center">
      {!noCount && <div className="found-count">
        {count} {countLabel} found
      </div>
      }
      <ul className="pagination">
        <li className="page-item">
          <button
            className="btn previous-button"
            style={{ pointerEvents: activatedPage === 1 ? "none" : "" }}
            onClick={previousButtonHandler}
          >
            <img src={arrowLeft} alt="arrow left" />
          </button>
        </li>
        {activatedPage > 3 && totalButtons > 5 ? (
          <Fragment>
            <li
              className={`${activatedPage === 1 ? "active" : ""} page-item`}
              onClick={() => changePageNumberHandler(1)}
            >
              <button className="page-link">{1}</button>
            </li>
            <li className="page-item me-1">
              <strong>...</strong>
            </li>
          </Fragment>
        ) : (
          ""
        )}

        {displayedButtons.map((number) => (
          <li
            key={number}
            onClick={() => changePageNumberHandler(number)}
            className={`${activatedPage === number ? "active" : ""} page-item`}
          >
            <button className="page-link">{number}</button>
          </li>
        ))}
        {totalButtons > 5 && activatedPage + 2 < totalButtons ? (
          <Fragment>
            <li className="page-item me-1">
              <strong>...</strong>
            </li>
            <li
              className={`${
                activatedPage === totalButtons ? "active" : ""
              } page-item`}
              onClick={() => changePageNumberHandler(totalButtons)}
            >
              <button className="page-link">{totalButtons}</button>
            </li>
          </Fragment>
        ) : (
          ""
        )}

        <li className="page-item">
          <button
            className="btn next-button"
            style={{
              pointerEvents: activatedPage === totalButtons ? "none" : "",
            }}
            onClick={nextButtonHandler}
          >
            <img src={arrowRight} alt="arrow right" />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
