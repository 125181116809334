import { useState, useEffect } from "react";
import classes from "./ModalRoute.module.scss";
import { useSelector, useDispatch, useStore } from "react-redux";
import closeBtn from "../../../assets/images/Icons/close-button-toast.svg";
import Modal from 'react-modal';
import { showCreateRequestModal, showEditRequestModal, showEditCompanyInfo, showEditCompanyExpertise } from "../../../redux/Modal/modalAction";
import ModalCreateRequest from "../../ModalCreateRequest/ModalCreateRequest";
import ModalEditRequest from "../../ModalEditRequest/ModalEditRequest";
import CompanyInfoModal from "../../EditCompanyModals/CompanyInfoModal/CompanyInfoModal";
import CompanyExpertiseModal from "../../EditCompanyModals/CompanyExpertiseModal/CompanyExpertiseModal";

const ModalRoute = () => {
  const dispatch = useDispatch();
  const requestmodal = useSelector((state) => state.modal.requestModal);
  const requestEditModal = useSelector((state) => state.modal.requestEditModal);
  const editCompanyInfo = useSelector((state) => state.modal.editCompanyInfo);
  const editCompanyExpertise = useSelector((state)=>state.modal.editCompanyExpertise);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '880px',
      maxWidth: '90%',
      padding: '32px',
      borderRadius: '15px',
      border: 'none',
      minHeight: '610px',
    },
  };
  // useEffect(() => {
  //   setToastList(list);
  // }, [list]);
  function closeModal() {
    dispatch(showEditCompanyExpertise(false));
    dispatch(showCreateRequestModal(false));
    dispatch(showEditRequestModal(false));
    dispatch(showEditCompanyInfo(false));
  }
  useEffect(() => {

  }, []);
  return (
    <>
      <Modal
        isOpen={requestmodal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Create Request"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <ModalCreateRequest />
      </Modal>
      <Modal
        isOpen={requestEditModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Edit Draft Request"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <ModalEditRequest />
      </Modal>
      <Modal
        isOpen={editCompanyInfo}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Edit Company Info"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <CompanyInfoModal />
      </Modal>
      <Modal
        isOpen={editCompanyExpertise}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Edit Company Expertise"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <CompanyExpertiseModal />
      </Modal>
    </>
  );
};

export default ModalRoute;
