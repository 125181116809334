import classes from "./ButtonSortItems.module.scss";

const ButtonSortItems = (props) => {
  const { sortItem, dispatchSortItem, className, dropdownItems, dropdownTitle } = props;

  const getSortItemHandler = (item) => {
    dispatchSortItem(dropdownItems[item]);
  };

  const findSortItem = () => {
    let result = Object.keys(dropdownItems).find(key => dropdownItems[key] === sortItem);    
    return result ? result : "All"
  }

  return (
    <>
      <span className={classes["filter-title"]}>
        Filter: 
      </span>
      {        
        Object.keys(dropdownItems).map((key) => {
          if (key == findSortItem()) {
            return (
              <button key={key}
                onClick={() => getSortItemHandler(key)}
                className={`${classes["btn-filter"]} , ${classes["active"]}`}
              >
                <span>{key}</span>
              </button>
            )
          }
          else {
            return (
              <button key={key}
                onClick={() => getSortItemHandler(key)}
                className={`${classes["btn-filter"]}`}
              >
                <span>{key}</span>
              </button>
            )
          }

        }
        )
      }

    </>
  );
};

export default ButtonSortItems;